import {useCallback, useMemo, useState} from 'react';
import {useFetch, useOnFetchFinish, useWatchEffect} from 'hooks';
import {saveAs} from 'file-saver';
import {format} from 'lib/intl/format';
import {api} from 'api';
import {getErrorMessage} from 'lib/api/axios';
import {IUseDownloadProps} from './use-download.types';
import {useSnackbar} from 'components/ui/snackbar';
import {useTranslation} from 'lib/intl/i18n';

/**
 * Download the document
 *
 * @example
 * 
 *   import {useDownload} from 'hooks';
 *
 *   // ...
 * 
 *   const {download} = useDownload();
 *   
 *   handleDownload((document: IDocumentResponse) => {
 *     download(document);
 *   }, []);
 */
export const useDownload = (): IUseDownloadProps => {
  const documentDownload = useFetch(api.documentDownload);
  const [document, setDocument] = useState<IDocumentResponse | null>(null);
  const {showSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const download = useCallback((document: IDocumentResponse) => {
    setDocument(document);
  }, []);

  useWatchEffect(() => {
    if (document)
      documentDownload.fetch({documentId: document.id});
  }, [document]);

  useOnFetchFinish([documentDownload], () => {
    const {error} = documentDownload;
    if (error) {
      const message = getErrorMessage(error, t('hooks.ui.use_download.failed_download'));
      showSnackbar(message, {variant: 'error'});
    }
    else if (document) {
      const fileName = format.fileName(document.name, document.type);
      saveAs(documentDownload.data!, fileName);
    }
    setDocument(null);
  });

  return useMemo(() => ({
    download,
    pending: documentDownload.pending,
    error: documentDownload.error
  }), [download, documentDownload.error, documentDownload.pending]);
};