import {Reducer} from 'redux';
import {PasswordChangeAction, PasswordChangeActionTypes} from 'actions';
import {IPasswordChangeState} from './password-change.types';

const initialState: IPasswordChangeState = {
  pending: false,
  error: null
};

/** Password change */
export const passwordChange: Reducer<IPasswordChangeState, PasswordChangeAction> = (state = initialState, action): IPasswordChangeState => {
  switch (action.type) {
    case PasswordChangeActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case PasswordChangeActionTypes.success:
      return {
        ...state,
        pending: false
      };
    case PasswordChangeActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};