import React, {ChangeEvent, forwardRef, ReactElement, Ref, useCallback, useMemo, useState} from 'react';
import {useWatchEffect} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {LOCALE_DATE_TIME_FORMAT} from 'lib/intl/format';
import {IDateTimePickerProps} from './date-time-picker.types';
import {PopperProps} from '@material-ui/core/Popper';
import {MuiTextFieldProps} from '@material-ui/pickers/_shared/PureDateInput';
import {MobileDateTimePicker, DesktopDateTimePicker, DateTimePickerProps} from '@material-ui/pickers';
import {PickerField, PickerFieldProps} from 'components/ui/picker-field';
import {validate} from 'lib/global/validate';
import {parse} from 'lib/intl/parse';
import {format} from 'lib/intl/format';
import {Moment} from 'moment';

/**
 * Date and time picker
 *
 * @see src/views/app/kit-view/date-time-kit
 * @see https://v4-0-0-alpha-12.material-ui-pickers.dev/api/DateTimePicker
 *
 * @example
 *
 *   const [dateTime, setDateTime] = useState('');
 *
 *   const handleChange = useCallback((value: string) => {
 *     setDateTime(value);
 *   }, []);
 *
 *   // ...
 *
 *   <DateTimePicker
 *     label="Select a date and time"
 *     value={dateTime}
 *     onChange={handleChange}
 *   />
 */
export const DateTimePicker = forwardRef(function DateTimePicker <T extends PickerFieldProps = PickerFieldProps>(props: IDateTimePickerProps<T>, ref: Ref<HTMLInputElement>) {  
  const {
    name,
    placeholder,
    minDate,
    maxDate,
    minTime,
    maxTime,
    minDateTime,
    maxDateTime,
    value: valueProp,
    onChange,
    FieldComponent: FieldComponentProp,
    fieldProps,
    ...restProps
  } = props;
  const [open, setOpen] = useState(false);
  const [accept, setAccept] = useState(false);
  const [value, setValue] = useState(valueProp || '');
  const {t} = useTranslation();

  // Store the previous value on picker open
  const handleOpen = useCallback(() => {
    setValue(valueProp || '');
    setAccept(false);
    setOpen(true);
  }, [valueProp]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleAccept = useCallback(() => {
    setAccept(true);
  }, []);

  // Set new accepted value
  useWatchEffect(() => {
    if (!open && accept)
      onChange(value);
  }, [open]);

  // Convert the string value to a date object for use in the picker
  const pickerValue = useMemo<Date | null>(() => {
    if (validate.isDateTime(value))
      return parse.dateTime(value).toDate();
    return null;
  }, [value]);

  // Convert the date object to a string after selecting a date in the picker
  const handlePickerChange = useCallback((dateTime: unknown) => {
    if (!dateTime)
      setValue('');
    else if (validate.isDateTime(dateTime as Moment))
      setValue(format.dateTime(dateTime as Moment));
  }, []);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);
  
  const FieldComponent = useMemo(() => {
    return FieldComponentProp || PickerField;
  }, [FieldComponentProp]);

  const renderInput = useCallback((renderProps: MuiTextFieldProps) => {
    const fieldComponentProps = {
      ...renderProps,
      ...(fieldProps || {}),
      inputProps: {
        ...(renderProps.inputProps || {}),
        ...(fieldProps?.InputProps || {}),
        value: valueProp
      } as T['inputProps'],
      onChange: handleInputChange,
      placeholder,
      name
    } as T;
    return (<FieldComponent {...fieldComponentProps} />);
  }, [FieldComponent, fieldProps, handleInputChange, name, placeholder, valueProp]);

  // Bureaucracy...
  const renderDummyInput = useCallback(() => (<></>), []);

  // Set the default popper position
  const popperProps = useMemo(() => ({
    placement: 'bottom-end',
    ...(props.PopperProps || {})
  } as PopperProps), [props.PopperProps]);

  const pickerProps = useMemo<Omit<DateTimePickerProps, 'renderInput'>>(() => ({
    ...restProps,
    minDate: minDate && parse.date(minDate),
    maxDate: maxDate && parse.date(maxDate),
    minTime: minTime && parse.time(minTime),
    maxTime: maxTime && parse.time(maxTime),
    minDateTime: minDateTime && parse.dateTime(minDateTime),
    maxDateTime: maxDateTime && parse.dateTime(maxDateTime),
    value: pickerValue,
    onChange: handlePickerChange,
    disableMaskedInput: true,
    PopperProps: popperProps,
    inputFormat: LOCALE_DATE_TIME_FORMAT,
    clearable: true,
    allowSameDateSelection: true,
    toolbarTitle: t('components.ui.date_time_picker.title'),
    okText: t('components.ui.date_time_picker.ok'),
    cancelText: t('components.ui.date_time_picker.cancel'),
    clearText: t('components.ui.date_time_picker.clear'),
    todayText: t('components.ui.date_time_picker.today')
  }),
  [
    handlePickerChange,
    maxDate,
    maxDateTime,
    maxTime,
    minDate,
    minDateTime,
    minTime,
    pickerValue,
    popperProps,
    restProps,
    t
  ]);

  // Combine desktop input with mobile picker
  return (
    <>

      {/* Input */}

      <DesktopDateTimePicker
        ref={ref}
        {...pickerProps}
        renderInput={renderInput}
        open={false}
        onOpen={handleOpen}
      />

      {/* Picker */}

      <MobileDateTimePicker
        {...pickerProps}
        renderInput={renderDummyInput}
        open={open}
        onClose={handleClose}
        onAccept={handleAccept}
      />
    </>
  );
}) as <T extends PickerFieldProps = PickerFieldProps>(props: IDateTimePickerProps<T> & {ref?: Ref<HTMLInputElement>;}) => ReactElement;
// ^ Make JSX generic parameters work with forwardRef
//
// @see https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref