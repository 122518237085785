import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IPasswordResetConfirmRequest} from './password-reset-confirm.types';

/** Confirm user password reset */
export const passwordResetConfirm = async (request: IPasswordResetConfirmRequest) => {
  const response = await axios.post(urls.passwordResetConfirm(), request, {
    headers: headers.language()
  });
  return response.data;
};
