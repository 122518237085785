import './granted-partners.scss';
import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {useDialog} from 'hooks';
import {IGrantedPartnersProps} from './granted-partners.types';
import {GrantedPartnersList, OnRemove} from './granted-partners-list';
import {FormLabel} from 'components/ui/form-label';
import {IconLink} from 'components/ui/icon-link';
import AddIcon from '@material-ui/icons/AddBox';
import RemoveIcon from '@material-ui/icons/IndeterminateCheckBox';
import {AddPartnersDialog, OnAdd} from './add-partners-dialog';
import {Box} from 'components/ui/box';

/** Access demand granted partners */
export const GrantedPartners: FunctionComponent<IGrantedPartnersProps> = (props) => {
  const {t} = useTranslation();
  const {partners, onChange, disabled} = props;
  const addPartnersDialog = useDialog();

  const addPartners = useCallback<OnAdd>((partnersToAdd) => {
    const partnerIds = partners.map(({id}) => id);
    const newPartners = [...partners];
    for (const partner of partnersToAdd) {
      if (!partnerIds.includes(partner.id))
        newPartners.push(partner);
    }
    onChange(newPartners);
  }, [onChange, partners]);

  const removePartner = useCallback<OnRemove>((partnerId) => {
    const newPartners = partners.filter(({id}) => id !== partnerId);
    onChange(newPartners);
  }, [onChange, partners]);

  const removeAllPartners = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const classes = useMemo(() => {
    let classes = 'ui-granted-partners';
    if (disabled)
      classes += ' ui-granted-partners--disabled';
    return classes;
  }, [disabled]);

  return (
    <div className={classes}>
      <FormLabel>
        {t('components.demand.access_demand_form.partners.label')}
      </FormLabel>
      
      {/* Add partners */}
      
      <IconLink
        icon={<AddIcon />}
        onClick={addPartnersDialog.open}
      >
        {t('components.demand.access_demand_form.partners.add')}
      </IconLink>
      <AddPartnersDialog
        {...addPartnersDialog.props}
        addedPartners={partners}
        onAdd={addPartners}
      />

      {/* List of partners */}

      <Box mt={2}>
        <GrantedPartnersList
          partners={partners}
          onRemove={removePartner}
        />
      </Box>
      
      {/* Remove partner */}

      {partners.length > 1 && (
        <Box mt={2}>
          <IconLink
            icon={<RemoveIcon />}
            onClick={removeAllPartners}
          >
            {t('components.demand.access_demand_form.partners.remove_all')}
          </IconLink>
        </Box>
      )}
    </div>
  );
};