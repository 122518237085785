import {IUserActivationRequest} from 'api';

export enum UserActivationActionTypes {
  request = 'USER_ACTIVATION_REQUEST',
  success = 'USER_ACTIVATION_SUCCESS',
  failure = 'USER_ACTIVATION_FAILURE'
}

export interface IUserActivationRequestAction {
  type: UserActivationActionTypes.request;
  payload: IUserActivationRequest;
}

export interface IUserActivationSuccessAction {
  type: UserActivationActionTypes.success;
}

export interface IUserActivationFailureAction {
  type: UserActivationActionTypes.failure;
  payload: IRequestError;
}

export type UserActivationAction = (
  IUserActivationRequestAction |
  IUserActivationSuccessAction |
  IUserActivationFailureAction
);
