import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {t} from 'lib/intl/i18n';
import memoize from 'fast-memoize';

/**
 * Get password confirm validator
 *
 * @param password - Password to be confirmed
 */
export const passwordConfirm = memoize((password: string): IValidator => {

  /**
   * Validate that both passwords are equal
   *
   * @param input - Password for confirmation
   * @returns Error message or undefined
   */
  const passwordConfirm: IValidator = (input) => {
    const value = String(input || '').trim();
    if (value && value !== password)
      return t('lib.global.validate.password_confirm');
  };

  return passwordConfirm;
});

/**
 * Check that the password confirm validator doesn't return any error
 *
 * @param input - Password for confirmation
 * @param password - Password to be confirmed
 */
export const isPasswordConfirm: ValidatorQuery = (input, password: string): boolean => {
  return !!input && !passwordConfirm(password)(input);
};


