import {useMemo} from 'react';
import {IUseFilterChangeOptions} from './use-filter-change.types';
import {FilterValues} from '../with-filter.types';
import {useWatchEffect} from 'hooks';
import {FILTER_MESSAGE} from '../with-filter';

/** Filter onChange handler logic */
export const useFilterChange = <T extends FilterValues<T>>(options: IUseFilterChangeOptions<T>) => {
  const {
    values,
    transformValues,
    showMessage,
    onChange,
    pending,
    isFilterComplete
  } = options;

  const transformedValues = useMemo(() => {
    if (transformValues)
      return transformValues({...values});
    return {...values};
  }, [transformValues, values]);

  const message = useMemo(() => {
    return showMessage?.(values);
  }, [showMessage, values]);

  // Trigger onChange handler on any change in the values
  useWatchEffect(() => {
    if (onChange)
      onChange({
        values: transformedValues,
        isComplete: !pending && isFilterComplete(values) && !message,
        isPending: pending,
        [FILTER_MESSAGE]: message
      });
  }, [JSON.stringify(values), pending]);
};