import './user-info.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {Box} from 'components/ui/box';
import {Flexbox} from 'components/ui/flexbox';
import {Tooltip} from 'components/ui/tooltip';
import {Typography} from 'components/ui/typography';
import {UserMenu} from './user-menu';

/** Authenticated user info */
export const UserInfo: FunctionComponent = () => {
  const activePartnerId = useSelector(selectors.activePartnerId);
  const userDetail = useSelector(selectors.userDetail, shallowEqual);

  const activePartner = useMemo(() => {
    return userDetail?.partners.find((partner) => {
      return String(partner.id) === String(activePartnerId);
    });
  }, [activePartnerId, userDetail?.partners]);

  const partnerLabel = activePartner?.name || '...';

  return (
    <Flexbox
      alignItems="center"
      className="ui-user-info"
    >
      <Box
        mr={1}
        className="ui-user-info__detail"
      >

        {/* User */}

        <Typography variant="body2">
          <div className="ui-user-info__user">
            <Tooltip
              title={userDetail?.email || ''}
              enterDelay={500}
              arrow={false}
            >
              <span>
                {userDetail?.email}
              </span>
            </Tooltip>
          </div>
        </Typography>

        {/* Partner */}

        <Typography variant="body2">
          <div className="ui-user-info__partner">
            <Tooltip
              title={partnerLabel}
              enterDelay={500}
              arrow={false}
            >
              <span>
                {partnerLabel}
              </span>
            </Tooltip>          
          </div>
        </Typography>
      </Box>
      
      {/* User menu */}

      <Flexbox alignItems="center">
        <UserMenu />
      </Flexbox>
    </Flexbox>
  );
};