import {Reducer} from 'redux';
import {ISetThemeAction, ThemeActionTypes} from 'actions';
import {ThemeState} from './theme.types';
import {DEFAULT_THEME} from 'config/theme';

const initialState: ThemeState = DEFAULT_THEME;

/** Current theme name */
export const theme: Reducer<ThemeState, ISetThemeAction> = (state = initialState, action): ThemeState => {
  switch (action.type) {
    case ThemeActionTypes.set:
      return action.payload.theme;
    default:
      return state;
  }
};
