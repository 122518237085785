import {IAuthRefreshRequest, IAuthRefreshResponse} from './refresh.types';
import {axios} from 'lib/api/axios';
import {urls} from 'config/urls';

/** Refresh access token */
export const refresh = async (request: IAuthRefreshRequest) => {
  const response = await axios.post<IAuthRefreshResponse>(urls.token(), {
    grantType: 'refresh_token',
    refreshToken: request.refreshToken
  });
  return response.data;
};