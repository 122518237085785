import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IContactEntryListRequest, ContactEntryPagedListResponse} from './contact-entry-list.types';

/** Get a list of contact entries */
export const contactEntryList = async (request: IContactEntryListRequest) => {
  const {contactId, ...params} = request;
  const response = await axios.get<ContactEntryPagedListResponse>(urls.contactEntryList(contactId), {
    params,
    headers: headers.language()
  });
  return response.data;
};