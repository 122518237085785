import {Button} from '@material-ui/core';
import {useTranslation} from 'lib/intl/i18n';
import React, {FC} from 'react';

interface IDetailCookiesButtonsProps {
  wasCheckedAtLeastOne: boolean;
  approveSelectedCookies: () => void;
  refuseCookies: () => void;
}

export const DetailCookiesButtons: FC<IDetailCookiesButtonsProps> = ({
  wasCheckedAtLeastOne,
  approveSelectedCookies,
  refuseCookies
}) => {

  const {t} = useTranslation();
  const translationPath = 'components.main.app.cookies_popup.button';
  const onSelectedCookies = () => {
    if (wasCheckedAtLeastOne) {
      approveSelectedCookies();
    } else {
      refuseCookies();
    }
  };

  return (
    <Button
      variant="outlined" onClick={onSelectedCookies}
      style={{textTransform: 'none'}}>
      {wasCheckedAtLeastOne ? t(`${translationPath}.enable_selected`) : t(`${translationPath}.refuse`)}
    </Button>
  );
};
