import React, {forwardRef} from 'react';
import {AccordionDetailsProps} from './accordion-details.types';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';

/**
 * Accordion details
 *
 * @example
 *
 *   import {Accordion} from 'components/ui/accordion';
 *   import {AccordionSummary} from 'components/ui/accordion-summary';
 *   import {AccordionDetails} from 'components/ui/accordion-details';
 *   
 *   // ...
 *   
 *   <Accordion>
 *     <AccordionSummary id="my-accordion">
 *       First item
 *     </AccordionSummary>
 *     <AccordionDetails>
 *       Item content
 *     </AccordionDetails>
 *   </Accordion>
 *   
 *   <Accordion>
 *     <AccordionSummary id="my-accordion">
 *       Second item
 *     </AccordionSummary>
 *     <AccordionDetails>
 *       Item content
 *     </AccordionDetails>
 *   </Accordion>
 */
export const AccordionDetails = forwardRef<HTMLDivElement, AccordionDetailsProps>(function AccordionDetails(props, ref) {
  const {children, ...restProps} = props;

  return (
    <MuiAccordionDetails
      ref={ref}
      {...restProps}
    >
      {children}
    </MuiAccordionDetails>
  );
});