import {useMemo} from 'react';
import {useWatchEffect} from 'hooks';

/**
 * Effect to run when fetch is finished
 *
 * @param requestProgress - Any object with "pending" property
 * @param callback - Callback to fire when fetch is finished
 *
 * @example
 *
 *   import {useFetch, useOnFetchFinish} from 'hooks';
 *
 *   // ...
 * 
 *   const MyComponent: FunctionComponent = () => {
 *     const list = useFetch(someApi.fetchSomeList);
 *
 *     useEffect(() => {
 *       list.fetch(); // calls someApi.fetchSomeList
 *     }, []);
 *
 *     useOnFetchFinish([list], () => {
 *       if (list.error)
 *         console.error('Fetch failed: ', list.error);
 *       else
 *         console.log('Fetch succeeded: ', list.data);
 *     });
 *   };
 *
 * @example
 *
 *   // Wait for multiple requests
 * 
 *   useOnFetchFinish([someList, anotherList] () => {
 *     if (someList.error || anotherList.error)
 *       console.error('Fetch failed');
 *     else
 *       console.log('Fetch succeeded');
 *   });
 */
export const useOnFetchFinish = (requests: IRequestProgress[], callback: () => void) => {
  const pending = useMemo(() => {
    return requests.reduce((pending, request) => {
      return pending || request.pending;
    }, false);
  }, [requests]);

  useWatchEffect(() => {
    if (!pending)
      callback();
  }, [pending]);
};
