import {phone as validatePhone} from 'lib/global/validate/phone';
import {phone as parsePhone} from 'lib/intl/parse/phone';
import {logErrorOnDev} from 'lib/global/dev';

/**
 * Format phone number
 *
 * @param value - Phone number
 * @returns Formatted phone number
 */
export const phone = (value?: (number | string)) => {
  if (value === undefined || value === null)
    return '';
  if (typeof value === 'string') {
    const validationError = validatePhone(value);
    if (validationError) {
      logErrorOnDev(validationError);
      return value;
    }
  }
  try {
    const parsedPhone = parsePhone(String(value));
    return parsedPhone.formatInternational();
  }
  catch {
    return String(value);
  }
};
