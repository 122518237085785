import React, {FunctionComponent} from 'react';
import {IPasswordChangeDialogProps} from './password-change-dialog.types';
import {useTranslation} from 'lib/intl/i18n';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {PasswordChangeForm} from 'components/user/password-change-form';

/**
 * PasswordChange form dialog
 * 
 * @example
 * 
 *   const [dialogOpen, setDialogOpen] = useState(false);
 *   
 *   const toggleDialog = useCallback(() => {
 *     setDialogOpen((open) => !open);
 *   }, []);
 *   
 *   // ...
 *   
 *   <PasswordChangeDialog
 *     open={dialogOpen}
 *     onClose={toggleDialog}
 *   />
 */  
export const PasswordChangeDialog: FunctionComponent<IPasswordChangeDialogProps> = (props) => {
  const {open, onClose} = props;
  const {t} = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('components.user.password_change_dialog.title')}
    >
      <DialogContent>
        <PasswordChangeForm onSuccess={onClose} />
      </DialogContent>
    </Dialog>
  );
};