import './tooltip.scss';
import React, {forwardRef} from 'react';
import {TooltipProps} from './tooltip.types';
import MuiTooltip from '@material-ui/core/Tooltip';

/**
 * Show info box on hover
 * 
 * @see src/views/app/kit-view/tooltip-kit
 *
 * @example
 *
 *   <Tooltip title="Tooltip hint">
 *     <span>
 *       Hover me!
 *     </span>
 *   </Tooltip>
 */
export const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(function Tooltip(props, ref) {
  const {title, children, ...restProps} = props;

  if (!title)
    return children;

  return (
    <MuiTooltip
      {...restProps}
      ref={ref}
      title={title}
      disableInteractive
    >
      {children}
    </MuiTooltip>
  );
});

Tooltip.defaultProps = {
  placement: 'top',
  arrow: true
};
