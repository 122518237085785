import './filter-checkbox.scss';
import React, {forwardRef, useMemo} from 'react';
import {FilterCheckBoxProps} from './filter-checkbox.types';
import {Checkbox} from 'components/ui/checkbox';
// import {FormFieldError} from 'components/ui/filter-field-error';

/**
 * Filter wrapper for checkbox field
 * 
 * @example
 * 
 *   import {useContext} from 'react';
 *   import {FilterContext} from './my-filter-context';
 * 
 *   // ...
 * 
 *   const {getValues, setValues} = useContext(FilterContext);
 *   const {isChecked} = getValues();
 * 
 *   const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
 *     setValues({isChecked: checked});
 *   }, [setValues]);
 * 
 *   // ...
 * 
 *   <Checkbox
 *     label="I agree to the terms and conditions"
 *     checked={isChecked}
 *     onChange={handleChange}
 *   />
 */
export const FilterCheckbox = forwardRef<HTMLButtonElement, FilterCheckBoxProps>(function FilterCheckbox(props, ref) {
  const {/*TODO validate,*/className, ...restProps} = props;
  
  // const fieldProps = useMemo(() => ({
  //   ...props,
  //   type: 'checkbox'
  // }), [props]);

  // const {field, meta} = useFilterField<HTMLInputElement, boolean>(fieldProps);

  const classes = useMemo(() => {
    let classes = 'ui-filter-checkbox';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <div className={classes}>
      {/* TODO
      <Checkbox
        ref={ref}
        {...restProps}
        {...field}
      /> */}
      <Checkbox
        ref={ref}
        {...restProps}
      />
      {/* <FilterFieldError>
        {meta.touched && meta.error}
      </FilterFieldError> */}
    </div>
  );
});