import {time as parseTime} from 'lib/intl/parse/time';
import {time as validateTime} from 'lib/global/validate/time';
import {logErrorOnDev} from 'lib/global/dev';

export const LOCALE_TIME_FORMAT = 'LT';
export const LOCALE_TIME_SECONDS_FORMAT = 'LTS';

/**
 * Format time
 *
 * @param value - Date to format
 * @param [format] - Custom format
 * @returns Formatted time
 */
export const time = (value: AnyDate, format = LOCALE_TIME_FORMAT) => {
  const validationError = validateTime(value);
  if (validationError) {
    logErrorOnDev(validationError);
    return value !== undefined && value !== null ? value.toString() : value;
  }
  return parseTime(value).format(format);
};
