/** @file API related config */

import {Exception} from 'lib/global/exceptions';

const {PUBLIC_URL} = process.env;

export const BASE_URL = PUBLIC_URL ? new URL(PUBLIC_URL).pathname : '/';

if (!process.env.REACT_APP_API_URL)
  throw new Exception('Environment variable "REACT_APP_API_URL" is not defined. Please define it in one of the .env files.');

export const API_URL = process.env.REACT_APP_API_URL;