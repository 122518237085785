import './radio-kit.scss';
import React, {FunctionComponent, useCallback, ChangeEvent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {RadioGroup} from 'components/ui/radio-group';
import {Radio} from 'components/ui/radio';

/** Radiobutton control preview */
export const RadioKit: FunctionComponent = () => {
  const [value, setValue] = React.useState('option1');
  const {t} = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  return (
    <div className="ui-radio-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.radio.heading')}
      </Typography>
      <RadioGroup
        name="options"
        value={value}
        onChange={handleChange}
      >
        <Radio
          label={t('views.app.ui_kit.radio.label.first')}
          value="option1"
        />
        <Radio
          label={t('views.app.ui_kit.radio.label.second')}
          value="option2"
        />
        <Radio
          label={t('views.app.ui_kit.radio.label.third')}
          value="option3"
        />
        <Radio
          label={t('views.app.ui_kit.radio.label.disabled')}
          value="disabled"
          disabled
        />
      </RadioGroup>
    </div>
  );
};
