import React, {FunctionComponent} from 'react';
import {View} from 'components/ui/view';
import {InvoicesFilter, IInvoicesFilterValues} from './invoices-filter';
import {useFilterState, FilterOutput} from 'components/ui/filter';
import {InvoicesInfo} from './invoices-info';
import {FilteredInvoicesTable} from './filtered-invoices-table';

/** Invoices view */
export const InvoicesView: FunctionComponent = () => {
  const [filter, setFilter] = useFilterState<IInvoicesFilterValues>();

  return (
    <>
      <InvoicesFilter onChange={setFilter} />
      <View>
        <FilterOutput filter={filter}>
          <InvoicesInfo filter={filter} />
          <FilteredInvoicesTable filter={filter} />
        </FilterOutput>
      </View>
    </>
  );
};