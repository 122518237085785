import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IConsumptionPlaceListRequest, ConsumptionPlacePagedListResponse} from './consumption-place-list.types';

/** Get a list of consumption places */
export const consumptionPlaceList = async (request?: IConsumptionPlaceListRequest) => {
  const response = await axios.get<ConsumptionPlacePagedListResponse>(urls.consumptionPlaceList(), {
    params: request,
    headers: {
      ...headers.auth(),
      ...headers.language(),
      ...headers.partner()
    }
  });
  return response.data;
};