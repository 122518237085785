import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {validate, composeValidators, IValidator} from 'lib/global/validate';
import {useSnackbar} from 'components/ui/snackbar';
import {useTranslation} from 'lib/intl/i18n';
import {IFormValues} from './form-kit.types';
import {Typography} from 'components/ui/typography';
import {Form} from 'components/ui/form';
import {Grid} from 'components/ui/grid';
import {FormTextField} from 'components/ui/form-text-field';
import {FormIntegerField} from 'components/ui/form-integer-field';
import {PasswordConfirm} from './password-confirm';
import {FormDatePicker} from 'components/ui/form-date-picker';
import {FormTimePicker} from 'components/ui/form-time-picker';
import {FormDateTimePicker} from 'components/ui/form-date-time-picker';
import {FormDateRangePicker} from 'components/ui/form-date-range-picker';
import {Sliders} from './sliders';
import {FormRadioGroup} from 'components/ui/form-radio-group';
import {Radio} from 'components/ui/radio';
import {FormCheckbox} from 'components/ui/form-checkbox';
import {ButtonActions} from 'components/ui/button-actions';
import {Flexbox} from 'components/ui/flexbox';
import {Button} from 'components/ui/button';

/** Form control preview */
export const FormKit: FunctionComponent = () => {
  const {t} = useTranslation();
  const {showSnackbar} = useSnackbar();

  const initialValues = useMemo<IFormValues>(() => ({
    required: '',
    length: '',
    email: '',
    phone: '',
    number: '',
    integer: '',
    password: '',
    passwordConfirm: '',
    url: '',
    bankAccount: '',
    date: '',
    dateTime: '',
    time: '',
    dateRangeFrom: '',
    dateRangeTo: '',
    radioGroup: 'option1',
    checkbox: false,
    value: 20,
    valueRangeFrom: 33,
    valueRangeTo: 66
  }), []);
  
  const handleSubmit = useCallback((values: IFormValues) => {
    console.log(values);
    showSnackbar(t('views.app.ui_kit.form.submit.message'), {variant: 'success'});
  }, [showSnackbar, t]);

  // Custom validator. Make sure that the user agrees to the terms and conditions.
  const validateAgreement: IValidator<boolean> = useCallback((value) => {
    if (value === false)
      return t('views.app.ui_kit.form.agreement_error');
  }, [t]);

  return (
    <div className="ui-form-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.form.heading')}
      </Typography>
      <Form
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Grid container>
          
          {/* Required */}

          <Grid item md={6}>
            <FormTextField
              name="required"
              label={t('views.app.ui_kit.form.label.required')}
              required
            />
          </Grid>
          
          {/* Length */}

          <Grid item md={6}>
            <FormTextField
              name="length"
              label={t('views.app.ui_kit.form.label.length')}
              validate={composeValidators(
                validate.minLength(2),
                validate.maxLength(4)
              )}
            />
          </Grid>
          
          {/* E-mail */}

          <Grid item md={6}>
            <FormTextField
              name="email"
              label={t('views.app.ui_kit.form.label.email')}
              validate={validate.email}
            />
          </Grid>
          
          {/* Phone */}

          <Grid item md={6}>
            <FormTextField
              name="phone"
              label={t('views.app.ui_kit.form.label.phone')}
              validate={validate.phone}
            />
          </Grid>
          
          {/* Number */}

          <Grid item md={6}>
            <FormTextField
              name="number"
              label={t('views.app.ui_kit.form.label.number')}
              align="right"
              validate={validate.number}
            />
          </Grid>
          
          {/* Integer */}

          <Grid item md={6}>
            <FormIntegerField
              name="integer"
              label={t('views.app.ui_kit.form.label.integer')}
            />
          </Grid>
          
          {/* Password confirmation */}

          <PasswordConfirm />
      
          {/* URL */}

          <Grid item md={6}>
            <FormTextField
              name="url"
              label={t('views.app.ui_kit.form.label.url')}
              validate={validate.url}
            />
          </Grid>
          
          {/* Bank account */}

          <Grid item md={6}>
            <FormTextField
              name="bankAccount"
              label={t('views.app.ui_kit.form.label.bank_account')}
              validate={validate.bankAccount()}
            />
          </Grid>
         
          {/* Date */}

          <Grid item md={6}>
            <FormDatePicker
              name="date"
              label={t('views.app.ui_kit.form.label.date')}
            />
          </Grid>
          
          {/* Time */}

          <Grid item md={6}>
            <FormTimePicker
              name="dateTime"
              label={t('views.app.ui_kit.form.label.time')}
            />
          </Grid>
          
          {/* Date/time */}

          <Grid item md={6}>
            <FormDateTimePicker
              name="time"
              label={t('views.app.ui_kit.form.label.date_time')}
            />
          </Grid>
          
          {/* Date range */}

          <Grid item md={6}>
            <FormDateRangePicker
              fromName="dateRangeFrom"
              toName="dateRangeTo"
              label={t('views.app.ui_kit.form.label.date_range')}
            />
          </Grid>

          {/* Value sliders */}

          <Sliders />

          {/* Radio buttons */}

          <Grid item md={6}>
            <FormRadioGroup
              name="radioGroup"
              direction="row"
            >
              <Radio
                label={t('views.app.ui_kit.form.label.radio.first')}
                value="option1"
              />
              <Radio
                label={t('views.app.ui_kit.form.label.radio.second')}
                value="option2"
              />
            </FormRadioGroup>
          </Grid>
            
          {/* Checkbox */}

          <Grid item md={6}>
            <FormCheckbox
              name="checkbox"
              label={t('views.app.ui_kit.form.label.checkbox')}
              validate={validateAgreement}
            />
          </Grid>

          {/* Submit button */}

          <Grid item xs={12}>
            <Flexbox justifyContent="flex-end">
              <ButtonActions align="right">
                <Button type="submit">
                  {t('views.app.ui_kit.form.submit.button')}
                </Button>
              </ButtonActions>
            </Flexbox>
          </Grid>
        </Grid>
      </Form>
    </div>
  );
};
