import 'lib/intl/i18n';
import React, {FunctionComponent, StrictMode} from 'react';
import {Provider as Store} from 'react-redux';
import {store, persistor} from 'store';
import {PersistGate} from 'redux-persist/integration/react';
import {LocaleManager} from 'components/intl/locale-manager';
import {Theme} from 'components/ui/theme';
import {QueryParamProvider} from 'use-query-params';
import {Router} from 'components/main/router';

/** Application root */
export const Root: FunctionComponent = () => {
  return (
    <PersistGate persistor={persistor}>
      <Store store={store}>
        <StrictMode>
          <QueryParamProvider>
            <LocaleManager>
              <Theme>
                <Router />
              </Theme>
            </LocaleManager>
          </QueryParamProvider>
        </StrictMode>
      </Store>
    </PersistGate>
  );
};
