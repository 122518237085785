import {IPasswordResetRequest} from 'api';

export enum PasswordResetActionTypes {
  request = 'PASSWORD_RESET_REQUEST',
  success = 'PASSWORD_RESET_SUCCESS',
  failure = 'PASSWORD_RESET_FAILURE'
}

export interface IPasswordResetRequestAction {
  type: PasswordResetActionTypes.request;
  payload: IPasswordResetRequest;
}

export interface IPasswordResetSuccessAction {
  type: PasswordResetActionTypes.success;
}

export interface IPasswordResetFailureAction {
  type: PasswordResetActionTypes.failure;
  payload: IRequestError;
}

export type PasswordResetAction = (
  IPasswordResetRequestAction |
  IPasswordResetSuccessAction |
  IPasswordResetFailureAction
);
