import './filter-picker-field.scss';
import React, {ChangeEvent, FocusEvent, forwardRef, useCallback, useMemo} from 'react';
import {FilterPickerFieldProps} from './filter-picker-field.types';
import {PickerField} from 'components/ui/picker-field';
import {FormFieldError} from 'components/ui/form-field-error';

/**
 * Filter wrapper for picker field (internal)
 * 
 * @example
 * 
 *   import {DatePicker} from 'components/ui/date-picker';
 *   import {FormPickerField} from 'components/ui/filter-picker-field';
 *   
 *   // ...
 *   
 *   <DatePicker
 *     ...
 *     FieldComponent={FilterPickerField}
 *   />
 */
export const FilterPickerField = forwardRef<HTMLDivElement, FilterPickerFieldProps>(function FilterPickerField(props, ref) {
  const {
    className,
    onBlur,
    onChange,
    field,
    meta,
    errorMessage,
    ...restProps
  } = props;

  const handleBlur = useCallback((event: FocusEvent<unknown>) => {
    if (onBlur)
      onBlur(event);
    if (field.onBlur)
      field.onBlur(event);
  }, [field, onBlur]);

  const handleChange = useCallback((event: ChangeEvent<unknown>) => {
    if (onChange)
      onChange(event);
    if (field.onChange)
      field.onChange(event);
  }, [field, onChange]);

  const classes = useMemo(() => {
    let classes = 'ui-filter-picker-field';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <>
      <PickerField
        ref={ref}
        {...restProps}
        onBlur={handleBlur}
        onChange={handleChange}
        className={classes}
      />
      <FormFieldError>
        {errorMessage || meta.touched && meta.error}
      </FormFieldError>
    </>
  );
});