import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {IContractPlacesProps} from './contract-places.types';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import PlacesIcon from '@material-ui/icons/Place';
import {PlacesTable} from 'components/consumption/places-table';
import {Pagination, usePagination} from 'components/ui/pagination';

const ROWS_PER_PAGE = 7;

/** Contract consumption places */
export const ContractPlaces: FunctionComponent<IContractPlacesProps> = (props) => {
  const {contractId} = props;
  const places = useFetch(api.consumptionPlaceList);
  const {currentPage, setCurrentPage} = usePagination();
  const {t} = useTranslation();

  const fetchOptions = useMemo(() => ({
    contractId
  }), [contractId]);
  
  /** Get the data to be displayed in the places table */
  const fetchTableData = useCallback(() => {
    places.fetch({
      ...fetchOptions,
      page: currentPage - 1,
      size: ROWS_PER_PAGE
    });
  }, [currentPage, fetchOptions, places]);

  /** Get all places data for export */
  const fetchExportData = useCallback((paging?: IPagingRequest) => {
    return api.consumptionPlaceList({
      ...fetchOptions,
      ...paging
    });
  }, [fetchOptions]);

  useEffect(() => {
    fetchTableData();
  // eslint-disable-next-line
  }, []);
  
  const render = useCallback(() => (
    <>
      <PlacesTable
        places={places.data!.content}
        exportDataSource={fetchExportData}
      />
      <Pagination
        gutterTop
        pageSize={ROWS_PER_PAGE}
        totalItemCount={places.data!.totalElements}
        currentPage={currentPage}
        onChange={setCurrentPage}
      />
    </>
  ), [currentPage, fetchExportData, places.data, setCurrentPage]);

  return (
    <>

      {/* Heading */}

      <Typography
        variant="h2"
        color="primary"
        gutterBottom
      >
        <PlacesIcon />
        {t('views.user.contract.places.heading')}
      </Typography>

      {/* Table */}

      <WaitForFetch
        pending={places.pending}
        error={places.error}
        noData={!places.data?.content?.length}
        noDataMessage={t('views.user.contract.places.no_data')}
        onRetry={fetchTableData}
        render={render}
      />
    </>
  );
};