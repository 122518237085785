import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Banner} from 'components/ui/banner';
import failuresImage from './assets/failures.png';

/** Failures banner */
export const FailuresBanner: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <Banner
      image={failuresImage}
      text={t('views.app.home.banners.failures')}
      url="https://vesr.sk/sk/aktualne-poruchy"
    />
  );
};