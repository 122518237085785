import React, {forwardRef} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IPartnerSelectDialogProps} from './partner-select-dialog.types';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {PartnerSelectForm} from 'components/user/partner-select-form';

/**
 * Partner select dialog
 *
 * @example
 *
 *   const [dialogOpen, setDialogOpen] = useState(false);
 *   
 *   const toggleDialog = useCallback(() => {
 *     setDialogOpen((open) => !open);
 *   }, []);
 *   
 *   // ...
 *   
 *   <PartnerSelectDialog
 *     open={dialogOpen}
 *     onClose={toggleDialog}
 *   />
 */
export const PartnerSelectDialog = forwardRef<HTMLDivElement, IPartnerSelectDialogProps>(function PartnerSelectDialog(props, ref) {
  const {open, onClose} = props;
  const {t} = useTranslation();
  
  return (
    <Dialog
      ref={ref}
      open={open}
      onClose={onClose}
      title={t('components.user.partner_select_dialog.title')}
    >
      <DialogContent>
        <PartnerSelectForm onSuccess={onClose} />
      </DialogContent>
    </Dialog>
  );
});