import './list-item-icon.scss';
import React, {forwardRef} from 'react';
import {ListItemIconProps} from './list-item-icon.types';
import MuiListItemIcon from '@material-ui/core/ListItemIcon';

/**
 * List item icon
 * 
 * @example
 * 
 *   import {List, ListItem, ListItemIcon, ListItemText} from 'components/ui/list';
 *   
 *   // ...
 *   
 *   <List>
 *     <ListItem>
 *       <ListItemIcon>
 *         <InboxIcon />
 *       </ListItemIcon>
 *       <ListItemText primary="Inbox" />
 *     </ListItem>
 *     <ListItem button>
 *       <ListItemIcon>
 *         <SentIcon />
 *       </ListItemIcon>
 *       <ListItemText primary="Sent mails" />
 *     </ListItem>
 *   </List>
 */
export const ListItemIcon = forwardRef<HTMLDivElement, ListItemIconProps>(function ListItemIcon(props, ref) {
  const {className, children, ...restProps} = props;
  
  return (
    <MuiListItemIcon
      ref={ref}
      {...restProps}
      className="ui-list-item-icon"
    >
      {children}
    </MuiListItemIcon>
  );
});