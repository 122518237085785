import './demand-detail-button.scss';
import React, {FunctionComponent, useCallback, useState} from 'react';
import {IDemandDetailLinkProps} from './demand-detail-button.types';
import {IconButton} from 'components/ui/icon-button';
import DetailIcon from '@material-ui/icons/MoreHoriz';
import {DemandDetailDialog} from 'components/demand/demand-detail-dialog';

/** Demand detail button */
export const DemandDetailButton: FunctionComponent<IDemandDetailLinkProps> = (props) => {
  const {demand} = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  return (
    <>
      <IconButton
        className="ui-demand-detail-button"
        size="small"
        onClick={toggleOpen}
      >
        <DetailIcon />
      </IconButton>
      <DemandDetailDialog
        demand={demand}
        open={open}
        onClose={toggleOpen}
      />
    </>
  );
};