import './contract-detail-table.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {format} from 'lib/intl/format';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {IContractDetailTableProps} from './contract-detail-table.types';
import {useTranslation} from 'lib/intl/i18n';
import {DataTable, asTableColumns, asTableRows} from 'components/ui/data-table';
import {Link} from 'components/ui/link';

/** Contract detail table */
export const ContractDetailTable: FunctionComponent<IContractDetailTableProps> = (props) => {
  const {contract} = props;
  const partners = useSelector(selectors.userPartners, shallowEqual);
  const {t} = useTranslation();

  const columns = useMemo(() => asTableColumns({
    number: {
      label: t('components.user.contract_detail_table.number')
    },
    subject: {
      label: t('components.user.contract_detail_table.subject')
    },
    validFrom: {
      label: t('components.user.contract_detail_table.valid_from')
    },
    validTo: {
      label: t('components.user.contract_detail_table.valid_to')
    },
    name: {
      label: t('components.user.contract_detail_table.name')
    },
    address: {
      label: t('components.user.contract_detail_table.address')
    },
    companyIdentificationNumber: {
      label: t('components.user.contract_detail_table.company_identification_number')
    },
    taxIdentificationNumber: {
      label: t('components.user.contract_detail_table.tax_identification_number')
    },
    vatIdentificationNumber: {
      label: t('components.user.contract_detail_table.vat_identification_number')
    },
    email: {
      label: t('components.user.contract_detail_table.email')
    }
  }), [t]);

  const partner = useMemo(() => {
    return partners?.find((partner) => {
      return partner.id === contract.partnerId;
    });
  }, [contract.partnerId, partners]);

  const rows = useMemo(() => {
    const emails = partner?.emails || [];
    return asTableRows(columns, [{
      number: contract.number,
      subject: contract.subject,
      validFrom: format.date(contract.from),
      validTo: format.date(contract.to),
      name: partner?.name,
      address: partner?.address.singleLine,
      companyIdentificationNumber: partner?.companyIdentificationNumber,
      taxIdentificationNumber: partner?.taxIdentificationNumber,
      vatIdentificationNumber: partner?.vatIdentificationNumber,
      email: emails.map((email, id) => (
        <>
          <Link to={`mailto:${email}`}>
            {email}
          </Link>
          {id < emails.length - 1 && ', '}
        </>
      ))
    }]);
  }, [columns, contract, partner]);
  
  return (
    <DataTable
      className="ui-contract-detail-table"
      columns={columns}
      rows={rows}
      renderVertically
    />
  );
};