import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Get a list of invoice statuses */
const invoiceStatusListSaga = function* () {
  try {
    const response = yield* call(api.invoiceStatusList);
    yield put(actions.invoiceStatusListSuccess(response));
  }
  catch (error) {
    yield put(actions.invoiceStatusListFailure(error as IRequestError));
  }
};

export const invoiceStatusList = function* () {
  yield takeLatest(actions.InvoiceStatusListActionTypes.request, invoiceStatusListSaga);
};