/**
 * @file memoization helpers
 * 
 * @see https://github.com/caiogondim/fast-memoize.js
 */

import {Serializer} from 'fast-memoize';
import {IMemoizedFn} from './memoize.types';

/**
 * Allow memoization while using functions as argument values
 * 
 * @example
 * 
 *   // Default serializer converts any function to null:
 *   
 *   import memoize from 'fast-memoize';
 *   
 *   const a = () => {};
 *   const b = () => {};
 *   
 *   const memo = memoize((fn: () => void) => {
 *     return fn.name;
 *   });
 *   
 *   console.log(memo(a)); // a
 *   console.log(memo(b)); // a -> wrong result, should be "b"
 * 
 * @example
 * 
 *   // With custom serializer:
 *   
 *   import memoize from 'fast-memoize';
 *   import {fnArgsSerializer} from 'lib/global/memoize';
 *   
 *   const a = () => {};
 *   const b = () => {};
 *   
 *   const memo = memoize(
 *     (fn: () => void) => {
 *       return fn.name;
 *     },
 *     {serializer: fnArgsSerializer}
 *   );
 *   
 *   console.log(memo(a)); // a
 *   console.log(memo(b)); // b -> OK
 */
export const fnArgsSerializer = ((): Serializer => {
  let id = 0;
  return (args) => {
    if (!Array.isArray(args))
      args = [args];
    const argsWithFnIds = args.map((arg) => {
      if (Array.isArray(arg))
        return fnArgsSerializer(arg);
      if (typeof arg === 'function') {
        const fnArg = arg as IMemoizedFn;
        if (!fnArg.__memoizedId)
          fnArg.__memoizedId = ++id;
        return {__memoizedId: fnArg.__memoizedId};
      }
      return arg;
    });
    return JSON.stringify(argsWithFnIds);
  };
})();
