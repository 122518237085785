import './activation-form.scss';
import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {getErrorMessage} from 'lib/api/axios';
import {gtm} from 'lib/global/gtm';
import {IFormValues} from './activation-form.types';
import {useOnFetchFinish} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {useSnackbar} from 'components/ui/snackbar';
import {IActivationFormProps} from './activation-form.types';
import {Form} from 'components/ui/form';
import {ActivationFormFields} from './activation-form-fields';

/** Account activation form */
export const ActivationForm: FunctionComponent<IActivationFormProps> = (props) => {
  const {onSuccess} = props;
  const {showSnackbar} = useSnackbar();
  const userActivation = useSelector(selectors.userActivation, shallowEqual);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const initialValues = useMemo<IFormValues>(() => ({
    activationCode: '',
    login: '',
    password: '',
    passwordConfirm: '',
    agreement: false
  }), []);

  const handleSubmit = useCallback((values: IFormValues) => {
    const {activationCode, login, password} = values;
    dispatch(actions.userActivationRequest({
      activationCode,
      login,
      password
    }));
  }, [dispatch]);

  useOnFetchFinish([userActivation], () => {
    const {error} = userActivation;
    if (error) {
      const message = getErrorMessage(error, t('views.user.activation.form.failure'));
      showSnackbar(message, {variant: 'error'});
    }
    else {
      showSnackbar(t('views.user.activation.form.success'), {variant: 'success'});
      onSuccess();
      gtm.pushToDataLayer({
        event: 'hitType',
        eventCategory: 'uzivatel',
        eventAction: 'aktivace',
        eventLabel: 'done'
      });
    }
  });

  return (
    <Form
      className="ui-activatin-form"
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <ActivationFormFields />
    </Form>
  );
};