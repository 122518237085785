/**
 * Parse bank account code
 *
 * @param bankAccount - Bank account string
 * @returns Bank account code
 */
export const bankAccountCode = (bankAccount: string) => {
  const match = (bankAccount || '').match(/^.*\/([0-9]*)$/);
  return match && match.length === 2 ? match[1] : '';
};
