import {
  ITokenFailureAction,
  ITokenRequestAction,
  ITokenSuccessAction,
  TokenActionTypes
} from './auth-token.types';
import {IAuthTokenRequest, IAuthTokenResponse} from 'api';

/** Authenticate user (get access token) */
export const tokenRequest = (payload: IAuthTokenRequest): ITokenRequestAction => ({
  type: TokenActionTypes.request,
  payload
});

export const tokenSuccess = (payload: IAuthTokenResponse): ITokenSuccessAction => ({
  type: TokenActionTypes.success,
  payload: {
    ...payload,
    expiresAt: Date.now() + payload.expiresIn * 1000
  }
});

export const tokenFailure = (payload: IRequestError): ITokenFailureAction => ({
  type: TokenActionTypes.failure,
  payload
});
