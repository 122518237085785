import {HttpException} from 'lib/global/exceptions';
import {race, take, call} from 'typed-redux-saga/macro';
import {IWaitForFetchActions, IFailureAction} from './wait-for-fetch.types';

/**
 * Wait for the fetch action to finish
 *
 * @param actions - Object containing success and failure action types
 * @throws {HttpException}
 */
const waitForFetchSaga = function* (actions: IWaitForFetchActions) {
  const {failure} = yield* race({
    success: take(actions.success),
    failure: take(actions.failure)
  });
  if (failure)
    throw new HttpException((failure as IFailureAction).payload);
};

/**
 * Wait for the fetch action to finish
 *
 * @param actions - Object containing success and failure action types
 *
 * @example
 *
 *   try {
 *     yield put({type: 'FETCH_REQUEST'});
 *     yield waitForFetch({
 *       success: 'FETCH_SUCCESS',
 *       failure: 'FETCH_FAILURE'
 *     });
 *     console.log('Fetch succeeded.');
 *   }
 *   catch {
 *     console.log('Fetch failed');
 *   }
 */
export const waitForFetch = (actions: IWaitForFetchActions) => {
  return call(waitForFetchSaga, actions);
};
