import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {dateTime as parseDateTime} from 'lib/intl/parse/date-time';
import {t} from 'lib/intl/i18n';

/**
 * Validate date-time value
 *
 * @param input - Date-time to validate
 * @returns Error message or undefined
 */
export const dateTime: IValidator<AnyDate> = (input) => {
  if (input) {
    const dateIsValid = parseDateTime(input).isValid();
    if (!dateIsValid)
      return t('lib.global.validate.date_time.invalid');
  }
};

/**
 * Check that the date-time validator doesn't return any error
 *
 * @param input - Date-time value to check
 */
export const isDateTime: ValidatorQuery<AnyDate> = (input) => {
  return !!input && !dateTime(input);
};
