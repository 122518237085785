import './partner-select-view.scss';
import React, {FunctionComponent} from 'react';
import {View} from 'components/ui/view';
import {PartnerSelectForm} from 'components/user/partner-select-form';

/** Partner select view */
export const PartnerSelectView: FunctionComponent = () => {
  return (
    <View className="ui-partner-select-view">
      <div className="ui-partner-select-view__content">
        <PartnerSelectForm />
      </div>
    </View>
  );
};