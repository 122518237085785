import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IUserDetailResponse} from './user-detail.types';

/** Get user detail */
export const userDetail = async () => {
  const response = await axios.get<IUserDetailResponse>(urls.userDetail(), {
    headers: {
      ...headers.auth(),
      ...headers.language()
    }
  });
  return response.data;
};
