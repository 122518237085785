import {Reducer} from 'redux';
import {ISelectPartnerAction, SelectPartnerActionTypes} from 'actions';
import {IPartnerState} from './partner.types';

const initialState: IPartnerState = {
  activePartnerId: null
};

/** Active partner */
export const partner: Reducer<IPartnerState, ISelectPartnerAction> = (state = initialState, action): IPartnerState => {
  switch (action.type) {
    case SelectPartnerActionTypes.select:
      return {
        ...state,
        activePartnerId: action.payload.partnerId
      };
    default:
      return state;
  }
};
