import './add-partners-dialog.scss';
import React, {ChangeEvent, FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useDeepEffect, useWatchEffect} from 'hooks';
import diacritics from 'diacritics';
import {useTranslation} from 'lib/intl/i18n';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {IAddPartnersDialogProps} from './add-partners-dialog.types';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {DialogActions} from 'components/ui/dialog-actions';
import {Box} from 'components/ui/box';
import {TextField} from 'components/ui/text-field';
import SearchIcon from '@material-ui/icons/Search';
import {AddPartnersList, IPartnerItem, OnPartnerToggle, OnToggleAll} from './add-partners-list';
import {Button} from 'components/ui/button';
import AddIcon from '@material-ui/icons/Add';
import {Tooltip} from 'components/ui/tooltip';

/** Access demand partners dialog */
export const AddPartnersDialog: FunctionComponent<IAddPartnersDialogProps> = (props) => {
  const {open, onClose, addedPartners, onAdd} = props;
  const partners = useSelector(selectors.userPartners, shallowEqual);
  const [search, setSearch] = useState('');
  const [items, setItems] = useState<IPartnerItem[]>([]);
  const {t} = useTranslation();

  // Do not offer already added partners
  useDeepEffect(() => {
    const addedPartnersIds = addedPartners.map(({id}) => id);
    const remainingPartners = partners!.filter(({id}) => !addedPartnersIds.includes(id));
    const partnerItems = remainingPartners.map<IPartnerItem>((partner) => ({
      ...partner,
      checked: false
    }));
    setItems(partnerItems);
  }, [addedPartners, partners]);

  // Uncheck all items on dialog open
  useWatchEffect(() => {
    if (open) {
      const newItems = items.map((item) => ({
        ...item,
        checked: false
      }));
      setItems(newItems);
      setSearch('');
    }
  }, [open]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handlePartnerToggle = useCallback<OnPartnerToggle>((partnerId, checked) => {
    const newItems = items.map((item) => ({
      ...item,
      checked: item.id === partnerId ? checked : item.checked
    }));
    setItems(newItems);
  }, [items]);

  const handleToggleAll = useCallback<OnToggleAll>((checked) => {
    const newItems = items.map((item) => ({
      ...item,
      checked
    }));
    setItems(newItems);
  }, [items]);

  const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  }, []);

  const getSearchValue = useCallback((value: (string | number)) => {
    return diacritics.remove(String(value)).toLowerCase();
  }, []);

  const filteredPartners = useMemo(() => {
    const searchValue = getSearchValue(search);
    return items.filter((partner) => {
      const nameValue = getSearchValue(partner.name);
      const idValue = getSearchValue(partner.id);
      const nameMatch = nameValue.includes(searchValue);
      const idMatch = idValue.includes(searchValue);
      return nameMatch || idMatch;
    });
  }, [getSearchValue, items, search]);

  const handleAdd = useCallback(() => {
    const checkedPartners = filteredPartners.filter(({checked}) => checked);
    onAdd(checkedPartners);
    onClose();
  }, [filteredPartners, onAdd, onClose]);

  const submitDisabled = useMemo(() => {
    return filteredPartners.every(({checked}) => !checked);
  }, [filteredPartners]);
  
  return (
    <Dialog
      className="ui-add-partners-dialog"
      title={t('components.demand.access_demand_form.add_partners_dialog.title')}
      open={open}
      onClose={onClose}
      draggable={false}
    >
      <DialogContent>
        
        {/* Search */}
        
        <TextField
          value={search}
          onChange={handleSearchChange}
          prefix={<SearchIcon />}
          autoFocus
        />

        {/* Partners list */}

        <Box mt={2}>
          <AddPartnersList
            partners={filteredPartners}
            onPartnerToggle={handlePartnerToggle}
            onToggleAll={handleToggleAll}
          />
        </Box>
      </DialogContent>

      {/* Add partners */}

      <DialogActions>
        <Tooltip title={submitDisabled && t('components.demand.access_demand_form.add_partners_dialog.submit_hint')}>
          <span>
            <Button
              icon={<AddIcon />}
              disabled={submitDisabled}
              onClick={handleAdd}
            >
              {t('components.demand.access_demand_form.add_partners_dialog.add')}
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};