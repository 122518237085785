import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {paths} from 'config/router'; 
import {useWatchEffect, usePrevious} from 'hooks';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useFetch, useOnFetchSuccess} from 'hooks';
import {useFormikContext} from 'formik';
import {IFormValues} from '../activation-form.types';
import {IValidator, validate} from 'lib/global/validate';
import {api} from 'api';
import {useTranslation} from 'lib/intl/i18n';
import {Grid} from 'components/ui/grid';
import {FormTextField} from 'components/ui/form-text-field';
import {FormCheckbox} from 'components/ui/form-checkbox';
import {Link} from 'components/ui/link';
import {Box} from 'components/ui/box';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import SendIcon from '@material-ui/icons/Check';

/** Activation form fields */
export const ActivationFormFields: FunctionComponent = () => {
  const passwordValidation = useFetch(api.passwordValidation);
  const userActivation = useSelector(selectors.userActivation, shallowEqual);
  const {values, setFieldTouched, validateField} = useFormikContext<IFormValues>();
  const prevPassword = usePrevious(values.password);
  const [validationError, setValidationError] = useState<string | null>(null);
  const {t} = useTranslation();

  // Validate password
  useWatchEffect(() => {
    passwordValidation.fetch({password: values.password});
  }, [values.password]);

  useOnFetchSuccess([passwordValidation], () => {
    const {strongEnough, errorMessages} = passwordValidation.data!;
    if (strongEnough)
      setValidationError(null);
    else
      setValidationError(errorMessages![0]);
  });

  useEffect(() => {
    if (values.password || prevPassword) {
      setFieldTouched('passwordConfirm');
      validateField('passwordConfirm');
    }
  }, [prevPassword, setFieldTouched, validateField, values.password]);

  const validateAgreement: IValidator<boolean> = useCallback((value) => {
    if (value === false)
      return t('views.user.activation.form.agreement.error');
  }, [t]);

  return (
    <>
      <Grid
        container
        disabled={userActivation.pending}
      >
          
        {/* PID */}

        <Grid item md={6}>
          <FormTextField
            name="activationCode"
            label={t('views.user.activation.form.pid.label')}
            placeholder={t('views.user.activation.form.pid.placeholder')}
            required
            autoFocus
          />
        </Grid>
          
        {/* E-mail */}

        <Grid item md={6}>
          <FormTextField
            name="login"
            label={t('views.user.activation.form.email.label')}
            placeholder={t('views.user.activation.form.email.placeholder')}
            validate={validate.email}
            required
          />
        </Grid>

        {/* Password */}

        <Grid item md={6}>
          <FormTextField
            name="password"
            type="password"
            autoComplete="new-password"
            label={t('views.user.activation.form.password.value')}
            required
            errorMessage={validationError}
            pending={passwordValidation.pending}
          />
        </Grid>

        {/* Password confirmation */}

        <Grid item md={6}>
          <FormTextField
            name="passwordConfirm"
            type="password"
            label={t('views.user.activation.form.password.confirm')}
            required
            validate={validate.passwordConfirm(values.password)}
          />
        </Grid>

        {/* Terms and conditions */}

        <Grid item xs={12}>
          <FormCheckbox
            className="ui-activation-form__agreement"
            name="agreement"
            label={(
              <>
                {t('views.user.activation.form.agreement.label.read')}
                {' '}
                <Link
                  to={paths.conditions}
                  target="_blank"
                >
                  {t('views.user.activation.form.agreement.label.link')}
                </Link>
                {' '}
                {t('views.user.activation.form.agreement.label.agree')}
              </>
            )}
            validate={validateAgreement}
          />
        </Grid>
      </Grid>

      {/* Submit */}

      <Box mt={4}>
        <ButtonActions align="center">
          <Button
            type="submit"
            icon={<SendIcon />}
            pending={userActivation.pending}
            disabled={passwordValidation.pending || !!validationError}
          >
            {t('views.user.activation.form.submit')}
          </Button>
        </ButtonActions>
      </Box>
    </>
  );
};