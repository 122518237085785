import './user-menu.scss';
import React, {FunctionComponent, useCallback, useState, MouseEvent, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import memoize from 'fast-memoize';
import {useNavigate} from 'react-router-dom';
import {paths} from 'config/router';
import {actions} from 'actions';
import {useDispatch} from 'react-redux';
import {useTranslation} from 'lib/intl/i18n';
import {IconButton} from 'components/ui/icon-button';
import UserIcon from '@material-ui/icons/Person';
import {DataMenu, DataMenuItems} from 'components/ui/data-menu';
import EmailIcon from '@material-ui/icons/Email';
import PartnerIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Tune';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import {PartnerSelectDialog} from 'components/user/partner-select-dialog';

/** Authenticated user menu */
export const UserMenu: FunctionComponent = () => {
  const partners = useSelector(selectors.userPartners, shallowEqual);
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);
  const activePartnerId = useSelector(selectors.activePartnerId, shallowEqual);
  const userDetail = useSelector(selectors.userDetail, shallowEqual);
  const partnerSelect = userIsLoggedIn && activePartnerId === null;
  const [partnerDialogOpen, setPartnerDialogOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const activePartner = useMemo(() => {
    return userDetail?.partners.find((partner) => {
      return String(partner.id) === String(activePartnerId);
    });
  }, [activePartnerId, userDetail?.partners]);

  const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigateTo = useCallback(memoize((path) => () => {
    navigate(path);
    closeMenu();
  }), [navigate]);

  const handleLogout = useCallback(() => {
    dispatch(actions.logoutRequest({
      logoutReason: 'userLogout'
    }));
  }, [dispatch]);

  const togglePartnerDialog = useCallback(() => {
    setPartnerDialogOpen((open) => !open);
    closeMenu();
  }, [closeMenu]);

  const getMenuItems = useCallback((): DataMenuItems => [
    
    //--- User info
    
    {
      icon: (<EmailIcon />),
      label: userDetail?.email || '',
      className: 'ui-user-menu__info'
    },
    {
      icon: (<PartnerIcon />),
      label: activePartner?.name || '...',
      className: 'ui-user-menu__info'
    },
    {
      label: '-',
      className: 'ui-user-menu__info'
    },
    
    //--- Actions
    
    {
      icon: (<PartnerIcon />),
      label: t('components.main.app.header.user_info.user_menu.select_partner'),
      onClick: togglePartnerDialog,
      visible: !partnerSelect && partners && partners.length > 1
    },
    {
      icon: (<SettingsIcon />),
      label: t('components.main.app.header.user_info.user_menu.settings'),
      onClick: navigateTo(paths.settings),
      visible: !partnerSelect
    },
    {
      icon: (<LogoutIcon />),
      label: t('components.main.app.header.user_info.user_menu.logout'),
      onClick: handleLogout
    }
  ], [activePartner?.name, handleLogout, navigateTo, partnerSelect, partners, t, togglePartnerDialog, userDetail?.email]);

  return (
    <>
      <IconButton
        className="ui-user-menu"
        size="small"
        onClick={openMenu}      
      >
        <UserIcon className="ui-user-menu__icon" />
      </IconButton>
      <DataMenu
        open={menuAnchor !== null}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        items={getMenuItems()}
      />
      <PartnerSelectDialog
        open={partnerDialogOpen}
        onClose={togglePartnerDialog}
      />      
    </>
  );
};