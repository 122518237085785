import './invoices-table.scss';
import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {format} from 'lib/intl/format';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {useTranslation} from 'lib/intl/i18n';
import {IInvoicesTableProps} from './invoices-table.types';
import {
  DataTable,
  asTableColumns,
  mapTableRows,
  asTableExportColumns,
  mapTableExportData,
  getExportSource
} from 'components/ui/data-table';
import {InvoiceCommodity} from './invoice-commodity';
import {InvoiceStatus} from './invoice-status';
import {DownloadButton} from 'components/ui/download-button';
import {useMediaQuery} from '@material-ui/core';

/**
 * Invoices table
 * 
 * @example
 * 
 *   <InvoicesTable invoices={someInvoices} />
 */
export const InvoicesTable: FunctionComponent<IInvoicesTableProps> = (props) => {
  const {invoices, exportDataSource} = props;
  const invoiceStatusList = useSelector(selectors.invoiceStatusList, shallowEqual);
  const isMobile = useMediaQuery('(max-width: 1357px)');
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (!invoiceStatusList.data)
      dispatch(actions.invoiceStatusListRequest());
  }, [dispatch, invoiceStatusList.data]);
  
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const columns = useMemo(() => asTableColumns({
    commodity: {
      label: isMobile && t('components.invoice.invoices_table.column.commodity'),
      align: 'center'
    },
    number: {
      label: t('components.invoice.invoices_table.column.number')
    },
    dateRange: {
      label: t('components.invoice.invoices_table.column.date_range'),
      align: 'center'
    },
    createdDate: {
      label: t('components.invoice.invoices_table.column.created_date'),
      shrink: true,
      nowrapCell: true
    },
    dueDate: {
      label: t('components.invoice.invoices_table.column.due_date'),
      shrink: true,
      nowrapCell: true
    },
    amount: {
      label: t('components.invoice.invoices_table.column.amount'),
      align: 'right',
      bold: true
    },
    remains: {
      label: t('components.invoice.invoices_table.column.remains'),
      align: 'right',
      bold: true
    },
    type: {
      label: t('components.invoice.invoices_table.column.type')
    },
    status: {
      label: t('components.invoice.invoices_table.column.status'),
      align: 'center'
    },
    download: {
      label: isMobile && t('components.invoice.invoices_table.column.download'),
      align: 'center',
      shrink: true
    }
  }), [isMobile, t]);

  const rows = useMemo(() => mapTableRows(columns, invoices, (invoice) => ({
    commodity: (
      <InvoiceCommodity invoice={invoice} />
    ),
    number: invoice.number,
    dateRange: invoice.dateRangeDescription,
    createdDate: format.date(invoice.createdDate),
    dueDate: format.date(invoice.dueDate),
    amount: format.currency(invoice.amount.value, invoice.amount.currencyId),
    remains: format.currency(invoice.amount.value - invoice.payedAmount.value, invoice.amount.currencyId),
    type: invoice.typeDescription,
    status: (
      <InvoiceStatus
        statusId={invoice.statusId}
        showTooltip={!isMobile}
      />
    ),
    download: (
      <DownloadButton
        documents={invoice.document}
        showTooltip={!isMobile}
      />
    )
  })), [columns, invoices, isMobile]);

  const getStatusName = useCallback((statusId: string) => {
    const status = invoiceStatusList.data?.find((status) => status.id === statusId);
    if (status)
      return status.name;
    return t('components.invoice.invoices_table.unknown_status');
  }, [invoiceStatusList.data, t]);

  const exportData = useCallback(async () => {
    const exportSource = await getExportSource(exportDataSource || invoices);
    const exportColumns = asTableExportColumns({
      commodity: t('components.invoice.invoices_table.column.commodity'),
      number: t('components.invoice.invoices_table.column.number'),
      createdDate: t('components.invoice.invoices_table.column.created_date'),
      dueDate: t('components.invoice.invoices_table.column.due_date'),
      amount: t('components.invoice.invoices_table.column.amount'),
      remains: t('components.invoice.invoices_table.column.remains'),
      status: t('components.invoice.invoices_table.column.status')
    });
    return mapTableExportData(exportColumns, exportSource, (invoice) => ({
      commodity: invoice.commodityTypeDescription,
      number: invoice.number,
      createdDate: format.date(invoice.createdDate),
      dueDate: format.date(invoice.dueDate),
      amount: format.number(invoice.amount.value, {useGrouping: false}),
      remains: format.number(invoice.amount.value - invoice.payedAmount.value, {useGrouping: false}),
      status: getStatusName(invoice.statusId)
    }));
  }, [exportDataSource, getStatusName, invoices, t]);

  const render = useCallback(() => (
    <DataTable
      className="ui-invoices-table"
      columns={columns}
      rows={rows}
      exportData={exportData}
      exportFilename={t('components.invoice.invoices_table.export_filename')}
      responsiveWidth={1244}
    />
  ), [columns, exportData, rows, t]);

  return (
    <WaitForFetch
      pending={invoiceStatusList.pending}
      error={invoiceStatusList.error}
      noData={!invoiceStatusList.data}
      onRetry={fetchData}
      render={render}
      absolutePosition
    />
  );
};