import './pagination.scss';
import React, {FunctionComponent, useCallback, useMemo, useRef} from 'react';
import memoize from 'fast-memoize';
import {useTranslation} from 'lib/intl/i18n';
import {IPaginationProps} from './pagination.types';
import {PaginationContext, IPaginationContext} from './pagination-context';
import {useTheme} from 'components/ui/theme';
import {useContainerQuery} from 'hooks';
import {PaginationButton} from './pagination-button';
import LeftArrowIcon from '@material-ui/icons/KeyboardArrowLeft';
import RightArrowIcon from '@material-ui/icons/KeyboardArrowRight';
import {PaginationList} from './pagination-list';

/**
 * Pagination
 * 
 * @see src/views/app/kit-view/pagination-kit
 *
 * @example
 *
 *   import {usePagination, Pagination} from 'components/ui/pagination';
 *
 *   // ...
 *
 *   const {currentPage, setCurrentPage} = usePagination();
 *
 *   // ...
 *
 *   <Pagination
 *     pageSize={7}
 *     totalItemCount={693}
 *     currentPage={currentPage}
 *     onChange={setCurrentPage}
 *   />
 *
 * @see ./use-pagination
 */
export const Pagination: FunctionComponent<IPaginationProps> = (props) => {
  const {
    className,
    pageSize,
    totalItemCount,
    currentPage: currentPageProp,
    jumpBy,
    showTooltips: showTooltipsProp,
    gutterTop,
    onChange
  } = props;
  const theme = useTheme();
  const {t} = useTranslation();
  const containerRef = useRef(null);
  
  const shrinkPageList = useContainerQuery(containerRef, {
    minWidth: theme.width.smMin,
    maxWidth: theme.width.smMax
  });
  
  const isMobile = useContainerQuery(containerRef, {
    maxWidth: theme.width.xsMax
  });

  const showTooltips = useMemo(() => {
    return showTooltipsProp && !isMobile;
  }, [isMobile, showTooltipsProp]);  
  
  const pageCount = useMemo(() => {
    if (pageSize === 0)
      return 0;
    return Math.ceil(totalItemCount / pageSize);
  }, [pageSize, totalItemCount]);

  const applyConstraints = useCallback((page: number, min = 1, max = pageCount) => {
    let pageNumber = Math.max(page, min);
    pageNumber = Math.min(pageNumber, max);
    return pageNumber;
  }, [pageCount]);

  const currentPage = useMemo(() => {
    return applyConstraints(currentPageProp);
  }, [applyConstraints, currentPageProp]);
  
  const visiblePages = useMemo(() => {
    return isMobile || shrinkPageList ? 5 : 9;
  }, [isMobile, shrinkPageList]);

  const handlePageChange = useCallback((page: number) => {
    const targetPage = applyConstraints(page);
    onChange(targetPage);
  }, [applyConstraints, onChange]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goToPage = useCallback(memoize((page: number) => () => {
    handlePageChange(page);
  }), [applyConstraints, onChange]);
  
  const contextValue = useMemo<IPaginationContext>(() => ({
    pageCount,
    currentPage,
    visiblePages,
    handlePageChange,
    goToPage,
    showTooltips,
    applyConstraints
  }), [pageCount, currentPage, visiblePages, handlePageChange, goToPage, showTooltips, applyConstraints]);

  const classes = useMemo(() => {
    let classes = 'ui-pagination';
    if (isMobile)
      classes += ' ui-pagination--mobile';
    if (gutterTop)
      classes += ' ui-pagination--gutter-top';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, gutterTop, isMobile]);

  if (pageCount <= 1)
    return null;
    
  return (
    <PaginationContext.Provider value={contextValue}>
      <div
        ref={containerRef}
        className={classes}
      >

        {/* Page list */}

        {isMobile && (
          <div className="ui-pagination__mobile-list">
            <PaginationList />
          </div>
        )}

        <div className="ui-pagination__mobile-controls">

          {/* Jump backward */}

          <PaginationButton
            tooltip={t('components.ui.pagination.jump_backward')}
            className="ui-pagination__backward"
            disabled={currentPage <= 1}
            onClick={goToPage(currentPage - jumpBy!)}
          >
            <LeftArrowIcon />
            <LeftArrowIcon />
          </PaginationButton>

          {/* Prev page */}

          <PaginationButton
            tooltip={t('components.ui.pagination.prev_page')}
            disabled={currentPage <= 1}
            onClick={goToPage(currentPage - 1)}
          >
            <LeftArrowIcon />
          </PaginationButton>

          {/* Page list */}

          {!isMobile && (
            <PaginationList />
          )}

          {/* Next page */}

          <PaginationButton
            tooltip={t('components.ui.pagination.next_page')}
            disabled={currentPage >= pageCount}
            onClick={goToPage(currentPage + 1)}
          >
            <RightArrowIcon />
          </PaginationButton>

          {/* Jump forward */}

          <PaginationButton
            className="ui-pagination__forward"
            tooltip={t('components.ui.pagination.jump_forward')}
            disabled={currentPage >= pageCount}
            onClick={goToPage(currentPage + jumpBy!)}
          >
            <RightArrowIcon />
            <RightArrowIcon />
          </PaginationButton>
        </div>
      </div>
    </PaginationContext.Provider>
  );
};

Pagination.defaultProps = {
  jumpBy: 10,
  showTooltips: true
};
