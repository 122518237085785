import './activation-button.scss';
import React, {FunctionComponent} from 'react';
import {IActivationButtonProps} from './activation-button.types';
import {useTranslation} from 'lib/intl/i18n';
import {Button} from 'components/ui/button';
import KeyIcon from '@material-ui/icons/VpnKey';

/** Account activation button */
export const ActivationButton: FunctionComponent<IActivationButtonProps> = (props) => {
  const {t} = useTranslation();
  
  return (
    <div className="ui-activation-button">
      <Button
        {...props}
        icon={<KeyIcon />}
      >
        {t('views.user.activation.button')}
      </Button>
    </div>
  );
};