import {Reducer} from 'redux';
import {UserActivationAction, UserActivationActionTypes} from 'actions';
import {IUserActivationState} from './user-activation.types';

const initialState: IUserActivationState = {
  pending: false,
  error: null
};

/** User account activation */
export const userActivation: Reducer<IUserActivationState, UserActivationAction> = (state = initialState, action): IUserActivationState => {
  switch (action.type) {
    case UserActivationActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case UserActivationActionTypes.success:
      return {
        ...state,
        pending: false
      };
    case UserActivationActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};