import {put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {waitForFetch} from 'sagas/api';
import {gtm} from 'lib/global/gtm';

/** Authenticate user and fetch an account detail */
const loginSaga = function* (action: actions.ILoginRequestAction) {
  try {

    // Get access token
    const {userName, password} = action.payload;
    yield put(actions.tokenRequest({userName, password}));
    yield waitForFetch(actions.TokenActionTypes);

    // Fetch user account detail
    yield put(actions.userDetailRequest());
    yield waitForFetch(actions.UserDetailActionTypes);

    yield put(actions.loginSuccess());

    gtm.pushToDataLayer({
      event: 'hitType',
      eventCategory: 'uzivatel',
      eventAction: 'login',
      eventLabel: 'done'
    });
  }
  catch (error) {
    yield put(actions.loginFailure(error as IRequestError));
  }
};

export const login = function* () {
  yield takeLatest(actions.LoginActionTypes.request, loginSaga);
};
