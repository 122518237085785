import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import memoize from 'fast-memoize';
import {t} from 'lib/intl/i18n';

/**
 * Get minimum length validator
 *
 * @param length - Minimum value length
 */
export const minLength = memoize((length: number) => {

  /**
   * Validate minimum value length
   *
   * @param input - Value to validate
   * @returns Error message or undefined
   */
  const minLength: IValidator = (input) => {
    const value = String(input || '').trim();
    const errorMessage = t('lib.global.validate.length.min', {length});
    if (value.length < length)
      return errorMessage;
  };

  return minLength;
});

/**
 * Check that the minimum length validator doesn't return any error
 *
 * @param input - Value to check
 * @param length - Minimum value length
 */
export const hasMinLength: ValidatorQuery = (input, length: number) => {
  return !minLength(length)(input);
};
