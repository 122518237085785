import React, {FunctionComponent, useCallback, useState} from 'react';
import {IPlaceDetailButtonProps} from './place-detail-button.types';
import {IconButton} from 'components/ui/icon-button';
import DetailIcon from '@material-ui/icons/MoreHoriz';
import {PlaceDetailDialog} from 'components/consumption/place-detail-dialog';

/** Consumption place detail button */
export const PlaceDetailButton: FunctionComponent<IPlaceDetailButtonProps> = (props) => {
  const {place} = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  return (
    <>
      <IconButton
        className="ui-demand-detail-button"
        size="small"
        onClick={toggleOpen}
      >
        <DetailIcon />
      </IconButton>
      <PlaceDetailDialog
        place={place}
        open={open}
        onClose={toggleOpen}
      />
    </>
  );
};