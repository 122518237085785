import {getCurrentLanguage} from 'lib/intl/app-locale';
import filesize from 'filesize';

/**
 * Format file size
 *
 * @param size - Size (bytes by default)
 * @param [bits] - Size is in bits
 * @returns Formatted file size
 */
export const fileSize = (size: number, bits = false) => {
  const language = getCurrentLanguage();
  return filesize(size, {locale: language, bits});
};
