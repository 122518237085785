import React, {forwardRef} from 'react';
import {IDescriptionValueProps} from './description-value.types';

/**
 * Description item value
 *
 * @example
 *
 *   import {DescriptionList, DescriptionLabel, DescriptionValue} from 'components/ui/description-list';
 *   
 *   // ...
 *   
 *   <DescriptionList>
 *     <DescriptionLabel>
 *       HTML
 *     </DescriptionLabel>
 *     <DescriptionValue>
 *       Hypertext Markup Language
 *     </DescriptionValue>
 * 
 *     <DescriptionLabel>
 *       CSS
 *     </DescriptionLabel>
 *     <DescriptionValue>
 *       Cascading Style Sheets
 *     </DescriptionValue>
 *   </DescriptionList>
 */
export const DescriptionValue = forwardRef<HTMLDListElement, IDescriptionValueProps>(function DescriptionValue(props, ref) {
  const {children} = props;
  
  return (
    <dd ref={ref}>
      {children}
    </dd>
  );
});