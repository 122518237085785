import './home-unlogged-view.scss';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'react-i18next';
import {View} from 'components/ui/view';
import {RevealFade} from 'components/ui/reveal-fade';
import {Typography} from 'components/ui/typography';
import {Box} from 'components/ui/box';
import {Grid} from 'components/ui/grid';
import {HomePortalFeatures} from '../sections/home-portal-features';
import {HomeActivation} from '../sections/home-activation';
import {HomeNews} from '../sections/home-news';
import {HomeAbout} from '../sections/home-about';

/** Home page view (unlogged user) */
export const HomeUnloggedView: FunctionComponent = () => {
  const {t} = useTranslation();
  
  return (
    <View>
      <Box mb={4}>
        <RevealFade bottom>
          <Typography
            className="ui-home-unlogged-view__heading"
            variant="h2"
            color="primary"
          >
            {t('views.app.home.heading')}
          </Typography>
        </RevealFade>
      </Box>
      <Box mb={5}>
        <HomePortalFeatures />
      </Box>
      <Box mb={5}>
        <HomeActivation />
      </Box>
      <Grid container>
        <Grid item xl={6}>
          <HomeNews />
        </Grid>
        <Grid item xl={6}>
          <HomeAbout />
        </Grid>
      </Grid>
    </View>
  );
};