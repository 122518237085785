
/** Get the consumption of the consumption place */
export const consumptionList = () => {
  return 'consumption/list';
};

/** Get a list of parameters that can be obtained from the consumption place response */
export const consumptionParameterList = () => {
  return 'index/consumption-parameter/list';
};

/** Get a list of energy types */
export const energyTypeList = () => {
  return 'index/energy-type/list';
};

/** Get the number of records per energy type */
export const consumptionSummary = () => {
  return 'consumption/summary';
};

/** Get a list of consumption places */
export const consumptionPlaceList = () => {
  return 'consumption-place/list';
};

/** Get a list of consumption place overview */
export const placeOverviewList = () => {
  return 'consumption-place/overview/list';
};
