import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import Paper, {PaperProps} from '@material-ui/core/Paper';
import Draggable, {ControlPosition, DraggableEvent, DraggableData} from 'react-draggable';

/** Drag & drop dialog support */
export const DraggableDialog: FunctionComponent<PaperProps> = (props) => {
  const [position, setPosition] = useState<ControlPosition>({x: 0, y: 0});
  const paperRef = useRef<HTMLDivElement>(null!);

  const resetPosition = useCallback(() => {
    setPosition({x: 0, y: 0});
  }, []);

  const handleDrag = useCallback((event: DraggableEvent, data: DraggableData) => {
    setPosition({x: data.x, y: data.y});
  }, []);

  useEffect(() => {
    window.addEventListener('resize', resetPosition);
    return () => window.removeEventListener('resize', resetPosition);
  // eslint-disable-next-line
  }, []);

  return (
    <Draggable
      handle=".ui-dialog--draggable .MuiDialogTitle-root"
      cancel=".MuiDialogContent-root"
      bounds="parent"
      position={position}
      onDrag={handleDrag}
    >
      <Paper
        {...props}
        ref={paperRef}
      />
    </Draggable>
  );
};
