import {IAuthTokenRequest, IAuthTokenResponse} from 'api';

export enum TokenActionTypes {
  request = 'AUTH_TOKEN_REQUEST',
  success = 'AUTH_TOKEN_SUCCESS',
  failure = 'AUTH_TOKEN_FAILURE'
}

export interface ITokenSuccessPayload extends Omit<IAuthTokenResponse, 'expiresIn'> {
  expiresAt: number;
}

export interface ITokenRequestAction {
  type: TokenActionTypes.request;
  payload: IAuthTokenRequest;
}

export interface ITokenSuccessAction {
  type: TokenActionTypes.success;
  payload: ITokenSuccessPayload;
}

export interface ITokenFailureAction {
  type: TokenActionTypes.failure;
  payload: IRequestError;
}

export type TokenAction = (
  ITokenRequestAction |
  ITokenSuccessAction |
  ITokenFailureAction
);
