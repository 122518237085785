import React, {forwardRef, useMemo} from 'react';
import {FormDatePickerProps} from './form-date-picker.types';
import {useFormField} from 'hooks';
import {DatePicker} from 'components/ui/date-picker';
import {FormPickerField} from 'components/ui/form-picker-field';
import {validate as validators, composeValidators} from 'lib/global/validate';

/**
 * Formik wrapper for date picker
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormDatePicker} from 'components/ui/form-date-picker';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormDatePicker
 *       name="someName"
 *       label="Some label"
 *     />
 *   </Form>
 */
export const FormDatePicker = forwardRef<HTMLInputElement, FormDatePickerProps>(function FormDatePicker(props, ref) {
  const {validate, ...restProps} = props;

  const pickerProps = useMemo(() => ({
    ...props,
    validate: composeValidators(
      validate,
      validators.date,
      !!props.minDate && validators.minDate(props.minDate),
      !!props.maxDate && validators.maxDate(props.maxDate)
    )
  } as FormDatePickerProps), [props, validate]);

  const fieldProps = useFormField(pickerProps);
  const {field, helpers} = fieldProps;

  return (
    <DatePicker
      ref={ref}
      {...restProps}
      value={field.value || ''}
      onChange={helpers.setValue}
      FieldComponent={FormPickerField}
      fieldProps={fieldProps}
    />
  );
});