import {IDemandCreationRequest, IDemandCreationResponse} from 'api';

export enum DemandCreationActionTypes {
  request = 'DEMAND_CREATION_REQUEST',
  success = 'DEMAND_CREATION_SUCCESS',
  failure = 'DEMAND_CREATION_FAILURE'
}

export interface IDemandCreationRequestAction {
  type: DemandCreationActionTypes.request;
  payload: IDemandCreationRequest;
}

export interface IDemandCreationSuccessAction {
  type: DemandCreationActionTypes.success;
  payload: IDemandCreationResponse;
}

export interface IDemandCreationFailureAction {
  type: DemandCreationActionTypes.failure;
  payload: IRequestError;
}

export type DemandCreationAction = (
  IDemandCreationRequestAction |
  IDemandCreationSuccessAction |
  IDemandCreationFailureAction
);