import React, {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {View} from 'components/ui/view';
import {Box} from 'components/ui/box';
import {CmsContent} from 'components/ui/cms-content';
import {Typography} from 'components/ui/typography';
import {RevealFade} from 'components/ui/reveal-fade';
import {OrderedList} from 'components/ui/ordered-list';
import {OrderedListItem} from 'components/ui/ordered-list-item';
import {ActivationButton} from './activation-button';
import {ActivationDialog} from './activation-dialog';
import {Link} from 'components/ui/link';

const LIST_MOCK = [
  'Kliknite nižšie na tlačidlo „Aktivácia účtu“.',
  'Zadajte váš PID.',
  'Následne zadajte váš e-mail (ktorý budete používať na prihlasovanie sa do portálu) a vytvorte si heslo.',
  'Oboznámte sa s podmienkami používania zákazníckeho portálu Moja Veolia. Kliknite na tlačidlo "Odoslať".',
  'Váš účet je aktívny. Teraz môžete použiť vaše prihlasovacie údaje na prihlásenie do zákazníckeho portálu Moja Veolia.'
];

/** Account activation view */
export const ActivationView: FunctionComponent = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {t} = useTranslation();

  const toggleDialog = useCallback(() => {
    setDialogOpen((open) => !open);
  }, []);

  return (
    <View className="ui-activation-view">
      <RevealFade>

        {/* Description */}

        <Box mb={4}>
          <CmsContent>
            <p>
              Prístup do Zákazníckeho portálu Moja Veolia majú len oprávnené osoby, uvedené v Zmluve o dodávke a odbere tepla (štatutárni zástupcovia správcovských spoločností, obchodných spoločností a inštitúcií). Prístup pre ostatných užívateľov (technici správcovských spoločností, ekonómovia, zástupcovia vlastníkov bytov) prideľujú vyššie spomenuté oprávnené osoby.
            </p>
            <p>
              Na aktiváciu účtu použije zmluvný partner (oprávnená osoba) jednorazové identifikačné číslo (PID), ktoré je súčasťou Zmluvy o dodávke a odbere tepla. V prípade, ak Zmluva o dodávke o odbere tepla tento údaj neobsahuje (zmluvy do roku 2020), bude mu PID doručený poštou.
            </p>
          </CmsContent>
        </Box>

        {/* Activation steps */}

        <Typography
          variant="h2"
          color="primary"
        >
          {t('views.user.activation.heading')}
        </Typography>
        <Box mt={4}>
          <OrderedList>
            {LIST_MOCK.map((step, id) => (
              <OrderedListItem key={`${id}${step}`}>
                {step}
              </OrderedListItem>
            ))}
          </OrderedList>
        </Box>

        {/* Activation dialog */}
        
        <Box mt={8} mb={8}>
          <ActivationButton onClick={toggleDialog} />
        </Box>
        <ActivationDialog
          open={dialogOpen}
          onClose={toggleDialog}
        />

        {/* Page footer */}

        <CmsContent>
          V prípade problémov s aktiváciou účtu nám napíšte na
          {' '}
          <Link to="mailto:portal@veoliaenergia.sk">
            portal@veoliaenergia.sk
          </Link>
          , alebo nás kontaktujte na čísle 0906 105 207.
        </CmsContent>
      </RevealFade>
    </View>
  );
};