import {Reducer} from 'redux';
import {ISetLocaleAction, LocaleActionTypes} from 'actions/intl';
import {LocaleState} from './locale.types';
import {DEFAULT_LOCALE, DEFAULT_LANGUAGE, DEFAULT_COUNTRY} from 'config/intl';

const initialState: LocaleState = {
  code: DEFAULT_LOCALE,
  language: DEFAULT_LANGUAGE,
  country: DEFAULT_COUNTRY
};

/** Application locale */
export const locale: Reducer<LocaleState, ISetLocaleAction> = (state = initialState, action): LocaleState => {
  switch (action.type) {
    case LocaleActionTypes.set:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};
