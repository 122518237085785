import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Reset the user password */
const passwordResetSaga = function* (action: actions.IPasswordResetRequestAction) {
  try {
    yield call(api.passwordReset, action.payload);
    yield put(actions.passwordResetSuccess());
  }
  catch (error) {
    yield put(actions.passwordResetFailure(error as IRequestError));
  }
};

export const passwordReset = function* () {
  yield takeLatest(actions.PasswordResetActionTypes.request, passwordResetSaga);
};
