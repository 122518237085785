/**
 * Parse bank account number
 *
 * @param bankAccount - Bank account string
 * @returns Bank account number
 */
export const bankAccountNumber = (bankAccount: string) => {
  const match = (bankAccount || '').match(/(^(.*-)?)([0-9]*)(\/.*)?$/);
  if (match)
    for (const result of match) {
      if (result && !result.includes('-') && !result.includes('/'))
        return result;
    }
  return '';
};
