import React, {forwardRef} from 'react';
import {FormRadioGroupProps} from './form-radio-group.types';
import {useFormField} from 'hooks';
import {RadioGroup} from 'components/ui/radio-group';
import {FormFieldError} from 'components/ui/form-field-error';

/**
 * Formik wrapper for radio button group
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormRadioGroup} from 'components/ui/form-radio-group';
 *   import {Radio} from 'components/ui/radio';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormRadioGroup name="someName">
 *       <Radio
 *         label="Some label"
 *         value="option1"
 *       />
 *       <Radio
 *         label="Some label"
 *         value="option2"
 *       />
 *     </FormRadioGroup>
 *   </Form>
 */
export const FormRadioGroup = forwardRef<HTMLButtonElement, FormRadioGroupProps>(function FormRadioGroup(props, ref) {
  const {field, meta} = useFormField(props);

  return (
    <>
      <RadioGroup
        ref={ref}
        {...props}
        {...field}
      />
      <FormFieldError>
        {meta.touched && meta.error}
      </FormFieldError>
    </>
  );
});
