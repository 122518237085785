import './login-form.scss';
import React, {FunctionComponent, useEffect} from 'react';
import {paths} from 'config/router';
import {ILoginFormProps} from './login-form.types';
import {useLoginForm, usePrevious} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {useSnackbar} from 'components/ui/snackbar';
import {Form} from 'components/ui/form';
import {Flexbox} from 'components/ui/flexbox';
import {Box} from 'components/ui/box';
import {FormTextField} from 'components/ui/form-text-field';
import {Button} from 'components/ui/button';
import LoginIcon from '@material-ui/icons/PowerSettingsNew';
import {Link} from 'components/ui/link';

/**
 * Login form
 * 
 * @example
 * 
 *   <LoginForm />
 */
export const LoginForm: FunctionComponent<ILoginFormProps> = (props) => {
  const {loginFormId} = props;
  const {initialValues, handleSubmit, loginPending, loginError} = useLoginForm(loginFormId!);
  const prevLoginPending = usePrevious(loginPending);
  const {showSnackbar} = useSnackbar();
  const {t} = useTranslation();

  useEffect(() => {
    if (prevLoginPending && !loginPending && loginError)
      showSnackbar(loginError.message, {
        variant: 'error',
        autoHideDuration: 8000
      });
  }, [loginPending, loginError, prevLoginPending, showSnackbar]);

  return (
    <Form
      className="ui-login-form"
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >

      {/* Login */}
      
      <Box mb={3}>
        <FormTextField
          name="login"
          label={t('components.user.login_form.user')}
          disabled={loginPending}
          autoFocus
        />
      </Box>
        
      {/* Password */}
      
      <Box mb={3}>
        <FormTextField
          name="password"
          type="password"
          label={t('components.user.login_form.password')}
          disabled={loginPending}
        />
      </Box>

      {/* Submit */}

      <Flexbox
        justifyContent="flex-end"
        mb={3}
      >
        <Button
          type="submit"
          icon={<LoginIcon />}
          pending={loginPending}
          fullWidth
          disableRipple
        >
          {t('components.user.login_form.submit')}
        </Button>
      </Flexbox>
      <Flexbox justifyContent="flex-end">
        
        {/* Forgotten password */}
        
        <Link
          to={paths.passwordReset}
          underline
          size="small"
          disabled={loginPending}
        >
          {t('components.user.login_form.forgotten_password')}
        </Link>
      </Flexbox>
    </Form>
  );
};

LoginForm.defaultProps = {
  loginFormId: 'login-form'
};