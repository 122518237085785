import './login-view.scss';
import React, {FunctionComponent} from 'react';
import {View} from 'components/ui/view';
import {RevealFade} from 'components/ui/reveal-fade';
import {LoginForm} from 'components/user/login-form';

/** Login view */
export const LoginView: FunctionComponent = () => {
  return (
    <View className="ui-login-view">
      <div className="ui-login-view__content">
        <RevealFade>
          <LoginForm />
        </RevealFade>
      </div>
    </View>
  );
};