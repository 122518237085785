import {useState, useCallback, useMemo} from 'react';
import {IUseDialogInitialState, IUseDialogResult} from './use-dialog.types';

/**
 * Dialog open state
 * 
 * @example
 * 
 *   import {Dialog} from 'components/ui/dialog';
 *   import {useDialog} from 'hooks';
 *   import {Button} from 'components/ui/button';
 *   
 *   // ...
 *   
 *   const dialog = useDialog();
 *   
 *   // ...
 *   
 *   <Dialog {...dialog.props}>
 *     Some content
 *   </Dialog>
 * 
 *   <Button onClick={dialog.open}>
 *     Click to open the dialog
 *   </Button>
 */
export const useDialog = (initialState?: IUseDialogInitialState) => {
  const [isOpen, setIsOpen] = useState(initialState ? initialState.open : false);

  const open = useCallback(() => {
    setIsOpen(true);
  }, []);

  const close = useCallback(() => {
    setIsOpen(false);
  }, []);

  return useMemo<IUseDialogResult>(() => ({
    open,
    close,
    isOpen,
    props: {
      open: isOpen,
      onClose: close
    }
  }), [close, isOpen, open]);
};