import './home-news-list.scss';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {paths} from 'config/router';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {useTranslation} from 'lib/intl/i18n';
import {RevealFade} from 'components/ui/reveal-fade';
import {Typography} from 'components/ui/typography';
import NewsIcon from '@material-ui/icons/NewReleases';
import {NewsList} from 'components/article/news-list';
import {Flexbox} from 'components/ui/flexbox';
import {Link} from 'components/ui/link';

const NEWS_COUNT = 3;

/** News list */
export const HomeNewsList: FunctionComponent = () => {
  const news = useFetch(api.newsList);
  const {t} = useTranslation();

  useEffect(() => {
    news.fetch({size: NEWS_COUNT});
  // eslint-disable-next-line
  }, []);

  const render = useCallback(() => {
    return (
      <>
        <NewsList news={news.data!.content} />

        {/* All news link */}

        <Flexbox
          mt={3}
          justifyContent="flex-end"
        >
          <Link to={paths.news}>
            {t('views.app.home.news.all_news')}
          </Link>
        </Flexbox>
      </>
    );
  }, [news.data, t]);
  
  return (
    <>
      <RevealFade>
        <Typography
          variant="h2"
          color="primary"
          gutterBottom
        >
          <NewsIcon />
          {t('views.app.home.news.heading')}
        </Typography>
      </RevealFade>
      <div className="ui-home-news-list__content">
        <WaitForFetch
          pending={news.pending}
          error={news.error}
          noData={!news.data?.content?.length}
          noDataMessage={t('views.app.home.news.no_data')}
          onRetry={news.fetch}
          render={render}
          absolutePosition
        />
      </div>
    </>
  );
};