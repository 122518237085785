import {useMemo} from 'react';
import {validate as validators, composeValidators} from 'lib/global/validate';
import {IUseFormFieldOptions, IUseFormFieldProps} from './use-form-field.types';
import {useField} from 'formik';

/**
 * Get form field config
 * 
 * @example
 * 
 *   import {ITextFieldProps} from 'components/ui/text-field';
 *   import {FormFieldProps} from 'hooks';
 *   
 *   type FormTextFieldProps = FormFieldProps<ITextFieldProps>;
 *   
 * @example
 * 
 *   import {TextField} from 'components/ui/text-field';
 *   import {FormFieldError} from 'components/ui/form-field-error';
 *   
 *   const FormTextField: FunctionComponent<FormTextFieldProps> = (props) => {
 *     const {field, meta} = useFormField(props);
 *     const {validate, ...restProps} = props;
 *   
 *     return (
 *       <>
 *         <TextField
 *           {...restProps}
 *           {...field}
 *         />
 *         <FormFieldError>
 *           {meta.touched && meta.error}
 *         </FormFieldError>
 *       </>
 *     );
 *   }
 */
export const useFormField = <Value extends FormFieldValue = string>(props: IUseFormFieldOptions<Value>): IUseFormFieldProps<Value> => {
  const enhancedProps = useMemo<IUseFormFieldOptions<Value>>(() => {
    const newProps = {...props};

    //--- Required field validation

    if (newProps.required)
      newProps.validate = composeValidators(newProps.validate, validators.required);
    else if (newProps.validate?.name === 'required' || newProps.validate?.names?.includes('required'))
      newProps.required = true;

    return newProps;
  }, [props]);

  const [field, meta, helpers] = useField<Value>(enhancedProps);  

  return {
    ...enhancedProps,
    field,
    meta,
    helpers
  };
};
