import React, {FunctionComponent, useMemo} from 'react';
import {IEnergyTypeProps} from './energy-type.types';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';

/** Energy type */
export const EnergyType: FunctionComponent<IEnergyTypeProps> = (props) => {
  const {typeId} = props;
  const energyTypeList = useSelector(selectors.energyTypeList, shallowEqual);
  const {t} = useTranslation();

  const energyTypeLabel = useMemo(() => {
    if (energyTypeList.data) {
      // const energyType = energyTypeList.data.find((type) => type.id === typeId);
      
      // Temporary type mismatch workaround
      const energyType = energyTypeList.data.find((type) => String(type.id) === String(typeId));
      
      if (energyType)
        return energyType.name;
    }
    return t('components.consumption.consumption_table.unknown_energy_type');
  }, [energyTypeList.data, t, typeId]);
  
  return (<>{energyTypeLabel}</>);
};