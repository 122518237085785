import React, {useContext, FunctionComponent, useCallback, useEffect} from 'react';
import moment from 'moment';
import {format} from 'lib/intl/format';
import {useTranslation} from 'lib/intl/i18n';
import {FilterContext} from '../payments-filter-context';
import {FilterDateRangePicker, IFilterDateRangeValues} from 'components/ui/filter-date-range-picker';
import IntervalIcon from '@material-ui/icons/Event';

/** Payments filter interval select */
export const IntervalSelect: FunctionComponent = () => {
  const {
    getValues,
    setValues,
    setAndStoreValues,
    getStoredValue,
    FilterField
  } = useContext(FilterContext);
  const {from, to} = getValues();
  const {t} = useTranslation();

  // Restore last values
  useEffect(() => {
    const from = getStoredValue('from').value as string;
    const to = getStoredValue('to').value as string;
    if (!from && !to)
      setValues({from: format.date(moment().subtract(1, 'year'))});
    else
      setAndStoreValues({from, to});
  // eslint-disable-next-line
  }, []);

  const handleChange = useCallback((values: IFilterDateRangeValues) => {
    setAndStoreValues(values);
  }, [setAndStoreValues]);

  return (
    <FilterField>
      <FilterDateRangePicker
        label={t('views.payment.filter.interval')}
        labelIcon={<IntervalIcon />}
        fromValue={from}
        toValue={to}
        onChange={handleChange}
      />
    </FilterField>
  );
};