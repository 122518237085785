import './icon-button.scss';
import React, {forwardRef, useMemo} from 'react';
import {IIconButtonProps} from 'components/ui/icon-button/icon-button.types';
import MuiIconButton from '@material-ui/core/IconButton';
import {Fade} from 'components/ui/fade';
import {Preloader} from 'components/ui/preloader';

/**
 * Icon button
 * 
 * @see src/views/app/kit-view/icon-button-kit
 *
 * @example
 *
 *   import CheckIcon from '@material-ui/icons/Check';
 * 
 *   // ...
 *
 *   <IconButton color="success">
 *     <CheckIcon />
 *   </IconButton>
 */
export const IconButton = forwardRef<HTMLButtonElement, IIconButtonProps>(function IconButton(props, ref) {
  const {
    className,
    color,
    size,
    disabled,
    pending,
    children,
    ...restProps
  } = props;
  
  const classes = useMemo(() => {
    let classes = 'ui-icon-button';
    classes += ` ui-icon-button--color-${color}`;
    if (size)
      classes += ` ui-icon-button--size-${size}`;
    if (pending)
      classes += ' ui-icon-button--pending';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, color, pending, size]);
  
  return (
    <MuiIconButton
      {...restProps}
      ref={ref}
      className={classes}
      size={size}
      disabled={disabled}
    >
      <Fade in={!pending}>
        <span>{children}</span>
      </Fade>
      <Preloader
        display="container"
        visible={pending}
        size="small"
      />
    </MuiIconButton>
  );
});

IconButton.defaultProps = {
  color: 'primary'
};