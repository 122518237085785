import React, {useContext, FunctionComponent, ChangeEvent, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {FilterContext} from '../invoices-filter-context';
import {useFetch, useOnFetchSuccess} from 'hooks';
import {api, CommodityTypeId} from 'api';
import {FilterComboBox, FilterComboBoxOptions} from 'components/ui/filter-combo-box';
import CommodityIcon from '@material-ui/icons/Category';

export const ALL_COMMODITY_TYPES = 'all-commodity-types';

/** Invoices filter commodity type select */
export const CommodityTypeSelect: FunctionComponent = () => {
  const {
    getValues,
    setAndStoreValues,
    getStoredValue,
    FilterField
  } = useContext(FilterContext);
  const {commodityTypeId: commodityType} = getValues();
  const commodityTypes = useFetch(api.invoiceCommodityList);
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    commodityTypes.fetch();
  }, [commodityTypes]);

  // Fetch a list of commodity types on mount
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const commodityTypeList = useMemo(() => {
    const list: FilterComboBoxOptions = [];
    if (commodityTypes.data) {
      
      // Get list of active commodity types
      for (const commodityType of commodityTypes.data)
        list.push({
          label: commodityType.name,
          value: commodityType.id
        });

      // Add 'all commodity types' option
      if (list && list.length > 1)
        list.unshift({
          label: t('views.invoice.filter.all_commodity_types'),
          value: ALL_COMMODITY_TYPES
        });
    }
    return list;
  }, [commodityTypes.data, t]);

  const setCommodityType = useCallback((value: (CommodityTypeId | typeof ALL_COMMODITY_TYPES)) => {
    setAndStoreValues({commodityTypeId: value as CommodityTypeId});
  }, [setAndStoreValues]);

  useOnFetchSuccess([commodityTypes], () => {
    const {value} = getStoredValue('commodityTypeId')
      .onlyIfIncludedInList(commodityTypeList)
      .orGetFirstFromList();
    setCommodityType(value as CommodityTypeId);
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
    setCommodityType(value as CommodityTypeId);
  }, [setCommodityType]);

  const disabled = !commodityTypeList || commodityTypeList.length <= 1;

  return (
    <FilterField
      pending={commodityTypes.pending}
      error={commodityTypes.error}
    >
      <FilterComboBox
        label={t('views.invoice.filter.commodity_types')}
        labelIcon={<CommodityIcon />}
        options={commodityTypeList}
        value={commodityType}
        disabled={disabled}
        onChange={handleChange}
      />
    </FilterField>
  );
};