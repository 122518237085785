import React, {forwardRef, ReactElement, Ref, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {validate} from 'lib/global/validate';
import {parse} from 'lib/intl/parse';
import {usePrevious} from 'hooks';
import {IDateRangePickerProps} from './date-range-picker.types';
import {Grid} from 'components/ui/grid';
import {PickerFieldProps} from 'components/ui/picker-field';
import {FormLabel} from 'components/ui/form-label';
import {DatePicker, IDatePickerProps} from 'components/ui/date-picker';

/**
 * Date range picker
 *
 * @see src/views/app/kit-view/date-time-kit
 * @see https://v4-0-0-alpha-12.material-ui-pickers.dev/api/DatePicker
 *
 * @example
 *
 *   const [dateRange, setDateRange] = useState({
 *     from: '',
 *     to: ''
 *   });
 *
 *   const handleChange = useCallback((from: string, to: string) => {
 *     setDateRange({from, to});
 *   }, []);
 *
 *   // ...
 *
 *   <DateRangePicker
 *     label="Select a date range"
 *     fromValue={dateRange.from}
 *     toValue={dateRange.to}
 *     onChange={handleChange}
 *   />
 */
export const DateRangePicker = forwardRef(function DateRangePicker<T extends PickerFieldProps = PickerFieldProps>(props: IDateRangePickerProps<T>, ref: Ref<HTMLDivElement>) {
  const {
    fromName,
    toName,
    fromValue,
    toValue,
    onChange,
    label,
    labelIcon,
    placeholder,
    minDate,
    maxDate,
    fromFieldProps,
    toFieldProps,
    ...restProps
  } = props;
  const prevFrom = usePrevious(fromValue);
  const prevTo = usePrevious(toValue);
  const {t} = useTranslation();

  const handleFromPickerChange = useCallback((value: string) => {
    onChange(value, toValue || '');
  }, [onChange, toValue]);

  const handleToPickerChange = useCallback((value: string) => {
    onChange(fromValue || '', value);
  }, [fromValue, onChange]);

  // Ensure that the end date follows the start date
  useEffect(() => {
    if (validate.isDate(fromValue) && validate.isDate(toValue)) {
      if (prevFrom !== fromValue && parse.date(fromValue!).isAfter(parse.date(toValue!)))
        handleToPickerChange(fromValue!);
      if (prevTo !== toValue && parse.date(toValue!).isBefore(parse.date(fromValue!)))
        handleFromPickerChange(toValue!);
    }
  }, [fromValue, handleFromPickerChange, handleToPickerChange, prevFrom, prevTo, toValue]);

  const pickerProps = useMemo<Omit<IDatePickerProps<T>, ('ref' | 'value' | 'onChange')>>(() => ({
    ...restProps,
    minDate,
    maxDate,
    okText: t('components.ui.date_range_picker.ok'),
    cancelText: t('components.ui.date_range_picker.cancel'),
    clearText: t('components.ui.date_range_picker.clear'),
    todayText: t('components.ui.date_range_picker.today')
  }), [maxDate, minDate, restProps, t]);

  const fromPickerProps = useMemo<IDatePickerProps<T>>(() => ({
    ...pickerProps,
    toolbarTitle: t('components.ui.date_range_picker.from'),
    fieldProps: {
      ...(fromFieldProps || {}),
      InputProps: {
        startAdornment: t('components.ui.date_range_picker.input.from'),
        ...(fromFieldProps?.InputProps || {})
      }
    } as T,
    name: fromName,
    value: fromValue,
    onChange: handleFromPickerChange
  }), [fromFieldProps, fromName, fromValue, handleFromPickerChange, pickerProps, t]);

  const toPickerProps = useMemo<IDatePickerProps<T>>(() => ({
    ...pickerProps,
    toolbarTitle: t('components.ui.date_range_picker.to'),
    fieldProps: {
      ...(toFieldProps || {}),
      InputProps: {
        startAdornment: t('components.ui.date_range_picker.input.to'),
        ...(toFieldProps?.InputProps || {})
      }
    } as T,
    name: toName,
    value: toValue,
    onChange: handleToPickerChange
  }), [handleToPickerChange, pickerProps, t, toFieldProps, toName, toValue]);
  
  // Combine desktop input with mobile picker
  return (
    <>
      <FormLabel
        icon={labelIcon}
        required={restProps.required}
      >
        {label}
      </FormLabel>
      <Grid
        container
        ref={ref}
      >
        <Grid item md={6}>
          <DatePicker {...fromPickerProps} />
        </Grid>
        <Grid item md={6}>
          <DatePicker {...toPickerProps} />
        </Grid>
      </Grid>
    </>
  );
}) as <T extends PickerFieldProps = PickerFieldProps>(props: IDateRangePickerProps<T> & {ref?: Ref<HTMLDivElement>;}) => ReactElement;
// ^ Make JSX generic parameters work with forwardRef
//
// @see https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref 