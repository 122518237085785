import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {useDialog} from 'hooks';
import {IDemandMenuProps} from './demand-menu.types';
import {DataMenu, DataMenuItems} from 'components/ui/data-menu';
import AccessDemandIcon from '@material-ui/icons/PersonAdd';
import OtherDemandsIcon from '@material-ui/icons/AssignmentOutlined';
import {AccessDemandDialog} from 'components/demand/access-demand-dialog';
import {DemandDialog} from 'components/demand/demand-dialog';

/** Chart actions menu */
export const DemandMenu: FunctionComponent<IDemandMenuProps> = (props) => {
  const {open, onClose, menuAnchor} = props;
  const demandDialog = useDialog();
  const accessDemandDialog = useDialog();
  const {t} = useTranslation();

  const openDemandDialog = useCallback(() => {
    onClose();
    demandDialog.open();
  }, [demandDialog, onClose]);

  const openAccessDemanDialog = useCallback(() => {
    onClose();
    accessDemandDialog.open();
  }, [accessDemandDialog, onClose]);

  const menuItems = useMemo<DataMenuItems>(() => [
    {
      icon: (<AccessDemandIcon />),
      label: t('views.demand.demand_menu.access'),
      onClick: openAccessDemanDialog
    },
    {
      icon: (<OtherDemandsIcon />),
      label: t('views.demand.demand_menu.other'),
      onClick: openDemandDialog
    }
  ], [openAccessDemanDialog, openDemandDialog, t]);

  return (
    <>
      <DataMenu
        open={open}
        onClose={onClose}
        anchorEl={menuAnchor}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        items={menuItems}
      />
      <DemandDialog {...demandDialog.props} />
      <AccessDemandDialog {...accessDemandDialog.props} />
    </>
  );
};
