import {
  IRetryRequestAction,
  RetryActionTypes
} from './auth-retry.types';
import {AxiosRequestConfig} from 'axios';

/**
 * Refresh token and retry unauthorized request
 *
 * @param payload - Failed request config
 */
export const retryRequest = (payload: AxiosRequestConfig): IRetryRequestAction => ({
  type: RetryActionTypes.retry,
  payload
});
