import React, {forwardRef, useCallback, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {FormDateRangePickerProps} from './form-date-range-picker.types';
import {useFormField} from 'hooks';
import {DateRangePicker} from 'components/ui/date-range-picker';
import {FormPickerField} from 'components/ui/form-picker-field';
import {validate as validators, composeValidators, IValidator} from 'lib/global/validate';
import {useFormikContext, FormikValues} from 'formik';
import memoize from 'fast-memoize';

/**
 * Formik wrapper for date range picker 
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormDateRangePicker} from 'components/ui/form-date-range-picker';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormDateRangePicker
 *       fromName="from"
 *       toName="to"
 *       label="Some label"
 *     />
 *   </Form>
 */
export const FormDateRangePicker = forwardRef<HTMLDivElement, FormDateRangePickerProps>(function FormDateRangePicker(props, ref) {
  const {validate, label, labelIcon, ...restProps} = props;
  const {values, setValues} = useFormikContext();
  const formValues = values as FormikValues;
  const fromValue = formValues[props.fromName] || '';
  const toValue = formValues[props.toName] || '';
  const {t} = useTranslation();
  
  const handleChange = useCallback((from: string, to: string) => {
    setValues({
      ...formValues,
      [props.fromName]: from,
      [props.toName]: to
    });
  }, [formValues, props.fromName, props.toName, setValues]);

  const fieldValidators = useMemo(() => {
    return composeValidators(
      validate,
      validators.date,
      !!props.minDate && validators.minDate(props.minDate),
      !!props.maxDate && validators.maxDate(props.maxDate)
    );
  }, [props.maxDate, props.minDate, validate]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateMissingDate = useCallback(memoize((date: string, errorMessage: string): IValidator => (value) => {
    if (date && !value)
      return errorMessage;
  }), []);

  const fromFieldProps = {
    ...useFormField({
      ...restProps,
      name: props.fromName,
      validate: fieldValidators
    }),
    errorMessage: validateMissingDate(toValue, t('components.ui.form_date_range_picker.missing_from'))(fromValue)
  };

  const toFieldProps = {
    ...useFormField({
      ...restProps,
      name: props.toName,
      validate: fieldValidators
    }),
    errorMessage: validateMissingDate(fromValue, t('components.ui.form_date_range_picker.missing_to'))(toValue)
  };

  return (
    <DateRangePicker
      ref={ref}
      {...restProps}
      label={label}
      labelIcon={labelIcon}
      fromName={props.fromName}
      toName={props.toName}
      fromValue={fromValue}
      toValue={toValue}
      onChange={handleChange}
      FieldComponent={FormPickerField}
      fromFieldProps={fromFieldProps}
      toFieldProps={toFieldProps}
    />
  );
});