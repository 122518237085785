import './snackbar-kit.scss';
import React, {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import InfoIconOutlined from '@material-ui/icons/InfoOutlined';
import WarningIconOutlined from '@material-ui/icons/ReportProblemOutlined';
import SuccessIconOutlined from '@material-ui/icons/CheckCircleOutline';
import ErrorIconOutlined from '@material-ui/icons/ErrorOutline';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import {Typography} from 'components/ui/typography';
import memoize from 'fast-memoize';
import {useSnackbar, ISnackbarOptions} from 'components/ui/snackbar';

/** Snackbar component preview */
export const SnackbarKit: FunctionComponent = () => {
  const {t} = useTranslation();
  const {showSnackbar, isSnackbarOpen} = useSnackbar();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const showMessage = useCallback(memoize((message: string, options: ISnackbarOptions) => () => {
    showSnackbar(message, options);
  }), [showSnackbar]);

  return (
    <div className="ui-snackbar-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.snackbar.heading')}
      </Typography>
      <br />

      {/* Temporary snackbars */}

      <Typography
        variant="h3"
        gutterBottom
      >
        {t('views.app.ui_kit.snackbar.temporary.heading')}
      </Typography>
      <ButtonActions>

        {/* Info */}

        <Button
          className="ui-snackbar-kit__info-button"
          icon={<InfoIconOutlined />}
          onClick={showMessage(
            t('views.app.ui_kit.snackbar.temporary.info.message'),
            {variant: 'info'}
          )}
        >
          {t('views.app.ui_kit.snackbar.temporary.info.button')}
        </Button>

        {/* Success */}

        <Button
          color="success"
          icon={<SuccessIconOutlined />}
          onClick={showMessage(
            t('views.app.ui_kit.snackbar.temporary.success.message'),
            {variant: 'success'}
          )}
        >
          {t('views.app.ui_kit.snackbar.temporary.success.button')}
        </Button>

        {/* Warning */}

        <Button
          color="warning"
          icon={<WarningIconOutlined />}
          onClick={showMessage(
            t('views.app.ui_kit.snackbar.temporary.warning.message'),
            {variant: 'warning'}
          )}
        >
          {t('views.app.ui_kit.snackbar.temporary.warning.button')}
        </Button>

        {/* Error */}

        <Button
          color="error"
          icon={<ErrorIconOutlined />}
          onClick={showMessage(
            t('views.app.ui_kit.snackbar.temporary.error.message'),
            {variant: 'error'}
          )}
        >
          {t('views.app.ui_kit.snackbar.temporary.error.button')}
        </Button>
      </ButtonActions>
      <br /><br />

      {/* Persistent snackbars */}

      <Typography
        variant="h3"
        gutterBottom
      >
        {t('views.app.ui_kit.snackbar.persistent.heading')}
      </Typography>

      {/* Info */}

      <ButtonActions>
        <Button
          className="ui-snackbar-kit__info-button"
          icon={<InfoIcon />}
          onClick={showMessage(t('views.app.ui_kit.snackbar.persistent.info.message'), {
            id: 'info',
            variant: 'info',
            persist: true
          })}
          disabled={isSnackbarOpen('info')}
        >
          {t('views.app.ui_kit.snackbar.persistent.info.button')}
        </Button>

        {/* Success */}

        <Button
          color="success"
          icon={<SuccessIcon />}
          onClick={showMessage(t('views.app.ui_kit.snackbar.persistent.success.message'), {
            id: 'success',
            variant: 'success',
            persist: true
          })}
          disabled={isSnackbarOpen('success')}
        >
          {t('views.app.ui_kit.snackbar.persistent.success.button')}
        </Button>

        {/* Warning */}

        <Button
          color="warning"
          icon={<WarningIcon />}
          onClick={showMessage(t('views.app.ui_kit.snackbar.persistent.warning.message'), {
            id: 'warning',
            variant: 'warning',
            persist: true
          })}
          disabled={isSnackbarOpen('warning')}
        >
          {t('views.app.ui_kit.snackbar.persistent.warning.button')}
        </Button>

        {/* Error */}

        <Button
          color="error"
          icon={<ErrorIcon />}
          onClick={showMessage(t('views.app.ui_kit.snackbar.persistent.error.message'), {
            id: 'error',
            variant: 'error',
            persist: true
          })}
          disabled={isSnackbarOpen('error')}
        >
          {t('views.app.ui_kit.snackbar.persistent.error.button')}
        </Button>
      </ButtonActions>
    </div>
  );
};
