import React, {FunctionComponent, useCallback, useEffect, useState} from 'react';
import {useWatchEffect, useRemount, useLocale} from 'hooks';

/**
 * Rerender application on locale change
 *
 * @example
 *
 *   <LocaleManager>
 *     <App>
 *       ...
 *     </App>
 *   </LocaleManager>
 * 
 * @example
 * 
 *   import {useLocale} from 'intl/hooks';
 * 
 *   // ...
 * 
 *   const {setLocale} = useLocale();
 *   
 *   // ...
 * 
 *   setLocale('en-US');
 *   
 */
export const LocaleManager: FunctionComponent = (props) => {
  const {children} = props;
  const {locale, setLocale} = useLocale();
  const [initializing, setInitializing] = useState(true);
  const remount = useRemount();

  const initLocale = useCallback(async () => {
    await setLocale(locale.code);
    setInitializing(false);
  }, [locale.code, setLocale]);

  useEffect(() => {
    initLocale();
  // eslint-disable-next-line
  }, []);

  const remountOnLocaleChange = useCallback(() => {
    remount.trigger();
  }, [remount]);

  useWatchEffect(() => {
    remountOnLocaleChange();
  }, [locale]);

  if (initializing)
    return null;

  return (
    <div {...remount.props}>
      {children}
    </div>
  );
};
