import './home-about.scss';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {RevealFade} from 'components/ui/reveal-fade';
import {Card} from 'components/ui/card';
import {CardHeader} from 'components/ui/card-header';
import {CardContent} from 'components/ui/card-content';
import {CmsContent} from 'components/ui/cms-content';

/** About us section */
export const HomeAbout: FunctionComponent = () => {
  const about = useFetch(api.aboutArticle);
  const {t} = useTranslation();

  useEffect(() => {
    about.fetch();
  // eslint-disable-next-line
  }, []);
  
  const render = useCallback(() => (
    <RevealFade>
      <CmsContent fromHtmlString={about.data!.perex} />
    </RevealFade>
  ), [about.data]);

  return (
    <RevealFade
      delay={1}
      bottom
      fullHeight
    >
      <Card>
        <CardHeader title={t('views.app.home.about_us.heading')} />
        <CardContent className="ui-home-about__content">
          <WaitForFetch
            pending={about.pending}
            error={about.error}
            noData={!about.data?.body}
            noDataMessage={t('views.app.home.about_us.no_data')}
            onRetry={about.fetch}
            render={render}
            absolutePosition
          />
        </CardContent>
      </Card>
    </RevealFade>
  );
};