import './card-header.scss';
import React, {forwardRef, useMemo} from 'react';
import {ICardHeaderProps} from './card-header.types';
import MuiCardHeader from '@material-ui/core/CardHeader';
import {Typography} from 'components/ui/typography';

/**
 * Card header
 *
 * @example
 *
 *   import {Card} from 'components/ui/card';
 *   import {CardHeader} from 'components/ui/card-header';
 *   import {CardMedia} from 'components/ui/card-media';
 *   import {CardContent} from 'components/ui/card-content';
 *   import {CardActionArea} from 'components/ui/card-action-area';
 *   import {CardActions} from 'components/ui/card-actions';
 *
 *   // ...
 * 
 *   <Card>
 *     <CardActionArea>
 *       <CardHeader
 *         icon={<SomeIcon />}
 *         title="Card title"
 *       />
 *       <CardMedia
 *         image="/path/to/image.png"
 *         title="Image title"
 *       />
 *       <CardContent>
 *         Card content
 *       </CardContent>
 *     </CardActionArea>
 *     <CardActions>
 *       <Link to="https://some.path">
 *         Card action
 *       </Link>
 *     </CardActions>
 *   </Card>
 */
export const CardHeader = forwardRef<HTMLDivElement, ICardHeaderProps>(function CardHeader(props, ref) {
  const {
    className,
    title,
    icon,
    ...restProps
  } = props;
  
  const classes = useMemo(() => {
    let classes = 'ui-card-header';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <MuiCardHeader
      ref={ref}
      {...restProps}
      className={classes}
      title={(
        <>
          {icon}
          {title && (
            <Typography variant="h2">
              {title}
            </Typography>
          )}
        </>
      )}
    />
  );
});