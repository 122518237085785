import './icon-link.scss';
import React, {forwardRef, useMemo} from 'react';
import {IIconLinkProps} from './icon-link.types';
import {Link} from 'components/ui/link';

/**
 * Link with icon
 *
 * @example
 *
 *   import {IconLink} from 'components/ui/icon-link';
 *   import SomeIcon from '@material-ui/icons/Check';
 * 
 *   // ...
 * 
 *   <IconLink
 *     icon={<SomeIcon />}
 *     to="/some-route"
 *   >
 *     Some label
 *   </IconLink>
 */
export const IconLink = forwardRef<HTMLAnchorElement, IIconLinkProps>(function IconLink(props, ref) {
  const {className, icon, children, ...restProps} = props;

  const classes = useMemo<string>(() => {
    let classes = 'ui-icon-link';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <Link
      ref={ref}
      {...restProps}
      className={classes}
      hoverAnimation={false}
    >
      {icon}
      <span className="ui-icon-link__label">
        {children}
      </span>
    </Link>
  );
});