import {useMemo} from 'react';
import {useWatchEffect} from 'hooks';

/**
 * Effect to run on fetch success
 *
 * @param requests - Any object with "pending" and "error" properties
 * @param callback - Callback to fire on fetch success
 *
 * @example
 *
 *   import {useFetch, useOnFetchSuccess} from 'hooks';
 *
 *   // ...
 * 
 *   const MyComponent: FunctionComponent = () => {
 *     const list = useFetch(someApi.fetchSomeList);
 *
 *     useEffect(() => {
 *       list.fetch(); // calls someApi.fetchSomeList
 *     }, []);
 *
 *     useOnFetchSuccess([list], () => {
 *       console.log('Fetch succeeded: ', list.data);
 *     });
 *   };
 * 
 * @example
 * 
 *   // Wait for multiple requests
 * 
 *   useOnFetchSuccess([someList, anotherList], () => {
 *     console.log('Fetch succeeded');
 *   });
 */
export const useOnFetchSuccess = (requests: IRequestProgress[], callback: () => void) => {
  const pending = useMemo(() => {
    return requests.reduce((pending, request) => {
      return pending || request.pending;
    }, false);
  }, [requests]);

  const error = useMemo(() => {
    return requests.reduce((error: (IRequestError | null), request) => {
      return error || request.error;
    }, null);
  }, [requests]);

  // const {pending, error} = requests;

  useWatchEffect(() => {
    if (!pending && !error)
      callback();
  }, [pending, error]);
};

