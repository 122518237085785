import React, {FunctionComponent} from 'react';
import {View} from 'components/ui/view';
import {ConsumptionFilter, IConsumptionFilterValues} from './consumption-filter';
import {useFilterState, FilterOutput} from 'components/ui/filter';
import {Box} from 'components/ui/box';
import {FilteredConsumptionTable} from './filtered-consumption-table';
import {PlaceConsumption} from './place-consumption';

/** Consumption view */
export const ConsumptionView: FunctionComponent = () => {
  const [filter, setFilter] = useFilterState<IConsumptionFilterValues>();
  
  return (
    <>
      <ConsumptionFilter onChange={setFilter} />
      <View>
        <FilterOutput filter={filter}>
          <FilteredConsumptionTable filter={filter} />
          <Box mt={5}>
            <PlaceConsumption consumptionFilter={filter} />
          </Box>
        </FilterOutput>
      </View>
    </>
  );
};