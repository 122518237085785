/**
 * @file Persist store state between page visits
 * 
 * @see https://github.com/rt2zz/redux-persist#readme
 */

import {createFilter} from 'redux-persist-transform-filter';
import {persistReducer} from 'redux-persist';
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1';
import storage from 'redux-persist/es/storage';
import {rootReducer, AppState} from 'reducers';
import {AppReducerKey} from 'reducers';
import {PERSISTED_STATE_KEYS} from 'config/store';

const whitelist = Object.keys(PERSISTED_STATE_KEYS) as AppReducerKey[];

const transforms = Object.entries(PERSISTED_STATE_KEYS).map(([key, paths]) => {
  return createFilter(key, paths);
});

export const persistedReducer = persistReducer<AppState>({
  storage,
  key: 'vesk',
  whitelist,
  transforms,
  stateReconciler: autoMergeLevel1
}, rootReducer);
