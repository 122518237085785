import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IPasswordResetRequest} from './password-reset.types';

/** Reset the user password */
export const passwordReset = async (request: IPasswordResetRequest) => {
  const response = await axios.post(urls.passwordReset(), request, {
    headers: headers.language()
  });
  return response.data;
};
