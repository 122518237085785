import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Pagination, usePagination} from 'components/ui/pagination';

/** Pagination control preview */
export const PaginationKit: FunctionComponent = () => {
  const {t} = useTranslation();
  const {currentPage, setCurrentPage} = usePagination({
    defaultPage: 50
  });

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.pagination.heading')}
      </Typography>
      <Pagination
        pageSize={7}
        totalItemCount={693}
        currentPage={currentPage}
        onChange={setCurrentPage}
      />
    </>
  );
};
