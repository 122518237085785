import './tree-item.scss';
import React, {forwardRef, useMemo} from 'react';
import {TreeItemProps} from './tree-item.types';
import MuiTreeItem from '@material-ui/lab/TreeItem';

/**
 * Tree view item
 * 
 * @see src/views/app/kit-view/tree-view-kit
 *
 * @example
 *
 *   import {TreeView, TreeItem} from 'components/ui/tree-item';
 *
 *   <TreeView>
 *     <TreeItem
 *       nodeId="a"
 *       label="Aaa aaa aaa"
 *     >
 *       <TreeItem
 *         nodeId="a1"
 *         label="111 111 111"
 *       />
 *       <TreeItem
 *         nodeId="a2"
 *         label="222 222 222"
 *       />
 *       <TreeItem
 *         nodeId="a3"
 *         label="333 333 333"
 *       />
 *     </TreeItem>
 *   </TreeView>
 */
export const TreeItem = forwardRef<HTMLDivElement, TreeItemProps>(function TreeItem(props, ref) {
  const {
    className,
    children,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-tree-item';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <MuiTreeItem
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiTreeItem>
  );
});
