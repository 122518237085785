import './filter-output.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {useTheme} from 'components/ui/theme';
import {FILTER_MESSAGE} from 'components/ui/filter';
import {IFilterOutputProps} from './filter-output.types';
import {ContainerQuery, IQueryClasses} from 'components/ui/container-query';
import {Preloader} from 'components/ui/preloader';
import {Grow} from 'components/ui/grow';
import {Fade} from 'components/ui/fade';
import UpArrowIcon from '@material-ui/icons/ArrowUpward';
import {Typography} from 'components/ui/typography';
import {Collapse} from 'components/ui/collapse';

/**
 * Filtered output container
 *
 * @example
 * 
 *   import React, {FunctionComponent, useState} from 'react';
 *   import {MyFilter, IMyFilterValues} from './my-filter';
 *   import {useFilterState, FilterOutput} from 'components/ui/filter';
 *   import {SomeTable} from './some-table';
 * 
 *   // ...
 * 
 *   const [filter, setFilter] = useFilterState<IMyFilterValues>();
 * 
 *   // ...
 * 
 *   <MyFilter onChange={setFilter} />
 *   <FilterOutput filter={filter}>
 *     <SomeTable filter={filter} />
 *   </FilterOutput>
 */
export const FilterOutput: FunctionComponent<IFilterOutputProps> = (props) => {
  const {className, filter, children} = props;
  const theme = useTheme();
  const {t} = useTranslation();
  const visible = !filter.isPending && filter.isComplete && !filter[FILTER_MESSAGE];

  const queryClasses = useMemo<IQueryClasses>(() => ({
    'ui-filter-output--narrow': {maxWidth: 320}
  }), []);

  const classes = useMemo(() => {
    let classes = 'ui-filter-output';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);  

  return (
    <div className={classes}>
      <Preloader
        visible={filter.isPending}
        display="container"
      />

      {/* Incomplete filter message */}

      <Grow
        timeout={theme.speed.slow}
        in={!filter.isPending && (!filter.isComplete || !!filter[FILTER_MESSAGE])}
      >
        <ContainerQuery
          queryClasses={queryClasses}
          className="ui-filter-output__container"
        >
          <div className="ui-filter-output__content">
            <UpArrowIcon className="ui-filter-output__icon" />
            <Typography
              variant="body1"
              className="ui-filter-output__message"
            >
              {!filter.isComplete && !filter[FILTER_MESSAGE] && (
                t('components.ui.filter.output.incomplete_filter')
              )}
              {filter[FILTER_MESSAGE]}
            </Typography>
          </div>
        </ContainerQuery>
      </Grow>

      {/* Filtered list */}
      
      <Collapse in={visible}>
        <Fade in={visible}>
          <div>
            {children}
          </div>
        </Fade>
      </Collapse>
    </div>
  );
};