import './form-field-error.scss';
import React, {FunctionComponent, useMemo, useEffect, useState, useCallback} from 'react';
import {IFormFieldErrorProps} from './form-field-error.types';
import {Collapse} from 'components/ui/collapse';

/**
 * Form field error message
 * 
 * @example
 *   
 *   <FormFieldError>
 *     {errorMessage}
 *   </FormFieldError>
 */
export const FormFieldError: FunctionComponent<IFormFieldErrorProps> = (props) => {
  const {children} = props;
  const [errorMessage, setErrorMessage] = useState<string | null | undefined | false>();

  // Original message should be visible while component is collapsing
  useEffect(() => {
    if (children)
      setErrorMessage(children);
  }, [children]);

  const scrollEvent = useMemo<CustomEvent>(() => {
    return new CustomEvent('scroll', {bubbles: true});
  }, []);
  
  const refreshScrollPosition = useCallback(() => {
    document.body.dispatchEvent(scrollEvent);
  }, [scrollEvent]);

  return (
    <Collapse
      in={!!children}
      onEntered={refreshScrollPosition}
      onExited={refreshScrollPosition}
    >
      <div className="ui-form-field-error">
        {errorMessage}
      </div>
    </Collapse>
  );
};