/** @file Color helpers */

import Color from 'color';
import {InputValidationException} from 'lib/global/exceptions';

/**
 * Validate any HEX, RGB or HSL color
 *
 * @example
 *
 *   //-- Valid
 *
 *   isColor('#111');              // true
 *   isColor('#222222');           // true
 *   isColor('rgb(3, 3, 3)');      // true
 *   isColor('rgba(4%,4,4%,0.4)'); // true
 *   isColor('hsl(5,5,5)');        // true
 *   isColor('hsla(6,6,6,0.06)');  // true
 *
 *   //--- Invalid
 *
 *   isColor('#11');               // false
 *   isColor('rgb(2,2,2,2)');      // false
 *   isColor('rgba(3,3,3,33)');    // false
 *   isColor('hsla(4,4,4,4)');     // false
 */
export const isColor = (color: string) => {
  const isColor = /(#([\da-f]{3}){1,2}|(rgb|hsl)a\((\d{1,3}%?,\s?){3}(1|0?\.\d+)\)|(rgb|hsl)\(\d{1,3}%?(,\s?\d{1,3}%?){2}\))/ig;
  return isColor.test(color);
};

/**
 * Mix two colors taking into account their alpha channels
 *
 * @param background - Background color
 * @param overlay - Overlay color to be mixed with the background color
 * @returns Resulting color
 * @throws {InputValidationException}
 *
 * @example
 *
 *   const background = '#fff';
 *   const overlay = 'rgba(0, 255, 0, 0.1)';
 *   const mixed = mixColorOverlay(background, overlay); // #e5ffe5
 */
export const mixColorOverlay = (background: string, overlay: string): string => {
  if (!isColor(background))
    throw new InputValidationException('Invalid "background" parameter argument. Expected HEX, RGB or HSL color.');
  if (!isColor(overlay))
    throw new InputValidationException('Invalid "overlay" parameter argument. Expected HEX, RGB or HSL color.');

  const backgroundColor = Color(background);
  const {r: r1, g: g1, b: b1} = backgroundColor.rgb().object();
  const a1 = backgroundColor.alpha();

  const overlayColor = Color(overlay);
  const {r: r2, g: g2, b: b2} = overlayColor.rgb().object();
  const a2 = overlayColor.alpha();

  const a = (2 - a1) * a2 + a1;
  const r = ((2 - a1) * a2 * r2 + a1 * r1) / a;
  const g = ((2 - a1) * a2 * g2 + a1 * g1) / a;
  const b = ((2 - a1) * a2 * b2 + a1 * b1) / a;

  return Color.rgb(r, g, b).alpha(a).string();
};
