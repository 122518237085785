import {EffectCallback, DependencyList, useEffect, useMemo} from 'react';

/**
 * Use effect while deep comparing object dependencies
 * 
 * @example
 * 
 *   const [object, setObject] = useState({a: {b: 1}});
 *   
 *   useDeepEffect(() => {
 *     console.log('Effect has fired.');
 *   }, [object]);
 *   
 *   // Objects will be equal, effect will not fire
 *   const effectWillNotFire = useCallback(() => {
 *     setObject({a: {b: 1}});
 *   }, []);
 *   
 *   // ...
 *   
 *   <button onClick={effectWillNotFire}>
 *     Effect will not fire
 *   </button>
 */
export const useDeepEffect = (callback: EffectCallback, dependencies: DependencyList) => {
  const wrappedDependencies = useMemo(() => {
    return dependencies.map((dependency) => {
      if (typeof dependency === 'object')
        return JSON.stringify(dependency);
      return dependency;
    });
  }, [dependencies]);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, wrappedDependencies);
};
