import {AppState} from 'reducers';
import {typedPath} from 'typed-path';
import {PersistedStateKeys} from './redux-persist.types';

/** Store state to persist between page visits */
export const PERSISTED_STATE_KEYS: PersistedStateKeys = {
  auth: [
    typedPath<AppState['auth']>().accessToken.toString(),
    typedPath<AppState['auth']>().tokenType.toString(),
    typedPath<AppState['auth']>().refreshToken.toString()
  ],
  intl: [
    typedPath<AppState['intl']>().locale.toString()
  ],
  ui: [
    typedPath<AppState['ui']>().theme.toString(),
    typedPath<AppState['ui']>().cookies.approved.toString()
  ],
  user: [
    typedPath<AppState['user']>().login.isLoggedIn.toString(),
    typedPath<AppState['user']>().detail.data.toString(),
    typedPath<AppState['user']>().partner.activePartnerId.toString()
  ]
};
