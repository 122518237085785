import {Exception} from 'lib/global/exceptions';
import Ajv from 'ajv';
import {JsonObject} from './json-schema.types';

/**
 * Validate JSON object against JSON schema
 *
 * @param json - Data to validate
 * @param schemas - Array of JSON schemas to include
 * @param schemaId - ID of schema to validate against
 * @param errorMessage - Error to throw on failed validation
 * @throws {Exception}
 */
export const validateJsonSchema = (json: JsonObject, schemas: JsonObject[], schemaId: string, errorMessage: string) => {
  const ajv = new Ajv({schemas});
  const validate = ajv.getSchema(schemaId);
  if (!validate)
    throw new Exception(`Schema with id "${schemaId}" not found.`);
  if (!validate(json)) {
    const errors = ajv.errorsText(validate.errors);
    const message = `${errorMessage}\n${errors}`;
    throw new Exception(message);
  }
};
