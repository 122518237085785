import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useDeepEffect} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {useFetch} from 'hooks';
import {api} from 'api';
import {format} from 'lib/intl/format';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {IPaymentsTableContainerProps} from './filtered-payments-table.types';
import {PaymentsTable} from 'components/payment/payments-table';
import {Pagination, usePagination} from 'components/ui/pagination';

const ROWS_PER_PAGE = 7;

/** Table of filtered payments */
export const FilteredPaymentsTable: FunctionComponent<IPaymentsTableContainerProps> = (props) => {
  const {filter} = props;
  const payments = useFetch(api.paymentList);
  const {currentPage, setCurrentPage} = usePagination();
  const {t} = useTranslation();

  const fetchOptions = useMemo(() => {
    const {contractId, from, to} = filter.values;
    return {
      contractId,
      from: from && format.requestDate(from),
      to: to && format.requestDate(to)
    };
  }, [filter.values]);

  /** Get the data to be displayed in the payments table */
  const fetchTableData = useCallback(() => {
    if (filter.isComplete)
      payments.fetch({
        ...fetchOptions,
        page: currentPage - 1,
        size: ROWS_PER_PAGE
      });
  }, [currentPage, filter.isComplete, fetchOptions, payments]);

  /** Get all payments data for export */
  const fetchExportData = useCallback((paging?: IPagingRequest) => {
    return api.paymentList({
      ...fetchOptions,
      ...paging
    });
  }, [fetchOptions]);

  // Fetch data on page load
  useEffect(() => {
    fetchTableData();
  // eslint-disable-next-line
  }, []);

  // Reset pagination on filter change
  useDeepEffect(() => {
    if (filter.isComplete)
      setCurrentPage(1);
  }, [filter.values, filter.isComplete]);

  // Fetch data on any change
  useDeepEffect(() => {
    fetchTableData();
  }, [filter.values, filter.isComplete, currentPage]);

  const render = useCallback(() => (
    <>
      <PaymentsTable
        payments={payments.data!.content}
        exportDataSource={fetchExportData}
      />
      <Pagination
        gutterTop
        pageSize={ROWS_PER_PAGE}
        totalItemCount={payments.data!.totalElements}
        currentPage={currentPage}
        onChange={setCurrentPage}
      />
    </>
  ), [currentPage, fetchExportData, payments.data, setCurrentPage]);

  return (
    <WaitForFetch
      pending={payments.pending}
      error={payments.error}
      noData={!payments.data?.content?.length}
      noDataMessage={t('views.payment.table.no_data')}
      onRetry={fetchTableData}
      render={render}
      absolutePosition
    />
  );
};