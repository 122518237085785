import './radio.scss';
import React, {forwardRef, useMemo} from 'react';
import {IRadioProps} from './radio.types';
import MuiRadio from '@material-ui/core/Radio';
import {FormControlLabel} from 'components/ui/form-control-label';

/**
 * Radio button field
 * 
 * @see src/views/app/kit-view/radio-kit
 *
 * @example
 *
 *   <Radio
 *     label="Some label"
 *     checked={checked}
 *     onChange={handleChange}
 *   />
 */
export const Radio = forwardRef<HTMLButtonElement, IRadioProps>(function Radio(props, ref) {
  const {
    className,
    label,
    size,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-radio';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  const control = useMemo(() => (
    <MuiRadio
      ref={ref}
      {...restProps}
      size={size}
      className={classes}
    />
  ), [classes, ref, restProps, size]);

  if (label)
    return (
      <FormControlLabel
        className={classes}  
        control={control}
        label={label}
        size={size}
      />
    );
  
  return control;
});

