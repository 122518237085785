import './data-table-menu.scss';
import React, {FunctionComponent, useState, MouseEvent, useCallback, useMemo} from 'react';
import {saveAs} from 'file-saver';
import {useSnackbar} from 'components/ui/snackbar';
import {useTranslation} from 'lib/intl/i18n';
import {IDataTableExportProps} from './data-table-menu.types';
import {DataTableExportData} from '../data-table.types';
import {Fade} from 'components/ui/fade';
import {Preloader} from 'components/ui/preloader';
import MenuIcon from '@material-ui/icons/MoreVert';
import DownloadIcon from '@material-ui/icons/GetApp';
import {DataMenu, DataMenuItems} from 'components/ui/data-menu';

/** Data table menu */
export const DataTableMenu: FunctionComponent<IDataTableExportProps> = (props) => {
  const {exportData, exportFilename} = props;
  const [menuAnchor, setMenuAnchor] = useState<HTMLDivElement | null>(null);
  const [pending, setPending] = useState(false);
  const {showSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const openMenu = useCallback((event: MouseEvent<HTMLDivElement>) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const filename = useMemo(() => {
    const filename = exportFilename || t('components.ui.data_table.export.filename');
    return `${filename}.csv`;
  }, [exportFilename, t]);

  const arrayToCsv = useCallback((array: DataTableExportData) => {
    if (!array)
      return '';
    const str = array.map((row) =>
      row.map((cell) => {
        const escaped = cell !== undefined && cell !== null ? cell.toString().replace(/"/g, '""') : '';
        return `"${escaped}"`;
      }).join(';')
    ).join('\r\n');
    return `\uFEFF${str}`;
  }, []);

  const exportCsv = useCallback(async () => {
    if (exportData) {
      setPending(true);
      try {
        const data = await exportData();
        const csvString = arrayToCsv(data);
        const csvBlob = new Blob([csvString], {
          type: 'text/csv;charset=UTF-8;'
        });
        saveAs(csvBlob, filename);
      }
      catch {
        showSnackbar(t('components.ui.data_table.export.error'), {variant: 'error'});
      }
      finally {
        setPending(false);
      }
    }
  }, [arrayToCsv, exportData, filename, showSnackbar, t]);

  const handleExportClick = useCallback(() => {
    exportCsv();
    closeMenu();
  }, [closeMenu, exportCsv]);

  const menuItems = useMemo<DataMenuItems>(() => [
    {
      icon: (<DownloadIcon />),
      label: t('components.ui.data_table.menu.export'),
      onClick: handleExportClick
    }
  ], [handleExportClick, t]);

  const classes = useMemo(() => {
    let classes = 'ui-data-table-menu';
    if (pending)
      classes += ' ui-data-table-menu--pending';
    return classes;
  }, [pending]);   
  
  if (!exportData)
    return null;

  return (
    <>

      {/* Button */}

      <div
        className={classes}
        onClick={openMenu}
      >
        <Fade in={!pending}>
          <MenuIcon className="ui-data-table-menu__icon" />
        </Fade>
        <Preloader
          display="container"
          visible={pending}
          size="tiny"
          contrast="text"
        />
      </div>

      {/* Menu */}

      <DataMenu
        open={menuAnchor !== null}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        items={menuItems}
      />
    </>
  );
};