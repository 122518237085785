import React, {FunctionComponent} from 'react';
import {View} from 'components/ui/view';
import {DemandsFilter, IDemandsFilterValues} from './demands-filter';
import {useFilterState, FilterOutput} from 'components/ui/filter';
import {FilteredDemandsTable} from './filtered-demands-table';

/** Requested demands view */
export const DemandsView: FunctionComponent = () => {
  const [filter, setFilter] = useFilterState<IDemandsFilterValues>();

  return (
    <>
      <DemandsFilter onChange={setFilter} />
      <View>
        <FilterOutput filter={filter}>
          <FilteredDemandsTable filter={filter} />
        </FilterOutput>
      </View>
    </>
  );
};