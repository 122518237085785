import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types'; 
import memoize from 'fast-memoize';
import {t} from 'lib/intl/i18n';
import {dateTime as validateDateTime} from 'lib/global/validate/date-time';
import {dateTime as parseDateTime} from 'lib/intl/parse/date-time';
import {date as formatDate} from 'lib/intl/format/date';
import {time as formatTime} from 'lib/intl/format/time';
import {logWarningOnDev} from 'lib/global/dev';

/**
 * Get minimum date/time validator
 *
 * @param minimumDateTime - Minimum date/time value
 */
export const minDateTime = memoize((minimumDateTime: AnyDate) => {
  if (!parseDateTime(minimumDateTime).isValid())
    logWarningOnDev(t('lib.global.validate.date_time.invalid'));

  /**
   * Validate minimum date/time
   *
   * @param input - Date/time to validate
   * @returns Error message or undefined
   */
  const minDateTime: IValidator<AnyDate> = (input) => {
    if (input) {
      const isValid = !validateDateTime(input);
      const isSameOrAfter = parseDateTime(input).isSameOrAfter(parseDateTime(minimumDateTime));
      if (isValid && !isSameOrAfter) {
        const date = formatDate(minimumDateTime);
        const time = formatTime(minimumDateTime);
        return t('lib.global.validate.date_time.min', {date, time});
      }
    }
  };

  return minDateTime;
});

/**
 * Check that the minimum date/time validator doesn't return any error
 *
 * @param input - Date/time to validate
 * @param minimumDateTime - Minimum date/time value
 */
export const isMinDateTime: ValidatorQuery = (input, minimumDateTime: AnyDate) => {
  return !!input && !minDateTime(minimumDateTime)(input);
};
