import {Reducer} from 'redux';
import {
  LoginAction,
  LoginActionTypes,
  ILogoutRequestAction,
  LogoutActionTypes
} from 'actions';
import {ILoginState} from './login.types';

export const initialState: ILoginState = {
  pending: false,
  error: null,
  isLoggedIn: false,
  logoutReason: null
};

/** User login */
export const login: Reducer<ILoginState, (LoginAction | ILogoutRequestAction)> = (state = initialState, action): ILoginState => {
  switch (action.type) {
    case LoginActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case LoginActionTypes.success:
      return {
        ...state,
        pending: false,
        isLoggedIn: true,
        logoutReason: null
      };
    case LoginActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    case LogoutActionTypes.request:
      return {
        ...state,
        logoutReason: action.payload.logoutReason
      };
    default:
      return state;
  }
};
