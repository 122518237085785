import './kit-view.scss';
import React, {FunctionComponent, useState} from 'react';
import {View} from 'components/ui/view';
import {Container} from 'components/ui/container';
import {Divider} from 'components/ui/divider';
import {ButtonKit} from './button-kit';
import {SidebarKit} from './sidebar-kit';
import {MenuKit} from './menu-kit';
import {PreloaderKit} from './preloader-kit';
import {SnackbarKit} from './snackbar-kit';
import {AlertKit} from './alert-kit';
import {DialogKit} from './dialog-kit';
import {BannerKit} from './banner-kit';
import {ChartKit} from './chart-kit';
import {TooltipKit} from './tooltip-kit';
import {ToggleButtonKit} from './toggle-button-kit';
import {IconButtonKit} from './icon-button-kit';
import {SliderKit} from './slider-kit';
import {TreeViewKit} from './tree-view-kit';
import {ListKit} from './list-kit';
import {OrderedListKit} from './ordered-list-kit';
import {BadgeKit} from './badge-kit';
import {CheckboxKit} from './checkbox-kit';
import {RadioKit} from './radio-kit';
import {TextFieldKit} from './text-field-kit';
import {ComboBoxKit} from './combo-box-kit';
import {DateTimeKit} from './date-time-kit';
import {FormKit} from './form-kit';
import {CardKit} from './card-kit';
import {TableKit} from './table-kit';
import {PaginationKit} from './pagination-kit';
import {TabsKit} from './tabs-kit';
import {AccordionKit} from './accordion-kit';
import {BreadcrumbsKit} from './breadcrumbs-kit';
import {GridKit} from './grid-kit';
import {KitPagination} from './kit-pagination';

/** UI controls preview */
export const KitView: FunctionComponent = () => {
  const [page, setPage] = useState(0);

  return (
    <View className="ui-kit-view">
      <Container maxWidth="lg">
        {page === 0 && (
          <>
            <ButtonKit />
            <Divider />
            <SidebarKit />
            <Divider />
            <MenuKit />
            <Divider />
            <PreloaderKit />
            <Divider />
            <DialogKit />
            <Divider />
            <AlertKit />
            <Divider />
            <SnackbarKit />
            <Divider />
            <ChartKit />
            <Divider />
            <BannerKit />
          </>
        )}
        {page === 1 && (
          <>
            <TooltipKit />
            <Divider />
            <ToggleButtonKit />
            <Divider />
            <IconButtonKit />
            <Divider />
            <SliderKit /> 
            <Divider />
            <ListKit />
            <Divider />
            <TreeViewKit />
            <Divider />
            <OrderedListKit />
            <Divider />
            <BadgeKit />
            <Divider />
            <CheckboxKit />
            <Divider />
            <RadioKit />
            <Divider />
            <TextFieldKit />
            <Divider />
            <ComboBoxKit />
            <Divider />
            <DateTimeKit />
          </>
        )}
        {page === 2 && (
          <>
            <FormKit /> 
            <Divider />
            <CardKit />
            <Divider />
            <TableKit />
            <Divider />
            <PaginationKit />
            <Divider />
            <TabsKit />
            <Divider />
            <AccordionKit />
            <Divider />
            <BreadcrumbsKit />
            <div className="ui-kit-view__grid">
              <Divider />
              <GridKit />
            </div>
          </>
        )}
        <Divider />
        <KitPagination
          page={page}
          onChange={setPage}
        />
      </Container>
    </View>
  );
};