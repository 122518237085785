import React, {FunctionComponent, useEffect, useMemo} from 'react';
import {IRouteProps} from './route.types';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';
import {gtm} from 'lib/global/gtm';
import {paths} from 'config/router';
import {Route as ReactRoute, useLocation, Navigate/*, matchPath*/} from 'react-router-dom';
import {matchPath} from 'lib/global/react-router';
import {Authenticate} from './authenticate';

/**
 * Page route
 *
 * @example
 * 
 *   <Route
 *     path="/some-path"
 *     element={<SomeView />}
 *     title="Some title"
 *   />
 */
export const Route: FunctionComponent<IRouteProps> = (props) => {
  const {
    title,
    path,
    active,
    element: elementProp,
    authenticate,
    ...routeProps
  } = props;
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);
  const activePartnerId = useSelector(selectors.activePartnerId, shallowEqual);
  const partnerSelect = userIsLoggedIn && activePartnerId === null;
  const {pathname} = useLocation();
  const currentPathIsActive = matchPath(path || '', pathname) !== null;
  const {t} = useTranslation();

  const element = useMemo(() => {
    if (partnerSelect && !matchPath(paths.partnerSelect, pathname))
      return (<Navigate to={paths.partnerSelect} />);
    if (authenticate)
      return (
        <Authenticate>
          {elementProp}
        </Authenticate>
      );
    return elementProp;
  }, [partnerSelect, pathname, authenticate, elementProp]);

  useEffect(() => {
    if (currentPathIsActive) {
      let pageTitle = t('config.router.title.root');
      if (title)
        pageTitle = `${pageTitle} - ${title}`;
      document.title = pageTitle;
      gtm.pushToDataLayer({event: 'javaPageview'});
    }
  }, [currentPathIsActive, t, title]);
  
  if (!active)
    return null;

  return (
    <ReactRoute
      {...routeProps}
      path={path}
      element={element}
    />
  );
};

Route.defaultProps = {
  active: true
}; 