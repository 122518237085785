import React, {FunctionComponent, Context, useContext} from 'react';
import {useWatchEffect} from 'hooks';
import {IFilterFieldContextValue, IFilterFieldProps} from './filter-field.types';

/** Create Filter field wrapper component */
export const createFilterField = (FilterFieldContext: Context<IFilterFieldContextValue>) => {

  /**
   * Filter field wrapper
   *
   * @example
   * 
   *   import {useContext} from 'react';
   *   import {FilterContext} from './my-filter-context';
   *   import {FilterComboBox} from 'components/ui/filter-combo-box';
   * 
   *   // ...
   * 
   *   const {FilterField} = useContext(FilterContext);
   * 
   *   // ...
   *
   *   <FilterField
   *     pending={someList.pending}
   *     error={someList.error}
   *   >
   *     <FilterComboBox
   *       options={someList.data}
   *       ...
   *     />
   *   </FilterField>
   */
  const FilterField: FunctionComponent<IFilterFieldProps> = (props) => {
    const {pending, error, children} = props;
    const {setPending, setError} = useContext(FilterFieldContext);

    useWatchEffect(() => {
      setPending(!!pending);
    }, [pending, setPending]);

    useWatchEffect(() => {
      if (error)
        setError(error);
    }, [error]);

    return (<>{children}</>);
  };

  return FilterField;
};