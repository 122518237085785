export enum LogoutActionTypes {
  request = 'LOGOUT_REQUEST'
}

export type LogoutReason = (
  'userLogout' |
  'tokenExpired' |
  'tokenRefreshFail'
)

export interface ILogoutRequestPayload {
  logoutReason: LogoutReason;
}

export interface ILogoutRequestAction {
  type: LogoutActionTypes.request;
  payload: ILogoutRequestPayload;
}