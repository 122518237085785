import './mui-pickers-provider.scss';
import React, {FunctionComponent} from 'react';
import {LocalizationProvider} from '@material-ui/pickers';
import MomentUtils from '@material-ui/pickers/adapter/moment';

/**
 * Material UI pickers provider
 *
 * @see https://material-ui-pickers.dev/releases
 *
 * @example
 *
 *   <MuiPickersProvider>
 *     <App />
 *   </MuiPickersProvider>
 */
export const MuiPickersProvider: FunctionComponent = (props) => {
  const {children} = props;

  return (
    <LocalizationProvider dateAdapter={MomentUtils}>
      {children}
    </LocalizationProvider>
  );
};
