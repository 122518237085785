import {Reducer} from 'redux';
import {UploadAction, UploadActionTypes} from '../upload-actions';
import {UploadState} from './upload-reducer.types';

/** Generate unique file key */
const getFileKey = (file: File) => {
  const {name, size, lastModified} = file;
  return `${name}_${size}_${lastModified}`;
};

const initialState: UploadState = {};

/** File upload state logic */
export const uploadReducer: Reducer<UploadState, UploadAction> = (files = initialState, action): UploadState => {
  switch (action.type) {

    // Add files
    case UploadActionTypes.addFiles: {
      const newFiles = {...files};
      for (const file of action.payload.files)
        newFiles[getFileKey(file)] = {file, progress: 0};
      return newFiles;
    }

    // Remove file
    case UploadActionTypes.removeFile: {
      const newFiles = {...files};
      delete newFiles[getFileKey(action.payload.file)];
      return newFiles;
    }

    // Set file state
    case UploadActionTypes.setFileState: {
      const fileKey = getFileKey(action.payload.file);
      const oldState = files[fileKey] || {};
      return {
        ...files,
        [fileKey]: {...oldState, ...action.payload.state}
      };
    }    

    default:
      return files;
  }
};