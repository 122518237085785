import './theme.scss';
import React, {FunctionComponent, useEffect, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {themes, subThemes} from './themes';
import {variables} from './variables';
import {IThemeContext} from './theme.types';
import {ThemeContext} from './theme-context';
import {MaterialUI} from './material-ui';
import {MissingPropertyException} from 'lib/global/exceptions';

/**
 * Theme provider (contains values extracted from the theme/config folder)
 *
 * @example
 * 
 *   import {Theme} from 'components/ui/theme';
 * 
 *   // ...
 *
 *   <Theme>
 *     <App />
 *   </Theme>
 *
 * @example
 * 
 *   import {useTheme} from 'components/ui/theme';
 * 
 *   // ...
 *
 *   const theme = useTheme();
 *   console.log(theme.color.primary);
 */
export const Theme: FunctionComponent = (props) => {
  const {children} = props;
  const themeName = useSelector(selectors.theme);

  const themeContext: IThemeContext = useMemo(() => {
    if (!themes[themeName])
      throw new MissingPropertyException(`Theme "${themeName}" not found.`);
    return {
      ...variables,
      color: themes[themeName],
      themes: Object.keys(themes),      
      subThemes
    };
  }, [themeName]);

  useEffect(() => {
    document.documentElement.setAttribute('id', `ui-theme--${themeName}`);
    
    // Trigger page repaint
    document.documentElement.style.visibility = 'hidden';
    setTimeout(() => {
      document.documentElement.style.visibility = '';
    }, 20);
  }, [themeName]);

  return (
    <ThemeContext.Provider value={themeContext}>
      <MaterialUI>
        {children}
      </MaterialUI>
    </ThemeContext.Provider>
  );
};
