import React, {ChangeEvent, FunctionComponent, useCallback, useMemo} from 'react';
import {IIntegerFieldProps} from './integer-field.types';
import {TextField} from 'components/ui/text-field';

/**
 * Whole number text field
 *
 * @example
 *
 *   const [value, setValue] = useState('123');
 *   
 *   const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
 *     setValue(event.target.value);
 *   }, []);
 *
 *   // ...
 * 
 *   <IntegerField
 *     value={value}
 *     onChange={handleChange}
 *   />
 */
export const IntegerField: FunctionComponent<IIntegerFieldProps> = (props) => {
  const {
    unsigned,
    align = 'right',
    onChange,
    ...fieldProps
  } = props;
  
  const inputProps = useMemo(() => ({
    ...props.inputProps,
    pattern: unsigned ? '(^[1-9][0-9]*$)|^0$' : '(^[-,1-9][0-9]*$)|^0$'
  }), [props.inputProps, unsigned]);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value, 10);
    const emptyValue = !event.target.value;
    const {valid} = event.target.validity;
    const safeInteger = isNaN(value) || Math.abs(value) < Number.MAX_SAFE_INTEGER;
    if ((emptyValue || valid && safeInteger) && onChange)
      onChange(event);
  }, [onChange]);

  return (
    <TextField
      {...fieldProps}
      type="text"
      inputProps={inputProps}
      align={align}
      onChange={handleChange}
    />
  );
};