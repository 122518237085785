import {useRef, useEffect, EffectCallback, DependencyList} from 'react';

/**
 * Fire effect on each dependency mutation.
 * Unlike default useEffect this hook awaits for the first mutation.
 *
 * @param effect - Effect callback
 * @param deps - Effect dependencies
 * 
 * @example
 * 
 *   useWatchEffect(() => {
 *     if (!props.pending)
 *       ...
 *   }, [props.pending]);
 */
export const useWatchEffect = (effect: EffectCallback, deps: DependencyList) => {
  const ref = useRef(false);
  useEffect(() => {
    if (ref.current)
      effect();
    ref.current = true;
  // eslint-disable-next-line
  }, deps);
};
