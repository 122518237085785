import {useCallback, useMemo, useRef, useState} from 'react';
import {useTheme} from 'components/ui/theme';
import {IAutocompleteProps} from '../autocomplete.types';
import {IUseAutocompleteContainerProps, AutocompleteClasses} from './use-autocomplete-container.types';

/** Autocomplete container related logic */
export const useAutocompleteContainer = (props: IAutocompleteProps): IUseAutocompleteContainerProps => {
  const {value} = props;
  const [open, setOpen] = useState(false);
  const [popperOpen, setPopperOpen] = useState(open);
  const popperTimeout = useRef<Timeout | null>(null);
  const theme = useTheme();

  const handleOpen = useCallback(() => {
    if (popperTimeout.current)
      clearTimeout(popperTimeout.current);
    setOpen(true);
    popperTimeout.current = setTimeout(() => setPopperOpen(true), 20);
  }, []);

  const handleClose = useCallback(() => {
    if (popperTimeout.current)
      clearTimeout(popperTimeout.current);
    setPopperOpen(false);
    popperTimeout.current = setTimeout(() => {
      setOpen(false);
    }, theme.speed.fast);
  }, [theme.speed.fast]);

  const rootClasses = useMemo(() => {
    let classes = 'ui-autocomplete';
    if (popperOpen)
      classes += ' ui-autocomplete--open';
    if (value === null || value === undefined)
      classes += ' ui-autocomplete--empty';
    return classes;
  }, [popperOpen, value]);

  const popperClasses = useMemo(() => {
    let classes = 'ui-autocomplete-popper';
    if (popperOpen)
      classes += ' ui-autocomplete-popper--open';
    return classes;
  }, [popperOpen]);

  const classes = useMemo<AutocompleteClasses>(() => ({
    root: rootClasses,
    popper: popperClasses,
    inputRoot: 'ui-autocomplete__field',
    input: 'ui-autocomplete__input'
  }), [popperClasses, rootClasses]);

  return {
    handleOpen,
    handleClose,
    classes,
    open,
    popperOpen
  };
};
