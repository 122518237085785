import './view.scss';
import React, {forwardRef, useMemo} from 'react';
import {IViewProps} from './view.types';
import {Container} from 'components/ui/container';

/**
 * Page container
 *
 * @example
 *
 *   <View className="ui-home-view">
 *     Welcome!
 *   </View>
 */
export const View = forwardRef<HTMLDivElement, IViewProps>(function View(props, ref) {
  const {
    className,
    maxWidth,
    children,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-view';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <Container
      {...restProps}
      ref={ref}
      className={classes}
      maxWidth={maxWidth}
    >
      {children!}
    </Container>
  );
});
