import React, {FunctionComponent, useCallback, useState, MouseEvent, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {ButtonActions} from 'components/ui/button-actions';
import {Typography} from 'components/ui/typography';
import {Button} from 'components/ui/button';
import {DataMenu, DataMenuItems} from 'components/ui/data-menu';
import MenuIcon from '@material-ui/icons/ListAlt';
import PartnerIcon from '@material-ui/icons/People';
import SettingsIcon from '@material-ui/icons/Tune';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';

/** Context menu preview */
export const MenuKit: FunctionComponent = () => {
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const {t} = useTranslation();

  const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const menuItems = useMemo<DataMenuItems>(() => [
    {
      icon: (<PartnerIcon />),
      label: t('views.app.ui_kit.menu.item.select_partner'),
      onClick: closeMenu
    },
    {
      icon: (<SettingsIcon />),
      label: t('views.app.ui_kit.menu.item.settings'),
      onClick: closeMenu
    },
    {
      icon: (<LogoutIcon />),
      label: t('views.app.ui_kit.menu.item.logout'),
      onClick: closeMenu
    }
  ], [closeMenu, t]);

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.menu.heading')}
      </Typography>
      <ButtonActions>
        <Button
          icon={<MenuIcon />}
          onClick={openMenu}
        >
          {t('views.app.ui_kit.menu.open')}
        </Button>
      </ButtonActions>
      <DataMenu
        open={menuAnchor !== null}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        items={menuItems}
      />
    </>
  );
};
