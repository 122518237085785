import './form-control-label.scss';
import React, {forwardRef, useMemo} from 'react';
import {IFormControlLabelProps} from './form-control-label.types';
import MuiFormControlLabel from '@material-ui/core/FormControlLabel';

/**
 * Form control label
 *
 * @example
 *
 *   <FormControlLabel
 *     label="My checkbox"
 *     control={(<Checkbox />)}
 *   />
 */
export const FormControlLabel = forwardRef<HTMLDivElement, IFormControlLabelProps>(function FormControlLabel(props, ref) {
  const {className, size, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-form-control-label';
    classes += ` ui-form-control-label--size-${size}`;
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, size]);

  return (
    <MuiFormControlLabel
      ref={ref}
      {...restProps}
      className={classes}
    />
  );
});

FormControlLabel.defaultProps = {
  size: 'medium'
};