
/**
 * Format bank account
 *
 * @param account - Bank account object
 * @returns Formatted bank account
 */
export const bankAccount = (account: IBankAccount) => {
  let bankAccount = '';
  if (account.accountNumber)
    bankAccount = account.accountNumber;
  if (account.accountPrefix)
    bankAccount = `${account.accountPrefix}-${bankAccount}`;
  if (account.bankCode)
    bankAccount += `/${account.bankCode}`;
  return bankAccount;
};
