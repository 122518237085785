import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {
  useFetch,
  useFetchErrorMessage,
  useFetchSuccessMessage,
  useOnFetchSuccess,
  useRemount
} from 'hooks';
import {api} from 'api';
import {View} from 'components/ui/view';
import {Box} from 'components/ui/box';
import {Typography} from 'components/ui/typography';
import {RevealFade} from 'components/ui/reveal-fade';
import {RegistrationForm, IFormValues, OnSubmit} from './registration-form';
import {Divider} from 'components/ui/divider';


/** User registration view */
export const RegistrationView: FunctionComponent = () => {
  const registration = useFetch(api.userRegistration);
  const viewRemount = useRemount();
  const {t} = useTranslation();

  const refreshView = useCallback(() => {
    viewRemount.trigger();
  }, [viewRemount]);

  const initialValues = useMemo<IFormValues>(() => ({
    privilegedPersonFirstName: '',
    privilegedPersonSurname: '',
    privilegedPersonEmail: '',
    phoneNumber: '',
    companyName: '',
    contractNumber: ''
  }), []);

  const handleSubmit = useCallback<OnSubmit>((values) => {
    registration.fetch(values);
  }, [registration]);

  useFetchErrorMessage([registration], t('views.user.registration.error'));
  
  useFetchSuccessMessage([registration], t('views.user.registration.success'));

  useOnFetchSuccess([registration], refreshView);

  return (
    <View {...viewRemount.props}>
      <RevealFade>
        <Typography
          variant="h2"
          color="primary"
        >
          {t('views.user.registration.heading')}
        </Typography>

        {/* Description */}

        <Box mt={3}>
          <Typography variant="body1">
            {t('views.user.registration.description')}
          </Typography>
        </Box>
        <Box mt={4}>
          <Divider />
        </Box>
        
        {/* Form */}
        
        <Box mt={4}>
          <RegistrationForm
            initialValues={initialValues}
            pending={registration.pending}
            onSubmit={handleSubmit}
          />
        </Box>
      </RevealFade>
    </View>
  );
};