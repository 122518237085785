import {AppState} from 'reducers';

/** Check if login is pending */
export const loginPending = (state: AppState) => {
  return state.user.login.pending;
};

/** Get failed login error */
export const loginError = (state: AppState) => {
  return state.user.login.error;
};

/** Check if the user is authenticated and user detail has loaded */
export const userIsLoggedIn = (state: AppState) => {
  return state.user.login.isLoggedIn;
};

/** Get a reason of last logout */
export const logoutReason = (state: AppState) => {
  return state.user.login.logoutReason;
};

/** Check if user detail is pending */
export const userDetailPending = (state: AppState) => {
  return state.user.detail.pending;
};

/** Get user detail error */
export const userDetailError = (state: AppState) => {
  return state.user.detail.error;
};

/** Get user detail */
export const userDetail = (state: AppState) => {
  return state.user.detail.data;
};

/** Get user partners */
export const userPartners = (state: AppState) => {
  return state.user.detail.data?.partners;
};

/** Get an active partner */
export const activePartnerId = (state: AppState) => {
  return state.user.partner.activePartnerId;
};

/** Get the status of the password change  */
export const passwordChange = (state: AppState) => {
  return state.user.passwordChange;
};

/** Get the status of the password reset confirmation */
export const passwordResetConfirm = (state: AppState) => {
  return state.user.passwordResetConfirm;
};

/** Get the status of the user account activation */
export const userActivation = (state: AppState) => {
  return state.user.activation;
};
