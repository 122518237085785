import {
  IDemandCreationRequestAction,
  IDemandCreationSuccessAction,
  IDemandCreationFailureAction,
  DemandCreationActionTypes
} from './demand-creation.types';
import {IDemandCreationRequest, IDemandCreationResponse} from 'api';

/** Demand creation */
export const demandCreationRequest = (payload: IDemandCreationRequest): IDemandCreationRequestAction => ({
  type: DemandCreationActionTypes.request,
  payload
});

export const demandCreationSuccess = (payload: IDemandCreationResponse): IDemandCreationSuccessAction => ({
  type: DemandCreationActionTypes.success,
  payload
});

export const demandCreationFailure = (payload: IRequestError): IDemandCreationFailureAction => ({
  type: DemandCreationActionTypes.failure,
  payload
});