import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IPaymentListRequest, PaymentPagedListResponse} from './payment-list.types';

/** Get a list of payments */
export const paymentList = async (request?: IPaymentListRequest) => {
  const response = await axios.get<PaymentPagedListResponse>(urls.paymentList(), {
    params: request,
    headers: {
      ...headers.auth(),
      ...headers.language(),
      ...headers.partner()
    }
  });
  return response.data;
};
