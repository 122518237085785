import React, {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Grid} from 'components/ui/grid';
import {FormSlider, Mark} from 'components/ui/form-slider';
import {FormSliderRange} from 'components/ui/form-slider-range';

/** Form kit sliders */
export const Sliders: FunctionComponent = () => {
  const {t} = useTranslation();

  const sliderMarks = useMemo<Mark[]>(() => {
    const values = [0, 20, 40, 60, 80, 100];
    return values.map((value) => ({
      value,
      label: `${value}°C`
    }));
  }, []);

  return (
    <>

      {/* Value */}

      <Grid item md={6}>
        <FormSlider
          name="value"
          label={t('views.app.ui_kit.form.label.slider.value')}
          marks={sliderMarks}
        />
      </Grid>

      {/* Range */}

      <Grid item md={6}>
        <FormSliderRange
          fromName="valueRangeFrom"
          toName="valueRangeTo"
          label={t('views.app.ui_kit.form.label.slider.range')}
          marks={sliderMarks}
        />
      </Grid>
    </>
  );
};