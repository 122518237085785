import './slider-range.scss';
import React, {forwardRef, useCallback, useMemo} from 'react';
import {ISliderRangeProps} from './slider-range.types';
import {FormLabel} from 'components/ui/form-label';
import MuiSlider from '@material-ui/core/Slider';

/**
 * Value range slider
 *
 * @example
 *
 *   const [range, setRange] = useState({
 *     from: 33,
 *     to: 66
 *   });
 *   
 *   const handleChange = useCallback((from: number, to: number) => {
 *     setRange({from, to});
 *   }, []);
 * 
 *   // ...
 *   
 *   <SliderRange
 *     fromValue={range.from}
 *     toValue={range.to}
 *     onChange={handleChange}
 *   />
 */
export const SliderRange = forwardRef<HTMLSpanElement, ISliderRangeProps>(function SliderRange(props, ref) {
  const {
    className,
    label,
    fromName,
    toName,
    fromValue,
    toValue,
    onChange,
    children,
    ...restProps
  } = props;

  const handleChange = useCallback((event: Event, value: (number | number[])) => {
    const [from, to] = value as number[];
    onChange(from, to);
  }, [onChange]);

  const value = useMemo<number[]>(() => [
    fromValue,
    toValue
  ], [fromValue, toValue]);
  
  const classes = useMemo(() => {
    let classes = 'ui-slider';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <>
      <FormLabel>
        {label}
      </FormLabel>

      {/* Form inputs */}

      {fromName && (
        <input
          name={fromName}
          value={fromValue}
          hidden
        />
      )}
      {toName && (
        <input
          name={toName}
          value={toValue}
          hidden
        />
      )}

      {/* Slider */}

      <MuiSlider
        ref={ref}
        {...restProps}
        className={classes}
        value={value}
        onChange={handleChange}
      >
        {children}
      </MuiSlider>
    </>
  );
});

SliderRange.defaultProps = {
  valueLabelDisplay: 'auto'
};