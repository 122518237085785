import React, {useContext, FunctionComponent, ChangeEvent, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {FilterContext} from '../place-consumption-filter-context';
import {useFetch, useOnFetchSuccess} from 'hooks';
import {api} from 'api';
import {FilterComboBox, FilterComboBoxOptions} from 'components/ui/filter-combo-box';
import EnergyIcon from '@material-ui/icons/Category';

/** Consumption filter energy type select */
export const EnergyTypeSelect: FunctionComponent = () => {
  const {
    getValues,
    setAndStoreValues,
    getStoredValue,
    FilterField
  } = useContext(FilterContext);
  const {chartEnergyTypeId, chartPlaceId, chartPlacePartnerId} = getValues();
  const consumptionSummary = useFetch(api.consumptionSummary);
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (chartPlaceId !== null)
      consumptionSummary.fetch({
        consumptionPlaceId: chartPlaceId,
        partnerId: chartPlacePartnerId
      });
  // eslint-disable-next-line
  }, [chartPlaceId]);

  // Fetch list of energy types on mount
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, [chartPlaceId]);

  // Map list of energy types to combo box options
  const energyTypeList = useMemo(() => {
    const list: FilterComboBoxOptions = [];
    if (consumptionSummary.data) {
      
      // Temporary workaround for places with no consumption records
      const noRecords = consumptionSummary.data.every(({numberOfRecords}) => !numberOfRecords);
      
      // Get list of active energy types
      for (const summary of consumptionSummary.data) {
        if (noRecords || summary.numberOfRecords)
          list.push({
            label: summary.energyTypeName,
            value: summary.energyTypeId
          });
      }
    }
    return list;
  }, [consumptionSummary.data]);

  const setEnergyType = useCallback((value: string) => {
    setAndStoreValues({chartEnergyTypeId: value});    
  }, [setAndStoreValues]);

  useOnFetchSuccess([consumptionSummary], () => {
    const {value} = getStoredValue('chartEnergyTypeId')
      .onlyIfIncludedInList(energyTypeList)
      .orGetFirstFromList();
    setEnergyType(value as string);
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
    setEnergyType(value as string);
  }, [setEnergyType]);

  const disabled = !energyTypeList || energyTypeList.length <= 1;

  return (
    <FilterField
      pending={consumptionSummary.pending}
      error={consumptionSummary.error}
    >
      <FilterComboBox
        label={t('views.consumption.place.filter.energy')}
        labelIcon={<EnergyIcon />}
        options={energyTypeList}
        value={chartEnergyTypeId}
        disabled={disabled}
        onChange={handleChange}
      />
    </FilterField>
  );
};