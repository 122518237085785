import React, {forwardRef, ComponentType, HTMLAttributes, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IAutocompleteProps} from './autocomplete.types';
import MuiAutocomplete from '@material-ui/core/Autocomplete';
import {AutocompletePopper} from './autocomplete-popper';
import {useAutocompleteContainer} from './use-autocomplete-container';
import {useAutocompleteInput} from './use-autocomplete-input';
import {useAutocompleteOptions} from './use-autocomplete-options';
import {VirtualizedList, IVirtualizedListProps} from 'components/ui/virtualized-list';

/**
 * Autocomplete
 *
 * @example
 *
 *   import {Autocomplete, AutocompleteOptions, AutocompleteRenderInputParams} from 'components/ui/autocomplete';
 *   import {TextField} from 'components/ui/text-field';
 *
 *   const options: AutocompleteOptions = [
 *     {
 *       label: 'Foo',
 *       value: 0
 *     },
 *     {
 *       label: 'Bar',
 *       value: 1
 *     }
 *   ];
 *
 *   // ...
 *
 *   const [value, setValue] = useState<AutocompleteOption | null>(null);
 *
 *   const handleChange = useCallback((event, option: (AutocompleteOption | null)) => {
 *     setValue(option);
 *   }, [onChange]);
 *
 *   const renderInput = useCallback((renderInputProps: AutocompleteRenderInputParams) => (
 *     <TextField {...renderInputProps} />
 *   ), []);
 *
 *   // ...
 *
 *   <Autocomplete
 *     renderInput={renderInput}
 *     options={options}
 *     value={value}
 *     onChange={handleChange}
 *   />
 */
export const Autocomplete = forwardRef<HTMLDivElement, IAutocompleteProps>(function Autocomplete(props, ref) {
  const {virtualizedList, virtualizedRowHeight, disableListWrap} = props;
  const containerProps = useAutocompleteContainer(props);
  const {open, handleOpen, handleClose, classes} = containerProps;
  const {renderInput} = useAutocompleteInput(props, containerProps);
  const {renderOption, getOptionLabel} = useAutocompleteOptions(props);
  const {t} = useTranslation();

  const ListboxComponent = useMemo(() => {
    if (!virtualizedList)
      return undefined;
    return VirtualizedList as ComponentType<HTMLAttributes<HTMLElement>>;
  }, [virtualizedList]);

  const listboxProps = useMemo(() => {
    const props: IVirtualizedListProps = {
      rowHeight: virtualizedRowHeight
    };
    return props as HTMLAttributes<HTMLUListElement>;
  }, [virtualizedRowHeight]);

  return (
    <MuiAutocomplete
      ref={ref}
      {...props}
      PopperComponent={AutocompletePopper}
      noOptionsText={t('components.ui.autocomplete.no_options')}
      classes={classes}
      renderInput={renderInput}
      renderOption={renderOption}
      getOptionLabel={getOptionLabel}
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      ListboxComponent={ListboxComponent}
      ListboxProps={listboxProps}
      disableListWrap={virtualizedList ? true : disableListWrap}
    />
  );
});

Autocomplete.defaultProps = {
  clearable: true
};