import './dialog-title.scss';
import React, {forwardRef, useMemo} from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import {DialogTitleProps} from './dialog-title.types';

/**
 * Dialog window title
 *
 * @see /src/views/app/kit-view/dialog-kit
 * 
 * @example
 *
 *   import {Dialog} from 'components/ui/dialog';
 *   import {DialogContent} from 'components/ui/dialog-content';
 *   import {DialogContentText} from 'components/ui/dialog-content-text';
 *   import {DialogActions} from 'components/ui/dialog-actions';
 *   import {Button} from 'components/ui/button';
 *
 *   // ...
 *
 *   <Dialog
 *     title="Title"
 *     width="narrow"
 *     open={dialogIsOpen}
 *     onClose={closeDialog}
 *   >
 *     <DialogContent>
 *       <DialogContentText>
 *         Lorem ipsum dolor sit amet. Consectetur adipiscing elit. Lorem ipsum dolor sit amet.
 *         Consectetur adipiscing elit. Lorem ipsum dolor sit amet.
 *       </DialogContentText>
 *     </DialogContent>
 *     <DialogActions>
 *       <Button>
 *         Yes
 *       </Button>
 *       <Button>
 *         No
 *       </Button>
 *     </DialogActions>
 *   </Dialog>
 */
export const DialogTitle = forwardRef<HTMLDivElement, DialogTitleProps>(function DialogTitle(props, ref) {
  const {className, children, ...restProps} = props;

  const classes = useMemo<string>(() => {
    let classes = 'ui-dialog-title';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <MuiDialogTitle
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiDialogTitle>
  );
});