import React, {useContext, FunctionComponent, ChangeEvent, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {FilterContext} from '../consumption-filter-context';
import {useFetch, useOnFetchSuccess} from 'hooks';
import {api} from 'api';
import {FilterComboBox, FilterComboBoxOption} from 'components/ui/filter-combo-box';
import PlaceIcon from '@material-ui/icons/Place';
import {ALL_CONTRACTS} from '../consumption-filter-contract';

export const ALL_PLACES = -1;

/** Consumption filter place select */
export const PlaceSelect: FunctionComponent = () => {
  const {
    getValues,
    setAndStoreValues,
    getStoredValue,
    FilterField
  } = useContext(FilterContext);
  const {placeId, contractId} = getValues();
  const places = useFetch(api.consumptionPlaceList);
  const {t} = useTranslation();

  const fetchPlaces = useCallback(() => {
    const contract = contractId === ALL_CONTRACTS ? null : contractId;
    places.fetch({
      contractId: contract,
      size: 999999999
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractId]);

  // Fetch list of places
  useEffect(() => {
    fetchPlaces();
  }, [contractId, fetchPlaces]);

  const placeList = useMemo(() => {

    // Map list of places to combo box options
    const list = places.data?.content.map((place): FilterComboBoxOption => ({
      label: place.name,
      value: place.id
    }));

    // Add 'all places' option
    if (list && list.length > 1)
      list.unshift({
        label: t('views.consumption.filter.all_places'),
        value: ALL_PLACES
      });
    
    return list;
  }, [places.data, t]);

  const setPlace = useCallback((value: number) => {
    setAndStoreValues({placeId: value});    
  }, [setAndStoreValues]);

  useOnFetchSuccess([places], () => {
    if (placeList) {
      const listWithoutAllOption = placeList.filter((place) => {
        return place.value !== ALL_PLACES;
      });
      const {value} = getStoredValue('placeId')
        .onlyIfIncludedInList(placeList)
        .andNotEqualsTo(ALL_PLACES)
        .orGetFirstFromList(listWithoutAllOption);
      setPlace(value as number);
    }
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
    setPlace(value as number);
  }, [setPlace]);

  const disabled = !placeList || placeList.length <= 1;

  return (
    <FilterField
      pending={places.pending}
      error={places.error}
    >
      <FilterComboBox
        label={t('views.consumption.filter.places')}
        labelIcon={<PlaceIcon />}
        options={placeList}
        value={placeId}
        disabled={disabled}
        onChange={handleChange}
        virtualizedList
      />
    </FilterField>
  );
};