import './demand-status.scss';
import React, {FunctionComponent, ReactElement, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';
import {IDemandStatusProps} from './demand-status.types';
import NewIcon from '@material-ui/icons/Stars';
import InProgressIcon from '@material-ui/icons/WatchLater';
import EscalatedIcon from '@material-ui/icons/RemoveCircle';
import ClosedIcon from '@material-ui/icons/CheckCircle';
import UnknownIcon from '@material-ui/icons/Help';

const UNKNOWN_STATUS = 'unknown';

const statusIcons: Record<string, ReactElement> = {
  new: (<NewIcon />),
  in_progress: (<InProgressIcon />),
  escalated: (<EscalatedIcon />),
  closed: (<ClosedIcon />),
  [UNKNOWN_STATUS]: (<UnknownIcon />)
};

/** Demand status */
export const DemandStatus: FunctionComponent<IDemandStatusProps> = (props) => {
  const {className, statusId: statusIdProp} = props;
  const demandStatusList = useSelector(selectors.demandStatusList, shallowEqual);  
  const {t} = useTranslation();

  const status = useMemo(() => {
    return demandStatusList.data?.find((status) => status.id === statusIdProp);
  }, [demandStatusList, statusIdProp]);

  const statusId = useMemo(() => {
    return status ? statusIdProp : UNKNOWN_STATUS;
  }, [status, statusIdProp]);

  const statusLabel = useMemo(() => {
    if (status)
      return status.name;
    return t('components.demand.demands_table.unknown_status');
  }, [status, t]);

  const classes = useMemo(() => {
    let classes = 'ui-demand-status';
    classes += ` ui-demand-status--${statusId.replace(/_/g, '-')}`;
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, statusId]);   
      
  return (
    <div className={classes}>
      {statusIcons[statusId]}
      {statusLabel}
    </div>
  );
};