import {AppState} from 'reducers';

/** Get list of dynamic consumption place parameters */
export const consumptionParameterList = (state: AppState) => {
  return state.consumption.parameterList;
};

/** Get list of energy types */
export const energyTypeList = (state: AppState) => {
  return state.consumption.energyTypeList;
};
