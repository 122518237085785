import './toggle-button.scss';
import React, {FunctionComponent, useCallback, useState, MouseEvent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {ToggleButtonGroup} from 'components/ui/toggle-button-group';
import {ToggleButton} from 'components/ui/toggle-button';
import CheckIcon from '@material-ui/icons/Check';
import AlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import AlignCenterIcon from '@material-ui/icons/FormatAlignCenter';
import AlignRightIcon from '@material-ui/icons/FormatAlignRight';
import JustifyIcon from '@material-ui/icons/FormatAlignJustify';
import LaptopIcon from '@material-ui/icons/Laptop';
import TvIcon from '@material-ui/icons/Tv';
import PhoneIcon from '@material-ui/icons/PhoneAndroid';

/** Toggle button preview */
export const ToggleButtonKit: FunctionComponent = () => {
  const [check, setCheck] = useState(false);
  const [alignment, setAlignment] = useState<string[]>(['left']);
  const [device, setDevice] = useState<string[]>(['tv', 'laptop']);
  const {t} = useTranslation();

  const handleCheck = useCallback(() => {
    setCheck((check) => !check);
  }, []);

  const handleAlignment = useCallback((event: MouseEvent, values: string[]) => {
    if (values !== null)
      setAlignment(values);
  }, []);

  const handleDevice = useCallback((event: MouseEvent, values: string[]) => {
    setDevice(values);
  }, []);

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.toggle_button.heading')}
      </Typography>
      <div className="ui-toggle-button-kit__items">

        {/* Check */}

        <div className="ui-toggle-button-kit__item">
          <ToggleButton
            value="check"
            selected={check}
            onChange={handleCheck}
          >
            <CheckIcon />
          </ToggleButton>
        </div>

        {/* Alignment */}

        <div className="ui-toggle-button-kit__item">
          <ToggleButtonGroup
            exclusive
            value={alignment}
            onChange={handleAlignment}
          >
            <ToggleButton value="left">
              <AlignLeftIcon />
            </ToggleButton>
            <ToggleButton value="center">
              <AlignCenterIcon />
            </ToggleButton>
            <ToggleButton value="right">
              <AlignRightIcon />
            </ToggleButton>
            <ToggleButton value="justify">
              <JustifyIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>

        {/* Device */}

        <div className="ui-toggle-button-kit__item">
          <ToggleButtonGroup
            value={device}
            onChange={handleDevice}
          >
            <ToggleButton value="tv">
              <TvIcon />
            </ToggleButton>
            <ToggleButton value="laptop">
              <LaptopIcon />
            </ToggleButton>
            <ToggleButton value="phone">
              <PhoneIcon />
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </>
  );
};
