import {
  ISelectPartnerAction,
  ISelectPartnerPayload,
  SelectPartnerActionTypes
} from './select-partner.types';

/** Select active partner */
export const selectPartner = (payload: ISelectPartnerPayload): ISelectPartnerAction => ({
  type: SelectPartnerActionTypes.select,
  payload
});
