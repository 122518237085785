import './container.scss';
import React, {forwardRef, useMemo} from 'react';
import {ContainerProps} from './container.types';
import MuiContainer from '@material-ui/core/Container';

/**
 * Horizontally centered container
 * 
 * @example
 * 
 *   <Container>
 *     Page content
 *   </Container>
 */
export const Container = forwardRef<HTMLDivElement, ContainerProps>(function Container(props, ref) {
  const {className, maxWidth, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-container';
    if (!maxWidth)
      classes += ' ui-container--default-width';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, maxWidth]);   
  
  return (
    <MuiContainer
      ref={ref}
      {...restProps}
      className={classes}
      maxWidth={maxWidth}
    />
  );
});

Container.defaultProps = {
  maxWidth: false
};
