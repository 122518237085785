import './tooltip-box.scss';
import React, {forwardRef, useMemo} from 'react';
import {ITooltipBoxProps} from './tooltip-box.types';

/** Tooltip kit box */
export const TooltipBox = forwardRef<HTMLDivElement, ITooltipBoxProps>(function TooltipBox(props, ref) {
  const {
    className,
    children,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-tooltip-box';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <div
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </div>
  );
});
