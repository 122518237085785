import './backdrop.scss';
import React, {forwardRef, useMemo} from 'react';
import {useTheme} from 'components/ui/theme';
import {BackdropProps} from './backdrop.types';
import MuiBackdrop from '@material-ui/core/Backdrop';

/**
 * Backdrop overlay
 *
 * @example
 *
 *   <Backdrop open={isOpen}>
 *     ...
 *   </Backdrop>
 */
export const Backdrop = forwardRef<unknown, BackdropProps>(function Backdrop(props, ref) {
  const {
    className,
    timeout: timeoutProp,
    children,
    ...restProps
  } = props;
  const theme = useTheme();

  const timeout = useMemo(() => {
    if (timeoutProp === undefined)
      return theme.speed.fast;
    return timeoutProp;
  }, [theme.speed.fast, timeoutProp]);
  
  const classes = useMemo(() => {
    let classes = 'ui-backdrop';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <MuiBackdrop
      ref={ref}
      {...restProps}
      className={classes}
      timeout={timeout}
    >
      {children}
    </MuiBackdrop>
  );
});