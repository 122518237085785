import './app-logo.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {IAppLogoProps} from './app-logo.types';
import {ReactComponent as LogoSymbol} from './assets/logo-symbol.svg';
import {ReactComponent as LogoText} from './assets/logo-text.svg';
import {Link} from 'components/ui/link';

/** Application logo */
export const AppLogo: FunctionComponent<IAppLogoProps> = (props) => {
  const {className, to, target, animate} = props;

  const classes = useMemo(() => {
    let classes = 'ui-app-logo';
    if (animate)
      classes += ' ui-app-logo--animate';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [animate, className]);   

  return (
    <Link
      className={classes}
      hoverAnimation={false}
      to={to}
      target={target}
    >
      <LogoSymbol className="ui-app-logo__symbol" />
      <LogoText className="ui-app-logo__text" />
    </Link>
  );
};

AppLogo.defaultProps = {
  animate: false
};