import {useOnFetchSuccess, useWatchEffect} from 'hooks';
import {useSnackbar} from 'components/ui/snackbar';
import {SnackbarOptions} from './use-fetch-success-message.types';
import {useMemo} from 'react';
import uniqid from 'uniqid';

/**
 * Show message on fetch success
 *
 * @example
 * 
 *   import {useFetch, useFetchSuccessMessage} from 'hooks';
 *   import {api} from 'api';
 * 
 *   // ...
 * 
 *   const someRequest = useFetch(api.someApiCall);
 *   
 *   useFetchSuccessMessage([someRequest], 'Message to show on fetch success.');
 * 
 * @example
 * 
 *   // Wait for multiple requests
 *   useFetchSuccessMessage([someRequest, anotherRequest]);
 */
export const useFetchSuccessMessage = (requests: IRequestProgress[], message: string, snackbarOptions?: SnackbarOptions) => {
  const {showSnackbar, hideSnackbar} = useSnackbar();

  const snackbarId = useMemo(() => {
    return uniqid();
  }, []);

  const pending = useMemo(() => {
    return requests.reduce((pending: boolean, request) => {
      return pending || request.pending;
    }, false);
  }, [requests]);

  useOnFetchSuccess(requests, () => {
    showSnackbar(message, {
      id: snackbarId,
      variant: 'success',
      ...snackbarOptions
    });  
  });

  useWatchEffect(() => {
    if (pending)
      hideSnackbar(snackbarId);
  }, [pending]);
};