import './add-partners-list.scss';
import React, {ChangeEvent, FunctionComponent, useCallback, useMemo} from 'react';
import memoize from 'fast-memoize';
import {useTranslation} from 'lib/intl/i18n';
import {IAddPartnersListProps} from './add-partners-list.types';
import {Checkbox} from 'components/ui/checkbox';
import SelectAllIcon from '@material-ui/icons/DoneAll';
import {VirtualizedList} from 'components/ui/virtualized-list';

/** Access demand partners list */
export const AddPartnersList: FunctionComponent<IAddPartnersListProps> = (props) => {
  const {partners, onPartnerToggle, onToggleAll} = props;
  const {t} = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(memoize((partnerId: number) => (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onPartnerToggle(partnerId, checked);
  }), [onPartnerToggle]);

  const allChecked = useMemo(() => {
    return partners.every(({checked}) => checked);
  }, [partners]);

  const handleToggleAll = useCallback(() => {
    onToggleAll(!allChecked);
  }, [allChecked, onToggleAll]);

  return (
    <div className="ui-add-partners-list">

      {/* Select all */}

      {partners.length > 1 && (
        <Checkbox
          className="ui-add-partners-list__select-all"  
          label={t('components.demand.access_demand_form.add_partners_dialog.select_all')}
          icon={<SelectAllIcon />}
          checkedIcon={<SelectAllIcon />}
          checked={allChecked}
          onClick={handleToggleAll}
        />
      )}

      {/* No partners */}
      
      {partners.length === 0 && (
        <div className="ui-add-partners-list__no-items">
          {t('components.demand.access_demand_form.add_partners_dialog.no_items')}
        </div>
      )}

      {/* List of partners */}

      {partners.length > 0 && (
        <VirtualizedList
          maxVisibleItems={12}
          rowHeight={42}
        >
          {partners.map((item) => (
            <div
              key={item.id}
              className="ui-add-partners-list__item"
              title={item.name}
            >
              <Checkbox
                label={item.name}
                checked={item.checked}
                hoverAnimation={false}
                onChange={handleChange(item.id)}
              />
            </div>
          ))}
        </VirtualizedList>
      )}
    </div>
  );
};