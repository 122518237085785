import {IUserDetailResponse} from 'api';

export enum UserDetailActionTypes {
  request = 'USER_DETAIL_REQUEST',
  success = 'USER_DETAIL_SUCCESS',
  failure = 'USER_DETAIL_FAILURE'
}

export interface IUserDetailRequestAction {
  type: UserDetailActionTypes.request;
}

export interface IUserDetailSuccessAction {
  type: UserDetailActionTypes.success;
  payload: IUserDetailResponse;
}

export interface IUserDetailFailureAction {
  type: UserDetailActionTypes.failure;
  payload: IRequestError;
}

export type UserDetailAction = (
  IUserDetailRequestAction |
  IUserDetailSuccessAction |
  IUserDetailFailureAction
);
