import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IConditionsArticleResponse} from './api-article-conditions.types';

/** Get terms and conditions article */
export const conditionsArticle = async () => {
  const response = await axios.get<IConditionsArticleResponse>(urls.conditionsArticle(), {
    headers: headers.language()
  });
  return response.data;
};