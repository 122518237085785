import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import validator from 'validator';
import {t} from 'lib/intl/i18n';

/**
 * Validate integer value
 *
 * @param input - Value to validate
 * @returns Error message or undefined
 */
export const integer: IValidator = (input) => {
  const value = String(input || '').trim();
  if (value && !validator.isInt(value))
    return t('lib.global.validate.integer');
};

/**
 * Check that the integer validator doesn't return any error
 *
 * @param input - Value to check
 */
export const isInteger: ValidatorQuery = (input) => {
  return !!input && !integer(input);
};
