import './demands-filter.scss';
import React, {FunctionComponent} from 'react';
import {IDemandsFilterValues} from './demands-filter.types';
import {withFilter} from 'components/ui/filter';
import {FilterContext} from './demands-filter-context';
import {Grid} from 'components/ui/grid';
import {TypeSelect, ALL_DEMAND_TYPES} from './demands-filter-type';
import {DemandButton} from '../demand-button';

const FilterComponent: FunctionComponent = () => {
  return (
    <Grid
      container
      className="ui-demands-filter"
    >
      <Grid
        item
        className="ui-demands-filter__type"
      >
        <TypeSelect />
      </Grid>
      <Grid
        item
        className="ui-demands-filter__actions"
      >
        <DemandButton />
      </Grid>
    </Grid>
  );
};

export const DemandsFilter = withFilter<IDemandsFilterValues>({
  filterId: 'demands',

  FilterContext,

  initialValues: {
    typeId: null
  },
  
  isFilterComplete: (values) => (
    values.typeId !== null
  ),

  transformValues: (values) => {
    if (values.typeId === ALL_DEMAND_TYPES)
      values.typeId = null;
    return values;
  }
})(FilterComponent);