import './app-footer.scss';
import React, {FunctionComponent} from 'react';
import {paths} from 'config/router';
import {useTranslation} from 'lib/intl/i18n';
import {useSelector, shallowEqual,useDispatch} from 'react-redux';
import {selectors} from 'selectors';
import {Container} from 'components/ui/container';
import {Divider} from 'components/ui/divider';
import {Typography} from 'components/ui/typography';
import {Link} from 'components/ui/link';
import {ReactComponent as CreatedByIcon} from './assets/created-by.svg';
import {AppLogo} from '../app-logo';
import {toggleCookiesDialog} from 'actions';

/** Application footer */
export const AppFooter: FunctionComponent = () => {
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);
  const activePartnerId = useSelector(selectors.activePartnerId, shallowEqual);
  const {t} = useTranslation();
  const partnerSelect = userIsLoggedIn && activePartnerId === null;
  const dispatch = useDispatch();

  const onOpenCookiesDialog = () => {
    dispatch(toggleCookiesDialog(true));
  };

  return (
    <Container className="ui-app-footer">
      <Divider />
      <div className="ui-app-footer__content">

        {/* Left column */}
        
        <div className="ui-app-footer__left-column">
            
          {/* Copyright */}
          
          <div className="ui-app-footer__copyright">
            <Typography variant="body1">
              Copyright &copy; 2020
            </Typography>
          </div>

          {/* External links */}

          <ul className="ui-app-footer__external-links">
            <li>
              <Link
                to="https://vesr.sk/sk"
                target="_blank"
                underline
              >
                www.vesr.sk
              </Link>
            </li>
            <li>
              <Link
                to="https://www.veolia.sk"
                target="_blank"
                underline
              >
                www.veolia.sk
              </Link>
            </li>
          </ul>

          {/* Logo */}
          
          <div className="ui-app-footer__logo">
            <CreatedByIcon className="ui-app-footer__created-by" />
            <AppLogo
              className="ui-app-footer__logo-icon"
              to="https://www.veolia.sk"
              target="_blank"
            />
          </div>
        </div>
        
        {/* Right column */}
        
        <div className="ui-app-footer__right-column">

          {/* Internal links */}

          {!partnerSelect && (
            <ul className="ui-app-footer__internal-links">
              <li>
                <Link
                  to={paths.about}
                  underline
                >
                  {t('components.main.app.footer.about_us')}
                </Link>
              </li>
              <li>
                <Link
                  to={paths.contacts}
                  underline
                >
                  {t('components.main.app.footer.contacts')}
                </Link>
              </li>
              <li>
                <Link
                  to={paths.conditions}
                  underline
                >
                  {t('components.main.app.footer.conditions')}
                </Link>
              </li>
              <li>
                <Link
                  onClick={onOpenCookiesDialog} 
                  underline
                >
                  {t('components.main.app.footer.cookies')}
                </Link>
              </li>
            </ul>
          )}
        </div>

        {/* Copyright info */}

        <div className="ui-app-footer__copyright-info">

          {/* Copyright */}
          
          <div className="ui-app-footer__copyright">
            <Typography variant="body1">
              Copyright &copy; 2021
            </Typography>
          </div>

          {/* Logo */}
          
          <div className="ui-app-footer__logo">
            <CreatedByIcon className="ui-app-footer__created-by" />
            <AppLogo
              className="ui-app-footer__logo-icon"
              to="https://www.veolia.sk"
              target="_blank"
            />
          </div>
        </div>
      </div>
    </Container>
  );
};