import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Confirm user password reset */
const passwordResetConfirmSaga = function* (action: actions.IPasswordResetConfirmRequestAction) {
  try {
    yield call(api.passwordResetConfirm, action.payload);
    yield put(actions.passwordResetConfirmSuccess());
  }
  catch (error) {
    yield put(actions.passwordResetConfirmFailure(error as IRequestError));
  }
};

export const passwordResetConfirm = function* () {
  yield takeLatest(actions.PasswordResetConfirmActionTypes.request, passwordResetConfirmSaga);
};
