
/** Document upload */
export const documentUpload = () => {
  return 'document/creation';
};

/** Document download */
export const documentDownload = (documentId: string) => {
  return `document/${documentId}`;
};
