import React, {forwardRef, useMemo} from 'react';
import {FormTimePickerProps} from './form-time-picker.types';
import {useFormField} from 'hooks';
import {TimePicker} from 'components/ui/time-picker';
import {FormPickerField} from 'components/ui/form-picker-field';
import {validate as validators, composeValidators} from 'lib/global/validate';

/**
 * Formik wrapper for time picker
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 *
 *   import {Form} from 'components/ui/form';
 *   import {FormTimePicker} from 'components/ui/form-time-picker';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormTimePicker
 *       name="someName"
 *       label="Some label"
 *     />
 *   </Form>
 */
export const FormTimePicker = forwardRef<HTMLInputElement, FormTimePickerProps>(function FormTimePicker(props, ref) {
  const {validate, ...restProps} = props;

  const pickerProps = useMemo(() => ({
    ...props,
    validate: composeValidators(
      validate,
      validators.time,
      !!props.minTime && validators.minTime(props.minTime),
      !!props.maxTime && validators.maxTime(props.maxTime)
    )
  } as FormTimePickerProps), [props, validate]);

  const fieldProps = useFormField(pickerProps);
  const {field, helpers} = fieldProps;

  return (
    <TimePicker
      ref={ref}
      {...restProps}
      value={field.value || ''}
      onChange={helpers.setValue}
      FieldComponent={FormPickerField}
      fieldProps={fieldProps}
    />
  );
});