import React, {forwardRef, useMemo} from 'react';
import {useTheme} from 'components/ui/theme';
import {GrowProps} from './grow.types';
import MuiGrow from '@material-ui/core/Grow';

/**
 * Grow transition
 *
 * @example
 *
 *   <Grow in={isOpen}>
 *     Animated content...
 *   </Grow>
 */
export const Grow = forwardRef<unknown, GrowProps>(function Grow(props, ref) {
  const {
    timeout: timeoutProp,
    children,
    ...restProps
  } = props;
  const theme = useTheme();

  const timeout = useMemo(() => {
    if (timeoutProp === undefined)
      return theme.speed.fast;
    return timeoutProp;
  }, [theme.speed.fast, timeoutProp]);
  
  return (
    <MuiGrow
      ref={ref}
      {...restProps}
      timeout={timeout}
    >
      {children}
    </MuiGrow>
  );
});