import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {OrderedList, OrderedListItem} from 'components/ui/ordered-list';

/** Ordered list control preview */
export const OrderedListKit: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.ordered_list.heading')}
      </Typography>
      <OrderedList>
        <OrderedListItem>
          {t('views.app.ui_kit.ordered_list.label.first')}
        </OrderedListItem>
        <OrderedListItem>
          {t('views.app.ui_kit.ordered_list.label.second')}
        </OrderedListItem>
        <OrderedListItem>
          {t('views.app.ui_kit.ordered_list.label.third')}
        </OrderedListItem>
      </OrderedList>
    </>
  );
};
