import './news-list.scss';
import React, {FunctionComponent} from 'react';
import {format} from 'lib/intl/format';
import {paths} from 'config/router';
import {INewsListProps} from './news-list.types';
import {NewsListItem} from './news-list-item';

/**
 * News list
 * 
 * @example
 *  
 *   <NewsList news={someNewsList} />
 */
export const NewsList: FunctionComponent<INewsListProps> = (props) => {
  const {news} = props;

  return (
    <ul className="ui-news-list">
      {news.map((item) => (
        <NewsListItem
          key={`${item.title}${item.body}`}
          title={item.title}
          date={format.date(item.createdDateTime)}
          content={item.perex}
          detailUrl={`${paths.news}/${item.id}`}
        />
      ))}
    </ul>
  );
};