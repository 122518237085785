import './ordered-list.scss';
import React, {forwardRef, useMemo} from 'react';
import {IOrderedListProps} from './ordered-list.types';

/**
 * Ordered list
 * 
 * @see src/views/app/kit-view/ordered-list-kit
 *
 * @example
 *
 *   import {OrderedList, OrderedListItem} from 'components/ui/ordered-list';
 * 
 *   // ...
 * 
 *   <OrderedList>
 *     <OrderedListItem>
 *       First item
 *     </OrderedListItem>
 *     <OrderedListItem>
 *       Second item
 *     </OrderedListItem>
 *     <OrderedListItem>
 *       Third item
 *     </OrderedListItem>
 *   </OrderedList>
 */
export const OrderedList = forwardRef<HTMLOListElement, IOrderedListProps>(function OrderedList(props, ref) {
  const {className, children} = props;

  const classes = useMemo(() => {
    let classes = 'ui-ordered-list';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <ol
      ref={ref}
      className={classes}
    >
      {children}
    </ol>
  );
});