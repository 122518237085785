import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {zipCodes} from './zip-codes';
import {getCurrentCountry} from 'lib/intl/app-locale';
import {MissingPropertyException} from 'lib/global/exceptions';
import {t} from 'lib/intl/i18n';

/**
 * Validate ZIP code
 *
 * @param input - ZIP code value
 * @returns Error - message or undefined
 * @throws {MissingPropertyException}
 */
export const zipCode: IValidator = (input) => {
  const value = String(input || '').trim();
  if (value) {
    const countryCode = getCurrentCountry();
    if (!zipCodes[countryCode])
      throw new MissingPropertyException(t('lib.global.validate.zip.no_validator'));
    const isZipCode = new RegExp(zipCodes[countryCode]!);
    if (!isZipCode.test(value))
      return t('lib.global.validate.zip.invalid');
  }
};

/**
 * Check that the ZIP code validator doesn't return any error
 *
 * @param input - ZIP code to check
 */
export const isZipCode: ValidatorQuery = (input) => {
  return !!input && !zipCode(input);
};
