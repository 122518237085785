import './data-list.scss';
import React, {forwardRef, useCallback, useMemo} from 'react';
import {IDataListProps, DataListItems} from './data-list.types';
import {List} from 'components/ui/list';
import {Divider} from 'components/ui/divider';
import {DataListItem} from './data-list-sub-item';

/**
 * Data list
 * 
 * @see src/views/app/kit-view/list-kit
 *
 * @example
 *
 *   <DataList
 *     items={[
 *       {label: 'First item'},
 *       {label: 'Second item'},
 *       {label: '-'},
 *       {label: 'Third item'},
 *       {
 *         label: 'Fourth item',
 *         items: [
 *           {label: 'Fifth item'},
 *           {label: 'Sixth item'}
 *         ]
 *       }
 *     ]}
 *   />
 */
export const DataList = forwardRef<HTMLUListElement, IDataListProps>(function DataList(props, ref) {
  const {
    className,
    items,
    ...restProps
  } = props;

  const renderItems = useCallback((items: DataListItems) => {
    return items.map((item, id) => {
      const itemKey = `${id}${item.label}`;
      if (item.label === '-')
        return (<Divider key={itemKey} />);
      const {icon, label, ...itemProps} = item;
      return (
        <DataListItem
          key={itemKey}
          {...itemProps}
          label={item.label}
          icon={item.icon}
        >
          {item.items && renderItems(item.items)}
        </DataListItem>
      );
    });
  }, []);

  const classes = useMemo(() => {
    let classes = 'ui-data-list';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <List
      ref={ref}
      {...restProps}
      className={classes}
    >
      {renderItems(items)}
    </List>
  );
});

DataList.defaultProps = {
  disablePadding: true
};