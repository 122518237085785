import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {format} from 'lib/intl/format';
import {ConsumptionResponseList} from 'api';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {usePrevious} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {useFetch} from 'hooks';
import {api} from 'api';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {IPlaceConsumptionChartProps} from './place-consumption-chart.types';
import {Chart, ChartOptions, SeriesOptionsType} from 'components/ui/chart';
import moment from 'moment';

/** Chart of consumption at consumption place */
export const PlaceConsumptionChart: FunctionComponent<IPlaceConsumptionChartProps> = (props) => {
  const {chartFilter, consumptionFilter} = props;
  const {chartEnergyTypeId, placesList, chartPlacePartnerId} = chartFilter.values;
  const filterPlaceId = consumptionFilter.values.placeId as number;
  const chartPlaceId = chartFilter.values.chartPlaceId as number;
  const partnerId = useSelector(selectors.activePartnerId);
  const prevEnergyTypeId = usePrevious(chartEnergyTypeId);
  const prevFilterPlaceId = usePrevious(filterPlaceId);
  const prevChartPlaceId = usePrevious(chartPlaceId);
  const consumptionList = useFetch(api.consumptionList);
  const chartConsumptionList = useFetch(api.consumptionList);
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (chartFilter.isComplete && consumptionFilter.isComplete) {
      const fetchOptions = {
        energyTypeId: chartEnergyTypeId,
        sort: 'to,asc'
      };
      consumptionList.fetch({
        ...fetchOptions,
        partnerId,
        consumptionPlaceId: filterPlaceId
      });
      chartConsumptionList.fetch({
        ...fetchOptions,
        consumptionPlaceId: chartPlaceId,
        partnerId: chartPlacePartnerId
      });
    }
  }, [chartFilter.isComplete, consumptionFilter.isComplete, chartEnergyTypeId, consumptionList, partnerId, filterPlaceId, chartConsumptionList, chartPlaceId, chartPlacePartnerId]);

  useEffect(() => {
    if (
      prevEnergyTypeId !== chartEnergyTypeId ||
      prevFilterPlaceId !== filterPlaceId ||
      prevChartPlaceId !== chartPlaceId
    )
      fetchData();
  }, [chartEnergyTypeId, fetchData, chartPlaceId, prevEnergyTypeId, prevFilterPlaceId, prevChartPlaceId, consumptionFilter.values.placeId, filterPlaceId, consumptionFilter.isComplete, chartFilter.isComplete]);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const sortConsumptionList = useCallback((list: ConsumptionResponseList) => {
    return list.sort((a, b) => {
      const aTime = moment(a.to).toDate().getTime();
      const bTime = moment(b.to).toDate().getTime();
      return aTime - bTime;
    });
  }, []);
  
  const getPlaceName = useCallback((placeId: number) => {
    const place = placesList!.find((place) => {
      return place.id === placeId;
    });
    return place!.name;
  }, [placesList]);

  const getSeriesData = useCallback((placeId: number, list: ConsumptionResponseList): SeriesOptionsType => ({
    type: 'spline',
    name: getPlaceName(placeId),
    data: sortConsumptionList(list).map((item) => ({
      x: moment(item.to).toDate().getTime(),
      y: item.consumption.value,
      unit: item.consumption.unitId
    }))
  }), [getPlaceName, sortConsumptionList]);

  const getSeries = useCallback(() => {
    const places: Record<number, ConsumptionResponseList> = {};
    
    // Generate series data for the consumption place that was selected in the chart dropdown
    const series = [getSeriesData(chartPlaceId, chartConsumptionList.data!.content)];
    
    // Separate consumption lists for individual consumption places in the case the user has selected "all consumption places" in the filter.
    consumptionList.data!.content.forEach((item) => {
      const placeId = item.consumptionPlace.id;
      
      // Exclude consumption place already selected in the chart dropdown
      if (placeId !== chartPlaceId) {
        places[placeId] = places[placeId] || [];
        places[placeId].push(item);
      }
    });
    
    // Generate rest of series data
    Object.entries(places).forEach(([placeId, consumptionList]) => {
      series.push(getSeriesData(Number(placeId), consumptionList));
    });

    return series;
  }, [consumptionList.data, chartConsumptionList.data, getSeriesData, chartPlaceId]);
  
  const getChartOptions = useCallback((): ChartOptions => {
    return ({
      chart: {
        type: 'spline'
      },
      time: {
        useUTC: false
      },
      xAxis: {
        type: 'datetime',
        tickPixelInterval: 150
      },
      tooltip: {
        // eslint-disable-next-line object-shorthand
        formatter: function() {
          const date = format.date(this.x);
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const value = format.unit(this.y, this.point.unit);
          return `<b>${this.series.name}</b><br/>${date}<br />${value}`;
        }
      },
      legend: {
        enabled: false
      },
      series: getSeries(),
      exporting: {
        filename: t('views.consumption.place.chart.filename')
      }
    });
  }, [getSeries, t]);
  
  const render = useCallback(() => (
    <Chart options={getChartOptions()} />
  ), [getChartOptions]);

  return (
    <WaitForFetch
      pending={consumptionList.pending || chartConsumptionList.pending}
      error={consumptionList.error || chartConsumptionList.error}
      onRetry={fetchData}
      noData={!consumptionList.data?.content?.length || !chartConsumptionList.data?.content?.length}
      noDataMessage={t('views.consumption.place.chart.no_data')}
      render={render}
      effect="zoom"
      absolutePosition
    />
  );
};