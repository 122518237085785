import {Reducer} from 'redux';
import {UserDetailAction, UserDetailActionTypes} from 'actions';
import {IUserDetailState} from './user-detail.types';

export const initialState: IUserDetailState = {
  pending: false,
  error: null,
  data: null
};

/** User account detail */
export const userDetail: Reducer<IUserDetailState, UserDetailAction> = (state = initialState, action): IUserDetailState => {
  switch (action.type) {
    case UserDetailActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case UserDetailActionTypes.success:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case UserDetailActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};
