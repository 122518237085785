import {DemandStatusListResponse} from 'api';

export enum DemandStatusListActionTypes {
  request = 'DEMAND_STATUS_LIST_REQUEST',
  success = 'DEMAND_STATUS_LIST_SUCCESS',
  failure = 'DEMAND_STATUS_LIST_FAILURE'
}

export interface IDemandStatusListRequestAction {
  type: DemandStatusListActionTypes.request;
}

export interface IDemandStatusListSuccessAction {
  type: DemandStatusListActionTypes.success;
  payload: DemandStatusListResponse;
}

export interface IDemandStatusListFailureAction {
  type: DemandStatusListActionTypes.failure;
  payload: IRequestError;
}

export type DemandStatusListAction = (
  IDemandStatusListRequestAction |
  IDemandStatusListSuccessAction |
  IDemandStatusListFailureAction
);