import {useCallback, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {IUseRolesResult, HasRole, HasAllOfRoles, HasAnyOfRoles} from './use-roles.types';

/**
 * Check user roles
 * 
 * @example
 * 
 * import {useRoles, UserRole} from 'hooks';
 * 
 * // ...
 * 
 * const {hasRole, hasAllOfRoles, hasAnyOfRoles} = useRoles();
 * 
 * useEffect(() => {
 *   if (hasRole(UserRole.Admin))
 *     console.log('User has admin role.');
 *   if (hasAllOfRoles(UserRole.Editor, UserRole.Admin))
 *     console.log('User has both of editor and admin roles.');
 *   if (hasAnyOfRoles(UserRole.Hotline, UserRole.Support))
 *     console.log('User has either hotline or support role.');
 * }, []);
 */
export const useRoles = () => {
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);
  const userDetail = useSelector(selectors.userDetail, shallowEqual);

  const hasRole = useCallback<HasRole>((role) => {
    if (!userIsLoggedIn)
      return false;
    return !!userDetail?.roleIds?.includes(role);
  }, [userDetail?.roleIds, userIsLoggedIn]);

  const hasAllOfRoles = useCallback<HasAllOfRoles>((...roles) => {
    return roles.every(hasRole);
  }, [hasRole]);

  const hasAnyOfRoles = useCallback<HasAnyOfRoles>((...roles) => {
    return roles.some(hasRole);
  }, [hasRole]);
  
  return useMemo<IUseRolesResult>(() => ({
    hasRole,
    hasAllOfRoles,
    hasAnyOfRoles
  }), [hasAllOfRoles, hasAnyOfRoles, hasRole]);
};