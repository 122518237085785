import {combineReducers} from 'redux';
import {LogoutActionTypes} from 'actions';
import {auth} from './auth';
import {consumption} from './consumption';
import {demand} from './demand';
import {intl} from './intl';
import {invoice} from './invoice';
import {payment} from './payment';
import {user} from './user';
import {ui} from './ui';

const appReducers = {
  auth,
  consumption,
  demand,
  intl,
  invoice,
  payment,
  user,
  ui
};

/** Application state */
export const appReducer = combineReducers(appReducers);

export type AppReducerKey = keyof (typeof appReducers);

export type AppState = ReturnType<typeof appReducer>;

/** Application state root */
export const rootReducer: typeof appReducer = (state, action) => {

  // Reset application state on logout
  if (action.type === LogoutActionTypes.request)
    // return appReducer(undefined, action);
    return appReducer({ // Quick fix to persist config on logout
      ui: {
        theme: state?.ui.theme
      },
      intl: {
        locale: state?.intl.locale
      }
    } as AppState, action);

  return appReducer(state, action);
};

