import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useDeepEffect} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {useFetch} from 'hooks';
import {api, IInvoiceListRequest} from 'api';
import {format} from 'lib/intl/format';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {IFilteredInvoicesTableProps} from './filtered-invoices-table.types';
import {InvoicesTable} from 'components/invoice/invoices-table';
import {Pagination, usePagination} from 'components/ui/pagination';

const ROWS_PER_PAGE = 7;

/** Table of filtered invoices */
export const FilteredInvoicesTable: FunctionComponent<IFilteredInvoicesTableProps> = (props) => {
  const {filter} = props;
  const invoices = useFetch(api.invoiceList);
  const {currentPage, setCurrentPage} = usePagination();
  const {t} = useTranslation();

  const fetchOptions = useMemo<IInvoiceListRequest>(() => {
    const {contractId, commodityTypeId, unpaid, from, to} = filter.values;
    return {
      contractId,
      commodityTypeId,
      isAfterDue: unpaid ? true : undefined,
      isPaid: unpaid ? false : undefined,
      from: from && format.requestDate(from),
      to: to && format.requestDate(to)
    };
  }, [filter.values]);

  /** Get the data to be displayed in the invoices table */
  const fetchTableData = useCallback(() => {
    if (filter.isComplete)
      invoices.fetch({
        ...fetchOptions,
        page: currentPage - 1,
        size: ROWS_PER_PAGE
      });
  }, [currentPage, filter.isComplete, fetchOptions, invoices]);

  /** Get all invoices data for export */
  const fetchExportData = useCallback((paging?: IPagingRequest) => {
    return api.invoiceList({
      ...fetchOptions,
      ...paging
    });
  }, [fetchOptions]);
  
  // Fetch data on page load
  useEffect(() => {
    fetchTableData();
  // eslint-disable-next-line
  }, []);

  // Reset pagination on filter change
  useDeepEffect(() => {
    if (filter.isComplete)
      setCurrentPage(1);
  }, [filter.values, filter.isComplete]);

  // Fetch data on any change
  useDeepEffect(() => {
    fetchTableData();
  }, [filter.values, filter.isComplete, currentPage]);

  const render = useCallback(() => (
    <>
      <InvoicesTable
        invoices={invoices.data!.content}
        exportDataSource={fetchExportData}
      />
      <Pagination
        gutterTop
        pageSize={ROWS_PER_PAGE}
        totalItemCount={invoices.data!.totalElements}
        currentPage={currentPage}
        onChange={setCurrentPage}
      />
    </>
  ), [currentPage, fetchExportData, invoices.data, setCurrentPage]);

  return (
    <WaitForFetch
      pending={invoices.pending}
      error={invoices.error}
      noData={!invoices.data?.content?.length}
      noDataMessage={t('views.invoice.table.no_data')}
      onRetry={invoices.fetch}
      render={render}
    />
  );
};