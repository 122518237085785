import './heading.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {useTheme} from 'components/ui/theme';
import {Typography} from 'components/ui/typography';
import {IHeadingProps} from './heading.types';

/**
 * Default heading
 *
 * @example
 *
 *   <Heading>
 *     Some heading
 *   </Heading>
 */
export const Heading: FunctionComponent<IHeadingProps> = (props) => {
  const {
    className,
    variant = 'h2',
    color = 'primary',
    mt,
    mb,
    ...restProps
  } = props;
  const theme = useTheme();

  const styles = useMemo(() => ({
    marginTop: mt && theme.spacing[mt],
    marginBottom: mb && theme.spacing[mb]
  }), [mb, mt, theme]);

  const classes = useMemo(() => {
    let classes = 'ui-heading';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <Typography
      className={classes}
      variant={variant}
      color={color}
      style={styles}
      {...restProps}
      data-testid="heading"
    />
  );
};