/** @file Axios headers */

import {getState} from 'store';
import {selectors} from 'selectors';
import {AxiosHeaders} from './headers.types';

/**
 * Get authorization headers
 * 
 * @example
 * 
 *   import {axios, headers} from 'lib/api/axios';
 * 
 *   axios.get('my/endpoint', {
 *     headers: headers.auth()
 *   });
 */
export const auth = (): AxiosHeaders => {
  const appState = getState();
  const accessToken = selectors.accessToken(appState);
  if (!accessToken)
    return {};
  const tokenType = selectors.tokenType(appState);
  let authorization = accessToken;
  if (tokenType)
    authorization = `${tokenType} ${authorization}`;
  return {Authorization: authorization};
};

/**
 * Get language headers
 * 
 * @param [code] Custom language code
 * 
 * @example
 * 
 *   import {axios, headers} from 'lib/api/axios';
 * 
 *   axios.get('my/endpoint', {
 *     headers: headers.language()
 *   });
 */
export const language = (code?: ISOLangCode): AxiosHeaders => {
  const language = code || selectors.language(getState());
  return {'X-Current-Language': language};
};

/**
 * Get selected partner headers
 * 
 * @example
 * 
 *   import {axios, headers} from 'lib/api/axios';
 * 
 *   axios.get('my/endpoint', {
 *     headers: headers.partner()
 *   });
 */
export const partner = (): AxiosHeaders => {
  const activePartnerId = selectors.activePartnerId(getState());
  if (activePartnerId === null)
    return {};
  return {'X-Selected-Partner-Id': activePartnerId};
};