import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {useOnFetchFinish} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {useSnackbar} from 'components/ui/snackbar';
import {getErrorMessage} from 'lib/api/axios';
import {IPasswordChangeFormProps, IFormValues} from './password-change-form.types';
import {Form} from 'components/ui/form';
import {PasswordChangeFormFields} from './password-change-form-fields';

/**
 * Password change form
 * 
 * @example
 * 
 *   <PasswordChangeForm />
 */
export const PasswordChangeForm: FunctionComponent<IPasswordChangeFormProps> = (props) => {
  const {onSuccess} = props;
  const {showSnackbar} = useSnackbar();
  const passwordChange = useSelector(selectors.passwordChange, shallowEqual);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const initialValues = useMemo<IFormValues>(() => ({
    oldPassword: '',
    newPassword: '',
    passwordConfirm: ''
  }), []);

  const handleSubmit = useCallback((values: IFormValues) => {
    dispatch(actions.passwordChangeRequest(values));
  }, [dispatch]);

  useOnFetchFinish([passwordChange], () => {
    const {error} = passwordChange;
    if (error) {
      const message = getErrorMessage(error, t('components.user.password_change_form.failure'));
      showSnackbar(message, {variant: 'error'});
    }
    else {
      showSnackbar(t('components.user.password_change_form.success'), {variant: 'success'});
      onSuccess();
    }
  });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <PasswordChangeFormFields />
    </Form>
  );
};