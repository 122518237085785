import React, {FunctionComponent, ReactElement} from 'react';
import {paths} from 'config/router';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {Navigate} from 'react-router-dom';

/** Redirect an unauthorized user to the login form */
export const Authenticate: FunctionComponent = (props)  => {
  const {children} = props;
  const isAuthenticated = useSelector(selectors.userIsAuthenticated);
  
  if (!isAuthenticated)
    return (<Navigate to={paths.login} />);

  return children as ReactElement;
};
