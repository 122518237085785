/** @file Internationalization related config */

import {SupportedLocales} from './intl.types';
import {getLanguageCode, getCountryCode} from 'lib/intl/locale-code';

export const SUPPORTED_LOCALES: readonly ISOLocaleCode[] = ['sk-SK', 'en-US'];

export const SUPPORTED_LANGUAGES: readonly ISOLangCode[] = SUPPORTED_LOCALES.map(getLanguageCode);

export const DEFAULT_LOCALE: SupportedLocales = 'sk-SK';

//--- Get default country and language codes from default locale code

export const DEFAULT_LANGUAGE = getLanguageCode(DEFAULT_LOCALE);

export const DEFAULT_COUNTRY = getCountryCode(DEFAULT_LOCALE);