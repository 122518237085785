import './news-detail-view.scss';
import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useDeepEffect} from 'hooks';
import {paths} from 'config/router';
import {format} from 'lib/intl/format';
import {useParams} from 'react-router-dom';
import {useBreadcrumbs} from 'components/ui/breadcrumbs';
import {useTranslation} from 'lib/intl/i18n';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {View} from 'components/ui/view';
import {NoData} from 'components/api/no-data';
import {RevealFade} from 'components/ui/reveal-fade';
import {Typography} from 'components/ui/typography';
import {CmsContent} from 'components/ui/cms-content';
import {Box} from 'components/ui/box';
import {Link} from 'components/ui/link';

/** News detail view */
export const NewsDetailView: FunctionComponent = () => {
  const news = useFetch(api.newsList);
  const {addUrlTokenLabel} = useBreadcrumbs();
  const {t} = useTranslation();
  const itemId = useParams()['id'];

  useEffect(() => {
    news.fetch();
  // eslint-disable-next-line
  }, []);

  const item = useMemo(() => {
    if (news.data)
      return news.data.content.find((item) => {
        return item.id === itemId;
      });
  }, [itemId, news]);

  // Add news item title to breadcrumbs
  useDeepEffect(() => {
    if (item)
      addUrlTokenLabel(item.id, item.title || item.id);
  }, [item]);

  const render = useCallback(() => {
    if (!item)
      return (<NoData message={t('views.article.news_detail.not_found')} />);
    return (
      <RevealFade>

        {/* Heading */}

        {item.title && (
          <Typography
            variant="h3"
            gutterBottom
          >
            {item.title}
          </Typography>
        )}

        {/* Content */}

        <CmsContent fromHtmlString={`
          <span class="ui-news-detail-view__date">
            ${format.date(item.createdDateTime)}
          </span>
          ${item.body}
        `} />

        {/* Footer */}

        <Box mt={3}>
          <Link to={paths.news}>
            {t('views.article.news_detail.all_news')}
          </Link>
        </Box>
      </RevealFade>
    );
  }, [item, t]);

  return (
    <View className="ui-news-detail-view">
      <WaitForFetch
        pending={news.pending}
        error={news.error}
        noData={!news.data}
        onRetry={news.fetch}
        render={render}
        absolutePosition
      />
    </View>
  );
};