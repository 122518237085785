import React, {FunctionComponent, useState} from 'react';
import {useWatchEffect} from 'hooks';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useFetch, useOnFetchSuccess} from 'hooks';
import {useFormikContext} from 'formik';
import {IFormValues} from '../password-change-form.types';
import {validate} from 'lib/global/validate';
import {api} from 'api';
import {useTranslation} from 'lib/intl/i18n';
import {Grid} from 'components/ui/grid';
import {FormTextField} from 'components/ui/form-text-field';
import {Box} from 'components/ui/box';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import SendIcon from '@material-ui/icons/Check';

/** Password change form fields */
export const PasswordChangeFormFields: FunctionComponent = () => {
  const passwordValidation = useFetch(api.passwordValidation);
  const passwordChange = useSelector(selectors.passwordChange, shallowEqual);
  const {values} = useFormikContext<IFormValues>();
  const [validationError, setValidationError] = useState<string | null>(null);
  const {t} = useTranslation();

  // Validate new password
  useWatchEffect(() => {
    passwordValidation.fetch({password: values.newPassword});
  }, [values.newPassword]);

  useOnFetchSuccess([passwordValidation], () => {
    const {strongEnough, errorMessages} = passwordValidation.data!;
    if (strongEnough)
      setValidationError(null);
    else
      setValidationError(errorMessages![0]);
  });

  return (
    <>
      <Grid
        container
        disabled={passwordChange.pending}
      >
      
        {/* Old password */}

        <Grid item>
          <FormTextField
            name="oldPassword"
            type="password"
            label={t('components.user.password_change_form.label.old_password')}
            required
            autoFocus
          />
        </Grid>
      
        {/* New password */}

        <Grid item>
          <FormTextField
            name="newPassword"
            type="password"
            autoComplete="new-password"
            label={t('components.user.password_change_form.label.new_password')}
            required
            errorMessage={validationError}
            pending={passwordValidation.pending}
          />
        </Grid>

        <Grid item>
          <FormTextField
            name="passwordConfirm"
            type="password"
            label={t('components.user.password_change_form.label.password_confirm')}
            required
            validate={validate.passwordConfirm(values.newPassword)}
          />
        </Grid>
      </Grid>

      {/* Submit */}

      <Box mt={4}>
        <ButtonActions align="center">
          <Button
            type="submit"
            icon={<SendIcon />}
            pending={passwordChange.pending}
            disabled={passwordValidation.pending || !!validationError}
          >
            {t('components.user.password_change_form.submit')}
          </Button>
        </ButtonActions>
      </Box>
    </>
  );
};