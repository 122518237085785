import './mobile-menu.scss';
import React, {FunctionComponent, useCallback, useState} from 'react';
import {IconButton} from 'components/ui/icon-button';
import MenuIcon from '@material-ui/icons/Menu';
import {MobileMenuPanel} from './mobile-menu-panel';

/** Application menu for mobile */
export const MobileMenu: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  
  const toggleMenu = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  return (
    <>
      <IconButton
        className="ui-mobile-menu"
        size="small"
        onClick={toggleMenu}
      >
        <MenuIcon />
      </IconButton>
      <MobileMenuPanel
        open={open}
        onClose={toggleMenu}
      />
    </>
  );
};