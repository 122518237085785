import './home-last-news.scss';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {useFetch} from 'hooks';
import {api} from 'api';
import {paths} from 'config/router';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {useTranslation} from 'lib/intl/i18n';
import {RevealFade} from 'components/ui/reveal-fade';
import {Card} from 'components/ui/card';
import {CardHeader} from 'components/ui/card-header';
import {CardContent} from 'components/ui/card-content';
import {CardActions} from 'components/ui/card-actions';
import {NewsList} from 'components/article/news-list';
import {ButtonActions} from 'components/ui/button-actions';
import {Link} from 'components/ui/link';

/** Last news */
export const HomeLastNews: FunctionComponent = () => {
  const news = useFetch(api.newsList);
  const {t} = useTranslation();
  
  useEffect(() => {
    news.fetch();
  // eslint-disable-next-line
  }, []);
  
  // Content
  const renderContent = useCallback(() => {
    const lastNews = news.data!.content.slice(0, 2);
    return (<NewsList news={lastNews} />);
  }, [news.data]);

  // Footer
  const renderActions = useCallback(() => (
    <CardActions>
      <ButtonActions align="right">
        <Link to={paths.news}>
          {t('views.app.home.news.all_news')}
        </Link>
      </ButtonActions>
    </CardActions>
  ), [t]);

  const noData = !news.data?.content?.length || !news.data.content[0].perex;

  return (
    <RevealFade
      bottom
      fullHeight
    >
      <Card className="ui-home-last-news" fullHeight>
        <CardHeader title={t('views.app.home.news.heading')} />
        <CardContent className="ui-home-last-news__content">
          <WaitForFetch
            pending={news.pending}
            error={news.error}
            noData={noData}
            noDataMessage={t('views.app.home.news.no_data')}
            onRetry={news.fetch}
            render={renderContent}
            absolutePosition
          />
        </CardContent>
        <WaitForFetch
          silent
          pending={news.pending}
          error={news.error}
          noData={noData}
          render={renderActions}
        />
      </Card>
    </RevealFade>
  );
};