import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {ButtonActions} from 'components/ui/button-actions';
import {Typography} from 'components/ui/typography';
import {IconButton} from 'components/ui/icon-button';
import PrimaryIcon from '@material-ui/icons/CachedOutlined';
import SecondaryIcon from '@material-ui/icons/Settings';
import SuccessIcon from '@material-ui/icons/Check';
import WarningIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Close';
import DisabledIcon from '@material-ui/icons/NotInterested';

/** Icon button control preview */
export const IconButtonKit: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.icon_button.heading')}
      </Typography>
      <ButtonActions>
        <IconButton>
          <PrimaryIcon />
        </IconButton>
        <IconButton color="secondary">
          <SecondaryIcon />
        </IconButton>
        <IconButton color="success">
          <SuccessIcon />
        </IconButton>
        <IconButton color="warning">
          <WarningIcon />
        </IconButton>
        <IconButton color="error">
          <ErrorIcon />
        </IconButton>
        <IconButton disabled>
          <DisabledIcon />
        </IconButton>
      </ButtonActions>
    </>
  );
};
