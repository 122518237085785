import './upload-zone.scss';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useWatchEffect} from 'hooks';
import {useSnackbar} from 'components/ui/snackbar';
import {useTranslation} from 'lib/intl/i18n';
import {IUploadZoneProps} from './upload-zone.types';
import {getDeviceType, DeviceType} from 'lib/global/device-info';
import filesize from 'filesize';
import Dropzone, {DropzoneState, FileRejection} from 'react-dropzone';
import {Typography} from 'components/ui/typography';

/** File upload drop zone */
export const UploadZone: FunctionComponent<IUploadZoneProps> = (props) => {
  const {
    accept,
    maxSize,
    multiple,
    onDrop
  } = props;
  const [tooLargeFile, setTooLargeFile] = useState(false);
  const {showSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const isDesktop = useMemo(() => {
    return getDeviceType() === DeviceType.desktop;
  }, []);

  const getAcceptedExtensions = useCallback(() => {
    return Object.keys(accept).join(', ');
  }, [accept]);

  const getAcceptedMimeTypes = useCallback(() => {
    return Object.values(accept);
  }, [accept]);

  const maxFileSize = useMemo(() => {
    return filesize(maxSize);
  }, [maxSize]);

  const checkTooLargeFile = useCallback((rejections?: FileRejection[]) => {
    const hasTooLargeFile = !!rejections && rejections.some((rejection) => {
      return rejection.file.size > maxSize;
    });
    setTooLargeFile(hasTooLargeFile);
  }, [maxSize]);

  useWatchEffect(() => {
    if (tooLargeFile)
      showSnackbar(t('components.ui.upload.zone.too_large_file', {maxSize: maxFileSize}), {variant: 'error'});    
  }, [tooLargeFile]);

  const renderDropzone = useCallback((props: DropzoneState) => {
    checkTooLargeFile(props.fileRejections);
    return (
      <div
        {...props.getRootProps()}
        className="ui-upload-zone__zone"
      >
        <input {...props.getInputProps()} />
        {!props.isDragReject && (
          <Typography variant="body1">
            {isDesktop && t('components.ui.upload.zone.instructions.desktop', {maxSize: maxFileSize})}
            {!isDesktop && t('components.ui.upload.zone.instructions.mobile', {maxSize: maxFileSize})}
          </Typography>
        )}
        {props.isDragReject && (
          <Typography variant="body1">
            {t('components.ui.upload.zone.wrong_file_type', {fileTypes: getAcceptedExtensions()})}
          </Typography>
        )}
      </div>
    );
  }, [checkTooLargeFile, getAcceptedExtensions, isDesktop, maxFileSize, t]);

  return (
    <div className="ui-upload-zone">
      <Dropzone
        accept={getAcceptedMimeTypes()}
        minSize={0}
        maxSize={maxSize}
        multiple={multiple}
        onDrop={onDrop}
      >
        {renderDropzone}
      </Dropzone>
      <Typography
        variant="body2"
        className="ui-upload-zone__file-types"
      >
        {t('components.ui.upload.zone.supported_types', {fileTypes: getAcceptedExtensions()})}
      </Typography>
    </div>
  );
};