import {useCallback, useMemo} from 'react';
import {useLocation} from 'react-router-dom';
import {IUseBreadcrumbsProps, IBreadcrumb} from './use-breadcrumbs.types';
import {createUseSharedState} from 'hooks';

const useUrlTokenLabelsState = createUseSharedState<Record<string, string>>({});

/**
 * Get breadcrumbs
 * 
 * @example
 * 
 *   import {Breadcrumbs, useBreadcrumbs} from 'components/ui/breadcrumbs';
 *   import {Link} from 'components/ui/link';
 *   
 *   // ...
 *   
 *   const {breadcrumbs} = useBreadcrumbs();
 *   
 *   // ...
 *   
 *   {breadcrumbs.map(({path, urlToken}) => (
 *     <Link
 *       key={path}
 *       to={path}
 *     >
 *       {urlToken}
 *     </Link>
 *   ))}
 * 
 * @example
 * 
 *   import {useBreadcrumbs} from 'components/ui/breadcrumbs';
 * 
 *   // ...
 *   
 *   const {addUrlTokenLabel} = useBreadcrumbs();
 *   
 *   // ...
 * 
 *   // Replace news item ID with news item title in breadcrumbs (my-site/news/item-123)
 *   for (const item of news) 
 *     breadcrumbs.addUrlTokenLabel(item.id, item.title);
 */
export const useBreadcrumbs = (): IUseBreadcrumbsProps => {
  const [urlTokenLabels, setUrlTokenLabels] = useUrlTokenLabelsState();
  const location = useLocation();

  const urlTokens = useMemo(() => {
    let tokens: string[] = [];
    if (location.pathname !== '/')
      tokens = location.pathname.split('/');
    if (tokens.length > 0)
      tokens.shift();  
    tokens.unshift('/');
    return tokens;
  }, [location.pathname]);

  const breadcrumbs = useMemo(() => {
    const breadcrumbs: IBreadcrumb[] = [];
    let path = '';
    for (let i = 1; i < urlTokens.length; i++) {
      const urlToken = urlTokens[i];
      breadcrumbs.push({
        urlToken,
        path: `${path}/${urlTokens[i]}`
      });
      path = breadcrumbs[i - 1].path;
    } 
    breadcrumbs.unshift({
      urlToken: '/',
      path: '/'
    });
    return breadcrumbs;
  }, [urlTokens]);

  const addUrlTokenLabel = useCallback((urlToken: string, label: string) => {
    setUrlTokenLabels({
      ...urlTokenLabels,
      [urlToken]: label
    });
  }, [setUrlTokenLabels, urlTokenLabels]);
  
  return {
    breadcrumbs,
    urlTokenLabels,
    addUrlTokenLabel
  };
};