import {Button} from '@material-ui/core';
import {useTranslation} from 'lib/intl/i18n';
import React, {FC} from 'react';

interface IConsentCookiesButtonsProps {
  setTab: (type: 'secondTab') => void;
}

export const ConsentCookiesButtons: FC<IConsentCookiesButtonsProps> = ({setTab}) => {

  const {t} = useTranslation();

  const handleTabChange = () => {
    setTab('secondTab');
  };

  return (
    <Button
      variant="outlined"
      onClick={handleTabChange}
      style={{textTransform: 'none'}}
    >
      {t('components.main.app.cookies_popup.button.edit')}
    </Button>
  );
};
