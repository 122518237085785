import './combo-box.scss';
import React, {ChangeEvent, FunctionComponent, useCallback, useMemo, useRef, useState} from 'react';
import uniqueId from 'uniqid';
import {IComboBoxProps, ComboBoxOption, ComboBoxValue} from './combo-box.types';
import {Autocomplete, AutocompleteRenderInputParams} from 'components/ui/autocomplete';
import {TextField} from 'components/ui/text-field';
import {useWatchEffect} from 'hooks';
import {triggerChangeEvent} from 'lib/ui/dom';

/**
 * Combo box
 * 
 * @see src/views/app/kit-view/combo-box-kit
 *
 * @example
 *
 *   import {ComboBox, ComboBoxOptions} from 'components/ui/combo-box';
 *
 *   const options: ComboBoxOptions = [
 *     {
 *       label: 'Foo',
 *       value: 0
 *     },
 *     {
 *       label: 'Bar',
 *       value: 1
 *     }
 *   ];
 *
 *   // ...
 *
 *   const [value, setValue] = useState<number | null>(null);
 *
 *   const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
 *     setValue(value as number);
 *   }, []);
 *
 *   // ...
 *
 *   <ComboBox
 *     options={options}
 *     value={value}
 *     onChange={handleChange}
 *   />
 */
export const ComboBox: FunctionComponent<IComboBoxProps> = (props) => {
  const {
    className,
    name,
    options: optionsProp,
    value: valueProp,
    onChange,
    renderOption,
    renderOptionsAsHtml,
    clearable,
    virtualizedList,
    virtualizedRowHeight,
    ...fieldProps
  } = props;
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [value, setValue] = useState<ComboBoxValue | null>(valueProp);

  // Get rid of the Chrome autocomplete
  const uniqueFieldName = useMemo(() => uniqueId(), []);

  const options = useMemo(() => {
    return optionsProp || [];
  }, [optionsProp]);

  const selectedOption = useMemo<ComboBoxOption | null>(() => {
    const selected = options.find((option) => {
      return String(option.value) === String(valueProp);
    });
    return selected || null;
  }, [options, valueProp]);

  const handleChange = useCallback((event: ChangeEvent<unknown>, option: (ComboBoxOption | null)) => {
    setValue(option && option.value);
  }, []);

  useWatchEffect(() => {
    triggerChangeEvent(inputRef.current!, String(value));
  }, [value]);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event, value);
  }, [onChange, value]);

  const renderInput = useCallback((renderInputProps: AutocompleteRenderInputParams) => (
    <>
      <input
        ref={inputRef}
        hidden
        type="text"
        name={name}
        onChange={handleInputChange}
      />
      <TextField
        {...renderInputProps}
        {...fieldProps}
        name={uniqueFieldName}
      />
    </>
  ), [fieldProps, name, uniqueFieldName, handleInputChange]);

  const classes = useMemo(() => {
    let classes = 'ui-combo-box';
    if (fieldProps.disabled)
      classes += ' ui-combo-box--disabled';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, fieldProps.disabled]);

  return (
    <Autocomplete
      className={classes}
      renderInput={renderInput}
      options={options}
      value={selectedOption}
      onChange={handleChange}
      renderOption={renderOption}
      renderOptionsAsHtml={renderOptionsAsHtml}
      clearable={clearable}
      virtualizedList={virtualizedList}
      virtualizedRowHeight={virtualizedRowHeight}
    />
  );
};
