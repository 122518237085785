import './link.scss';
import React, {forwardRef, useMemo, MouseEvent, useCallback} from 'react';
import {ILinkProps} from './link.types';
import {Link as RouterLink} from 'react-router-dom';

/**
 * Router link
 *
 * @example
 *
 *   <Link to="home">
 *     Home page
 *   </Link>
 */
export const Link = forwardRef<HTMLAnchorElement, ILinkProps>(function Link(props, ref) {
  const {
    className,
    hoverAnimation,
    to,
    href,
    target,
    underline,
    size,
    disabled,
    onClick,
    children,
    ...restProps
  } = props;

  const classicLink = useMemo(() => {
    return !to || to === '#' || to.includes('//') || to.includes('.') || to.startsWith('mailto:') || href;
  }, [to,href]);

  const handleClick = useCallback((event: MouseEvent<HTMLAnchorElement>) => {
    if (!to && !href || to === '#')
      event.preventDefault();
    if (onClick)
      onClick(event);
    
  }, [onClick, to,href]);

  const classes = useMemo(() => {
    let classes = 'ui-link';
    classes += ` ui-link--size-${size}`;
    if (hoverAnimation)
      classes += ' ui-link--hover-animation';
    if (underline)
      classes += ' ui-link--underline';
    if (disabled)
      classes += ' ui-link--disabled';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, disabled, hoverAnimation, size, underline]);

  if (classicLink)
    return (
      <a
        ref={ref}
        {...restProps}
        className={classes}
        href={href ? href : to === '#' ? '' : to}
        target={target}
        onClick={handleClick}
      >
        {children}
      </a>
    );

  return (
    <RouterLink
      ref={ref}
      {...restProps}
      className={classes}
      to={to!}
      target={target}
    >
      {children}
    </RouterLink>
  );
});

Link.defaultProps = {
  hoverAnimation: true,
  size: 'medium'
};
