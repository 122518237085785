import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IActivationDialogProps} from './activation-dialog.types';
import {ActivationForm} from './activation-form';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {DialogContentText} from 'components/ui/dialog-content-text';
import {Divider} from 'components/ui/divider';

/** Account activation dialog */
export const ActivationDialog: FunctionComponent<IActivationDialogProps> = (props) => {
  const {open, onClose} = props;
  const {t} = useTranslation();

  return (
    <Dialog
      title={t('views.user.activation.dialog.title')}
      open={open}
      onClose={onClose}
      width="wide"
    >
      <DialogContent>
        <DialogContentText>
          {t('views.user.activation.dialog.instructions')}
        </DialogContentText>
        <Divider />
        <ActivationForm onSuccess={onClose} />
      </DialogContent>
    </Dialog>
  );
};