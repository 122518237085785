import './autocomplete-controls.scss';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IAutocompleteControlsProps} from './autocomplete-controls.types';
import {IconButton} from 'components/ui/icon-button';
import ClearIcon from '@material-ui/icons/Close';
import DownIcon from '@material-ui/icons/ArrowDropDown';
import {Tooltip} from 'components/ui/tooltip';

/** Autocomplete control buttons */
export const AutocompleteControls: FunctionComponent<IAutocompleteControlsProps> = (props) => {
  const {onClear, onToggle, clearable} = props;
  const {t} = useTranslation();

  return (
    <div className="ui-autocomplete-controls">

      {/* Clear button */}

      {clearable && (
        <div className="ui-autocomplete-controls__button ui-autocomplete-controls__button--clear">
          <Tooltip title={t('components.ui.autocomplete.clear')}>
            <IconButton
              tabIndex={-1}
              size="small"
              onClick={onClear}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        </div>
      )}

      {/* Toggle button */}

      <div className="ui-autocomplete-controls__button ui-autocomplete-controls__button--toggle">
        <IconButton
          size="small"
          onClick={onToggle}
        >
          <DownIcon />
        </IconButton>
      </div>
    </div>
  );
};
