import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IAboutArticleResponse} from './about.types';

/** Get about us article */
export const aboutArticle = async () => {
  const response = await axios.get<IAboutArticleResponse>(urls.aboutArticle(), {
    headers: headers.language()
  });
  return response.data;
};