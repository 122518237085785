import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Fetch current user detail */
const userDetailSaga = function* () {
  try {
    const response = yield* call(api.userDetail);
    
    // Select a partner automatically if the user has only one partner
    if (response.partners.length === 1)
      yield put(actions.selectPartner({
        partnerId: response.partners[0].id
      }));
    
    yield put(actions.userDetailSuccess(response));
  }
  catch (error) {
    yield put(actions.userDetailFailure(error as IRequestError));
  }
};

export const userDetail = function* () {
  yield takeLatest(actions.UserDetailActionTypes.request, userDetailSaga);
};
