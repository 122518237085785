/**
 * Parse bank account prefix
 *
 * @param bankAccount - Bank account string
 * @returns Bank account prefix
 */
export const bankAccountPrefix = (bankAccount: string) => {
  const match = (bankAccount || '').match(/^[0-9]*(?=-)/);
  return match ? match[0] : '';
};
