import {ITheme} from 'components/ui/theme';
import Color from 'color';
import {ChartOptions} from './chart.types';
import {mixColorOverlay} from 'lib/ui/colors';

export const getChartDefaults = (theme: ITheme): ChartOptions => ({
  colors: [
    theme.color.primary,
    theme.color.secondary,
    theme.color.warning,
    theme.color.error
  ],
  title: {
    text: '',
    style: {
      fontFamily: theme.typography.fontFamily,
      fontSize: `${theme.typography.h2.fontSize}px`,
      fontWeight: String(theme.typography.h2.fontWeight),
      color: theme.color.text
    }
  },
  chart: {
    style: {
      fontFamily: theme.typography.fontFamily,
      fontSize: `${theme.typography.smallFontSize}px`,
      color: theme.color.text
    },
    backgroundColor: mixColorOverlay(
      theme.color.paper,
      Color(theme.color.text).alpha(0.03).string()
    )
  },
  xAxis: {
    lineColor: Color(theme.color.text).alpha(0.4).string(),
    tickColor: Color(theme.color.text).alpha(0.18).string(),
    lineWidth: 1,
    gridLineColor: Color(theme.color.text).alpha(0.16).string(),
    gridLineWidth: 0,
    labels: {
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: `${theme.typography.smallFontSize}px`,
        color: theme.color.text
      }
    },
    title: {
      text: '',
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: `${theme.typography.h3.fontSize}px`,
        fontWeight: String(theme.typography.h3.fontWeight),
        color: theme.color.text
      }
    }
  },
  yAxis: {
    lineColor: Color(theme.color.text).alpha(0.4).string(),
    tickColor: Color(theme.color.text).alpha(0.18).string(),
    lineWidth: 1,
    gridLineColor: Color(theme.color.text).alpha(0.16).string(),
    gridLineWidth: 2,
    labels: {
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: `${theme.typography.smallFontSize}px`,
        color: theme.color.text
      }
    },
    title: {
      text: '',
      style: {
        fontFamily: theme.typography.fontFamily,
        fontSize: `${theme.typography.h3.fontSize}px`,
        fontWeight: String(theme.typography.h3.fontWeight),
        color: theme.color.text
      }
    }
  },
  legend: {
    enabled: true,
    align: 'center',
    verticalAlign: 'bottom',
    x: 0,
    y: 0,
    itemStyle: {
      fontFamily: theme.typography.fontFamily,
      fontSize: `${theme.typography.smallFontSize}px`,
      fontWeight: 'normal',
      color: theme.color.text
    },
    itemHoverStyle: {
      color: theme.color.primary
    }
  },
  tooltip: {
    backgroundColor: theme.color.paper,
    style: {
      fontFamily: theme.typography.fontFamily,
      fontSize: `${theme.typography.smallFontSize}px`,
      color: theme.color.text
    }
  },
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false,
    chartOptions: {
      credits: {
        enabled: false
      }
    }
  }
});
