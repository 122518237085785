import React, {Children, cloneElement, forwardRef, useMemo, PropsWithChildren} from 'react';
import {IVirtualizedListProps} from './virtualized-list.types';
import {FixedSizeList, ListChildComponentProps} from 'react-window';
import {OuterElementContext, OuterElementType} from './outer-element';

const renderRow = (props: ListChildComponentProps) => {
  const {data, index, style} = props;
  return cloneElement(data[index], {style});
};

/**
 * List virtualization
 * 
 * @example
 * 
 *   <VirtualizedList
 *     maxVisibleItems={10}
 *     rowHeight={40}
 *   >
 *     {items.map((item) => (
 *       <div key={item.id}>
 *         {item.label}
 *       </div>
 *     ))}
 *   </VirtualizedList>
 */
export const VirtualizedList = forwardRef<HTMLDivElement, PropsWithChildren<IVirtualizedListProps>>(function VirtualizedList(props, ref) {
  const {
    rowHeight,
    maxVisibleItems = 8,
    children,
    ...other
  } = props;
  const itemHeight = rowHeight || 44;
  const itemData = Children.toArray(children);
  const itemCount = itemData.length;

  const listboxHeight = useMemo(() => {
    let height = 0;
    if (itemCount > maxVisibleItems)
      height += maxVisibleItems * itemHeight;
    else
      height += itemCount * itemHeight;
    return height;
  }, [itemCount, itemHeight, maxVisibleItems]);

  return (
    <div ref={ref}> 
      <OuterElementContext.Provider value={other}>
        <FixedSizeList
          itemData={itemData}
          width="100%"
          height={listboxHeight}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={itemHeight}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </FixedSizeList>
      </OuterElementContext.Provider>
    </div>
  );
});