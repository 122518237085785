import React, {FunctionComponent, useCallback, useContext, useMemo} from 'react';
import memoize from 'fast-memoize';
import {useNavigate} from 'react-router-dom';
import {AppMenuContext} from '../../app-menu-context';
import {AppMenuItems} from '../../app-menu.types';
import {IDataListItem, DataListItems} from 'components/ui/data-list';
import {MobileMenuPanelProps} from './mobile-menu-panel.types';
import {useTranslation} from 'lib/intl/i18n';
import {Sidebar} from 'components/ui/sidebar';
import {DataList} from 'components/ui/data-list';

/** Mobile menu sidebar */
export const MobileMenuPanel: FunctionComponent<MobileMenuPanelProps> = (props) => {
  const {open, onClose} = props;
  const {menuItems} = useContext(AppMenuContext);
  const navigate = useNavigate();
  const {t} = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const navigateTo = useCallback(memoize((path) => () => {
    navigate(path);
    onClose();
  }), [navigate]);

  const getListItems = useCallback((menuItems: AppMenuItems): DataListItems => {
    let listItems: DataListItems = [];
    for (const item of menuItems) {
      if (item.subMenu?.items)
        listItems = [...listItems, ...getListItems(item.subMenu.items)];
      else {
        const label = item.label || '';
        const icon = item.icon || item.subMenu?.icon;
        const onClick = navigateTo(item.goToPath || item.path);
        const listItem: IDataListItem = {label, icon, onClick};
        listItems.push(listItem);
      }
    }
    return listItems;
  }, [navigateTo]);
  
  const listItems = useMemo<DataListItems>(() => {
    const visibleItems = menuItems.filter((item) => {
      return !item.hidden && item.label;
    });
    return getListItems(visibleItems);
  }, [getListItems, menuItems]);

  return (
    <Sidebar
      heading={t('components.main.app.menu.heading')}
      side="right"
      open={open}
      onClose={onClose}
    >
      <DataList items={listItems} />
    </Sidebar>
  );
};