import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useOnFetchSuccess} from 'hooks';
import {useDeepEffect} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {useFetch} from 'hooks';
import {api} from 'api';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {IFilteredDemandsTableProps} from './filtered-demands-table.types';
import {DemandsTable} from 'components/demand/demands-table';
import {Pagination, usePagination} from 'components/ui/pagination';

const ROWS_PER_PAGE = 7;

/** Table of filtered demands */
export const FilteredDemandsTable: FunctionComponent<IFilteredDemandsTableProps> = (props) => {
  const {filter} = props;
  const demands = useFetch(api.demandList);
  const {currentPage, setCurrentPage} = usePagination();
  const demandCreation = useSelector(selectors.demandCreation, shallowEqual);
  const {t} = useTranslation();

  const fetchOptions = useMemo(() => ({
    typeId: filter.values.typeId
  }), [filter.values]);

  /** Get the data to be displayed in the demands table */
  const fetchTableData = useCallback(() => {
    if (filter.isComplete)
      demands.fetch({
        ...fetchOptions,
        page: currentPage - 1,
        size: ROWS_PER_PAGE
      });
  }, [currentPage, demands, filter.isComplete, fetchOptions]);

  /** Get all demands data for export */
  const fetchExportData = useCallback((paging?: IPagingRequest) => {
    return api.demandList({
      ...fetchOptions,
      ...paging
    });
  }, [fetchOptions]);
  
  // Fetch data on page load
  useEffect(() => {
    fetchTableData();
  // eslint-disable-next-line
  }, []);

  // Reset pagination on filter change
  useDeepEffect(() => {
    if (filter.isComplete)
      setCurrentPage(1);
  }, [filter.values, filter.isComplete]);

  // Fetch data on any change
  useDeepEffect(() => {
    fetchTableData();
  }, [filter, currentPage]);

  // Refresh on successful demand creation
  useOnFetchSuccess([demandCreation], () => {
    fetchTableData();
  });

  const render = useCallback(() => (
    <>
      <DemandsTable
        demands={demands.data!.content}
        exportDataSource={fetchExportData}
      />
      <Pagination
        gutterTop
        pageSize={ROWS_PER_PAGE}
        totalItemCount={demands.data!.totalElements}
        currentPage={currentPage}
        onChange={setCurrentPage}
      />
    </>
  ), [currentPage, demands.data, fetchExportData, setCurrentPage]);

  return (
    <WaitForFetch
      pending={demands.pending}
      error={demands.error}
      noData={!demands.data?.content?.length}
      noDataMessage={t('views.demand.table.no_data')}
      onRetry={fetchTableData}
      render={render}
      absolutePosition
    />
  );
};