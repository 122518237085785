import './checkbox.scss';
import React, {forwardRef, useMemo} from 'react';
import {ICheckboxProps} from './checkbox.types';
import MuiCheckbox from '@material-ui/core/Checkbox';
import {FormControlLabel} from 'components/ui/form-control-label';

/**
 * Checkbox field
 * 
 * @see src/views/app/kit-view/checkbox-kit
 *
 * @example
 *
 *   const [isChecked, setIsChecked] = useState(false);
 *   
 *   const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
 *     setIsChecked(checked);
 *   }, []);
 * 
 *   // ...
 * 
 *   <Checkbox
 *     label="I agree to the terms and conditions"
 *     checked={isChecked}
 *     onChange={handleChange}
 *   />
 */
export const Checkbox = forwardRef<HTMLButtonElement, ICheckboxProps>(function Checkbox(props, ref) {
  const {
    className,
    label,
    size,
    hoverAnimation = true,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-checkbox';
    if (hoverAnimation)
      classes += ' ui-checkbox--hover-animation';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, hoverAnimation]);

  const control = useMemo(() => (
    <MuiCheckbox
      ref={ref}
      {...restProps}
      size={size}
      className={classes}
    />
  ), [classes, ref, restProps, size]);
  
  if (label)
    return (
      <FormControlLabel
        className={classes}
        control={control}
        label={label}
        size={size}
      />
    );

  return control;
});
