import './breadcrumbs-separator.scss';
import React, {FunctionComponent} from 'react';
import {SvgIcon} from 'components/ui/svg-icon';

/** Breadcrumbs separator icon */
export const BreadcrumbsSeparator: FunctionComponent = () => {
  return (
    <SvgIcon
      className="ui-breadcrumbs-separator"
      viewBox="0 0 7.761 13.205"
    >
      <path
        fill="none"
        stroke="currentColor"
        strokeLinecap="butt"
        strokeLinejoin="miter"
        strokeWidth="1"
        d="M2.35231505 2.35231505l3.05613712 4-3.05613712 4.5"
      />
    </SvgIcon>
  );
};