/** @file Application locale helpers */

import moment from 'moment';
import {Highcharts} from 'lib/ui/highcharts';
import {i18n} from 'lib/intl/i18n';
import {setIntlLocale} from 'lib/intl/intl-api';
import {getState, dispatch} from 'store';
import {actions} from 'actions';
import {selectors} from 'selectors';
import {SupportedLocales, SUPPORTED_LOCALES} from 'config/intl';
import {localeCode} from 'lib/intl/locale-code';
import {InputValidationException} from 'lib/global/exceptions';

/** Get the current local code */
export const getCurrentLocale = (): ISOLocaleCode => {
  return selectors.locale(getState());
};

/** Get the current locale language code */
export const getCurrentLanguage = (): ISOLangCode => {
  return selectors.language(getState());
};

/** Get the current locale country code */
export const getCurrentCountry = (): ISOCountryCode => {
  return selectors.country(getState());
};

/**
 * Set the application locale
 *
 * @param locale - ISO locale code
 * @throws {InputValidationException}
 */
export const setLocale = async (locale: SupportedLocales) => {
  if (!SUPPORTED_LOCALES.includes(locale))
    throw new InputValidationException('Requested locale is not supported.');
  await setupLocale(locale);
  dispatch(actions.setLocale(locale));
};

/**
 * Set the correct locale for all libraries used in the application
 * 
 * @param locale - ISO locale code
 */
const setupLocale = async (locale: ISOLocaleCode) => {
  const language = localeCode.getLanguageCode(locale);
  document.documentElement.setAttribute('lang', language);
  setIntlLocale(locale);
  await Promise.all([
    i18n.changeLanguage(language),
    setMomentLanguage(language),
    setHighchartsLanguage(language)
  ]);
};

/**
 * Set the locale of the Moment library
 *
 * @param language - ISO language code
 *
 * @see https://momentjs.com
 */
const setMomentLanguage = async (language: ISOLangCode) => {
  const code = language === 'en' ? 'en-gb' : language;
  await import(`moment/locale/${code}`);
  moment.locale(code);
};

/**
 * Set Highcharts library locale
 *
 * @param language ISO language code
 *
 * @see https://highcharts.com
 */
export const setHighchartsLanguage = async (language: ISOLangCode) => {
  const lang = await import(`config/translations/${language}/highcharts.json`);
  Highcharts.setOptions({lang});
};