import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IPasswordValidationRequest, IPasswordValidationResponse} from './password-validation.types';

/** Password strength evaluation */
export const passwordValidation = async (request: IPasswordValidationRequest) => {
  const response = await axios.post<IPasswordValidationResponse>(urls.passwordValidation(), request, {
    headers: headers.language()
  });
  return response.data;
};