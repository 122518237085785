/**
 * @file Redux middlewares
 * 
 * @see https://redux.js.org/tutorials/fundamentals/part-4-store#middleware
 */

import {stateSyncMiddleware} from './redux-state-sync';
import {sagaMiddleware} from './redux-saga';
import {composeWithDevTools} from 'redux-devtools-extension';
import {applyMiddleware} from 'redux';

export const middlewares = composeWithDevTools(
  applyMiddleware(
    stateSyncMiddleware,
    sagaMiddleware
  )
);
