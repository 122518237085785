import {
  IUserActivationRequestAction,
  IUserActivationSuccessAction,
  IUserActivationFailureAction,
  UserActivationActionTypes
} from './user-activation.types';
import {IUserActivationRequest} from 'api';

/** Activate new user account */
export const userActivationRequest = (payload: IUserActivationRequest): IUserActivationRequestAction => ({
  type: UserActivationActionTypes.request,
  payload
});

export const userActivationSuccess = (): IUserActivationSuccessAction => ({
  type: UserActivationActionTypes.success
});

export const userActivationFailure = (payload: IRequestError): IUserActivationFailureAction => ({
  type: UserActivationActionTypes.failure,
  payload
});
