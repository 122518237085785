import './invoices-info.scss';
import React, {FunctionComponent} from 'react';
import {IInvoicesInfoProps} from './invoices-info.types';
// import {useTranslation} from 'lib/intl/i18n';
import {InvoiceSummary} from 'components/invoice/invoice-summary';
// import {ContractInvoicingDemand} from 'components/ContractInvoicingDemand';

/** Invoices overview */
export const InvoicesInfo: FunctionComponent<IInvoicesInfoProps> = (props) => {
  const {filter} = props;
  // const {t} = useTranslation();
  
  if (!filter.isComplete)
    return null;

  return (
    <div className="ui-invoices-info">
      <InvoiceSummary contractId={filter.values.contractId} />
      {/* <div className="ui-invoices-info__button">
        <ContractInvoicingDemand label={t('views.invoice.info.sending_method')}/>
      </div> */}
    </div>
  );
};