import './contract-info.scss';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {IContractInfoProps} from './contract-info.types';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import InfoIcon from '@material-ui/icons/Info';
import {ContractTable} from 'components/user/contract-table';

/** Contract info */
export const ContractInfo: FunctionComponent<IContractInfoProps> = (props) => {
  const {contractId} = props;
  const contracts = useFetch(api.contractList);
  const {t} = useTranslation();

  const fetchContracts = useCallback(() => {
    contracts.fetch({contractId});
  }, [contractId, contracts]);
  
  useEffect(() => {
    fetchContracts();
  // eslint-disable-next-line
  }, []);

  const render = useCallback(() => (
    <ContractTable contract={contracts.data![0]} />
  ), [contracts.data]);

  return (
    <>

      {/* Heading */}

      <Typography
        variant="h2"
        color="primary"
        gutterBottom
      >
        <InfoIcon />
        {t('views.user.contract.info.heading')}
      </Typography>
      
      {/* Table */}
      
      <WaitForFetch
        className="ui-contract-info"
        pending={contracts.pending}
        error={contracts.error}
        noData={!contracts.data || !contracts.data[0]}
        onRetry={fetchContracts}
        render={render}
      />
    </>
  );
};