import {EnergyTypeListResponse} from 'api';

export enum EnergyTypeListActionTypes {
  request = 'ENERGY_TYPE_LIST_REQUEST',
  success = 'ENERGY_TYPE_LIST_SUCCESS',
  failure = 'ENERGY_TYPE_LIST_FAILURE'
}

export interface IEnergyTypeListRequestAction {
  type: EnergyTypeListActionTypes.request;
}

export interface IEnergyTypeListSuccessAction {
  type: EnergyTypeListActionTypes.success;
  payload: EnergyTypeListResponse;
}

export interface IEnergyTypeListFailureAction {
  type: EnergyTypeListActionTypes.failure;
  payload: IRequestError;
}

export type EnergyTypeListAction = (
  IEnergyTypeListRequestAction |
  IEnergyTypeListSuccessAction |
  IEnergyTypeListFailureAction
);