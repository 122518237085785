import {ChangeEvent, useCallback, useMemo, useState} from 'react';
import {validate as validators, composeValidators} from 'lib/global/validate';
import {IUseFilterFieldOptions, IUseFilterFieldProps} from './use-filter-field.types';

/** Filter field logic */
export const useFilterField = <V extends FormFieldValue = string>(options: IUseFilterFieldOptions<V>): IUseFilterFieldProps<V> => {
  const {
    value: valueProp,
    checked,
    onBlur,
    onChange
  } = options;
  const [touched, setTouched] = useState(false);
  const [error, setError] = useState<string>();

  const value = useMemo(() => {
    if (checked !== undefined)
      return checked;
    return valueProp;
  }, [checked, valueProp]);

  const props = useMemo<IUseFilterFieldOptions<V>>(() => {
    const props = {...options};

    //--- Required field validation

    if (props.required)
      props.validate = composeValidators(props.validate, validators.required);
    else if (props.validate?.name === 'required' || props.validate?.names?.includes('required'))
      props.required = true;

    return props;
  }, [options]);

  const validateValue = useCallback((value: V) => {
    if (props.validate)
      setError(props.validate(value));
  }, [props]);
  
  const handleBlur: typeof onBlur = useCallback((...args) => {
    setTouched(true);
    validateValue(value as V);
    if (onBlur)
      onBlur(...args);
  }, [onBlur, validateValue, value]);

  const handleChange: typeof onChange = useCallback((event: ChangeEvent<unknown>, ...args) => {
    const target = event.target as HTMLInputElement;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    validateValue(value as V);
    if (onChange)
      onChange(event, ...args);
  }, [onChange, validateValue]);

  return useMemo(() => ({
    ...props,
    field: {
      onBlur: handleBlur,
      onChange: handleChange
    },
    meta: {
      touched,
      error
    }
  }), [error, handleBlur, handleChange, props, touched]);
};