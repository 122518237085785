import {useState, useCallback} from 'react';
import {IUseUploadProps} from './use-upload.types';

/**
 * File upload logic
 * 
 * @example
 *
 *   import {Upload, useUpload} from 'components/ui/upload';
 *   
 *   // ...
 *   
 *   const {
 *     uploadPending,
 *     uploadError,
 *     uploadFileIds,
 *     handleUploadRequest,
 *     handleUploadSuccess,
 *     handleUploadError,
 *     handleUploadRemove
 *   } = useUpload();
 *   
 *   useEffect(() => {
 *     console.log('Upload pending: ', uploadPending);
 *     console.log('Upload error: ', uploadError);
 *     console.log('Upload file ids:', uploadFileIds);
 *   }, [uploadPending, uploadError, uploadFileIds]);
 *   
 *   // ...
 *   
 *   <Upload
 *     onRequest={handleUploadRequest}
 *     onSuccess={handleUploadSuccess}
 *     onError={handleUploadError}
 *     onRemove={handleUploadRemove}
 *   />
 */
export const useUpload = (): IUseUploadProps => {
  const [uploadState, setUploadState] = useState({pending: false, error: false});
  const [fileIds, setFileIds] = useState<string[]>();

  const handleUploadRequest = useCallback(() => {
    setUploadState({
      pending: true,
      error: false
    });
  }, []);

  const handleUploadSuccess = useCallback((fileIds: string[]) => {
    setUploadState({
      ...uploadState,
      pending: false
    });
    setFileIds(fileIds);
  }, [uploadState]);

  const handleUploadError = () => {
    setUploadState({
      pending: false,
      error: true
    });
  };

  const handleUploadRemove = useCallback((fileIds: string[]) => {
    setFileIds(fileIds);
  }, []);

  return {
    uploadPending: uploadState.pending,
    uploadError: uploadState.error,
    uploadFileIds: fileIds,
    handleUploadRequest,
    handleUploadSuccess,
    handleUploadError,
    handleUploadRemove
  };
};