/** @file Workarounds for beta version of React router v6 */

import {useMatch as routerUseMatch, matchPath as routerMatchPath} from 'react-router-dom';

/**
 * Workaround for incorrect match of subpaths
 * 
 * @see https://github.com/ReactTraining/react-router/issues/7517
 */
const subPathNotMatch = (pattern: Parameters<typeof routerMatchPath>[0]): boolean => {
  let {pathname} = window.location;
  let path = typeof pattern === 'string' ? pattern : pattern.path;
  if (path.endsWith('/*')) {
    if (path.startsWith('/'))
      path = path.slice(1);
    if (pathname.startsWith('/'))
      pathname = pathname.slice(1);
    pathname = `${pathname.split('/')[0]}/`;
    path = `${path.split('/')[0]}/`;
    return pathname !== path;
  }
  return false;
};

/**
 * Workaround for incorrect match of subpaths
 * 
 * @example
 * 
 *   // Result of the original useMatch is true even for '/contracts' or '/contract-list'
 *   const isContractDetailPage = useMatch('/contract/*') !== null;
 * 
 * @see https://github.com/ReactTraining/react-router/issues/7517
 */
export const useMatch: typeof routerUseMatch = (pattern) => {
  if (subPathNotMatch(pattern))
    return null;
  return routerUseMatch(pattern);
};

/**
 * Workaround for incorrect match of subpaths
 * 
 * @example
 * 
 *   // Result of the original matchPath is true even for '/contracts' or '/contract-list'
 *   const isContractDetailPage = matchPath('/contract/*', location.pathname) !== null;
 * 
 * @see https://github.com/ReactTraining/react-router/issues/7517
 */
export const matchPath: typeof routerMatchPath = (pattern, pathname) => {
  if (subPathNotMatch(pattern))
    return null;
  return routerMatchPath(pattern, pathname);
};
