import './picker-field.scss';
import React, {forwardRef, useMemo} from 'react';
import {TextField} from 'components/ui/text-field';
import {PickerFieldProps} from './picker-field.types';

/**
 * Input field for usage with Material-UI pickers library components
 *
 * @see https://material-ui-pickers.dev/releases
 *
 * @example
 *
 *   import {DatePicker, DatePickerProps} from '@material-ui/pickers';
 *   import {MuiTextFieldProps} from '@material-ui/pickers/_shared/PureDateInput';
 *   import {PickerField} from 'components/ui/picker-field';
 *
 *   // ...
 * 
 *   const renderInput = useCallback((inputProps: MuiTextFieldProps) => (
 *     <PickerField
 *       {...inputProps}
 *       ...
 *     />
 *   ), []);
 *
 *   return (
 *     <DatePicker
 *       renderInput={renderInput}
 *       ...
 *     />
 *   );
 */
export const PickerField = forwardRef<HTMLDivElement, PickerFieldProps>(function PickerField(props, ref) {
  const {
    className,
    inputProps: inputPropsProp,
    placeholder,
    ...restProps
  } = props;

  const inputProps = useMemo<typeof inputPropsProp>(() => {
    if (inputPropsProp)
      return {...inputPropsProp, placeholder};
    return inputPropsProp;
  }, [inputPropsProp, placeholder]);

  const classes = useMemo(() => {
    let classes = 'ui-picker-field';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <TextField
      ref={ref}
      {...restProps}
      inputProps={inputProps}
      className={classes}
      helperText={false}
    />
  );
});
