import './slider-kit.scss';
import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Slider, Mark} from 'components/ui/slider';
import {SliderRange} from 'components/ui/slider-range';

/** Slider control preview */
export const SliderKit: FunctionComponent = () => {
  const [value, setValue] = useState(20);
  const [range, setRange] = useState({
    from: 33,
    to: 66
  });
  const {t} = useTranslation();

  const valueChange = useCallback((value: number) => {
    setValue(value);
  }, []);

  const rangeChange = useCallback((from: number, to: number) => {
    setRange({from, to});
  }, []);

  const marks = useMemo<Mark[]>(() => {
    const values = [0, 20, 40, 60, 80, 100];
    return values.map((value) => ({
      value,
      label: `${value}°C`
    }));
  }, []);

  const valueLabelFormat = useCallback((value: number) => {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }, [marks]);

  return (
    <div className="ui-slider-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.slider.heading')}
      </Typography>

      {/* Value */}

      <div className="ui-slider-kit__row">
        <Slider
          value={value}
          onChange={valueChange}
          valueLabelFormat={valueLabelFormat}
          marks={marks}
          step={null}
        />
      </div>

      {/* Range */}

      <div className="ui-slider-kit__row">
        <SliderRange
          fromValue={range.from}
          toValue={range.to}
          onChange={rangeChange}
        />
      </div>
    </div>
  );
};
