import {
  PathsToPersist,
  PersistAll,
  PathArray,
  TypedPath,
  PrimitiveValue,
  PlainObject
} from './persist.types';
import {typedPath} from 'typed-path';

export const PERSIST_ALL = 'persist-all';

/**
 * Redux store persistence
 * 
 * @example
 * 
 *   //--- Persist only selected keys in state
 * 
 *   // Define keys to persist
 *   const persist = new Persist<IAuthState>('auth', (path) => [
 *     path.accessToken,
 *     path.tokenType,
 *     path.refreshToken,
 *     path.expiresAt
 *   ]);
 *   
 *   // Restore previous state
 *   export const initialState: IAuthState = persist.initialState({
 *     tokenPending: false,
 *     tokenError: null,
 *     refreshPending: false,
 *     refreshError: null,
 *     accessToken: null,     // This key will be rehydrated
 *     tokenType: null,       // This key will be rehydrated
 *     refreshToken: null,    // This key will be rehydrated
 *     expiresAt: null        // This key will be rehydrated
 *   });
 *   
 *   export const auth: Reducer<IAuthState, AuthAction> = persist.reducer((state = initialState, action): IAuthState => {
 *     switch (action.type) {
 *       case AuthActionTypes.request:
 *         return {
 *           ...state,
 *           tokenPending: true,
 *           tokenError: null,
 *           refreshError: null
 *         };
 *       case AuthActionTypes.success:
 *         return {
 *           ...state,
 *           tokenPending: false,
 *           accessToken: action.payload.accessToken,   // This key will be persisted
 *           tokenType: action.payload.tokenType,       // This key will be persisted
 *           refreshToken: action.payload.refreshToken, // This key will be persisted
 *           expiresAt: action.payload.expiresAt        // This key will be persisted
 *         };
 *       case AuthActionTypes.failure:
 *         return {
 *           ...state,
 *           tokenPending: false,
 *           tokenError: action.payload,
 *           accessToken: null,          // This key will be persisted
 *           tokenType: null,            // This key will be persisted
 *           refreshToken: null,         // This key will be persisted
 *           expiresAt: null             // This key will be persisted
 *         };
 *       default:
 *         return state;
 *     }
 *   });
 * 
 * @example
 * 
 *   //--- Persist whole state
 * 
 *   import {Persist} from 'lib/global/persist';
 * 
 *   const persist = new Persist<IAuthState>('auth');
 * 
 *   // Restore previous state
 *   export const initialState: IAuthState = persist.initialState({
 *     tokenPending: false,   // This key will be rehydrated
 *     tokenError: null,      // This key will be rehydrated
 *     refreshPending: false, // This key will be rehydrated
 *     refreshError: null,    // This key will be rehydrated
 *     accessToken: null,     // This key will be rehydrated
 *     tokenType: null,       // This key will be rehydrated
 *     refreshToken: null,    // This key will be rehydrated
 *     expiresAt: null        // This key will be rehydrated
 *   });
 * 
 * @example
 * 
 *   //--- Persist primitive value state (same as the above example)
 * 
 *   type SomeState = string;
 *
 *   const persist = new Persist<SomeState>('someState');
 *
 *   export const initialState: SomeState = persist.initialState('someValue');
 * 
 * @example
 * 
 *   //--- Persist selected keys in nested states
 * 
 *   interface IBalanceState {
 *     pending: boolean;
 *     account: number;
 *     cash: number;
 *   }
 *   
 *   interface IUserState {
 *     pending: boolean;
 *     name: string;
 *     email: string;
 *     balance: IBalanceState;
 *   }
 *   
 *   const persist = new Persist<IUserState>('user', (path) => [
 *     path.name,
 *     path.login,
 *     path.balance.account,
 *     path.balance.cash
 *   ]);
 *   
 *   // Restore previous state
 *   const initialState: IUserState = persist.initialState({
 *     pending: false,
 *     name: '',         // This key will be rehydrated
 *     login: '',        // This key will be rehydrated
 *     balance: {
 *       pending: false,
 *       account: 0,     // This key will be rehydrated
 *       cash: 0         // This key will be rehydrated
 *     }
 *   });
 */
export class Persist<TState> {
  private static STORAGE_PATH = 'persist';

  private pathsToPersist: (PersistAll | PathArray[]);

  constructor(
    private storageKey: string,
    pathsToPersist: PathsToPersist<TState> = PERSIST_ALL
  ) {
    this.pathsToPersist = this.getPathsToPersist(pathsToPersist);
  }

  private getPathsToPersist(pathsToPersist: PathsToPersist<TState> = PERSIST_ALL) {
    if (typeof pathsToPersist === 'function') {
      const paths = pathsToPersist(typedPath<TState>() as TypedPath<TState>);
      return paths.map((path) => path.$rawPath as PathArray);
    }
    return pathsToPersist;
  }

  private getStorageKey() {
    return `${Persist.STORAGE_PATH}.${this.storageKey}`;
  }
  
  private storeState(state: TState) {
    localStorage.setItem(this.getStorageKey(), JSON.stringify(state));
  }
  
  private getStoredState(): (TState | undefined) {
    const state = localStorage.getItem(this.getStorageKey());
    if (state !== null)
      return JSON.parse(state);
  }

  private getNestedProperty(state: TState, path: PathArray, getter: (value: PrimitiveValue | PlainObject) => void) {
    let ref = state as PlainObject;
    for (const key of path) {
      if (typeof ref !== 'object' || Array.isArray(ref))
        return; // Fail silently for non-existent paths
      ref = ref[key] as PlainObject;
    }
    getter(ref as (PrimitiveValue | PlainObject));
  }

  private setNestedProperty(state: TState, path: PathArray, value: unknown) {
    if (!path.length)
      throw new Error('Expected non-empty string array as a path.');
    this.getNestedProperty(state, path.slice(0, -1), (ref) => {
      (ref as PlainObject)[path[path.length - 1]] = value;
    });
  }
  
  private filterPersistedKeys(state: TState) {
    const newState = {} as TState;
    for (const path of this.pathsToPersist as PathArray[])
      this.getNestedProperty(state, path, (value) => {
        this.setNestedProperty(newState, path, value);
      });
    return newState;
  }
  
  private store(state: TState) {
    if (this.pathsToPersist === PERSIST_ALL)
      this.storeState(state);
    else {
      const stateToStore = this.filterPersistedKeys(state);
      this.storeState(stateToStore);
    }
    return state;
  }
 
  private restore(state: TState): TState {
    const storedState = this.getStoredState();
    if (storedState === undefined)
      return state;
    if (typeof storedState !== 'object' || Array.isArray(storedState) || this.pathsToPersist === PERSIST_ALL)
      return storedState;
    const stateToRestore = this.filterPersistedKeys(storedState);
    return {...state, ...stateToRestore};
  }

  initialState(state: TState) {
    return this.restore(state);
  }

  reducer<TAction>(reducerFn: (state: (TState | undefined), action: TAction) => TState) {
    return (state: (TState | undefined), action: TAction) => {
      return this.store(reducerFn(state, action));
    };
  }
}