import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IUserRegistrationRequest} from './user-registration.types';

/** User registration */
export const userRegistration = async (request: IUserRegistrationRequest) => {
  const response = await axios.post(urls.userRegistration(), request, {
    headers: headers.language()
  });
  return response.data;
};