import {
  IPaymentStatusListRequestAction,
  IPaymentStatusListSuccessAction,
  IPaymentStatusListFailureAction,
  PaymentStatusListActionTypes
} from './payment-status-list.types';
import {PaymentStatusListResponse} from 'api';

/** Get list of payment statuses */
export const paymentStatusListRequest = (): IPaymentStatusListRequestAction => ({
  type: PaymentStatusListActionTypes.request
});

export const paymentStatusListSuccess = (payload: PaymentStatusListResponse): IPaymentStatusListSuccessAction => ({
  type: PaymentStatusListActionTypes.success,
  payload
});

export const paymentStatusListFailure = (payload: IRequestError): IPaymentStatusListFailureAction => ({
  type: PaymentStatusListActionTypes.failure,
  payload
});