import {
  IDemandPriorityListRequestAction,
  IDemandPriorityListSuccessAction,
  IDemandPriorityListFailureAction,
  DemandPriorityListActionTypes
} from './demand-priority-list.types';
import {DemandPriorityListResponse} from 'api';

/** Fetch list of demand priorities */
export const demandPriorityListRequest = (): IDemandPriorityListRequestAction => ({
  type: DemandPriorityListActionTypes.request
});

export const demandPriorityListSuccess = (payload: DemandPriorityListResponse): IDemandPriorityListSuccessAction => ({
  type: DemandPriorityListActionTypes.success,
  payload
});

export const demandPriorityListFailure = (payload: IRequestError): IDemandPriorityListFailureAction => ({
  type: DemandPriorityListActionTypes.failure,
  payload
});