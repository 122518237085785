import React, {FunctionComponent, useCallback, useRef} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {useDialog, useRoles, UserRole} from 'hooks';
import {Button} from 'components/ui/button';
import DemandIcon from '@material-ui/icons/PostAddOutlined';
import {DemandDialog} from 'components/demand/demand-dialog';
import {DemandMenu} from './demand-menu';

/** Create demand button */
export const DemandButton: FunctionComponent = () => {
  const demandDialog = useDialog();
  const demandMenu = useDialog();
  const buttonRef = useRef<HTMLButtonElement>(null!);
  const {hasRole} = useRoles();
  const {t} = useTranslation();

  const handleClick = useCallback(() => {
    if (hasRole(UserRole.HouseManager))
      demandMenu.open();
    else
      demandDialog.open();
  }, [demandDialog, demandMenu, hasRole]);

  return (
    <>
      <Button
        ref={buttonRef}
        color="secondary"
        icon={<DemandIcon />}
        onClick={handleClick}
      >
        {t('views.demand.demand_button')}  
      </Button>
      <DemandDialog {...demandDialog.props} />
      <DemandMenu
        {...demandMenu.props}
        menuAnchor={buttonRef.current}
      />
    </>
  );
};