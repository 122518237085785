import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Fetch a list of demand types */
const demandTypeListSaga = function* () {
  try {
    const response = yield* call(api.demandTypeList);
    yield put(actions.demandTypeListSuccess(response));
  }
  catch (error) {
    yield put(actions.demandTypeListFailure(error as IRequestError));
  }
};

export const demandTypeList = function* () {
  yield takeLatest(actions.DemandTypeListActionTypes.request, demandTypeListSaga);
};