import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {format} from 'lib/intl/format';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {IConsumptionTableProps} from './consumption-table.types';
import {useTranslation} from 'lib/intl/i18n';
import {EnergyType} from './energy-type';
import {
  DataTable,
  asTableColumns,
  mapTableRows,
  asTableExportColumns,
  mapTableExportData,
  getExportSource
} from 'components/ui/data-table';
import {IConsumptionResponse} from 'api';

/**
 * Consumption table
 * 
 * @example
 * 
 *   <ConsumptionTable consumption={someConsumptionList} />
 */
export const ConsumptionTable: FunctionComponent<IConsumptionTableProps> = (props) => {
  const {consumption, exportDataSource} = props;
  const energyTypeList = useSelector(selectors.energyTypeList, shallowEqual);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (!energyTypeList.data)
      dispatch(actions.energyTypeListRequest());
  }, [dispatch, energyTypeList.data]);
  
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const columns = useMemo(() => asTableColumns({
    place: {
      label: t('components.consumption.consumption_table.column.place')
    },
    period: {
      label: t('components.consumption.consumption_table.column.period')
    },
    energyType: {
      label: t('components.consumption.consumption_table.column.energy'),
      bold: true
    },
    amount: {
      label: t('components.consumption.consumption_table.column.amount'),
      align: 'right',
      bold: true
    }
  }), [t]);

  const getConsumptionAddress = useCallback((item: IConsumptionResponse) => {
    const {city, street} = item.consumptionPlace.address;
    const addressItems = [];
    if (city)
      addressItems.push(city);
    if (street)
      addressItems.push(street);
    return addressItems.join(', ');
  }, []);
  
  const rows = useMemo(() => mapTableRows(columns, consumption, (item) => {
    const address = getConsumptionAddress(item);
    return {
      place: (
        <>
          <b>
            {item.consumptionPlace.name}
            {address && (<>,<br /></>)}
          </b>
          {address}
        </>
      ),
      period: `${format.date(item.from)} - ${format.date(item.to)}`,
      energyType: (
        <EnergyType typeId={item.energyTypeId} />
      ),
      amount: format.unit(item.consumption.value, item.consumption.unitId)
    };
  }), [columns, consumption, getConsumptionAddress]);
  
  const getEnergyTypeName = useCallback((typeId: string) => {
    const energyType = energyTypeList.data?.find((type) => String(type.id) === String(typeId));
    if (energyType)
      return energyType.name;
    return t('components.consumption.consumption_table.unknown_energy_type');
  }, [energyTypeList.data, t]);

  const exportData = useCallback(async () => {
    const exportSource = await getExportSource(exportDataSource || consumption);
    const exportColumns = asTableExportColumns({
      place: t('components.consumption.consumption_table.column.place'),
      period: t('components.consumption.consumption_table.column.period'),
      energyType: t('components.consumption.consumption_table.column.energy'),
      amount: t('components.consumption.consumption_table.column.amount')
    });
    return mapTableExportData(exportColumns, exportSource, (item) => {
      const address = getConsumptionAddress(item);      
      return {
        place: item.consumptionPlace.name + (address ? `, ${address}` : ''),
        period: `${format.date(item.from)} - ${format.date(item.to)}`,
        energyType: getEnergyTypeName(item.energyTypeId),
        amount: format.unit(item.consumption.value, item.consumption.unitId)
      };
    });
  }, [consumption, exportDataSource, getConsumptionAddress, getEnergyTypeName, t]);

  const render = useCallback(() => (
    <DataTable
      columns={columns}
      rows={rows}
      exportData={exportData}
      exportFilename={t('components.consumption.consumption_table.export_filename')}
    />
  ), [columns, exportData, rows, t]);

  return (
    <WaitForFetch
      pending={energyTypeList.pending}
      error={energyTypeList.error}
      noData={!energyTypeList.data}
      onRetry={fetchData}
      render={render}
      absolutePosition
    />
  );
};