import './grid-kit.scss';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Grid} from 'components/ui/grid';
import {Paper} from 'components/ui/paper';

/** Grid control preview */
export const GridKit: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <div className="ui-grid-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.grid.heading')}
      </Typography>

      <Grid container>
        <Grid item>
          <Paper>
            1
          </Paper>
        </Grid>

        <Grid item md={6}>
          <Paper>
            1/2
          </Paper>
        </Grid>
        <Grid item md={6}>
          <Paper>
            1/2
          </Paper>
        </Grid>

        <Grid item md={4}>
          <Paper>
            1/3
          </Paper>
        </Grid>
        <Grid item md={4}>
          <Paper>
            1/3
          </Paper>
        </Grid>
        <Grid item md={4}>
          <Paper>
            1/3
          </Paper>
        </Grid>

        <Grid item md={3}>
          <Paper>
            1/4
          </Paper>
        </Grid>
        <Grid item md={3}>
          <Paper>
            1/4
          </Paper>
        </Grid>
        <Grid item md={3}>
          <Paper>
            1/4
          </Paper>
        </Grid>
        <Grid item md={3}>
          <Paper>
            1/4
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};
