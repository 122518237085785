import './granted-partners-list.scss';
import React, {FunctionComponent, useCallback} from 'react';
import memoize from 'fast-memoize';
import {useTranslation} from 'lib/intl/i18n';
import {IGrantedPartnersListProps} from './granted-partners-list.types';
import {VirtualizedList} from 'components/ui/virtualized-list';
import {IconButton} from 'components/ui/icon-button';
import {Tooltip} from 'components/ui/tooltip';
import RemoveIcon from '@material-ui/icons/Delete';

/** Access demand partners list */
export const GrantedPartnersList: FunctionComponent<IGrantedPartnersListProps> = (props) => {
  const {partners, onRemove} = props;
  const {t} = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRemove = useCallback(memoize((partnerId: number) => () => {
    onRemove(partnerId);
  }), [onRemove]);

  if (!partners.length)
    return null;

  return (
    <VirtualizedList
      className="ui-granted-partners-list__list"
      maxVisibleItems={12}
      rowHeight={42}
    >
      {partners.map((partner) => (
        <div
          key={partner.id}  
          className="ui-granted-partners-list__item"
          title={partner.name}
        >
          <Tooltip title={t('components.demand.access_demand_form.partners.remove')}>
            <IconButton
              size="small"
              onClick={handleRemove(partner.id)}
            >
              <RemoveIcon />
            </IconButton>
          </Tooltip>
          {partner.name}
        </div>
      ))}
    </VirtualizedList>
  );
};