import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IDemandDetailDialogProps} from './demand-detail-dialog.types';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {DemandDetailTable} from 'components/demand/demand-detail-table';

/**
 * Demand detail dialog
 * 
 * @example
 * 
 *   const [dialogOpen, setDialogOpen] = useState(false);
 *   
 *   const toggleDialog = useCallback(() => {
 *     setDialogOpen((open) => !open);
 *   }, []);
 *   
 *   // ...
 *   
 *   <DemandDetailDialog
 *     demand={someDemand}
 *     open={dialogOpen}
 *     onClose={toggleDialog}
 *   />
 */
export const DemandDetailDialog: FunctionComponent<IDemandDetailDialogProps> = (props) => {
  const {demand, open, onClose} = props;
  const {t} = useTranslation();

  return (
    <Dialog
      title={t('components.demand.demand_detail_dialog.title')}
      open={open}
      onClose={onClose}
      width="wide"
    >
      <DialogContent>
        <DemandDetailTable demand={demand} />
      </DialogContent>
    </Dialog>
  );
};