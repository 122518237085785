import './payments-table.scss';
import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {format} from 'lib/intl/format';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {useTranslation} from 'lib/intl/i18n';
import {IPaymentsTableProps} from './payments-table.types';
import {
  DataTable,
  asTableColumns,
  mapTableRows,
  asTableExportColumns,
  mapTableExportData,
  getExportSource
} from 'components/ui/data-table';
import {PaymentStatus} from './payment-status';

/**
 * Payments table
 * 
 * @example
 * 
 *   <PaymentsTable payments={somePayments} />
 */
export const PaymentsTable: FunctionComponent<IPaymentsTableProps> = (props) => {
  const {payments, exportDataSource} = props;
  const paymentStatusList = useSelector(selectors.paymentStatusList, shallowEqual);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (!paymentStatusList.data)
      dispatch(actions.paymentStatusListRequest());
  }, [dispatch, paymentStatusList.data]);
  
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);
  
  const columns = useMemo(() => asTableColumns({
    date: {
      label: t('components.payment.payments_table.column.date'),
      align: 'right',
      shrink: true,
      nowrapHeader: true,
      nowrapCell: true
    },
    variableSymbol: {
      label: t('components.payment.payments_table.column.variable_symbol')
    },    
    amount: {
      label: t('components.payment.payments_table.column.amount'),
      align: 'right',
      bold: true
    },
    status: {
      label: t('components.payment.payments_table.column.status'),
      shrink: true
    }
  }), [t]);

  const rows = useMemo(() => mapTableRows(columns, payments, (payment) => ({
    date: format.date(payment.receivedDate),
    variableSymbol: payment.variableSymbol,
    amount: format.currency(payment.amount.value, payment.amount.currencyId),
    status: (
      <PaymentStatus statusId={payment.statusId} />
    )
  })), [columns, payments]);

  const getStatusName = useCallback((statusId: string) => {
    const status = paymentStatusList.data?.find((status) => status.id === statusId);
    if (status)
      return status.name;
    return t('components.invoice.invoices_table.unknown_status');
  }, [paymentStatusList.data, t]);
  
  const exportData = useCallback(async () => {
    const exportSource = await getExportSource(exportDataSource || payments);
    const exportColumns = asTableExportColumns({
      date: t('components.payment.payments_table.column.date')!,
      variableSymbol: t('components.payment.payments_table.column.variable_symbol')!,
      amount: t('components.payment.payments_table.column.amount')!,
      status: t('components.payment.payments_table.column.status')!
    });
    return mapTableExportData(exportColumns, exportSource, (payment) => ({
      date: format.date(payment.receivedDate),
      variableSymbol: payment.variableSymbol,
      amount: format.number(payment.amount.value, {useGrouping: false}),
      status: getStatusName(payment.statusId)
    }));
  }, [exportDataSource, getStatusName, payments, t]);

  const render = useCallback(() => (
    <DataTable
      className="ui-payments-table"
      columns={columns}
      rows={rows}
      exportData={exportData}
      exportFilename={t('components.payment.payments_table.export_filename')}
      responsiveWidth={720}
    />
  ), [columns, exportData, rows, t]);

  return (
    <WaitForFetch
      pending={paymentStatusList.pending}
      error={paymentStatusList.error}
      noData={!paymentStatusList.data}
      onRetry={fetchData}
      render={render}
      absolutePosition
    />
  );
};