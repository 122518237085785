import {t} from 'lib/intl/i18n';

/**
 * Format file name
 *
 * @param name - File name
 * @param extension - File extension
 * @returns Formatted file name
 */
export const fileName = (name: (string | undefined | null), extension: (string | undefined | null)) => {
  name = name || t('lib.intl.format.file_name.no_name') as string;
  const suffix = extension ? `.${extension}` : '';
  if (!name.endsWith(suffix))
    return `${name}${suffix}`;
  return name;
};
