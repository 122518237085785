import React, {useContext, FunctionComponent, ChangeEvent, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {FilterContext} from '../consumption-filter-context';
import {useFetch, useOnFetchSuccess} from 'hooks';
import {api} from 'api';
import {FilterComboBox, FilterComboBoxOptions} from 'components/ui/filter-combo-box';
import EnergyIcon from '@material-ui/icons/Category';
import {ALL_CONTRACTS} from '../consumption-filter-contract';
import {ALL_PLACES} from '../consumption-filter-place';

export const ALL_ENERGY_TYPES = 'all-energy-types';

/** Consumption filter energy type select */
export const EnergyTypeSelect: FunctionComponent = () => {
  const {
    getValues,
    setAndStoreValues,
    getStoredValue,
    FilterField
  } = useContext(FilterContext);
  const {energyTypeId: energyType, contractId, placeId} = getValues();
  const consumptionSummary = useFetch(api.consumptionSummary);
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (contractId !== null && placeId !== null)
      consumptionSummary.fetch({
        contractId: contractId === ALL_CONTRACTS ? null : contractId,
        consumptionPlaceId: placeId === ALL_PLACES ? null : placeId
      });
  // eslint-disable-next-line
  }, [contractId, placeId]);

  // Fetch list of energy types on mount
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, [contractId, placeId]);

  const energyTypeList = useMemo(() => {
    const list: FilterComboBoxOptions = [];
    if (consumptionSummary.data) {
      
      // Get list of active energy types
      for (const summary of consumptionSummary.data) {
        if (summary.numberOfRecords)
          list.push({
            label: summary.energyTypeName,
            value: summary.energyTypeId
          });
      }

      // Add 'all energy types' option
      if (list && list.length > 1)
        list.unshift({
          label: t('views.consumption.filter.all_energy_types'),
          value: ALL_ENERGY_TYPES
        });
    }
    return list;
  }, [consumptionSummary.data, t]);

  const setEnergyType = useCallback((value: string) => {
    setAndStoreValues({energyTypeId: value});    
  }, [setAndStoreValues]);

  useOnFetchSuccess([consumptionSummary], () => {
    const {value} = getStoredValue('energyTypeId')
      .onlyIfIncludedInList(energyTypeList)
      .orGetFirstFromList();
    setEnergyType(value as string);
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
    setEnergyType(value as string);
  }, [setEnergyType]);

  const disabled = !energyTypeList || energyTypeList.length <= 1;

  return (
    <FilterField
      pending={consumptionSummary.pending}
      error={consumptionSummary.error}
    >
      <FilterComboBox
        label={t('views.consumption.filter.energy_types')}
        labelIcon={<EnergyIcon />}
        options={energyTypeList}
        value={energyType}
        disabled={disabled}
        onChange={handleChange}
      />
    </FilterField>
  );
};