import {
  IPasswordResetRequestAction,
  IPasswordResetSuccessAction,
  IPasswordResetFailureAction,
  PasswordResetActionTypes
} from './password-reset.types';
import {IPasswordResetRequest} from 'api';

/** Reset user password */
export const passwordResetRequest = (payload: IPasswordResetRequest): IPasswordResetRequestAction => ({
  type: PasswordResetActionTypes.request,
  payload
});

export const passwordResetSuccess = (): IPasswordResetSuccessAction => ({
  type: PasswordResetActionTypes.success
});

export const passwordResetFailure = (payload: IRequestError): IPasswordResetFailureAction => ({
  type: PasswordResetActionTypes.failure,
  payload
});
