import React, {ChangeEvent, forwardRef, ReactElement, Ref, useCallback, useMemo, useState} from 'react';
import {useWatchEffect} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {LOCALE_TIME_FORMAT} from 'lib/intl/format';
import {ITimePickerProps} from './time-picker.types';
import {PopperProps} from '@material-ui/core/Popper';
import {MuiTextFieldProps} from '@material-ui/pickers/_shared/PureDateInput';
import {MobileTimePicker, DesktopTimePicker, TimePickerProps} from '@material-ui/pickers';
import {PickerField, PickerFieldProps} from 'components/ui/picker-field';
import {validate} from 'lib/global/validate';
import {parse} from 'lib/intl/parse';
import {format} from 'lib/intl/format';
import {Moment} from 'moment';

/**
 * Time picker
 *
 * @see src/views/app/kit-view/date-time-kit
 * @see https://v4-0-0-alpha-12.material-ui-pickers.dev/api/TimePicker
 *
 * @example
 *
 *   const [time, setTime] = useState('');
 *
 *   const handleChange = useCallback((value: string) => {
 *     setTime(value);
 *   }, []);
 *
 *   // ...
 *
 *   <TimePicker
 *     label="Select time"
 *     value={time}
 *     onChange={handleChange}
 *   />
 */
export const TimePicker = forwardRef(function TimePicker<T extends PickerFieldProps = PickerFieldProps>(props: ITimePickerProps<T>, ref: Ref<HTMLInputElement>) {  
  const {
    name,
    placeholder,
    minTime,
    maxTime,
    value: valueProp,
    onChange,
    FieldComponent: FieldComponentProp,
    fieldProps,
    ...restProps
  } = props;
  const [open, setOpen] = useState(false);
  const [accept, setAccept] = useState(false);
  const [value, setValue] = useState(valueProp || '');
  const {t} = useTranslation();
  
  // Store the previous value on picker open
  const handleOpen = useCallback(() => {
    setValue(valueProp || '');
    setAccept(false);
    setOpen(true);
  }, [valueProp]);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleAccept = useCallback(() => {
    setAccept(true);
  }, []);

  // Set new accepted value
  useWatchEffect(() => {
    if (!open && accept)
      onChange(value);
  }, [open]);

  // Convert the string value to a date object for use in the picker
  const pickerValue = useMemo<Date | null>(() => {
    if (validate.isTime(value))
      return parse.time(value).toDate();
    return null;
  }, [value]);

  // Convert the date object to a string after selecting the time in the picker
  const handlePickerChange = useCallback((time: unknown) => {
    if (!time)
      setValue('');
    else if (validate.isTime(time as Moment))
      setValue(format.time(time as Moment));
  }, []);

  const handleInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  const FieldComponent = useMemo(() => {
    return FieldComponentProp || PickerField;
  }, [FieldComponentProp]);

  const renderInput = useCallback((renderProps: MuiTextFieldProps) => {
    const fieldComponentProps = {
      ...renderProps,
      ...(fieldProps || {}),
      inputProps: {
        ...(renderProps.inputProps || {}),
        ...(fieldProps?.InputProps || {}),
        value: valueProp
      } as T['inputProps'],
      onChange: handleInputChange,
      placeholder,
      name
    } as T;
    return (<FieldComponent {...fieldComponentProps} />);
  }, [FieldComponent, fieldProps, handleInputChange, name, placeholder, valueProp]);

  // Bureaucracy...
  const renderDummyInput = useCallback(() => (<></>), []);

  // Set the default popper position
  const popperProps = useMemo(() => ({
    placement: 'bottom-end',
    ...(props.PopperProps || {})
  } as PopperProps), [props.PopperProps]);

  const pickerProps = useMemo<Omit<TimePickerProps, 'renderInput'>>(() => ({
    ...restProps,
    minTime: minTime && parse.time(minTime),
    maxTime: maxTime && parse.time(maxTime),
    value: pickerValue,
    onChange: handlePickerChange,
    disableMaskedInput: true,
    PopperProps: popperProps,
    inputFormat: LOCALE_TIME_FORMAT,
    clearable: true,
    allowSameDateSelection: true,
    toolbarTitle: t('components.ui.time_picker.title'),
    okText: t('components.ui.time_picker.ok'),
    cancelText: t('components.ui.time_picker.cancel'),
    clearText: t('components.ui.time_picker.clear'),
    todayText: t('components.ui.time_picker.today')
  }), [handlePickerChange, maxTime, minTime, pickerValue, popperProps, restProps, t]);

  // Combine desktop input with mobile picker
  return (
    <>

      {/* Input */}

      <DesktopTimePicker
        ref={ref}
        {...pickerProps}
        renderInput={renderInput}
        open={false}
        onOpen={handleOpen}
      />

      {/* Picker */}

      <MobileTimePicker
        {...pickerProps}
        renderInput={renderDummyInput}
        open={open}
        onClose={handleClose}
        onAccept={handleAccept}
      />
    </>
  );
}) as <T extends PickerFieldProps = PickerFieldProps>(props: ITimePickerProps<T> & {ref?: Ref<HTMLInputElement>;}) => ReactElement;
// ^ Make JSX generic parameters work with forwardRef
//
// @see https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref