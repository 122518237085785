import './home-demands.scss';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {paths} from 'config/router';
import {useFetch} from 'hooks';
import {api} from 'api';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {useTranslation} from 'lib/intl/i18n';
import {RevealFade} from 'components/ui/reveal-fade';
import {Box} from 'components/ui/box';
import {Flexbox} from 'components/ui/flexbox';
import {Typography} from 'components/ui/typography';
import DemandsIcon from '@material-ui/icons/AssignmentOutlined';
import {DemandsTable} from 'components/demand/demands-table';
import {Link} from 'components/ui/link';

const DEMANDS_COUNT = 4;

/** Latest demands */
export const HomeDemands: FunctionComponent = () => {
  const demands = useFetch(api.demandList);
  const {t} = useTranslation();

  useEffect(() => {
    demands.fetch({size: DEMANDS_COUNT});
  // eslint-disable-next-line
  }, []);

  const render = useCallback(() => (
    <>
      <DemandsTable demands={demands.data!.content} />
      <Flexbox
        mt={3}
        justifyContent="flex-end"
      >
        <Link to={paths.demands}>
          {t('views.app.home.demands.all_demands')}
        </Link>
      </Flexbox>
    </>
  ), [demands.data, t]);

  return (
    <RevealFade>
      <Typography
        variant="h2"
        color="primary"
        gutterBottom
      >
        <DemandsIcon />
        {t('views.app.home.demands.heading')}
      </Typography>
      <Box mb={4}>
        <Typography variant="body1">
          {t('views.app.home.demands.description')}
        </Typography>
      </Box>

      {/* List of demands */}

      <div className="ui-home-demands__content">
        <WaitForFetch
          pending={demands.pending}
          error={demands.error}
          noData={!demands.data?.content?.length}
          noDataMessage={t('views.app.home.demands.no_data')}
          onRetry={demands.fetch}
          render={render}
          absolutePosition
        />
      </div>
    </RevealFade>
  );
};