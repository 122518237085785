import './app-header.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {AppLogo} from '../app-logo';
import {UserInfo} from './user-info';
import {HeaderLoginForm} from './header-login-form';
import {HeaderLoginDialog} from './header-login-dialog';
import {ThemeSwitch} from 'components/ui/theme-switch';
import {HeaderLoginError} from './header-login-error';
import {AppMenuProvider, DesktopMenu, MobileMenu} from '../app-menu';

/** Application header */
export const AppHeader: FunctionComponent = () => {
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);

  const classes = useMemo(() => {
    let classes = 'ui-app-header';
    if (!userIsLoggedIn)
      classes += ' ui-app-header--unlogged';
    return classes;
  }, [userIsLoggedIn]);   

  return (
    <AppMenuProvider>
      <div className={classes}>
        <div className="ui-app-header__content">
          
          {/* Logo */}
          
          <AppLogo
            className="ui-app-header__logo"
            to="/"
          />

          {/* Right panel */}
          
          <div className="ui-app-header__right-panel">
    
            {/* Login */}
    
            {userIsLoggedIn && <UserInfo />}
            {!userIsLoggedIn && (
              <>
                <HeaderLoginForm />
                <HeaderLoginDialog />
              </>
            )}

            {/* Theme switcher */}

            <ThemeSwitch />

            {/* Mobile menu */}

            <MobileMenu />
          </div>
        </div>

        {/* Login error message */}

        <HeaderLoginError />
      </div>

      {/* Desktop menu */}

      <DesktopMenu />
    </AppMenuProvider>
  );
};