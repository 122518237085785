import {dateTime as validateDateTime} from 'lib/global/validate/date-time';
import {dateTime as parseDateTime} from 'lib/intl/parse/date-time';
import {logErrorOnDev} from 'lib/global/dev';

export const LOCALE_DATE_TIME_FORMAT = 'l LT';

/**
 * Format date-time
 *
 * @param value - Date to format
 * @param [format] - Custom format
 * @returns Formatted date
 */
export const dateTime = (value: AnyDate, format = LOCALE_DATE_TIME_FORMAT) => {
  if (!value)
    return '';
  const validationError = validateDateTime(value);
  if (validationError) {
    logErrorOnDev(validationError);
    return value !== undefined && value !== null ? value.toString() : value;
  }
  return parseDateTime(value).format(format);
};
