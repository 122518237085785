import React, {FunctionComponent} from 'react';
import {IDemandDialogProps} from './demand-dialog.types';
import {useTranslation} from 'lib/intl/i18n';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {DemandForm} from './demand-form';

/**
 * Demand form dialog
 * 
 * @example
 * 
 *   const [dialogOpen, setDialogOpen] = useState(false);
 *   
 *   const toggleDialog = useCallback(() => {
 *     setDialogOpen((open) => !open);
 *   }, []);
 *   
 *   // ...
 *   
 *   <DemandDialog
 *     open={dialogOpen}
 *     onClose={toggleDialog}
 *   />
 */  
export const DemandDialog: FunctionComponent<IDemandDialogProps> = (props) => {
  const {open, onClose, initialValues} = props;
  const {t} = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('components.demand.demand_dialog.title')}
      width="wide"
    >
      <DialogContent>
        <DemandForm
          initialValues={initialValues}
          onSuccess={onClose}
        />
      </DialogContent>
    </Dialog>
  );
};