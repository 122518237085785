import {parsePhoneNumberFromString, CountryCode, PhoneNumber} from 'libphonenumber-js';
import {getCurrentCountry} from 'lib/intl/app-locale';
import {t} from 'lib/intl/i18n';
import {Exception} from 'lib/global/exceptions';

/**
 * Parse phone number
 *
 * @param value - Phone string
 * @param defaultCountry - Default country to use for a calling code prefix
 * @returns PhoneNumber instance
 * @throws {Exception}
 *
 * @see https://github.com/catamphetamine/libphonenumber-js
 */
export const phone = (value: string, defaultCountry?: CountryCode): PhoneNumber => {
  const countryCode = defaultCountry || getCurrentCountry() as CountryCode;
  const parsedPhone = parsePhoneNumberFromString(value, countryCode);
  if (!parsedPhone)
    throw new Exception(t('lib.intl.parse.phone', {value}));
  return parsedPhone;
};
