import {IUploadFileState} from '../upload-reducer';

export enum UploadActionTypes {
  addFiles = 'UPLOAD_ADD_FILES',
  removeFile = 'UPLOAD_REMOVE_FILE',
  setFileState = 'UPLOAD_SET_FILE_STATE'
}

//--- Add files

export interface IAddFilesActionPayload {
  files: File[];
}

export interface IAddFilesAction {
  type: UploadActionTypes.addFiles;
  payload: IAddFilesActionPayload;
}

//--- Remove file

export interface IRemoveFileActionPayload {
  file: File;
}

export interface IRemoveFileAction {
  type: UploadActionTypes.removeFile;
  payload: IRemoveFileActionPayload;
}

//--- Set file state

export interface ISetFileStateActionPayload {
  file: File;
  state: Partial<IUploadFileState>;
}

export interface ISetFileStateAction {
  type: UploadActionTypes.setFileState;
  payload: ISetFileStateActionPayload;
}

export type UploadAction = (
  IAddFilesAction |
  IRemoveFileAction |
  ISetFileStateAction
);