import './slider.scss';
import React, {forwardRef, useCallback, useMemo} from 'react';
import {ISliderProps} from './slider.types';
import {FormLabel} from 'components/ui/form-label';
import MuiSlider from '@material-ui/core/Slider';

/**
 * Value slider
 * 
 * @see src/views/app/kit-view/slider-kit
 *
 * @example
 *
 *   import {Slider} from 'components/ui/slider';
 * 
 *   // ...
 *   
 *   const [value, setValue] = useState(50);
 *   
 *   const handleChange = useCallback((value: number) => {
 *     setValue(value);
 *   }, []);
 * 
 *   // ...
 *   
 *   <Slider
 *     value={value}
 *     onChange={handleChange}
 *   />
 */
export const Slider = forwardRef<HTMLSpanElement, ISliderProps>(function Slider(props, ref) {
  const {
    className,
    label,
    onChange,
    children,
    ...restProps
  } = props;

  const handleChange = useCallback((event: Event, value: (number | number[])) => {
    onChange(value as number);
  }, [onChange]);

  const classes = useMemo(() => {
    let classes = 'ui-slider';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <>
      <FormLabel>
        {label}
      </FormLabel>
      <MuiSlider
        ref={ref}
        {...restProps}
        className={classes}
        onChange={handleChange}
      >
        {children}
      </MuiSlider>
    </>
  );
});

Slider.defaultProps = {
  valueLabelDisplay: 'auto'
};