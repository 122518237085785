import moment, {Moment} from 'moment';
import {VALID_DATE_TIME_FORMATS} from 'lib/intl/parse/date-time';

export const VALID_TIME_FORMATS: string[] = [
  'H:mm',
  'h:mm A',
  'H:mm:ss',
  'h:mm:ss A'
];

/**
 * Parse time
 *
 * @param value - Input time
 * @returns Moment.js instance
 *
 * @see https://momentjs.com
 */
export const time = (value: AnyDate): Moment => {
  if (typeof value === 'string') {
    const validFormats = [...VALID_TIME_FORMATS, ...VALID_DATE_TIME_FORMATS];
    return moment(value, validFormats, true);
  }
  return moment(value);
};
