import './card-kit.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Box} from 'components/ui/box';
import {Card} from 'components/ui/card';
import {CardHeader} from 'components/ui/card-header';
import {CardMedia} from 'components/ui/card-media';
import {CardContent} from 'components/ui/card-content';
import {CardActionArea} from 'components/ui/card-action-area';
import {CardActions} from 'components/ui/card-actions';
import CardIcon from '@material-ui/icons/FontDownload';
import cardImagePath from './assets/image.png';
import {Link} from 'components/ui/link';
import {LoremIpsum} from 'lorem-ipsum';

/** Card control preview */
export const CardKit: FunctionComponent = () => {
  const {t} = useTranslation();

  const loremIpsum = useMemo(() => {
    return new LoremIpsum();
  }, []);
  
  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.card.heading')}
      </Typography>

      {/* Text card */}
      
      <Typography
        variant="h3"
        gutterBottom
      >
        {t('views.app.ui_kit.card.type.text')}
      </Typography>
      <Box mb={3}>
        <Card className="ui-card-kit__card">
          <CardHeader
            icon={<CardIcon />}
            title={t('views.app.ui_kit.card.title')}
          />
          <CardContent>
            <Typography
              variant="body1"
              gutterBottom
            >
              {loremIpsum.generateWords(30)}
            </Typography>
            <Typography variant="body1">
              {loremIpsum.generateWords(30)}
            </Typography>
          </CardContent>
          <CardActions>
            <Link to="#">
              {t('views.app.ui_kit.card.action')}
            </Link>
          </CardActions>
        </Card>
      </Box>

      {/* Image card */}

      <Typography
        variant="h3"
        gutterBottom
      >
        {t('views.app.ui_kit.card.type.image')}
      </Typography>
      <Box>
        <Card className="ui-card-kit__card">
          <CardActionArea>            
            <CardMedia image={cardImagePath} />
            <CardContent>
              <Typography
                variant="h3"
                gutterBottom
              >
                {t('views.app.ui_kit.card.title')}
              </Typography>
              <Typography variant="body1">
                {loremIpsum.generateWords(35)}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    </>
  );
};
