import {createStateSyncMiddleware} from 'redux-state-sync';
import {AnyAction} from 'redux';
import {SYNCED_ACTION_TYPES} from 'config/store';

const isSyncedAction = (action: AnyAction) => {
  return SYNCED_ACTION_TYPES.includes(action.type);
};

export const stateSyncMiddleware = createStateSyncMiddleware({
  predicate: isSyncedAction
});
