import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {EnergyTypeListResponse} from './api-energy-type-list.types';

/** Get a list of energy types */
export const energyTypeList = async () => {
  const response = await axios.get<EnergyTypeListResponse>(urls.energyTypeList(), {
    headers: {
      ...headers.auth(),
      ...headers.language()
    }
  });
  return response.data;
};