import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {AccessDemandOperationsResponse} from './access-demand-operations.types';

/** Get a list of access demand operations */
export const accessDemandOperations = async () => {
  const response = await axios.get<AccessDemandOperationsResponse>(urls.accessDemandOperations(), {
    headers: {
      ...headers.language(),
      ...headers.auth()
    }
  });
  return response.data;
};