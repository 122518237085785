import React, {FunctionComponent} from 'react';
import {View} from 'components/ui/view';
import {Box} from 'components/ui/box';
import {HomeInvoices} from '../sections/home-invoices';
import {HomeBanners} from '../sections/home-banners';
import {HomeDemands} from '../sections/home-demands';
import {HomeNews} from '../sections/home-news';

/** Home page view (logged-in user) */
export const HomeLoggedView: FunctionComponent = () => {
  return (
    <View>
      <HomeInvoices />
      <Box mt={4}>
        <HomeBanners />
      </Box>
      <Box mt={4}>
        <HomeDemands />
      </Box>
      <Box mt={4}>
        <HomeNews />
      </Box>
    </View>
  );
};