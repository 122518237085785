import React, {FunctionComponent} from 'react';
import {RevealFade} from 'components/ui/reveal-fade';
import {Grid} from 'components/ui/grid';
import {FailuresBanner} from './failures-banner';
import {OutagesBanner} from './outages-banner';

/** Home page banners */
export const HomeBanners: FunctionComponent = () => {
  return (
    <RevealFade>
      <Grid container>
        <Grid item xl={6}>
          <FailuresBanner />
        </Grid>
        <Grid item xl={6}>
          <OutagesBanner />
        </Grid>
      </Grid>
    </RevealFade>
  );
};