import './banner.scss';
import React, {forwardRef, useMemo} from 'react';
import {IBannerProps} from './banner.types';
import * as Css from 'csstype';
import {useTranslation} from 'lib/intl/i18n';
import {RevealFlip} from 'components/ui/reveal-flip';
import {Link, ILinkProps} from 'components/ui/link';
import {useTheme} from 'components/ui/theme';
import {ContainerQuery, IQueryClasses} from 'components/ui/container-query';
import {Typography} from 'components/ui/typography';

/**
 * Image banner (ideal image dimensions are 1440x320 or 720x160 pixels)
 * 
 * @see src/views/app/kit-view/banner-kit
 *
 * @example
 *
 *   <Banner
 *     image={myImageUrl}
 *     text="Lorem ipsum dolor sit amet"
 *     more="More..."
 *     url="http://example.com"
 *   />
 */
export const Banner = forwardRef<HTMLAnchorElement, IBannerProps>(function Banner(props, ref) {
  const {
    className,
    image,
    text,
    more: moreProp,
    animate,
    delay,
    url,
    ...restProps
  } = props;
  const theme = useTheme();
  const {t} = useTranslation();

  const more = useMemo<string | undefined>(() => {
    return moreProp || t('components.ui.banner.more_link');
  }, [moreProp, t]);

  const externalLink = useMemo<boolean>(() => {
    return url.includes('//') || url.includes('.');
  }, [url]);

  const linkTarget = useMemo<ILinkProps['target']>(() => {
    if (externalLink)
      return '_blank';
    return undefined;
  }, [externalLink]);

  const imageStyle = useMemo<Css.Properties>(() => ({
    backgroundImage: `url(${image})`
  }), [image]);

  const queryClasses = useMemo<IQueryClasses>(() => ({
    'ui-sm-min': {minWidth: theme.width.smMin},
    'ui-xs-max': {maxWidth: theme.width.xsMax}
  }), [theme.width]);

  const classes = useMemo(() => {
    let classes = 'ui-banner';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <RevealFlip
      top
      className={classes}
      timeout={animate === false ? 0 : undefined}
      delay={delay}
    >
      <ContainerQuery queryClasses={queryClasses}>
        <div className="ui-banner__wrapper">
          <Link
            {...restProps}
            ref={ref}
            className="ui-banner__container"
            hoverAnimation={false}
            to={url}
            target={linkTarget}
          >
            <div
              className="ui-banner__image"
              style={imageStyle}
            />
            <div className="ui-banner__content">
              <div className="ui-banner__content-corner" />
              <Typography
                className="ui-banner__heading"
                variant="h2"
              >
                {text}
              </Typography>
              <span className="ui-banner__more">
                {more}
              </span>
            </div>
          </Link>
        </div>
      </ContainerQuery>
    </RevealFlip>
  );
});
