import {
  IDemandStatusListRequestAction,
  IDemandStatusListSuccessAction,
  IDemandStatusListFailureAction,
  DemandStatusListActionTypes
} from './demand-status-list.types';
import {DemandStatusListResponse} from 'api';

/** Get list of demand statuses */
export const demandStatusListRequest = (): IDemandStatusListRequestAction => ({
  type: DemandStatusListActionTypes.request
});

export const demandStatusListSuccess = (payload: DemandStatusListResponse): IDemandStatusListSuccessAction => ({
  type: DemandStatusListActionTypes.success,
  payload
});

export const demandStatusListFailure = (payload: IRequestError): IDemandStatusListFailureAction => ({
  type: DemandStatusListActionTypes.failure,
  payload
});