import React, {forwardRef} from 'react';
import {IFormIntegerFieldProps} from './form-integer-field.types';
import {useFormField} from 'hooks';
import {IntegerField} from 'components/ui/integer-field';
import {FormFieldError} from 'components/ui/form-field-error';

/**
 * Formik wrapper for integer field
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormIntegerField} from 'components/ui/form-integer-field';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormIntegerField
 *       name="someName"
 *       label="Some label"
 *     />
 *   </Form>
 */
export const FormIntegerField = forwardRef<HTMLDivElement, IFormIntegerFieldProps>(function FormIntegerField(props, ref) {
  const {field, meta} = useFormField(props);
  const {validate, errorMessage, hidden, ...restProps} = props;

  return (
    <>
      <IntegerField
        ref={ref}
        {...restProps}
        {...field}
        hidden={hidden}
      />
      {!hidden && (
        <FormFieldError>
          {errorMessage || meta.touched && meta.error}
        </FormFieldError>
      )}
    </>
  );
});