export enum CookiesActionTypes {
  set = 'COOKIES_SET',
  toggleDialog = 'COOKIES_TOGGLE_DIALOG',
}

export type CookiesApprovalState = false | 'necessary' | 'all' | 'preferential' | 'analytical' | 'refused';

export interface ISetCookiesPayload {
  approved: CookiesApprovalState;
}

export interface IInitialCookiesState {
  approved: CookiesApprovalState;
  isOpenDialog: boolean;
}

export interface ISetCookiesAction {
  type: CookiesActionTypes.set;
  payload: ISetCookiesPayload;
}

export interface ICookiesToogleDialogAction {
  type: CookiesActionTypes.toggleDialog;
  payload: boolean;
}


export type TCookiesActions = ISetCookiesAction | ICookiesToogleDialogAction