import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {NewsListRequest, NewsPagedListResponse} from './news-list.types';

/** Get a list of news */
export const newsList = async (request?: NewsListRequest) => {
  const response = await axios.get<NewsPagedListResponse>(urls.newsList(), {
    params: request,
    headers: headers.language()
  });
  return response.data;
};