/** @file Extract theme variables from /config/theme/variables.scss */

// eslint-disable-next-line import/no-webpack-loader-syntax
import rawVariables from 'sass-extract-loader!config/theme/variables.scss';
import {SassValue, SassString} from 'types/extract-sass';
import extractSassSchema from 'schemas/extract-sass.schema.json';
import variablesSchema from './variables.schema.json';
import {validateJsonSchema} from 'lib/global/json-schema';
import {IThemeVariables} from '../theme.types';

const sassVariables: Record<string, SassValue> = rawVariables.global;
validateJsonSchema(sassVariables, [extractSassSchema, variablesSchema], 'themeVariables', 'Invalid theme variables.');

const getFontFamily = (sassFontFamily: SassValue): string => {
  if (sassFontFamily.type === 'SassList')
    return (sassFontFamily.value as SassString[])
      .map((family) => `"${family.value}"`)
      .join(', ');
  return (sassFontFamily as SassString).value;
};

/** Variables extracted from /config/theme/variables.scss */
export const variables: IThemeVariables = {
  typography: {
    fontFamily: getFontFamily(sassVariables['$body-font-family']),
    fontSize: sassVariables['$body-font-size'].value as number,
    lineHeight: sassVariables['$body-line-height'].value as number,
    smallFontSize: sassVariables['$small-font-size'].value as number,
    h1: {
      fontSize: sassVariables['$h1-font-size'].value as number,
      fontWeight: sassVariables['$h1-font-weight'].value as number,
      lineHeight: sassVariables['$h1-line-height'].value as number
    },
    h2: {
      fontSize: sassVariables['$h2-font-size'].value as number,
      fontWeight: sassVariables['$h2-font-weight'].value as number,
      lineHeight: sassVariables['$h2-line-height'].value as number
    },
    h3: {
      fontSize: sassVariables['$h3-font-size'].value as number,
      fontWeight: sassVariables['$h3-font-weight'].value as number,
      lineHeight: sassVariables['$h3-line-height'].value as number
    }
  },
  width: {
    xsMin: sassVariables['$xs-min'].value as number,
    smMin: sassVariables['$sm-min'].value as number,
    mdMin: sassVariables['$md-min'].value as number,
    lgMin: sassVariables['$lg-min'].value as number,
    xlMin: sassVariables['$xl-min'].value as number,
    xxlMin: sassVariables['$xxl-min'].value as number,
    xsMax: sassVariables['$xs-max'].value as number,
    smMax: sassVariables['$sm-max'].value as number,
    mdMax: sassVariables['$md-max'].value as number,
    lgMax: sassVariables['$lg-max'].value as number,
    xlMax: sassVariables['$xl-max'].value as number
  },
  speed: {
    fast: sassVariables['$speed-fast'].value as number,
    slow: sassVariables['$speed-slow'].value as number
  },
  spacing: {
    0: sassVariables['$spacing-0'].value as number,
    1: sassVariables['$spacing-1'].value as number,
    2: sassVariables['$spacing-2'].value as number,
    3: sassVariables['$spacing-3'].value as number,
    4: sassVariables['$spacing-4'].value as number,
    5: sassVariables['$spacing-5'].value as number,
    6: sassVariables['$spacing-6'].value as number,
    7: sassVariables['$spacing-7'].value as number,
    8: sassVariables['$spacing-8'].value as number,
    9: sassVariables['$spacing-9'].value as number,
    10: sassVariables['$spacing-10'].value as number
  }
};
