import {
  UploadActionTypes,
  IAddFilesAction,
  IAddFilesActionPayload,
  IRemoveFileAction,
  IRemoveFileActionPayload,
  ISetFileStateAction,
  ISetFileStateActionPayload
} from './upload-actions.types';

export const addFiles = (payload: IAddFilesActionPayload): IAddFilesAction => ({
  type: UploadActionTypes.addFiles,
  payload
});

export const removeFile = (payload: IRemoveFileActionPayload): IRemoveFileAction => ({
  type: UploadActionTypes.removeFile,
  payload
});

export const setFileState = (payload: ISetFileStateActionPayload): ISetFileStateAction => ({
  type: UploadActionTypes.setFileState,
  payload
});