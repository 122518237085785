import React, {FunctionComponent} from 'react';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {HomeUnloggedView} from './home-unlogged-view';
import {HomeLoggedView} from './home-logged-view';

/** Home page view */
export const HomeView: FunctionComponent = () => {
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);
  
  if (!userIsLoggedIn)
    return (<HomeUnloggedView />);

  return (<HomeLoggedView />);
};
