import React, {forwardRef, useCallback, useMemo, useState} from 'react';
import {useDebouncedState, useWatchEffect} from 'hooks';
import {IFilterDateRangePickerProps} from './filter-date-range-picker.types';
import {DateRangePicker} from 'components/ui/date-range-picker';
import {FilterPickerField} from 'components/ui/filter-picker-field';
import {validate as validators, composeValidators} from 'lib/global/validate';
import {useFilterField} from 'components/ui/filter';

/**
 * Filter wrapper for date range picker field
 * 
 * @example
 *
 *   import {useContext} from 'react';
 *   import {FilterContext} from './my-filter-context';
 * 
 *   // ...
 * 
 *   const {getValues, setValues} = useContext(FilterContext);
 *   const {from, to} = getValues();
 *   
 *   const handleChange = useCallback((from: string, to: string) => {
 *     setValues({from, to});
 *   }, [setValues]);
 *
 *   // ...
 *
 *   <FilterDateRangePicker
 *     label="Select a date range"
 *     fromValue={from}
 *     toValue={to}
 *     onChange={handleChange}
 *   />
 */
export const FilterDateRangePicker = forwardRef<HTMLDivElement, IFilterDateRangePickerProps>(function FilterDateRangePicker(props, ref) {
  const {
    fromName,
    fromValue,
    toName,
    toValue,
    label,
    labelIcon,
    validate,
    onChange,
    ...restProps
  } = props;
  const [innerState, setInnerState] = useState({from: fromValue, to: toValue});
  const outerState = useDebouncedState({from: fromValue, to: toValue});

  useWatchEffect(() => {
    setInnerState({from: fromValue, to: toValue});
  }, [fromValue, toValue]);

  const fieldValidators = useMemo(() => {
    return composeValidators(
      validate,
      validators.date,
      !!props.minDate && validators.minDate(props.minDate),
      !!props.maxDate && validators.maxDate(props.maxDate)
    );
  }, [props.maxDate, props.minDate, validate]);

  const fromFieldProps = {
    ...useFilterField({
      ...restProps,
      name: fromName,
      value: fromValue,
      validate: fieldValidators
    }),
    errorMessage: fieldValidators(fromValue)
  };

  const toFieldProps = {
    ...useFilterField({
      ...restProps,
      name: toName,
      value: toValue,
      validate: fieldValidators
    }),
    errorMessage: fieldValidators(toValue)
  };

  const handleChange = useCallback((from: string, to: string) => {
    setInnerState({from, to});
    outerState.setValue({from, to});
  }, [outerState]);

  useWatchEffect(() => {
    const {from, to} = outerState.value;
    const invalidFrom = !!fieldValidators(from);
    const invalidTo = !!fieldValidators(to);
    if (!invalidFrom && !invalidTo)
      onChange({from: from!, to: to!});
  }, [fieldValidators, onChange, outerState.value]);

  return (
    <DateRangePicker
      ref={ref}
      {...restProps}
      label={label}
      labelIcon={labelIcon}
      fromName={props.fromName}
      toName={props.toName}
      fromValue={innerState.from}
      toValue={innerState.to}
      onChange={handleChange}
      FieldComponent={FilterPickerField}
      fromFieldProps={fromFieldProps}
      toFieldProps={toFieldProps}
    />
  );
});