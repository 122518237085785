
/** Get a list of requested demands */
export const demandList = () => {
  return 'demand/list';
};

/** Get a list of demand types */
export const demandTypeList = () => {
  return 'index/demand-type/list';
};

/** Get a list of demand priorities */
export const demandPriorityList = () => {
  return 'index/demand-priority/list';
};

/** Get a list of demand statuses */
export const demandStatusList = () => {
  return 'index/demand-status/list';
};

/** Demand creation */
export const demandCreation = () => {
  return 'demand/creation';
};

/** Get a list of access demand operations */
export const accessDemandOperations = () => {
  return 'index/demand/access-grant/operation/list';
};

/** Get a list of access demand user roles */
export const accessDemandRoles = () => {
  return 'index/demand/access-grant/role/list';
};

/** Access demand creation */
export const accessDemandCreation = () => {
  return 'demand/access-grant-creation';
};
