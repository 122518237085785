import React, {forwardRef, useMemo} from 'react';
import {ITableProps} from './table.types';
import MuiTable from '@material-ui/core/Table';

/**
 * Table
 * 
 * @see src/views/app/kit-view/table-kit
 * 
 * @example
 *  
 *   import {Table, TableContainer, TableHead, TableBody, TableRow, TableCell} from 'components/ui/table';
 *   
 *   // ...
 *   
 *   <TableContainer>
 *     <Table>
 * 
 *       <TableHead>
 *         <TableRow>
 *           <TableCell>
 *             First name
 *           </TableCell>
 *           <TableCell>
 *             Last name
 *           </TableCell>
 *         </TableRow>
 *       </TableHead>
 *       
 *       <TableBody>
 *         <TableRow>
 *           <TableCell>
 *             Alan
 *           </TableCell>
 *           <TableCell>
 *             Turing
 *           </TableCell>
 *         </TableRow>
 *         ...
 *       </TableBody>
 * 
 *     </Table>
 *   </TableContainer>
 */
export const Table = forwardRef<HTMLTableElement, ITableProps>(function Table(props, ref) {
  const {className, hoverEffect, ...restProps} = props;
  
  const classes = useMemo(() => {
    let classes = 'ui-table';
    if (hoverEffect)
      classes += ' ui-table--hover';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, hoverEffect]);   
  
  return (
    <MuiTable
      ref={ref}  
      {...restProps}
      className={classes}
    />
  );
});

Table.defaultProps = {
  hoverEffect: true
};