import './demand-detail-table.scss';
import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {format} from 'lib/intl/format';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {IDemandDetailTableProps} from './demand-detail-table.types';
import {useTranslation} from 'lib/intl/i18n';
import {DataTable, asTableColumns, asTableRows} from 'components/ui/data-table';
import {DemandType} from '../demands-table/demand-type';
import {DemandStatus} from '../demands-table/demand-status';
import {DownloadButton} from 'components/ui/download-button';

/** Demand detail table */
export const DemandDetailTable: FunctionComponent<IDemandDetailTableProps> = (props) => {
  const {demand} = props;
  const demandTypeList = useSelector(selectors.demandTypeList, shallowEqual);
  const demandStatusList = useSelector(selectors.demandStatusList, shallowEqual);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (!demandTypeList.data)
      dispatch(actions.demandTypeListRequest());
    if (!demandStatusList.data)
      dispatch(actions.demandStatusListRequest());
  }, [demandStatusList.data, demandTypeList.data, dispatch]);
  
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const columns = useMemo(() => asTableColumns({
    id: {
      label: t('components.demand.demand_detail_table.column.id')
    },
    created: {
      label: t('components.demand.demand_detail_table.column.created')
    },
    subject: {
      label: t('components.demand.demand_detail_table.column.subject')
    },
    type: {
      label: t('components.demand.demand_detail_table.column.type')
    },
    status: {
      label: t('components.demand.demand_detail_table.column.status')
    },
    attachments: {
      label: t('components.demand.demand_detail_table.column.attachments')
    },
    description: {
      label: t('components.demand.demand_detail_table.column.description')
    }
  }), [t]);

  const rows = asTableRows(columns, [{
    id: demand.id,
    created: format.date(demand.creationDate),
    subject: demand.subject,
    type: (
      <DemandType typeId={demand.typeId} />
    ),
    status: (
      <DemandStatus statusId={demand.statusId} />
    ),
    attachments: (
      <DownloadButton documents={demand.attachments} />
    ),
    description: demand.description
  }]);
  
  const render = useCallback(() => (
    <DataTable
      className="ui-demand-detail-table"
      columns={columns}
      rows={rows}
      renderVertically
    />
  ), [columns, rows]);
  
  return (
    <WaitForFetch
      pending={demandTypeList.pending || demandStatusList.pending}
      error={demandTypeList.error || demandStatusList.error}
      noData={!demandTypeList.data || !demandStatusList.data}
      onRetry={fetchData}
      render={render}
      absolutePosition
    />
  );
};