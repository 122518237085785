import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Banner} from 'components/ui/banner';
import outagesImage from './assets/outages.png';

/** Outages banner */
export const OutagesBanner: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <Banner
      image={outagesImage}
      text={t('views.app.home.banners.outages')}
      url="https://vesr.sk/sk/planovane-odstavky"
      delay={1}
    />
  );
};