import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Change the user password */
const passwordChangeSaga = function* (action: actions.IPasswordChangeRequestAction) {
  try {
    yield call(api.passwordChange, action.payload);
    yield put(actions.passwordChangeSuccess());
  }
  catch (error) {
    yield put(actions.passwordChangeFailure(error as IRequestError));
  }
};

export const passwordChange = function* () {
  yield takeLatest(actions.PasswordChangeActionTypes.request, passwordChangeSaga);
};
