import './upload-item.scss';
import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IUploadItemProps} from './upload-item.types';
import {Typography} from 'components/ui/typography';
import FailIcon from '@material-ui/icons/PriorityHigh';
import SuccessIcon from '@material-ui/icons/Check';
import RetryIcon from '@material-ui/icons/Replay';
import RemoveIcon from '@material-ui/icons/Close';
import {UploadItemIcon} from './upload-item-icon';
import {Collapse} from 'components/ui/collapse';
import {Fade} from 'components/ui/fade';

/** File upload list item */
export const UploadItem: FunctionComponent<IUploadItemProps> = (props) => {
  const {
    name,
    progress,
    error,
    onRemove,
    onRetry
  } = props;
  const [visible, setVisible] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    setTimeout(() => setVisible(true), 20);
  }, []);
  
  const success = progress === 100;
  
  const classes = useMemo(() => {
    let classes = 'ui-upload-item';
    if (success)
      classes += ' ui-upload-item--success';
    if (error)
      classes += ' ui-upload-item--fail';
    return classes;
  }, [error, success]);   

  return (
    <div className={classes}>
      <Collapse
        in={visible}
        className={classes}
      >
        <Fade in={true || visible}>
          <div className="ui-upload-item__content">

            {/* Remove item icon */}
            
            <UploadItemIcon
              className="ui-upload-item__remove"
              icon={<RemoveIcon />}
              tooltip={t('components.ui.upload.list.remove')}
              visible={success}
              onClick={onRemove}
            />
            
            {/* Retry failed upload */}
            
            <UploadItemIcon
              className="ui-upload-item__retry"
              icon={<RetryIcon />}
              tooltip={t('components.ui.upload.list.retry')}
              visible={error}
              onClick={onRetry}
            />

            {/* File name */}
            
            <Typography
              variant="body1"
              className="ui-upload-item__name"
            >
              {name}
            </Typography>
            
            {/* Upload progress */}
            
            <Collapse
              className="ui-upload-item__progress"
              in={!error && !success}
              orientation="horizontal"
            >
              <Typography variant="body1">
                {progress}%
              </Typography>
            </Collapse>

            {/* Upload success icon */}

            <UploadItemIcon
              className="ui-upload-item__success"
              icon={<SuccessIcon />}
              tooltip={t('components.ui.upload.list.success')}
              visible={success}
            />

            {/* Upload fail icon */}

            <UploadItemIcon
              className="ui-upload-item__fail"
              icon={<FailIcon />}
              tooltip={t('components.ui.upload.list.fail')}
              visible={error}
            />
          </div>
        </Fade>
      </Collapse>
    </div>
  );
};