import './desktop-page-info.scss';
import React, {FunctionComponent, useContext, useEffect, useState} from 'react';
import {AppMenuContext} from '../../app-menu-context';
import {Collapse} from 'components/ui/collapse';
import {Container} from 'components/ui/container';
import {Typography} from 'components/ui/typography';

/** Desktop menu active page info */
export const DesktopPageInfo: FunctionComponent = () => {
  const {activeItem, activeSubItem} = useContext(AppMenuContext);
  const [pageInfo, setPageInfo] = useState('');
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    const info = activeSubItem?.pageInfo || activeItem?.pageInfo;
    if (info)
      setPageInfo(info);
    setOpen(!!info);
  }, [activeItem, activeSubItem]);

  return (
    <Collapse
      className="ui-desktop-page-info"
      in={open}
    >
      <Container className="ui-desktop-page-info__content">
        <Typography
          variant="body1"
          className="ui-desktop-page-info__message"
        >
          {pageInfo}
        </Typography>
      </Container>
    </Collapse>
  );
};