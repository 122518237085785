import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IContractInvoicingDemandProps} from './contract-invoicing-demand.types';
import {DemandDialog} from 'components/demand/demand-dialog';
import DemandIcon from '@material-ui/icons/MarkunreadMailbox';
import {Button} from 'components/ui/button';

/**
 * Contract invoicing settings demand
 *
 * @example
 *
 *   <ContractInvoicingDemand />
 */
export const ContractInvoicingDemand: FunctionComponent<IContractInvoicingDemandProps> = (props) => {
  const {label, contractNumber} = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const {t} = useTranslation();
  
  const toggleDialog = useCallback(() => {
    setDialogOpen((open) => !open);
  }, []);

  const initialDemandValues = useMemo(() => {
    if (contractNumber !== undefined)
      return {subject: t('components.demand.contract_invoicing_demand.subject_with_contract_number', {contractNumber})};
    return {subject: t('components.demand.contract_invoicing_demand.subject')};
  }, [contractNumber, t]);

  return (
    <>
      <Button
        icon={<DemandIcon />}
        onClick={toggleDialog}
      >
        {label || t('components.demand.contract_invoicing_demand.button')}
      </Button>
      <DemandDialog
        initialValues={initialDemandValues}
        open={dialogOpen}
        onClose={toggleDialog}
      />
    </>
  );
};