export enum SelectPartnerActionTypes {
  select = 'SELECT_PARTNER'
}

export interface ISelectPartnerPayload {
  partnerId: (number | null);
}

export interface ISelectPartnerAction {
  type: SelectPartnerActionTypes.select;
  payload: ISelectPartnerPayload;
}
