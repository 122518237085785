import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IAccessDemandCreationRequest, IAccessDemandCreationResponse} from './access-demand-creation.types';

/** Access demand creation */
export const accessDemandCreation = async (request: IAccessDemandCreationRequest) => {
  const response = await axios.post<IAccessDemandCreationResponse>(urls.accessDemandCreation(), request, {
    headers: {
      ...headers.language(),
      ...headers.auth()
    }
  });
  return response.data;
};