import './list-item.scss';
import React, {ElementType, forwardRef, ReactElement, useMemo} from 'react';
import {ListItemProps} from './list-item.types';
import MuiListItem from '@material-ui/core/ListItem';

/**
 * List item
 *
 * @example
 *
 *   import {List, ListItem, ListItemIcon, ListItemText} from 'components/ui/list';
 *   
 *   // ...
 *   
 *   <List>
 *     <ListItem>
 *       <ListItemIcon>
 *         <InboxIcon />
 *       </ListItemIcon>
 *       <ListItemText primary="Inbox" />
 *     </ListItem>
 *     <ListItem button>
 *       <ListItemIcon>
 *         <SentIcon />
 *       </ListItemIcon>
 *       <ListItemText primary="Sent mails" />
 *     </ListItem>
 *   </List>
 */
export const ListItem = forwardRef(function ListItem<T extends ElementType = 'li'>(props: ListItemProps<T>, ref: Unpack<ListItemProps<T>, 'ref'>) {
  const {className, children, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-list-item';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <MuiListItem
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiListItem>
  );
} as <T extends ElementType = 'li'>(props: ListItemProps<T> & {ref?: Unpack<ListItemProps<T>, 'ref'>;}) => ReactElement);
// ^ Make JSX generic parameters work with forwardRef
//
// @see https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref