import './description-list.scss';
import React, {forwardRef, useMemo} from 'react';
import {IDescriptionListProps} from './description-list.types';
import {ContainerQuery, IQueryClasses} from 'components/ui/container-query';

/**
 * Description list (dl tag)
 *
 * @example
 *
 *   import {DescriptionList, DescriptionLabel, DescriptionValue} from 'components/ui/description-list';
 *   
 *   // ...
 *   
 *   <DescriptionList>
 *     <DescriptionLabel>
 *       HTML
 *     </DescriptionLabel>
 *     <DescriptionValue>
 *       Hypertext Markup Language
 *     </DescriptionValue>
 * 
 *     <DescriptionLabel>
 *       CSS
 *     </DescriptionLabel>
 *     <DescriptionValue>
 *       Cascading Style Sheets
 *     </DescriptionValue>
 *   </DescriptionList>
 */
export const DescriptionList = forwardRef<HTMLDListElement, IDescriptionListProps>(function DescriptionList(props, ref) {
  const {className, children} = props;

  const queryClasses = useMemo<IQueryClasses>(() => ({
    'ui-description-list--narrow': {maxWidth: 320}
  }), []);
  
  const classes = useMemo(() => {
    let classes = 'ui-description-list';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <ContainerQuery
      ref={ref}
      component="dl"
      queryClasses={queryClasses}
      className={classes}
    >
      {children}
    </ContainerQuery>
  );
});