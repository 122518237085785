import {Reducer} from 'redux';
import {ConsumptionParameterListAction, ConsumptionParameterListActionTypes} from 'actions';
import {IConsumptionParameterListState} from './consumption-parameter-list.types';

const initialState: IConsumptionParameterListState = {
  pending: false,
  error: null,
  data: null
};

/** List of dynamic consumption place parameters */
export const consumptionParameterList: Reducer<IConsumptionParameterListState, ConsumptionParameterListAction> = (state = initialState, action): IConsumptionParameterListState => {
  switch (action.type) {
    case ConsumptionParameterListActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case ConsumptionParameterListActionTypes.success:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case ConsumptionParameterListActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};