import React, {forwardRef, useCallback, useState, FocusEvent} from 'react';
import {FormSliderProps} from './form-slider.types';
import {useFormField} from 'hooks';
import {Slider} from 'components/ui/slider';
import {FormFieldError} from 'components/ui/form-field-error';
import {FormikValues, useFormikContext} from 'formik';

/**
 * Formik wrapper for slider
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormSlider} from 'components/ui/form-slider';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormSlider
 *       name="someName"
 *       label="Some label"
 *     />
 *   </Form>
 */
export const FormSlider = forwardRef<HTMLSpanElement, FormSliderProps>(function FormSlider(props, ref) {
  const {field, meta, helpers} = useFormField<number>(props);
  const {validate, ...restProps} = props;
  const values = useFormikContext().values as FormikValues;
  const [value, setValue] = useState(values[props.name]);

  const handleChange = useCallback((newValue: number) => {
    setValue(newValue);
  }, []);

  const handleBlur = useCallback((event: FocusEvent<HTMLSpanElement>) => {
    helpers.setValue(value);
    field.onBlur(event);
  }, [field, helpers, value]);

  return (
    <>
      <Slider
        ref={ref}
        {...restProps}
        {...field}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <FormFieldError>
        {meta.touched && meta.error}
      </FormFieldError>
    </>
  );
});