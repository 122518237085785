import DeviceDetector from 'device-detector-js';
import {DeviceType, IDeviceInfo} from './device-info.types';

const deviceDetector = new DeviceDetector();

const getParsedUserAgent = () => {
  return deviceDetector.parse(navigator.userAgent);
};

/**
 * Get device type
 * 
 * @example
 * 
 *   import {getDeviceType, DeviceType} from 'lib/global/device-info';
 * 
 *   if (getDeviceType() === DeviceType.desktop)
 *     console.log('Current device is desktop');
 */
export const getDeviceType = () => {
  const {device} = getParsedUserAgent();
  if (device) {
    const parsedDeviceType = device.type as DeviceType;
    const isValidDeviceType = Object.values(DeviceType).includes(parsedDeviceType);
    if (isValidDeviceType)
      return parsedDeviceType;
  }
  return DeviceType.unknown;
};

/**
 * Get device model
 * 
 * @example
 * 
 *   import {getDeviceModel} from 'lib/global/device-info';
 * 
 *   const deviceModel = getDeviceModel();
 *   if (deviceModel)
 *     console.log(`Current device model is ${deviceModel}`);
 */
export const getDeviceModel = () => {
  const {device} = getParsedUserAgent();
  if (device?.brand) {
    let result = device.brand;
    if (device.model)
      result += ` ${device.model}`;
    return result;
  }
};

/**
 * Get device operating system
 * 
 * @example
 * 
 *   import {getDeviceModel} from 'lib/global/device-info';
 * 
 *   const deviceOs = getDeviceOs();
 *   if (deviceOs)
 *     console.log(`Current device operating system is ${deviceOs}`);
 */
export const getDeviceOs = () => {
  const {os} = getParsedUserAgent();
  if (os?.name) {
    let result = os.name.replace('GNU/Linux', 'Linux');
    if (os.version)
      result += ` ${os.version}`;
    return result;
  }
};

/**
 * Get device info
 * 
 * @example
 * 
 *   import {getDeviceInfo, DeviceType} from 'lib/global/device-info';
 * 
 *   const deviceInfo = getDeviceInfo();
 * 
 *   console.log(`Current device type is ${deviceInfo.type}`);
 *   if (deviceInfo.model)
 *     console.log(`Current device model is ${deviceInfo.model}`);
 *   if (deviceInfo.os)
 *     console.log(`Current device operating system is ${deviceInfo.os}`);
 */
export const getDeviceInfo = () => {
  const deviceInfo: IDeviceInfo = {
    type: getDeviceType(),
    model: getDeviceModel(),
    os: getDeviceOs()
  };
  return deviceInfo;
};