import React, {useContext, FunctionComponent, ChangeEvent, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {FilterContext} from '../consumption-filter-context';
import {useFetch, useOnFetchSuccess} from 'hooks';
import {api} from 'api';
import {FilterComboBox, FilterComboBoxOption} from 'components/ui/filter-combo-box';
import ContractsIcon from '@material-ui/icons/Work';

export const ALL_CONTRACTS = -1;

/** Consumption filter contract select */
export const ContractSelect: FunctionComponent = () => {
  const {
    getValues,
    setAndStoreValues,
    getStoredValue,
    FilterField
  } = useContext(FilterContext);
  const {contractId} = getValues();
  const contracts = useFetch(api.contractList);
  const {t} = useTranslation();

  // Fetch list of contracts on mount
  useEffect(() => {
    contracts.fetch();
  // eslint-disable-next-line
  }, []);

  const contractList = useMemo(() => {

    // Map demand list to combo box options
    const list = contracts.data?.map((contract): FilterComboBoxOption => ({
      label: `${contract.subject} (${contract.number})`,
      value: contract.id
    }));

    // Add 'all contracts' option
    if (list && list.length > 1)
      list.unshift({
        label: t('views.consumption.filter.all_contracts'),
        value: ALL_CONTRACTS
      });
    
    return list;
  }, [contracts.data, t]);

  const setContract = useCallback((value: number) => {
    setAndStoreValues({contractId: value});    
  }, [setAndStoreValues]);

  useOnFetchSuccess([contracts], () => {
    if (contractList) {
      const listWithoutAllOption = contractList.filter((place) => {
        return place.value !== ALL_CONTRACTS;
      });
      const {value} = getStoredValue('contractId')
        .onlyIfIncludedInList(contractList)
        .andNotEqualsTo(ALL_CONTRACTS)
        .orGetFirstFromList(listWithoutAllOption);
      setContract(value as number);
    }
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
    setContract(value as number);
  }, [setContract]);

  const disabled = !contractList || contractList.length <= 1;

  return (
    <FilterField
      pending={contracts.pending}
      error={contracts.error}
    >
      <FilterComboBox
        label={t('views.consumption.filter.contracts')}
        labelIcon={<ContractsIcon />}
        options={contractList}
        value={contractId}
        disabled={disabled}
        onChange={handleChange}
      />
    </FilterField>
  );
};