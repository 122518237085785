import {combineReducers} from 'redux';
import {demandCreation} from './demand-creation';
import {demandPriorityList} from './demand-priority-list';
import {demandStatusList} from './demand-status-list';
import {demandTypeList} from './demand-type-list';

export const demand = combineReducers({
  creation: demandCreation,
  priorityList: demandPriorityList,
  statusList: demandStatusList,
  typeList: demandTypeList
});