import {Reducer} from 'redux';
import {EnergyTypeListAction, EnergyTypeListActionTypes} from 'actions';
import {IEnergyTypeListState} from './energy-type-list.types';

const initialState: IEnergyTypeListState = {
  pending: false,
  error: null,
  data: null
};

/** List of energy types */
export const energyTypeList: Reducer<IEnergyTypeListState, EnergyTypeListAction> = (state = initialState, action): IEnergyTypeListState => {
  switch (action.type) {
    case EnergyTypeListActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case EnergyTypeListActionTypes.success:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case EnergyTypeListActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};