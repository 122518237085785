import {time as validateTime} from 'lib/global/validate/time';
import {time as parseTime} from 'lib/intl/parse/time';
import {logErrorOnDev} from 'lib/global/dev';

export const REQUEST_TIME_FORMAT = 'HH:mm';

/**
 * Format time for usage in request body
 *
 * @param value - Time to format
 * @returns Formatted time
 */
export const requestTime = (value: AnyDate) => {
  const validationError = validateTime(value);
  if (validationError) {
    logErrorOnDev(validationError);
    return value !== undefined && value !== null ? value.toString() : value;
  }
  return parseTime(value).format(REQUEST_TIME_FORMAT);
};
