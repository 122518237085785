import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {t} from 'lib/intl/i18n';

/**
 * Validate bank account prefix
 *
 * @param input - Bank account prefix value
 * @returns Error message or undefined
 */
export const bankAccountPrefix: IValidator = (input) => {
  const value = String(input || '').trim();
  if (value) {
    const errorMessage = t('lib.global.validate.bank_account.prefix');
    if (value.length > 6 || isNaN(Number(value)))
      return errorMessage;
    const weights: number[] = [10, 5, 8, 4, 2, 1];
    const paddedValue = Array.from(value.padStart(6, '0'));
    const sum = paddedValue.reduce((sum, current, id) => {
      return sum + Number(current) * weights[id];
    }, 0);
    if (sum % 11 !== 0)
      return errorMessage;
  }
};

/**
 * Check that the bank account prefix validator doesn't return any error
 *
 * @param input - Bank account prefix value to check
 */
export const isBankAccountPrefix: ValidatorQuery = (input) => {
  return !!input && !bankAccountPrefix(input);
};
