import {Reducer} from 'redux';
import {DemandPriorityListAction, DemandPriorityListActionTypes} from 'actions';
import {IDemandPriorityListState} from './demand-priority-list.types';

const initialState: IDemandPriorityListState = {
  pending: false,
  error: null,
  data: null
};

/** List of demand priorities */
export const demandPriorityList: Reducer<IDemandPriorityListState, DemandPriorityListAction> = (state = initialState, action): IDemandPriorityListState => {
  switch (action.type) {
    case DemandPriorityListActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case DemandPriorityListActionTypes.success:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case DemandPriorityListActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};