import {useFormikContext} from 'formik';
import {FunctionComponent, useEffect, useState} from 'react';
import {IOnFormSubmitErrorProps} from './on-form-submit-error.types';

/**
 * Catch error on form submit and call provided handler
 * 
 * @see https://github.com/formium/formik/issues/1484
 */
export const OnFormSubmitError: FunctionComponent<IOnFormSubmitErrorProps> = (props) => {
  const {onError} = props;
  const {submitCount, isValid} = useFormikContext();
  const [lastHandled, setLastHandled] = useState(0);
  
  useEffect(() => {
    if (submitCount > lastHandled && !isValid) {
      if (onError)
        onError();
      setLastHandled(submitCount);
    }
  }, [submitCount, isValid, lastHandled, onError]);
  
  return null;
};