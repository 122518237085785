import './invoice-commodity.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {IInvoiceCommodityProps} from './invoice-commodity.types';
import {Tooltip} from 'components/ui/tooltip';
import {ReactComponent as HeatIcon} from './assets/heat.svg';
import {ReactComponent as WaterIcon} from './assets/water.svg';
import {ReactComponent as GasIcon} from './assets/gas.svg';
import {ReactComponent as ElectricityIcon} from './assets/electricity.svg';
import {ReactComponent as OtherIcon} from './assets/other.svg';

/** Invoice commodity icon */
export const InvoiceCommodity: FunctionComponent<IInvoiceCommodityProps> = (props) => {
  const {className, invoice} = props;
  const {commodityTypeId, commodityTypeDescription} = invoice;

  const classes = useMemo(() => {
    let classes = 'ui-invoice-commodity';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);
  
  const Icon = useMemo(() => {
    if (commodityTypeId === 'heat')
      return HeatIcon;
    if (commodityTypeId === 'water')
      return WaterIcon;
    if (commodityTypeId === 'gas')
      return GasIcon;
    if (commodityTypeId === 'electricity')
      return ElectricityIcon;
    return OtherIcon;
  }, [commodityTypeId]);

  if (!commodityTypeId || !commodityTypeDescription)
    return null;

  return (
    <Tooltip title={commodityTypeDescription}>
      <Icon className={classes} />
    </Tooltip>
  );
};