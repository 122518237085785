import './invoice-status.scss';
import React, {FunctionComponent, ReactElement, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';
import {IInvoiceStatusProps} from './invoice-status.types';
import {Tooltip} from 'components/ui/tooltip';
import UnknownIcon from '@material-ui/icons/Help';
import PaidIcon from '@material-ui/icons/CheckCircle';
import PartiallyPaidBeforeDueIcon from '@material-ui/icons/WatchLater';
import UnpaidBeforeDueIcon from '@material-ui/icons/Warning';
import PartiallyPaidAfterDueIcon from '@material-ui/icons/Error';
import UnpaidAfterDueIcon from '@material-ui/icons/Error';

const statuses = {
  unknown: 'unknown',
  paid: 'paid',
  partially_paid_before_due: 'partially_paid_before_due',
  unpaid_before_due: 'unpaid_before_due',
  partially_paid_after_due: 'partially_paid_after_due',
  unpaid_after_due: 'unpaid_after_due'
};

const statusIcons: Record<string, ReactElement> = {
  unknown: (<UnknownIcon />),
  paid: (<PaidIcon />),
  unpaid_before_due: (<UnpaidBeforeDueIcon />),
  partially_paid_before_due: (<PartiallyPaidBeforeDueIcon />),
  partially_paid_after_due  : (<PartiallyPaidAfterDueIcon />),
  unpaid_after_due: (<UnpaidAfterDueIcon />)
};

/** Invoice status */
export const InvoiceStatus: FunctionComponent<IInvoiceStatusProps> = (props) => {
  const {className, statusId: statusIdProp, showTooltip} = props;
  const invoiceStatusList = useSelector(selectors.invoiceStatusList, shallowEqual);  
  const {t} = useTranslation();

  const status = useMemo(() => {
    return invoiceStatusList.data?.find((status) => status.id === statusIdProp);
  }, [invoiceStatusList.data, statusIdProp]);

  const statusId = useMemo(() => {
    if (!status)
      return statuses.unknown;
    return status.id;
  }, [status]);

  const statusDescription = useMemo(() => {
    if (status)
      return status.name;
    return t('components.invoice.invoices_table.unknown_status');
  }, [status, t]);

  const classes = useMemo(() => {
    let classes = 'ui-invoice-status';
    classes += ` ui-invoice-status--${statusId.replace(/_/g, '-')}`;
    if (showTooltip)
      classes += ' ui-invoice-status--tooltip';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, showTooltip, statusId]);   

  return (
    <Tooltip title={!!showTooltip && statusDescription}>
      <div className={classes}>
        {statusIcons[statusId]}
        {!showTooltip && statusDescription}
      </div>
    </Tooltip>
  );
};