import React, {forwardRef} from 'react';
import {FormComboBoxProps} from './form-combo-box.types';
import {useFormField} from 'hooks';
import {ComboBox} from 'components/ui/combo-box';
import {FormFieldError} from 'components/ui/form-field-error';

/**
 * Formik wrapper for combo box 
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormComboBox} from 'components/ui/form-combo-box';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormComboBox
 *       name="someName"
 *       label="Some label"
 *       options={someOptions}
 *     />
 *   </Form>
 */
export const FormComboBox = forwardRef<HTMLDivElement, FormComboBoxProps>(function FormComboBox(props, ref) {
  const {field, meta} = useFormField(props);
  const {validate, ...restProps} = props;

  return (
    <>
      <ComboBox
        ref={ref}
        {...restProps}
        {...field}
      />
      <FormFieldError>
        {meta.touched && meta.error}
      </FormFieldError>
    </>
  );
});