import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {AppMenuItems} from '../app-menu.types';
import {useMenu} from 'config/menu';
// import {useLocation, matchPath} from 'react-router-dom';
import {useLocation} from 'react-router-dom';
import {matchPath} from 'lib/global/react-router';
import {AppMenuContext, IAppMenuContext} from '../app-menu-context';

/**
 * Application menu context provider
 * 
 * @example
 * 
 *   <AppMenuProvider>
 *     <DesktopMenu />
 *     <MobileMenu />
 *   </AppMenuProvider>
 */
export const AppMenuProvider: FunctionComponent = (props) => {
  const {children} = props;
  const menuItems = useMenu();
  const location = useLocation();
  
  const usableMenuItems = useMemo(() => {
    return menuItems.filter(({inactive}) => !inactive);
  }, [menuItems]);
  
  const getActiveItem = useCallback((items?: AppMenuItems) => {
    if (!items)
      return null;
    for (const item of items) {
      let {path} = item;
      if (item.activeForSubPaths)
        path += '/*';
      const pathMatches = matchPath(path, location.pathname) !== null;
      const hasActiveSubItem = getActiveItem(item.subMenu?.items) !== null;
      if (pathMatches || hasActiveSubItem)
        return item;
    }
    return null;
  }, [location.pathname]);

  const activeItem = useMemo(() => {
    return getActiveItem(usableMenuItems);
  }, [getActiveItem, usableMenuItems]);

  const activeSubItem = useMemo(() => {
    return getActiveItem(activeItem?.subMenu?.items);
  }, [activeItem, getActiveItem]);
  
  const appMenuContext = useMemo<IAppMenuContext>(() => ({
    menuItems: usableMenuItems,
    activeItem,
    activeSubItem
  }), [activeItem, usableMenuItems, activeSubItem]);

  return (
    <AppMenuContext.Provider value={appMenuContext}>
      {children}
    </AppMenuContext.Provider>
  );
};