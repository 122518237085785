import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import moment from 'moment';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {DatePicker} from 'components/ui/date-picker';
import {TimePicker} from 'components/ui/time-picker';
import {DateTimePicker} from 'components/ui/date-time-picker';
import {DateRangePicker, IDateRange} from 'components/ui/date-range-picker';
import {Grid} from 'components/ui/grid';
import {format} from 'lib/intl/format';

/** Date and time controls preview */
export const DateTimeKit: FunctionComponent = () => {
  const [date, setDate] = useState(
    format.date(new Date())
  );
  const [time, setTime] = useState(
    format.time(new Date())
  );
  const [dateTime, setDateTime] = useState(
    format.dateTime(new Date())
  );
  const {t} = useTranslation();
  
  const initialValue = useMemo<IDateRange>(() => ({
    from: format.date(moment().subtract(3, 'days')),
    to: format.date(moment().add(3, 'days'))
  }), []);
  
  const [dateRange, setDateRange] = useState<IDateRange>(initialValue);

  const handleDateChange = useCallback((value: string) => {
    setDate(value);
  }, []);
  
  const handleTimeChange = useCallback((value: string) => {
    setTime(value);
  }, []);

  const handleDateTimeChange = useCallback((value: string) => {
    setDateTime(value);
  }, []);

  const handleDateRangeChange = useCallback((from: string, to: string) => {
    setDateRange({from, to});
  }, []);

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.date_time.heading')}
      </Typography>
      <Grid container>

        {/* Date */}

        <Grid item md={6}>
          <DatePicker
            label={t('views.app.ui_kit.date_time.label.date')}
            value={date}
            onChange={handleDateChange}
          />
        </Grid>

        {/* Time */}

        <Grid item md={6}>
          <TimePicker
            label={t('views.app.ui_kit.date_time.label.time')}
            value={time}
            onChange={handleTimeChange}
          />
        </Grid>

        {/* Date and time */}

        <Grid item md={6}>
          <DateTimePicker
            label={t('views.app.ui_kit.date_time.label.date_time')}
            value={dateTime}
            onChange={handleDateTimeChange}
          />
        </Grid>

        {/* Date range */}

        <Grid item md={6}>
          <DateRangePicker
            label={t('views.app.ui_kit.date_time.label.date_range')}
            fromValue={dateRange.from}
            toValue={dateRange.to}
            onChange={handleDateRangeChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
