import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {format} from 'lib/intl/format';
import {IDocumentsTableProps} from './documents-table.types';
import {useTranslation} from 'lib/intl/i18n';
import {
  DataTable,
  asTableColumns,
  mapTableRows,
  asTableExportColumns,
  mapTableExportData
} from 'components/ui/data-table';
import {DownloadButton} from 'components/ui/download-button';

/**
 * Documents table
 * 
 * @example
 * 
 *   <DocumentsTable documents={someDocuments} />
 */
export const DocumentsTable: FunctionComponent<IDocumentsTableProps> = (props) => {
  const {documents} = props;
  const {t} = useTranslation();

  const columns = useMemo(() => asTableColumns({
    name: {
      label: t('components.document.documents_table.column.name')
    },
    description: {
      label: t('components.document.documents_table.column.description')
    },
    size: {
      label: t('components.document.documents_table.column.size'),
      align: 'right'
    },
    download: {
      label: t('components.document.documents_table.column.download'),
      align: 'center',
      shrink: true
    }
  }), [t]);

  const rows = useMemo(() => mapTableRows(columns, documents, (document) => ({
    name: format.fileName(document.name, document.type),
    description: document.description || t('components.document.documents_table.no_description'),
    size: format.fileSize(document.size),
    download: (
      <DownloadButton documents={document} />
    )
  })), [columns, documents, t]);
  
  const exportData = useCallback(() => {
    const exportColumns = asTableExportColumns({
      name: t('components.document.documents_table.column.name')!,
      description: t('components.document.documents_table.column.description')!,
      size: t('components.document.documents_table.column.size')!
    });
    return mapTableExportData(exportColumns, documents, (document) => ({
      name: format.fileName(document.name, document.type),
      description: document.description || t('components.document.documents_table.no_description')!,
      size: format.fileSize(document.size)
    }));
  }, [documents, t]);

  return (
    <DataTable
      columns={columns}
      rows={rows}
      exportData={exportData}
      exportFilename={t('components.document.documents_table.export_filename')}
    />
  );
};