import React, {FunctionComponent, useMemo} from 'react';
import {IDemandTypeProps} from './demand-type.types';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';

/** Demand type */
export const DemandType: FunctionComponent<IDemandTypeProps> = (props) => {
  const {typeId} = props;
  const demandTypeList = useSelector(selectors.demandTypeList, shallowEqual);
  const {t} = useTranslation();

  const demandTypeLabel = useMemo(() => {
    if (demandTypeList.data) {
      const demandType = demandTypeList.data.find((type) => type.id === typeId);
      if (demandType)
        return demandType.name;
    }
    return t('components.demand.demands_table.unknown_type');
  }, [demandTypeList.data, t, typeId]);
  
  return (<>{demandTypeLabel}</>);
};