import {Reducer} from 'redux';
import {TokenAction, RefreshAction} from 'actions';
import {IAuthState} from './auth.types';
import {token} from './token/token';
import {refresh} from './refresh/refresh';

export const initialAuthState: IAuthState = {
  tokenPending: false,
  tokenError: null,
  refreshPending: false,
  refreshError: null,
  accessToken: null,
  tokenType: null,
  refreshToken: null,
  expiresAt: null
};

/** User authorization */
export const auth: Reducer<IAuthState, (TokenAction | RefreshAction)> = (state = initialAuthState, action): IAuthState => {
  state = token(state, action as TokenAction);
  state = refresh(state, action as RefreshAction);
  return state;
};
