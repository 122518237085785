import './table-cell.scss';
import React, {forwardRef, useMemo} from 'react';
import {TableCellProps} from './table-cell.types';
import MuiTableCell from '@material-ui/core/TableCell';

/**
 * Table cell
 * 
 * @see src/views/app/kit-view/table-kit
 * 
 * @example
 *  
 *   import {Table, TableContainer, TableHead, TableBody, TableRow, TableCell} from 'components/ui/table';
 *   
 *   // ...
 *   
 *   <TableContainer>
 *     <Table>
 * 
 *       <TableHead>
 *         <TableRow>
 *           <TableCell>
 *             First name
 *           </TableCell>
 *           <TableCell>
 *             Last name
 *           </TableCell>
 *         </TableRow>
 *       </TableHead>
 *       
 *       <TableBody>
 *         <TableRow>
 *           <TableCell>
 *             Alan
 *           </TableCell>
 *           <TableCell>
 *             Turing
 *           </TableCell>
 *         </TableRow>
 *         ...
 *       </TableBody>
 * 
 *     </Table>
 *   </TableContainer>
 */
export const TableCell = forwardRef<unknown, TableCellProps>(function TableCell(props, ref) {
  const {className, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-table-cell';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <MuiTableCell
      ref={ref}
      {...restProps}
      className={classes}
    />
  );
});