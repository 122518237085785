import parseDecimal from 'parse-decimal-number';

/**
 * Parse number
 *
 * @param value - Numeric string
 * @returns Parsed number or NaN
 */
export const number = (value: string) => {
  return parseDecimal(value);
};
