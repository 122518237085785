import './place-detail-table.scss';
import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {IConsumptionPlaceResponse} from 'api';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {IPlaceDetailTableProps} from './place-detail-table.types';
import {useTranslation} from 'lib/intl/i18n';
import {DataTable, asTableColumns, asTableRows, DataTableColumns} from 'components/ui/data-table';

/** Consumption place detail table */
export const PlaceDetailTable: FunctionComponent<IPlaceDetailTableProps> = (props) => {
  const {place} = props;
  const placeParameters = useSelector(selectors.consumptionParameterList, shallowEqual);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (!placeParameters.data)
      dispatch(actions.consumptionParameterListRequest());
  }, [dispatch, placeParameters.data]);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const columns = useMemo(() => asTableColumns({
    id: {
      label: t('components.consumption.place_detail_table.id')
    },
    name: {
      label: t('components.consumption.place_detail_table.name')
    }
    // VESK-133 - for future use
    // address: {
    //   label: t('components.consumption.place_detail_table.address')
    // }
  }), [t]);
  
  // Get columns including dynamic parameters
  const getColumns = useCallback(() => {
    for (const parameter of placeParameters.data!)
      (columns as DataTableColumns)[parameter.id] = {label: parameter.name};
    return columns;
  }, [columns, placeParameters.data]);

  // VESK-133 - for future use
  // const getPlaceAddress = useCallback((place: IConsumptionPlaceResponse) => {
  //   const {city, street} = place.address;
  //   const addressItems = [];
  //   if (city)
  //     addressItems.push(city);
  //   if (street)
  //     addressItems.push(street);
  //   return addressItems.join(', ');
  // }, []);

  const rows = useMemo(() => asTableRows(columns, [{
    id: place.id,
    name: place.name
    // VESK-133 - for future use
    // address: getPlaceAddress(place)
  }]), [columns, place.id, place.name]);

  // Get rows including dynamic parameters
  const getRows = useCallback(() => {
    for (const parameter of placeParameters.data!)
      (rows as Record<string, unknown>[])[0][parameter.id] = place[`parameter${parameter.id}` as keyof IConsumptionPlaceResponse];
    return rows;
  }, [place, placeParameters.data, rows]);
  
  const render = useCallback(() => (
    <DataTable
      className="ui-place-detail-table"
      columns={getColumns()}
      rows={getRows()}
      renderVertically
    />
  ), [getColumns, getRows]);
  
  return (
    <WaitForFetch
      pending={placeParameters.pending}
      error={placeParameters.error}
      noData={!placeParameters.data}
      onRetry={fetchData}
      render={render}
      absolutePosition
    />
  );
};