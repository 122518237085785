import {InvoiceStatusListResponse} from 'api';

export enum InvoiceStatusListActionTypes {
  request = 'INVOICE_STATUS_LIST_REQUEST',
  success = 'INVOICE_STATUS_LIST_SUCCESS',
  failure = 'INVOICE_STATUS_LIST_FAILURE'
}

export interface IInvoiceStatusListRequestAction {
  type: InvoiceStatusListActionTypes.request;
}

export interface IInvoiceStatusListSuccessAction {
  type: InvoiceStatusListActionTypes.success;
  payload: InvoiceStatusListResponse;
}

export interface IInvoiceStatusListFailureAction {
  type: InvoiceStatusListActionTypes.failure;
  payload: IRequestError;
}

export type InvoiceStatusListAction = (
  IInvoiceStatusListRequestAction |
  IInvoiceStatusListSuccessAction |
  IInvoiceStatusListFailureAction
);