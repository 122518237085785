import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import validator from 'validator';
import {t} from 'lib/intl/i18n';

/**
 * Validate e-mail address
 *
 * @param input - E-mail to validate
 * @returns Error message or undefined
 */
export const email: IValidator = (input) => {
  const value = String(input || '').trim();
  if (value && !validator.isEmail(value))
    return t('lib.global.validate.email');
};

/**
 * Check that the e-mail validator doesn't return any error
 *
 * @param input - E-mail value to check
 */
export const isEmail: ValidatorQuery = (input) => {
  return !!input && !email(input);
};
