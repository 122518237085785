import {useState, useEffect} from 'react';
import {SharedStateSetter, UseSharedStateProps} from './use-shared-state.types';

/**
 * Share state between components
 *
 * @param [initialState] - Initial shared state
 * @returns Hook providing access to shared state
 *
 * @example
 *
 *   import {createUseSharedState} from 'lib/hooks';
 *
 *   const useMyState = createUseSharedState({count: 0});
 *
 *   const A: FunctionComponent = () => {
 *     const [state, setState] = useMyState();
 *
 *     const increment = useCallback(() => {
 *       setState((prevState) => ({
 *         count: prevState.count + 1
 *       }));
 *     }, [setState]);
 *
 *     return (
 *       <div onClick={increment}>
 *         A: {state.count}
 *       </div>
 *     );
 *   };
 *
 *   const B: FunctionComponent = () => {
 *     const [state, setState] = useMyState();
 *
 *     const increment = useCallback(() => {
 *       setState((prevState) => ({
 *         count: prevState.count + 1
 *       }));
 *     }, [setState]);
 *
 *     return (
 *       <div onClick={increment}>
 *         B: {state.count}
 *       </div>
 *     );
 *   };
 *
 *   const App: FunctionComponent = () => (
 *     <>
 *       <A />
 *       <B />
 *     </>
 *   );
 */
export const createUseSharedState = <T extends Record<keyof T, unknown>>(initialState = {} as T) => {
  let state = initialState;
  const stateSetters = new Set<SharedStateSetter<T>>();

  /**
   * Set shared state values
   *
   * @param payload Object containing values to be merged into shared state
   */
  const setState = (payload: Partial<T>) => {
    state = {...state, ...payload};
    for (const setter of stateSetters)
      setter(state);
  };

  return (): UseSharedStateProps<T> => {
    const [, setter] = useState<T>(state);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      stateSetters.add(setter);
      return () => {
        stateSetters.delete(setter);
        if (stateSetters.size === 0)
          state = initialState;
      };
    }, []);

    return [state, setState as SharedStateSetter<Partial<T>>];
  };
};
