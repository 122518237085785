import './tab.scss';
import React, {forwardRef, ElementType, ReactElement, useMemo} from 'react';
import {TabProps} from './tab.types';
import MuiTab from '@material-ui/core/Tab';

/**
 * Tab item
 * 
 * @see src/views/app/kit-view/tabs-kit
 * 
 * @example
 * 
 *   import {Tabs, Tab} from 'components/ui/tabs';
 * 
 *   // ...
 *  
 *   const [value, setValue] = useState(0);
 * 
 *   const handleChange = useCallback((event: ChangeEvent<unknown>, newValue: number) => {
 *     setValue(newValue);
 *   }, []);
 * 
 *   // ...
 *  
 *   <Tabs
 *     value={value}
 *     onChange={handleChange}
 *   >
 *     <Tab label="First tab" />
 *     <Tab label="Second tab" />
 *     <Tab label="Third tab" />
 *   </Tabs>
 */
export const Tab = forwardRef(function Tabs<T extends ElementType = 'div'>(props: TabProps<T>, ref: Unpack<TabProps<T>, 'ref'>) {
  const {className, children, ...restProps} = props;
  
  const classes = useMemo(() => {
    let classes = 'ui-tab';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <MuiTab
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiTab>
  );
} as <T extends ElementType = 'div'>(props: TabProps<T> & {ref?: Unpack<TabProps<T>, 'ref'>;}) => ReactElement);
// ^ Make JSX generic parameters work with forwardRef
//
// @see https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref