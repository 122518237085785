import {unit as formatUnit, UnitDisplay} from 'lib/intl/format/unit';

/**
 * Get measurement unit symbol
 *
 * @example
 *
 *   format.unitSymbol('celsius');
 *   // °C
 *
 * @example
 *
 *   format.unitSymbol('celsius', 'long');
 *   // Celsius
 *
 * @param unit - Measurement unit
 * @param [display] - Unit display style
 * @returns Unit symbol
 */
export const unitSymbol = (unit: MeasurementUnit, display?: UnitDisplay) => {
  const PLACEHOLDER_VALUE = 123;
  const valueWithSymbol = formatUnit(PLACEHOLDER_VALUE, unit, display);
  const symbol = valueWithSymbol.replace(`${PLACEHOLDER_VALUE} `, '');
  return symbol;
};
