import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import memoize from 'fast-memoize';
import {t} from 'lib/intl/i18n';
import {time as parseTime} from 'lib/intl/parse/time';
import {time as formatTime} from 'lib/intl/format/time';
import {time as validateTime} from 'lib/global/validate/time';
import {logWarningOnDev} from 'lib/global/dev';

/**
 * Get minimum time validator
 *
 * @param minimumTime - Minimum time value
 */
export const minTime = memoize((minimumTime: AnyDate) => {
  if (!parseTime(minimumTime).isValid())
    logWarningOnDev(t('lib.global.validate.time.invalid'));

  /**
   * Validate minimum time
   *
   * @param input - Time to validate
   * @returns Error message or undefined
   */
  const minTime: IValidator<AnyDate> = (input) => {
    if (input) {
      const isValid = !validateTime(input);
      const isSameOrAfter = parseTime(input).isSameOrAfter(parseTime(minimumTime));
      if (isValid && !isSameOrAfter) {
        const time = formatTime(minimumTime);
        return t('lib.global.validate.time.min', {time});
      }
    }
  };

  return minTime;
});

/**
 * Check that the minimum time validator doesn't return any error
 *
 * @param input - Time to validate
 * @param minimumTime - Minimum time value
 */
export const isMinTime: ValidatorQuery = (input, minimumTime: AnyDate) => {
  return !!input && !minTime(minimumTime)(input);
};
