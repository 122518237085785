import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {t} from 'lib/intl/i18n';

/**
 * Validate bank account number
 *
 * @param input - Bank account number value
 * @returns Error message or undefined
 */
export const bankAccountNumber: IValidator = (input) => {
  const value = String(input || '').trim();
  if (value) {
    const errorMessage = t('lib.global.validate.bank_account.number');
    if (value.length < 2 || value.length > 10 || isNaN(Number(value)))
      return errorMessage;
    const weights: number[] = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1];
    const paddedValue = Array.from(value.padStart(10, '0'));
    const sum = paddedValue.reduce((sum, current, id) => {
      return sum + Number(current) * weights[id];
    }, 0);
    if (sum % 11 !== 0)
      return errorMessage;
  }
};

/**
 * Check that the bank account number validator doesn't return any error
 *
 * @param input - Bank account number value to check
 */
export const isBankAccountNumber: ValidatorQuery = (input) => {
  return !!input && !bankAccountNumber(input);
};
