import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {IPlacesTableProps} from './places-table.types';
import {useTranslation} from 'lib/intl/i18n';
import {
  DataTable,
  asTableColumns,
  mapTableRows,
  asTableExportColumns,
  mapTableExportData,
  getExportSource
} from 'components/ui/data-table';
import {PlaceDetailButton} from './place-detail-button';

/**
 * Consumption places table
 * 
 * @example
 * 
 *   <PlacesTable places={someConsumptionPlaces} />
 */
export const PlacesTable: FunctionComponent<IPlacesTableProps> = (props) => {
  const {places, exportDataSource} = props;
  const {t} = useTranslation();

  const columns = useMemo(() => asTableColumns({
    name: {
      label: t('components.consumption.places_table.column.name')
    },
    // VESK-133 - for future use
    // address: {
    //   label: t('components.consumption.places_table.column.address')
    // },
    detail: {
      label: t('components.consumption.places_table.column.detail'),
      align: 'center',
      shrink: true
    }
  }), [t]);

  const rows = useMemo(() => mapTableRows(columns, places, (place) => ({
    name: place.name,
    // VESK-133 - for future use
    // address: place.address.singleLine || '',
    detail: (
      <PlaceDetailButton place={place} />
    )
  })), [columns, places]);
  
  const exportData = useCallback(async () => {
    const exportSource = await getExportSource(exportDataSource || places);
    const exportColumns = asTableExportColumns({
      name: t('components.consumption.places_table.column.name')!
      // VESK-133 - for future use
      //address: t('components.consumption.places_table.column.address')!
    });
    return mapTableExportData(exportColumns, exportSource, (place) => ({
      name: place.name
      // VESK-133 - for future use
      // address: place.address.singleLine
    }));
  }, [exportDataSource, places, t]);

  return (
    <DataTable
      columns={columns}
      rows={rows}
      exportData={exportData}
      exportFilename={t('components.consumption.places_table.export_filename')}
    />
  );
};