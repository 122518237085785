import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IDocumentUploadRequest, DocumentUploadResponse} from './document-upload.types';

/** Document upload */
export const documentUpload = async (request: IDocumentUploadRequest) => {
  const {onUploadProgress, file} = request;
  const formData = new FormData();
  formData.append('file', file);
  const response = await axios.post<DocumentUploadResponse>(urls.documentUpload(), formData, {
    headers: {
      ...headers.auth(),
      ...headers.language(),
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  });
  return response.data;
};