import {AppState} from 'reducers';

/** Get list of demand priorities */
export const demandPriorityList = (state: AppState) => {
  return state.demand.priorityList;
};

/** Get list of demand types */
export const demandTypeList = (state: AppState) => {
  return state.demand.typeList;
};

/** Get state of demand creation */
export const demandCreation = (state: AppState) => {
  return state.demand.creation;
};

/** Get list of demand statuses */
export const demandStatusList = (state: AppState) => {
  return state.demand.statusList;
};
