import React, {forwardRef, useMemo} from 'react';
import {TreeViewProps} from './tree-view.types';
import MuiTreeView from '@material-ui/lab/TreeView';
import OpenIcon from '@material-ui/icons/AddBoxOutlined';
import CloseIcon from '@material-ui/icons/IndeterminateCheckBoxOutlined';
import ItemIcon from '@material-ui/icons/SubdirectoryArrowRight';

/**
 * Tree view
 * 
 * @see src/views/app/kit-view/tree-view-kit
 *
 * @example
 *
 *   import {TreeView, TreeItem} from 'components/ui/tree-view';
 *
 *   <TreeView>
 *     <TreeItem
 *       nodeId="a"
 *       label="Aaa aaa aaa"
 *     >
 *       <TreeItem
 *         nodeId="a1"
 *         label="111 111 111"
 *       />
 *       <TreeItem
 *         nodeId="a2"
 *         label="222 222 222"
 *       />
 *       <TreeItem
 *         nodeId="a3"
 *         label="333 333 333"
 *       />
 *     </TreeItem>
 *   </TreeView>
 */
export const TreeView = forwardRef<HTMLDivElement, TreeViewProps>(function TreeView(props, ref) {
  const {
    className,
    children,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-tree-view';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <MuiTreeView
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiTreeView>
  );
});

TreeView.defaultProps = {
  defaultExpandIcon: (<OpenIcon />),
  defaultCollapseIcon: (<CloseIcon />),
  defaultEndIcon: (<ItemIcon />),
  disableSelection: true
};
