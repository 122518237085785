import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {format} from 'lib/intl/format';
import {IContractTableProps} from './contract-table.types';
import {useTranslation} from 'lib/intl/i18n';
import {
  DataTable,
  asTableColumns,
  asTableExportColumns,
  asTableRows,
  asTableExportData
} from 'components/ui/data-table';
import {ContractDetailButton} from './contract-detail-button';

/** Contract info table */
export const ContractTable: FunctionComponent<IContractTableProps> = (props) => {
  const {contract} = props;
  const {t} = useTranslation();

  const columns = useMemo(() => asTableColumns({
    number: {
      label: t('components.user.contract_table.column.number')
    },
    subject: {
      label: t('components.user.contract_table.column.subject')
    },
    validFrom: {
      label: t('components.user.contract_table.column.valid_from'),
      align: 'right'
    },
    validTo: {
      label: t('components.user.contract_table.column.valid_to'),
      align: 'right'
    },
    detail: {
      label: t('components.user.contract_table.column.detail'),
      align: 'center',
      shrink: true
    }
  }), [t]);

  const rows = useMemo(() => asTableRows(columns, [{
    number: contract.number,
    subject: contract.subject,
    validFrom: format.date(contract.from),
    validTo: format.date(contract.to),
    detail: (
      <ContractDetailButton contract={contract} />
    )
  }]), [columns, contract]);
  
  const exportData = useCallback(() => {
    const exportColumns = asTableExportColumns({
      number: t('components.user.contract_table.column.number')!,
      subject: t('components.user.contract_table.column.subject')!,
      validFrom: t('components.user.contract_table.column.valid_from')!,
      validTo: t('components.user.contract_table.column.valid_to')!
    });
    return asTableExportData(exportColumns, [{
      number: contract.number,
      subject: contract.subject,
      validFrom: format.date(contract.from),
      validTo: format.date(contract.to)
    }]);
  }, [contract.from, contract.number, contract.subject, contract.to, t]);

  return (
    <DataTable
      columns={columns}
      rows={rows}
      exportData={exportData}
      exportFilename={t('components.user.contract_table.export_filename')}
    />
  );
};