import React, {FunctionComponent} from 'react';
import {View} from 'components/ui/view';
import {PaymentsFilter, IPaymentsFilterValues} from './payments-filter';
import {useFilterState, FilterOutput} from 'components/ui/filter';
import {FilteredPaymentsTable} from './filtered-payments-table';

/** Payments view */
export const PaymentsView: FunctionComponent = () => {
  const [filter, setFilter] = useFilterState<IPaymentsFilterValues>();

  return (
    <>
      <PaymentsFilter onChange={setFilter} />
      <View>
        <FilterOutput filter={filter}>
          <FilteredPaymentsTable filter={filter} />
        </FilterOutput>
      </View>
    </>
  );
};