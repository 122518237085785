import React, {FunctionComponent, useEffect, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IAccessDemandFormFieldsProps} from './access-demand-form-fields.types';
import {useFormikContext} from 'formik';
import {IFormValues} from '../access-demand-form.types';
import {validate} from 'lib/global/validate';
import {Grid} from 'components/ui/grid';
import {FormTextField} from 'components/ui/form-text-field';
import {FormComboBox, ComboBoxOption} from 'components/ui/form-combo-box';
import {GrantedPartners} from './granted-partners';
import {Box} from 'components/ui/box';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import SendIcon from '@material-ui/icons/Check';
import {Collapse} from 'components/ui/collapse';
import {Tooltip} from 'components/ui/tooltip';

/** AccessDemand form fields */
export const AccessDemandFormFields: FunctionComponent<IAccessDemandFormFieldsProps> = (props) => {
  const {
    pending,
    operations,
    roles,
    partners,
    onPartnersChange
  } = props;
  const {values, setFieldValue} = useFormikContext<IFormValues>();
  const submitDisabled = partners.length === 0;
  const {t} = useTranslation();

  useEffect(() => {
    setFieldValue('operationId', operations[0].id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const operationOptions = useMemo(() => {
    return operations.map<ComboBoxOption>((operation) => ({
      label: operation.name,
      value: operation.id
    }));
  }, [operations]);

  const roleOptions = useMemo(() => {
    return roles.map<ComboBoxOption>((role) => ({
      label: role.name,
      value: role.id
    }));
  }, [roles]);

  return (
    <>
      <Grid
        container
        disabled={pending}
      >

        {/* First name */}

        <Grid item md={6}>
          <FormTextField
            name="firstName"
            label={t('components.demand.access_demand_form.label.first_name')}
            required
            disabled={pending}
            autoFocus
          />
        </Grid>

        {/* Last name */}

        <Grid item md={6}>
          <FormTextField
            name="lastName"
            label={t('components.demand.access_demand_form.label.last_name')}
            required
            disabled={pending}
          />
        </Grid>

        {/* E-mail */}

        <Grid item md={6}>
          <FormTextField
            name="email"
            label={t('components.demand.access_demand_form.label.email')}
            validate={validate.email}
            required
            disabled={pending}
          />
        </Grid>

        {/* Phone number */}

        <Grid item md={6}>
          <FormTextField
            name="phoneNumber"
            label={t('components.demand.access_demand_form.label.phone')}
            validate={validate.phone}
            required
            disabled={pending}
          />
        </Grid>

        {/* Operation */}

        <Grid item md={values.operationId === 'creation' ? 6 : 12}>
          <FormComboBox
            name="operationId"
            label={t('components.demand.access_demand_form.label.operation')}
            options={operationOptions}
            clearable={false}
            required
            disabled={pending}
          />
        </Grid>

        {/* Role */}

        {values.operationId === 'creation' && (
          <Grid item md={6}>
            <FormComboBox
              name="grantedRoleId"
              label={t('components.demand.access_demand_form.label.role')}
              options={roleOptions}
              clearable={false}
              required
              disabled={pending}
            />
          </Grid>
        )}

        {/* Partners */}
      
        <Grid item>
          <Collapse in={values.operationId === 'creation' && !!values.grantedRoleId || values.operationId === 'cancellation'}>
            <GrantedPartners
              partners={partners}
              onChange={onPartnersChange}
            />
          </Collapse>
        </Grid>
      </Grid>

      {/* Submit */}

      <Box mt={4}>
        <ButtonActions align="center">
          <Tooltip title={submitDisabled && t('components.demand.access_demand_form.submit_hint')}>
            <span>
              <Button
                type="submit"
                icon={<SendIcon />}
                disabled={submitDisabled}
                pending={pending}
              >
                {t('components.demand.access_demand_form.submit')}
              </Button>
            </span>
          </Tooltip>
        </ButtonActions>
      </Box>
    </>
  );
};