import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IContractDetailDialogProps} from './contract-detail-dialog.types';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {ContractDetailTable} from 'components/user/contract-detail-table';

/**
 * Contract detail dialog
 * 
 * @example
 * 
 *   const [dialogOpen, setDialogOpen] = useState(false);
 *   
 *   const toggleDialog = useCallback(() => {
 *     setDialogOpen((open) => !open);
 *   }, []);
 *   
 *   // ...
 *   
 *   <ContractDetailDialog
 *     contract={someContract}
 *     open={dialogOpen}
 *     onClose={toggleDialog}
 *   />
 */
export const ContractDetailDialog: FunctionComponent<IContractDetailDialogProps> = (props) => {
  const {contract, open, onClose} = props;
  const {t} = useTranslation();

  return (
    <Dialog
      title={t('components.user.contract_detail_dialog.title')}
      open={open}
      onClose={onClose}
      width="wide"
    >
      <DialogContent>
        <ContractDetailTable contract={contract} />
      </DialogContent>
    </Dialog>
  );
};