/** @file Axios helpers */

import {t} from 'lib/intl/i18n';

/**
 * Get error "message" property if the status is in the range of 400 to 499 or default error message otherwise
 * 
 * @example
 * 
 *   const message = getErrorMessage(error, 'Default error message');
 *   console.error(message);
 */
export const getErrorMessage = (error: (IRequestError | null), defaultMessage?: string) => {
  if (error && error.message && error.status && error.status >= 400 && error.status < 500)
    return error.message;
  if (defaultMessage)
    return defaultMessage;
  return t('lib.api.axios.general_error');
};