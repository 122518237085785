import './filter-panel.scss';
import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useRemount} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {IFilterPanelProps} from './filter-panel.types';
import {Container} from 'components/ui/container';
import {RevealFade} from 'components/ui/reveal-fade';
import {Fade} from 'components/ui/fade';
import {Preloader} from 'components/ui/preloader';
import {Failure} from 'components/api/failure';

/**
 * Filter container. Render pending or error states retrieved from the filter fields.
 * 
 * @example
 * 
 *   import {FilterPanel} from 'components/ui/filter-panel';
 * 
 *   // ...
 * 
 *   <FilterPanel
 *     pending={pending}
 *     error={error}
 *   >
 *     ...
 *   </FilterPanel>
 */
export const FilterPanel: FunctionComponent<IFilterPanelProps> = (props) => {
  const {
    className,
    pending,
    error,
    children
  } = props;
  const remount = useRemount();
  const {t} = useTranslation();

  const resetFilter = useCallback(() => {
    remount.trigger();
  }, [remount]);  
  
  const classes = useMemo(() => {
    let classes = 'ui-filter-panel';
    if (pending)
      classes += ' ui-filter-panel--pending';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, pending]);

  return (
    <Container className={classes}>
      <RevealFade>
        <Fade in={!pending && !error}>
          <div {...remount.props}>
            {children}
          </div>
        </Fade>
        <Preloader
          visible={pending}
          contrast="primary"
        />
        <Failure
          visible={!!error && !pending}
          message={t('components.ui.filter.panel.fetch_error')}
          onRetry={resetFilter}
          contrast="primary"
        />
      </RevealFade>
    </Container>
  );
};