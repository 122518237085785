/** @file HTTP status codes */

export const CONTINUE_100 = 100;
export const SWITCHING_PROTOCOLS_101 = 101;
export const PROCESSING_102 = 102;
export const OK_200 = 200;
export const CREATED_201 = 201;
export const ACCEPTED_202 = 202;
export const NON_AUTHORITATIVE_INFORMATION_203 = 203;
export const NO_CONTENT_204 = 204;
export const RESET_CONTENT_205 = 205;
export const PARTIAL_CONTENT_206 = 206;
export const MULTI_STATUS_207 = 207;
export const MULTIPLE_CHOICES_300 = 300;
export const MOVED_PERMANENTLY_301 = 301;
export const MOVED_TEMPORARILY_302 = 302;
export const SEE_OTHER_303 = 303;
export const NOT_MODIFIED_304 = 304;
export const USE_PROXY_305 = 305;
export const TEMPORARY_REDIRECT_307 = 307;
export const PERMANENT_REDIRECT_308 = 308;
export const BAD_REQUEST_400 = 400;
export const UNAUTHORIZED_401 = 401;
export const PAYMENT_REQUIRED_402 = 402;
export const FORBIDDEN_403 = 403;
export const NOT_FOUND_404 = 404;
export const METHOD_NOT_ALLOWED_405 = 405;
export const NOT_ACCEPTABLE_406 = 406;
export const PROXY_AUTHENTICATION_REQUIRED_407 = 407;
export const REQUEST_TIMEOUT_408 = 408;
export const CONFLICT_409 = 409;
export const GONE_410 = 410;
export const LENGTH_REQUIRED_411 = 411;
export const PRECONDITION_FAILED_412 = 412;
export const REQUEST_TOO_LONG_413 = 413;
export const REQUEST_URI_TOO_LONG_414 = 414;
export const UNSUPPORTED_MEDIA_TYPE_415 = 415;
export const REQUESTED_RANGE_NOT_SATISFIABLE_416 = 416;
export const EXPECTATION_FAILED_417 = 417;
export const IM_A_TEAPOT_418 = 418;
export const INSUFFICIENT_SPACE_ON_RESOURCE_419 = 419;
export const METHOD_FAILURE_420 = 420;
export const UNPROCESSABLE_ENTITY_422 = 422;
export const LOCKED_423 = 423;
export const FAILED_DEPENDENCY_424 = 424;
export const PRECONDITION_REQUIRED_428 = 428;
export const TOO_MANY_REQUESTS_429 = 429;
export const REQUEST_HEADER_FIELDS_TOO_LARGE_431 = 431;
export const UNAVAILABLE_FOR_LEGAL_REASONS_451 = 451;
export const INTERNAL_SERVER_ERROR_500 = 500;
export const NOT_IMPLEMENTED_501 = 501;
export const BAD_GATEWAY_502 = 502;
export const SERVICE_UNAVAILABLE_503 = 503;
export const GATEWAY_TIMEOUT_504 = 504;
export const HTTP_VERSION_NOT_SUPPORTED_505 = 505;
export const INSUFFICIENT_STORAGE_507 = 507;
export const NETWORK_AUTHENTICATION_REQUIRED_511 = 511;
