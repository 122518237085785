import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IPlaceOverviewListRequest, PlaceOverviewListStructResponse} from './place-overview-list.types';

/** Get a list of consumption place overview */
export const placeOverviewList = async (request?: IPlaceOverviewListRequest) => {
  const response = await axios.get<PlaceOverviewListStructResponse>(urls.placeOverviewList(), {
    params: request,
    headers: {
      ...headers.auth(),
      ...headers.language()
    }
  });
  return response.data;
};