import React, {forwardRef} from 'react';
import {FormTextFieldProps} from './form-text-field.types';
import {useFormField} from 'hooks';
import {TextField} from 'components/ui/text-field';
import {FormFieldError} from 'components/ui/form-field-error';

/**
 * Formik wrapper for text field
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormTextField} from 'components/ui/form-text-field';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormTextField
 *       name="someName"
 *       label="Some label"
 *     />
 *   </Form>
 */
export const FormTextField = forwardRef<HTMLDivElement, FormTextFieldProps>(function FormTextField(props, ref) {
  const {field, meta} = useFormField(props);
  const {validate, errorMessage, ...restProps} = props;

  return (
    <>
      <TextField
        ref={ref}
        {...restProps}
        {...field}
      />
      <FormFieldError>
        {errorMessage || meta.touched && meta.error}
      </FormFieldError>
    </>
  );
});