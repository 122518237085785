import './partner-select-form.scss';
import React, {FunctionComponent, useCallback, useMemo, useState, ChangeEvent} from 'react';
import {paths} from 'config/router';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {useWatchEffect} from 'hooks';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {useNavigate} from 'react-router-dom';
import {useTranslation} from 'lib/intl/i18n';
import {ComboBox, ComboBoxOption} from 'components/ui/combo-box';
import {IPartnerSelectFormProps, IPartnerSelectPlace} from './partner-select-form.types';

/**
 * Partner select form
 *
 * @example
 *
 *   <PartnerSelectForm />
 */
export const PartnerSelectForm: FunctionComponent<IPartnerSelectFormProps> = (props) => {
  const {onSuccess} = props;
  const partners = useSelector(selectors.userPartners, shallowEqual);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedValue, setSelectedValue] = useState<string | null>(null);
  const {t} = useTranslation();

  // Get list of unique consumption places
  const places = useMemo(() => {
    if (partners) {
      const places = [] as IPartnerSelectPlace[];
      partners.forEach((partner) => {
        partner.contracts?.forEach((contract) => {
          contract.consumptionPlaces?.forEach((place) => {
            places.push({
              ...place,
              partnerId: partner.id,
              partnerName: partner.name
            });
          });
        });
      });
      return places;
    }
  }, [partners]);

  const getOptionValue = useCallback((place: IPartnerSelectPlace) => {
    return `${place.id},${place.partnerId}`;
  }, []);

  const parseOptionValue = useCallback((optionValue: string) => {
    const [placeId, partnerId] = optionValue.split(',');
    return {
      placeId: parseInt(placeId),
      partnerId: parseInt(partnerId)
    };
  }, []);

  const getSelectedPlace = useCallback(() => {
    return selectedValue && places?.find((place) => {
      const selected = parseOptionValue(selectedValue);
      return (
        place.id === selected.placeId &&
        place.partnerId === selected.partnerId
      );
    });
  }, [parseOptionValue, places, selectedValue]);

  const placeOptions = useMemo(() => {
    return places?.map((place): ComboBoxOption => ({
      label: `${place.partnerName} ${place.name}`,
      value: getOptionValue(place),
      element: (
        <>
          <div
            className="ui-partner-select-form__partner"
            title={place.partnerName}
          >
            {place.partnerName}
          </div>
          <div
            className="ui-partner-select-form__place"
            title={place.name}
          >
            {place.name}
          </div>
        </>
      )
    }));
  }, [getOptionValue, places]);

  const selectPartner = useCallback((partnerId: number) => {
    dispatch(actions.selectPartner({partnerId}));
    navigate(paths.home);
    if (onSuccess)
      onSuccess();
  }, [dispatch, navigate, onSuccess]);
  
  const handlePlaceChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
    setSelectedValue(value as string);
  }, []);

  useWatchEffect(() => {
    if (selectedValue !== null && places) {
      const place = getSelectedPlace();
      if (place)
        selectPartner(place.partnerId);
    }
  }, [selectedValue]);

  return (
    <>
      
      {/* Partner */}
      
      <ComboBox
        label={t('components.user.partner_select_form.partner.label')}
        placeholder={t('components.user.partner_select_form.partner.placeholder')}
        options={placeOptions}
        value={selectedValue}
        onChange={handlePlaceChange}
        virtualizedList
        virtualizedRowHeight={78}
      />
    </>
  );
};