import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {t} from 'lib/intl/i18n';
import {bankAccount as parseBankAccount} from 'lib/intl/parse/bank-account';
import {bankAccountPrefix as validateBankAccountPrefix} from '../account-prefix/account-prefix';
import {bankAccountNumber as validateBankAccountNumber} from '../account-number/account-number';
import {bankAccountCode as validateBankAccountCode} from '../account-code/account-code';

/**
 * Get bank account validator
 *
 * @param isBankCodeRequired - Bank code is mandatory
 */
export const bankAccount = (isBankCodeRequired = true) => {

  /**
   * Validate bank account
   *
   * @param input - Bank account value
   * @returns Error message or undefined
   */
  const bankAccount: IValidator = (input) => {
    const value = String(input || '').trim();
    if (value) {
      const errorMessage = t('lib.global.validate.bank_account.account');
      const bankCodePresence = isBankCodeRequired ? '' : '?';
      // eslint-disable-next-line no-useless-escape
      const accountRegex = new RegExp(`^(([0-9]{0,6})-)?([0-9]{2,10})\/([0-9]{4})${bankCodePresence}$`);
      if (!accountRegex.test(value))
        return errorMessage;
      const account = parseBankAccount(value);
      const invalidPrefix = !!validateBankAccountPrefix(account.accountPrefix);
      const invalidNumber = !!validateBankAccountNumber(account.accountNumber);
      const invalidCode = !!validateBankAccountCode(account.bankCode);
      if (invalidPrefix || invalidNumber || invalidCode)
        return errorMessage;
    }
  };

  return bankAccount;
};

/**
 * Check that the bank account validator doesn't return any error
 *
 * @param input - Bank account value to check
 * @param isBankCodeRequired - Bank code is mandatory
 */
export const isBankAccount: ValidatorQuery = (input, isBankCodeRequired: boolean) => {
  return !!input && !bankAccount(isBankCodeRequired)(input);
};
