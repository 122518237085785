/**
 * @file Locale code helpers
 * 
 * @see https://github.com/meikidd/locale-code#readme
 */

import localeCode from 'locale-code';

/**
 * Extract language code from locale code
 * 
 * @param locale - Locale code
 * @returns Language code
 * 
 * @example
 * 
 *   getLanguageCode('en-US'); // en
 */
export const getLanguageCode = (locale: ISOLocaleCode) => {
  return localeCode.getLanguageCode(locale) as ISOLangCode;
};

/**
 * Extract country code from locale code
 * 
 * @param locale - Locale code
 * @returns Country code
 * 
 * @example
 * 
 *   getCountryCode('en-US'); // US
 */
export const getCountryCode = (locale: ISOLocaleCode) => {
  return localeCode.getCountryCode(locale) as ISOCountryCode;
};

/**
 * Lookup language english name by locale code
 * 
 * @param code - Locale code
 * @returns Language english name
 * 
 * @example
 * 
 *   getLanguageName('en-US'); // English
 */
export const getLanguageName = (code: ISOLocaleCode) => {
  return localeCode.getLanguageName(code);
};

/**
 * Lookup language native name by locale code
 * 
 * @param code - Locale code
 * @returns Language native name
 * 
 * @example
 * 
 *   getLanguageNativeName('ja-JP'); // 日本語
 */
export const getLanguageNativeName = (code: ISOLocaleCode) => {
  return localeCode.getLanguageNativeName(code);
};

/**
 * Get the array of the language objects by the given codes
 * 
 * @param code - Locale code
 * @returns Language native name
 * 
 * @example
 * 
 *   getLanguages(['ja-JP', 'en-US']);
 * 
 *   // [
 *   //   {
 *   //     code: 'ja-JP',
 *   //     name: 'Japanese',
 *   //     nativeName: '日本語'
 *   //   },
 *   //   {
 *   //     code: 'en-US',
 *   //     name: 'English',
 *   //     nativeName: 'English'
 *   //   }
 *   // ]
 */
export const getLanguages = (codes: ISOLocaleCode[]) => {
  return localeCode.getLanguages(codes);
};

/**
 * Lookup country english name by locale code
 * 
 * @param code - Locale code
 * @returns Country english name
 * 
 * @example
 * 
 *   getCountryName('en-US'); // United States
 */
export const getCountryName = (code: ISOLangCode) => {
  return localeCode.getCountryName(code);
};

/**
 * Check whether the given language code is in the list of ISO-639-1
 * 
 * @param code - Locale code
 * @returns Validation result
 * 
 * @example
 * 
 *   validateLanguageCode('en-US'); // true
 *   validateLanguageCode('xy-US'); // false
 */
export const validateLanguageCode = (code: string) => {
  return localeCode.validateLanguageCode(code);
};

/**
 * Check whether the given country code is in the list of ISO-639-1
 * 
 * @param code - Locale code
 * @returns Validation result
 * 
 * @example
 * 
 *   validateCountryCode('en-US'); // true
 *   validateCountryCode('en-XY'); // false
 */
export const validateCountryCode = (code: string) => {
  return localeCode.validateCountryCode(code);
};

/**
 * Check whether the given locale code is in the list of ISO-639-1
 * 
 * @param code - Locale code
 * @returns Validation result
 * 
 * @example
 * 
 *   validateCountryCode('en-US'); // true
 *   validateCountryCode('xx-YY'); // false
 */
export const validateLocaleCode = (code: string) => {
  return localeCode.validate(code);
};
