import './place-consumption.scss';
import React, {FunctionComponent} from 'react';
import {IPlaceConsumptionProps} from './place-consumption.types';
import {PlaceConsumptionFilter, IPlaceConsumptionFilterValues} from './place-consumption-filter';
import {useFilterState, FilterOutput} from 'components/ui/filter';
import {PlaceConsumptionChart} from './place-consumption-chart';

/** Consumption chart */
export const PlaceConsumption: FunctionComponent<IPlaceConsumptionProps> = (props) => {
  const {consumptionFilter} = props;
  const [filter, setFilter] = useFilterState<IPlaceConsumptionFilterValues>();

  return (
    <>
      <PlaceConsumptionFilter onChange={setFilter} />
      <FilterOutput
        filter={filter}
        className="ui-place-consumption__content"
      >
        {consumptionFilter.isComplete && (
          <PlaceConsumptionChart
            chartFilter={filter}
            consumptionFilter={consumptionFilter}
          />
        )}
      </FilterOutput>
    </>
  );
};
