import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IDocumentDownloadRequest} from './document-download.types';

/** Document download */
export const documentDownload = async (request: IDocumentDownloadRequest) => {
  const response = await axios.get<Blob>(urls.documentDownload(request.documentId), {
    headers: {
      ...headers.auth(),
      'Accept': 'application/octet-stream'
    },
    responseType: 'blob'
  });
  return response.data;
};
