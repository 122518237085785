import './preloader-kit.scss';
import React, {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {ButtonActions} from 'components/ui/button-actions';
import {Typography} from 'components/ui/typography';
import {Button} from 'components/ui/button';
import {Preloader} from 'components/ui/preloader';
import InlineIcon from '@material-ui/icons/HourglassEmpty';
import CrossfadeIcon from '@material-ui/icons/FilterBAndW';
import FullscreenIcon from '@material-ui/icons/ZoomOutMap';
import ContainerIcon from '@material-ui/icons/Fullscreen';

const PRELOADER_TIMEOUT = 1200;

/** Preloader control preview */
export const PreloaderKit: FunctionComponent = () => {
  const [inlineVisible, setInlineVisible] = useState(false);
  const [crossfadeVisible, setCrossfadeVisible] = useState(false);
  const [fullscreenVisible, setFullscreenVisible] = useState(false);
  const [containerVisible, setContainerVisible] = useState(false);
  const {t} = useTranslation();

  const showInline = useCallback(() => {
    if (!inlineVisible)
      setTimeout(() => setInlineVisible(false), PRELOADER_TIMEOUT);
    setInlineVisible(true);
  }, [inlineVisible]);

  const showCrossfade = useCallback(() => {
    if (!crossfadeVisible)
      setTimeout(() => setCrossfadeVisible(false), PRELOADER_TIMEOUT);
    setCrossfadeVisible(true);
  }, [crossfadeVisible]);

  const showFullscreen = useCallback(() => {
    if (!fullscreenVisible)
      setTimeout(() => setFullscreenVisible(false), PRELOADER_TIMEOUT);
    setFullscreenVisible(true);
  }, [fullscreenVisible]);

  const showContainer = useCallback(() => {
    if (!containerVisible)
      setTimeout(() => setContainerVisible(false), PRELOADER_TIMEOUT);
    setContainerVisible(true);
  }, [containerVisible]);
  
  return (
    <div className="ui-preloader-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.preloader.heading')}
      </Typography>
      <ButtonActions rows>

        {/* Inline */}

        <Button
          icon={<InlineIcon />}
          pending={inlineVisible}
          pendingAnimation="inline"
          onClick={showInline}
        >
          {t('views.app.ui_kit.preloader.type.inline')}
        </Button>

        {/* Crossfade */}

        <Button
          icon={<CrossfadeIcon />}
          pending={crossfadeVisible}
          onClick={showCrossfade}
        >
          {t('views.app.ui_kit.preloader.type.crossfade')}
        </Button>

        {/* Fullscreen */}

        <Button
          icon={<FullscreenIcon />}
          onClick={showFullscreen}
        >
          {t('views.app.ui_kit.preloader.type.fullscreen')}
        </Button>

        {/* Container */}

        <Button
          icon={<ContainerIcon />}
          onClick={showContainer}
        >
          {t('views.app.ui_kit.preloader.type.container')}
        </Button>
      </ButtonActions>
      <Preloader
        display="fullscreen"
        visible={fullscreenVisible}
      />
      <div className="ui-preloader-kit__container">
        <Preloader
          display="container"
          visible={containerVisible}
        />
      </div>
    </div>
  );
};
