import {intlApi} from 'lib/intl/intl-api';
import {IFormatNumberOptions} from './number.types';

/**
 * Format number
 *
 * @example
 *
 *   format.number(1234567, {precision: 2});
 *   // 1,234,567.00
 *
 * @param value - Value to format
 * @param [precision] - Number of decimal digits
 * @returns Formatted number
 */
export const number = (value: number, options: IFormatNumberOptions = {}) => {
  const {precision, useGrouping} = options;
  if (precision !== undefined)
    return intlApi.formatNumber(value, {
      style: 'decimal',
      minimumFractionDigits: precision,
      maximumFractionDigits: precision,
      useGrouping
    });
  return intlApi.formatNumber(value, {
    style: 'decimal',
    minimumFractionDigits: 0,
    maximumFractionDigits: 10,
    useGrouping
  });
};
