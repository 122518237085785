import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {phone as parsePhone} from 'lib/intl/parse/phone';
import {t} from 'lib/intl/i18n';

/**
 * Validate phone number
 *
 * @param input - Phone number to validate
 * @returns Error message or undefined
 */
export const phone: IValidator = (input) => {
  const value = String(input || '').trim();
  if (value) {
    const errorMessage = t('lib.global.validate.phone');
    try {
      const parsedPhone = parsePhone(value);
      const allowedChars = /\d|\s|\+/g;
      const invalidLength = parsedPhone && parsedPhone.nationalNumber.length !== 9;
      const validChars = value && value.match(allowedChars);
      const hasInvalidChars = value && (!validChars || validChars.length !== value.length);
      if (value && (invalidLength || hasInvalidChars))
        return errorMessage;
    }
    catch {
      return errorMessage;
    }
  }
};

/**
 * Check that the phone validator doesn't return any error
 *
 * @param input - Phone number to check
 */
export const isPhone: ValidatorQuery = (input) => {
  return !!input && !phone(input);
};
