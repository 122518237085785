import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {time as parseTime} from 'lib/intl/parse/time';
import {t} from 'lib/intl/i18n';

/**
 * Validate time value
 *
 * @param input - Time to validate
 * @returns Error message or undefined
 */
export const time: IValidator<AnyDate> = (input) => {
  if (input) {
    const timeIsValid = parseTime(input).isValid();
    if (!timeIsValid)
      return t('lib.global.validate.time.invalid');
  }
};

/**
 * Check that the time validator doesn't return any error
 *
 * @param input - Time to validate
 */
export const isTime: ValidatorQuery<AnyDate> = (input) => {
  return !!input && !time(input);
};
