import {
  IDemandTypeListRequestAction,
  IDemandTypeListSuccessAction,
  IDemandTypeListFailureAction,
  DemandTypeListActionTypes
} from './demand-type-list.types';
import {DemandTypeListResponse} from 'api';

/** Fetch demand type list */
export const demandTypeListRequest = (): IDemandTypeListRequestAction => ({
  type: DemandTypeListActionTypes.request
});

export const demandTypeListSuccess = (payload: DemandTypeListResponse): IDemandTypeListSuccessAction => ({
  type: DemandTypeListActionTypes.success,
  payload
});

export const demandTypeListFailure = (payload: IRequestError): IDemandTypeListFailureAction => ({
  type: DemandTypeListActionTypes.failure,
  payload
});