import {useState} from 'react';
import {UseFilterStateProps} from './use-filter-state.types';
import {FilterValues} from '../with-filter.types';
import {FILTER_MESSAGE} from '../with-filter';
import {IFilterState} from '../with-filter.types';

/**
 * Use filter state
 * 
 * @example
 * 
 *   import {IMyFilterValues} from './my-filter';
 *   import {useFilter} from 'components/ui/filter';
 * 
 *   // ...
 * 
 *   const [filter, setFilter] = useFilterState<IMyFilterValues>();
 */
export const useFilterState = <T extends FilterValues<T>>(): UseFilterStateProps<T> => {
  return useState<IFilterState<T>>({
    values: {} as T,
    isComplete: false,
    isPending: false,
    [FILTER_MESSAGE]: null 
  });
};