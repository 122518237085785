import React, {FunctionComponent, useMemo} from 'react';
import {useTheme} from '../use-theme';
import {IThemeContext} from '../theme.types';
import {MuiThemeProvider, ThemeOptions, createMuiTheme} from '@material-ui/core/styles';
import {StylesProvider, createGenerateClassName} from '@material-ui/core/styles';
import {SnackbarProvider} from 'components/ui/snackbar';
import {MuiPickersProvider} from './mui-pickers-provider';
import StyledEngineProvider from '@material-ui/core/StyledEngineProvider';

// Custom breakpoints
declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

/** Convert values extracted from the SASS files into Material-UI theme format */
const getMuiTheme = (theme: IThemeContext): ThemeOptions => {
  const muiTheme: ThemeOptions = {

    //--- Palette

    palette: {
      primary: {
        main: theme.color.primary
      },
      secondary: {
        main: theme.color.secondary
      },
      success: {
        main: theme.color.success
      },
      warning: {
        main: theme.color.warning
      },
      error: {
        main: theme.color.error
      },
      text: {
        primary: theme.color.text
      },
      background: {
        paper: theme.color.paper
      }
    },

    //--- Breakpoints

    breakpoints: {
      values: {
        xs: theme.width.xsMin,
        sm: theme.width.smMin,
        md: theme.width.mdMin,
        lg: theme.width.lgMin,
        xl: theme.width.xlMin,
        xxl: theme.width.xxlMin
      }
    },

    //--- Typography

    typography: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      body1: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.fontSize,
        lineHeight: theme.typography.lineHeight
      },
      body2: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.smallFontSize,
        lineHeight: theme.typography.lineHeight
      },
      h1: {
        fontSize: theme.typography.h1.fontSize,
        fontWeight: theme.typography.h1.fontWeight,
        lineHeight: theme.typography.h1.lineHeight
      },
      h2: {
        fontSize: theme.typography.h2.fontSize,
        fontWeight: theme.typography.h2.fontWeight,
        lineHeight: theme.typography.h2.lineHeight
      },
      h3: {
        fontSize: theme.typography.h3.fontSize,
        fontWeight: theme.typography.h3.fontWeight,
        lineHeight: theme.typography.h3.lineHeight
      }
    }
  };
  return createMuiTheme(muiTheme);
};

/**
 * Material UI providers
 *
 * @see https://material-ui.com
 */
export const MaterialUI: FunctionComponent = (props) => {
  const {children} = props;
  const theme = useTheme();
  
  const muiTheme = useMemo(() => {
    return getMuiTheme(theme);
  }, [theme]);

  const generateClassName = useMemo(() => {
    return createGenerateClassName();
  }, []);

  return (
    
    <MuiThemeProvider theme={muiTheme}>
      <StyledEngineProvider injectFirst>
        <StylesProvider generateClassName={generateClassName}>
          <SnackbarProvider>
            <MuiPickersProvider>
              {children}
            </MuiPickersProvider>
          </SnackbarProvider>
        </StylesProvider>
      </StyledEngineProvider>
    </MuiThemeProvider>
  );
};
