import {Reducer} from 'redux';
import {InvoiceStatusListAction, InvoiceStatusListActionTypes} from 'actions';
import {IInvoiceStatusListState} from './invoice-status-list.types';

const initialState: IInvoiceStatusListState = {
  pending: false,
  error: null,
  data: null
};

/** List of invoice statuses */
export const invoiceStatusList: Reducer<IInvoiceStatusListState, InvoiceStatusListAction> = (state = initialState, action): IInvoiceStatusListState => {
  switch (action.type) {
    case InvoiceStatusListActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case InvoiceStatusListActionTypes.success:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case InvoiceStatusListActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};