import {AppState} from 'reducers';
import {userIsLoggedIn} from 'selectors';

/** Select access token */
export const accessToken = (state: AppState) => {
  return state.auth.accessToken;
};

/** Select access token type */
export const tokenType = (state: AppState) => {
  return state.auth.tokenType;
};

/** Check if user is authenticating */
export const accessTokenPending = (state: AppState) => {
  return state.auth.tokenPending;
};

/** Check if authentication failed */
export const accessTokenError = (state: AppState) => {
  return state.auth.tokenError;
};

/** Select refresh token */
export const refreshToken = (state: AppState) => {
  return state.auth.refreshToken;
};

/** Check if token is refreshing */
export const tokenRefreshPending = (state: AppState) => {
  return state.auth.refreshPending;
};

/** Check if token refresh failed */
export const tokenRefreshError = (state: AppState) => {
  return state.auth.refreshError;
};

/** Check if access token has expired */
export const accessTokenExpired = (state: AppState) => {
  return userIsLoggedIn(state) && (!state.auth.expiresAt || state.auth.expiresAt - Date.now() <= 0);
};

/** Check if refresh token has expired */
export const refreshTokenExpired = (state: AppState) => {
  return userIsLoggedIn(state) && (!state.auth.refreshToken || !!state.auth.refreshError);
};

/** Check if user is authenticated */
export const userIsAuthenticated = (state: AppState) => {
  return !refreshTokenExpired(state);
};
