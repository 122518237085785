import React, {useContext, FunctionComponent, ChangeEvent, useCallback} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {FilterContext} from '../invoices-filter-context';
import {FilterCheckbox} from 'components/ui/filter-checkbox';

/** Invoices filter unpaid invoices checkbox */
export const UnpaidCheckbox: FunctionComponent = () => {
  const {
    getValues,
    setAndStoreValues,
    FilterField
  } = useContext(FilterContext);
  const {unpaid} = getValues();
  const {t} = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setAndStoreValues({unpaid: checked});
  }, [setAndStoreValues]);

  return (
    <FilterField>
      <FilterCheckbox
        label={t('views.invoice.filter.unpaid')}
        checked={unpaid}
        onChange={handleChange}
      />
    </FilterField>
  );
};