import './pagination-input.scss';
import React, {
  FunctionComponent,
  useContext,
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
  KeyboardEvent,
  FocusEvent, useMemo
} from 'react';
import keycode from 'keycode';
import * as Css from 'csstype';
import {useTheme} from 'components/ui/theme';
import {PaginationContext} from '../pagination-context';
import {TextField} from 'components/ui/text-field';

/** Pagination page input */
export const PaginationInput: FunctionComponent = () => {
  const {currentPage, handlePageChange} = useContext(PaginationContext);
  const [value, setValue] = useState(String(currentPage));
  const theme = useTheme();

  useEffect(() => {
    setValue(String(currentPage));
  }, [currentPage]);
  
  const resetValue = useCallback(() => {
    setValue(String(currentPage));
  }, [currentPage]);
  
  const confirmValue = useCallback(() => {
    const page = value ? parseInt(value, 10) : 1;
    if (page === currentPage)
      resetValue();
    else
      handlePageChange(page);
  }, [currentPage, handlePageChange, resetValue, value]);
  
  const handleKeyDown = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    if (event.keyCode === keycode.aliases.escape)
      resetValue();
    if (event.keyCode === keycode.aliases.return)
      confirmValue();
  }, [confirmValue, resetValue]);
  
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  }, []);

  const filterNumbers = useCallback((event: KeyboardEvent<HTMLInputElement>) => {
    const isNumber = event.charCode >= keycode.codes['0'] && event.charCode <= keycode.codes['9'];
    if (!isNumber)
      event.preventDefault();
  }, []);

  const selectTextOnFocus = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.target.select();
  }, []);

  const resetValueOnBlur = useCallback(() => {
    resetValue();
  }, [resetValue]);
  
  const dynamicWidth = useMemo<Css.Properties>(() => ({
    width: `calc(${value.length}ch + ${theme.spacing[2] + 2}px)`
  }), [theme.spacing, value.length]);
  
  return (
    <TextField
      className="ui-pagination-input"
      align="center"
      fullWidth={false}
      value={value}
      style={dynamicWidth}
      onKeyDown={handleKeyDown}
      onKeyPress={filterNumbers}
      onChange={handleChange}
      onFocus={selectTextOnFocus}
      onBlur={resetValueOnBlur}
    />
  );
};
