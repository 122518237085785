import './tabs.scss';
import React, {forwardRef, useMemo} from 'react';
import {TabsProps} from './tabs.types';
import MuiTabs from '@material-ui/core/Tabs';

/**
 * Tabs container
 * 
 * @see src/views/app/kit-view/tabs-kit
 * 
 * @example
 * 
 *   import {Tabs, Tab} from 'components/ui/tabs';
 * 
 *   // ...
 *  
 *   const [value, setValue] = useState(0);
 * 
 *   const handleChange = useCallback((event: ChangeEvent<unknown>, newValue: number) => {
 *     setValue(newValue);
 *   }, []);
 * 
 *   // ...
 *  
 *   <Tabs
 *     value={value}
 *     onChange={handleChange}
 *   >
 *     <Tab label="First tab" />
 *     <Tab label="Second tab" />
 *     <Tab label="Third tab" />
 *   </Tabs>
 */
export const Tabs = forwardRef<HTMLButtonElement, TabsProps>(function Tabs(props, ref) {
  const {className, children, ...restProps} = {...props};
  
  const classes = useMemo(() => {
    let classes = 'ui-tabs';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <MuiTabs
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiTabs>
  );
});

Tabs.defaultProps = {
  textColor: 'inherit'
};