import {call, put, takeLatest, select} from 'typed-redux-saga/macro';
import {waitForFetch} from 'sagas/api';
import {actions} from 'actions';
import {AxiosRequestConfig} from 'axios';
import {axios, headers} from 'lib/api/axios';
import {selectors} from 'selectors';

let failedRequests: AxiosRequestConfig[] = [];

/** Retry all previously failed requests on token refresh */
const authRefreshSuccessSaga = function* () {
  for (const requestConfig of failedRequests)
    yield call(axios.request, {
      ...requestConfig,
      headers: {
        ...requestConfig.headers,
        ...headers.auth()
      }
    });
  failedRequests = [];
};

/** Logout user if token refresh fails */
const authRefreshFailureSaga = function* () {
  failedRequests = [];
  yield put(actions.logoutRequest({
    logoutReason: 'tokenRefreshFail'
  }));
};

/** Store failed request and trigger token refresh */
const authRetryRequestSaga = function* (action: actions.IRetryRequestAction) {
  failedRequests.push(action.payload);
  const refreshing = yield* select(selectors.tokenRefreshPending);
  if (!refreshing)
    try {
      yield put(actions.refreshRequest());
      yield waitForFetch(actions.RefreshActionTypes);
      yield* authRefreshSuccessSaga();
    }
    catch {
      yield* authRefreshFailureSaga();
    }
};

export const authRetry = function* () {
  yield takeLatest(actions.RetryActionTypes.retry, authRetryRequestSaga);
};