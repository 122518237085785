import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {AccessDemandRolesResponse} from './access-demand-roles.types';

/** Get a list of access demand user roles */
export const accessDemandRoles = async () => {
  const response = await axios.get<AccessDemandRolesResponse>(urls.accessDemandRoles(), {
    headers: {
      ...headers.language(),
      ...headers.auth()
    }
  });
  return response.data;
};