import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useSelector, shallowEqual, useDispatch} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {useQueryParam, StringParam} from 'use-query-params';
import {getErrorMessage} from 'lib/api/axios';
import {IFormValues} from './password-reset-confirm-form.types';
import {useOnFetchFinish} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {useSnackbar} from 'components/ui/snackbar';
import {IPasswordResetConfirmFormProps} from './password-reset-confirm-form.types';
import {Form} from 'components/ui/form';
import {PasswordResetConfirmFormFields} from './password-reset-confirm-form-fields';

/** Password reset confirmation form */
export const PasswordResetConfirmForm: FunctionComponent<IPasswordResetConfirmFormProps> = (props) => {
  const {onSuccess} = props;
  const [guid] = useQueryParam('guid', StringParam);
  const {showSnackbar} = useSnackbar();
  const passwordResetConfirm = useSelector(selectors.passwordResetConfirm, shallowEqual);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  
  const initialValues = useMemo<IFormValues>(() => ({
    password: '',
    passwordConfirm: ''
  }), []);

  const handleSubmit = useCallback((values: IFormValues) => {
    dispatch(actions.passwordResetConfirmRequest({
      password: values.password,
      guid: guid || ''
    }));
  }, [dispatch, guid]);

  useOnFetchFinish([passwordResetConfirm], () => {
    const {error} = passwordResetConfirm;
    if (error) {
      const message = getErrorMessage(error, t('views.user.password_reset_confirm.form.failure'));
      showSnackbar(message, {variant: 'error'});
    }
    else {
      showSnackbar(t('views.user.password_reset_confirm.form.success'), {variant: 'success'});
      if (onSuccess)
        onSuccess();
    }
  });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <PasswordResetConfirmFormFields />
    </Form>
  );
};