import React, {useCallback, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useSnackbar as nsUseSnackbar, OptionsObject as NsSnackbarOptions} from 'notistack';
import {ISnackbarOptions, IUseSnackbarProps, SnackbarId} from './use-snackbar.types';
import {IconButton} from 'components/ui/icon-button';
import CloseIcon from '@material-ui/icons/Close';
import uniqueId from 'uniqid';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {store} from 'store';

const defaultOptions: ISnackbarOptions = {
  variant: 'info',
  closeIcon: true
};

/**
 * Show or hide snackbar alerts
 *
 * @see ./snackbar-provider
 * @see src/views/app/kit-view/snackbar-kit
 *
 * @example
 * 
 *   import {useSnackbar} from 'components/ui/snackbar';
 * 
 *   // ...
 *
 *   const {showSnackbar} = useSnackbar();
 *
 *   const showMessage = useCallback(() => {
 *     showSnackbar('Hello world!');
 *   }, []);
 *
 *   // ...
 *
 *   <Button onClick={showMessage}>
 *     Show message
 *   </Button>
 *
 * @example
 *
 *   import {useSnackbar, ISnackbarOptions} from 'components/ui/snackbar';
 *
 *   // ...
 *
 *   const {showSnackbar} = useSnackbar();
 *
 *   const showMessage = useCallback((message: string, options?: ISnackbarOptions) => {
 *     showSnackbar(message, options);
 *   }, []);
 *
 *   // ...
 */
export const useSnackbar = () => {
  const {enqueueSnackbar, closeSnackbar} = nsUseSnackbar();
  const snackbars = useSelector(selectors.snackbar);
  const dispatch = useDispatch();

  /**
   * Hide snackbar with a given ID
   *
   * @example
   *
   *   {showSnackbar, hideSnackbar} = useSnackbar();
   *   const mySnackbarId = 'my-snackbar';
   *
   *   // ...
   *
   *   showSnackbar('Hello world!', {id: mySnackbarId});
   *
   *   // ...
   *
   *   hideSnackbar(mySnackbarId);
   */
  const hideSnackbar = useCallback((id: SnackbarId) => {
    const snackbars = selectors.snackbar(store.getState());
    const snackbar = snackbars.find((snackbar) => snackbar.id === id);
    if (snackbar) {
      closeSnackbar(snackbar.key);
      dispatch(actions.snackbarHide({id}));
    }
  }, [dispatch, closeSnackbar]);

  const hideAllSnackbars = useCallback(() => {
    closeSnackbar();
  }, [closeSnackbar]);

  const renderCloseIcon = useCallback((id: SnackbarId) => function SnackbarCloseIcon() {
    return (
      <IconButton
        size="small"
        onClick={() => hideSnackbar(id)}
      >
        <CloseIcon />
      </IconButton>
    );
  }, [hideSnackbar]);

  /**
   * Open snackbar
   *
   * @example
   *
   *   const {showSnackbar} = useSnackbar();
   *
   *   // ...
   *
   *   showSnackbar('Hello world!');
   */
  const showSnackbar = useCallback((message: string, options: ISnackbarOptions = {}) => {
    const {position, ...restOptions} = options;
    const mergedOptions: ISnackbarOptions = {...defaultOptions, ...restOptions};
    mergedOptions.id = mergedOptions.id || uniqueId();
    const {id, closeIcon, ...restProps} = mergedOptions;
    const nsOptions: NsSnackbarOptions = restProps;
    nsOptions.id = String(id);
    if (position)
      nsOptions.anchorOrigin = position;
    if (closeIcon)
      nsOptions.action = renderCloseIcon(id);
    const key = enqueueSnackbar(message, nsOptions);
    dispatch(actions.snackbarShow({id, key}));
    return id;
  }, [enqueueSnackbar, renderCloseIcon, dispatch]);

  /**
   * Check whether snackbar with a given ID is open
   *
   * @example
   *
   *   const {showSnackbar, isSnackbarOpen} = useSnackbar();
   *   const mySnackbarId = 'my-snackbar';
   *
   *   // ...
   *
   *   showSnackbar('Hello world!', {id: mySnackbarId});
   *
   *   // ...
   *
   *   const snackbarIsOpen = isSnackbarOpen(mySnackbarId);
   */
  const isSnackbarOpen = useCallback((id: SnackbarId) => {
    return snackbars.some((snackbar) => snackbar.id === id);
  }, [snackbars]);

  return useMemo<IUseSnackbarProps>(() => ({
    showSnackbar,
    hideSnackbar,
    hideAllSnackbars,
    isSnackbarOpen
  }), [showSnackbar, hideSnackbar, hideAllSnackbars, isSnackbarOpen]);
};
