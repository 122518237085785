import './user-settings.scss';
import React, {FunctionComponent, useCallback, useEffect, useState, useMemo} from 'react';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';
import {RevealFade} from 'components/ui/reveal-fade';
import {Card} from 'components/ui/card';
import {CardHeader} from 'components/ui/card-header';
import {CardContent} from 'components/ui/card-content';
import {CardActions} from 'components/ui/card-actions';
import {DescriptionList, DescriptionLabel, DescriptionValue} from 'components/ui/description-list';
import UserIcon from '@material-ui/icons/Person';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import PasswordIcon from '@material-ui/icons/VpnKey';
import {PasswordChangeDialog} from 'components/user/password-change-dialog';

/** User settings */
export const UserSettings: FunctionComponent = () => {
  const userDetail = useSelector(selectors.userDetail, shallowEqual);
  const placeList = useFetch(api.consumptionPlaceList);
  const contractList = useFetch(api.contractList);

  const [dialogOpen, setDialogOpen] = useState(false);
  const {t} = useTranslation();

  const toggleDialog = useCallback(() => {
    setDialogOpen((open) => !open);
  }, []);

  const fetchData = useCallback(() => {
    placeList.fetch({size: 1});
    contractList.fetch();
  }, [contractList, placeList]);

  const pending = useMemo(() => {
    return placeList.pending || contractList.pending;
  }, [contractList.pending, placeList.pending]);

  const error = useMemo(() => {
    return placeList.error || contractList.error;
  }, [contractList.error, placeList.error]);

  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const renderContent = useCallback(() => (
    <DescriptionList>

      {/* User name */}

      <DescriptionLabel>
        {t('views.user.settings.user.label.user_name')}
      </DescriptionLabel>
      <DescriptionValue>
        {userDetail?.email}
      </DescriptionValue>

      {/* Partner count */}

      <DescriptionLabel>
        {t('views.user.settings.user.label.partner_count')}
      </DescriptionLabel>
      <DescriptionValue>
        {userDetail?.partners.length}
      </DescriptionValue>

      {/* Contract count */}

      <DescriptionLabel>
        {t('views.user.settings.user.label.contract_count')}
      </DescriptionLabel>
      <DescriptionValue>
        {contractList.data?.length}
      </DescriptionValue>

      {/* Count of consumption places */}

      <DescriptionLabel>
        {t('views.user.settings.user.label.places_count')}
      </DescriptionLabel>
      <DescriptionValue>
        {placeList.data?.totalElements}
      </DescriptionValue>
    </DescriptionList>
  ), [contractList, placeList, t, userDetail]);

  const renderActions = useCallback(() => (
    <CardActions>
      <ButtonActions align="center">
        <Button
          icon={<PasswordIcon />}
          onClick={toggleDialog}
        >
          {t('views.user.settings.user.button.password')}
        </Button>
        <PasswordChangeDialog
          open={dialogOpen}
          onClose={toggleDialog}
        />            
      </ButtonActions>
    </CardActions>
  ), [dialogOpen, t, toggleDialog]);

  return (
    <RevealFade
      bottom
      fullHeight
    >
      <Card
        fullHeight
        className="ui-user-settings"
      >
        <CardHeader
          icon={<UserIcon />}
          title={t('views.user.settings.user.title')}
        />
        <CardContent className="ui-user-settings__content">
          <WaitForFetch
            pending={pending}
            error={error}
            noData={false}
            onRetry={fetchData}
            render={renderContent}
            absolutePosition
          />
        </CardContent>
        <WaitForFetch
          pending={pending}
          error={error}
          silent
          noData={false}
          render={renderActions}
        />
      </Card>
    </RevealFade>
  );
};