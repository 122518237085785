import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {t} from 'lib/intl/i18n';

/**
 * Validate required value
 *
 * @param input - Value to validate
 * @returns Error message or undefined
 */
export const required: IValidator<FormFieldValue> = (input) => {
  const isEmpty = input === null || input === undefined || typeof input === 'string' && !input.trim();
  const isUnchecked = typeof input === 'boolean' && input !== true;
  if (isEmpty || isUnchecked)
    return t('lib.global.validate.required');
};

/**
 * Check that the required value validator doesn't return any error
 *
 * @param input - Value to check
 */
export const isFilled: ValidatorQuery<FormFieldValue> = (input) => {
  return !!input && !required(input);
};
