import {useCallback, useMemo} from 'react';
import {
  IFormValues,
  IUseLoginFormSubmitHandler,
  IUseLoginFormProps
} from './use-login-form.types';
import {useDispatch, useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {useLoginError} from 'hooks';
import {useQueryParam, StringParam} from 'use-query-params';

/**
 * Login form logic
 * 
 * @example
 * 
 *   import {useLoginForm} from 'hooks';
 *   import {Form} from 'components/ui/form';
 *   import {FormTextField} from 'components/ui/form-text-field';
 *   import {Button} from 'components/ui/button';
 *   
 *   // ...
 *   
 *   const {initialValues, handleSubmit} = useLoginForm();
 *   
 *   // ...
 *   
 *   <Form
 *     initialValues={initialValues}
 *     onSubmit={handleSubmit}
 *   >
 *     <FormTextField
 *       name="login"
 *       label="Login"
 *     />
 *     <FormTextField
 *       name="password"
 *       label="Password"
 *     />
 *     <Button type="submit">
 *       Login
 *     </Button>
 *   </Form>
 */
export const useLoginForm = (loginFormId: string): IUseLoginFormProps => {
  const loginPending = useSelector(selectors.loginPending);
  const loginError = useLoginError(loginFormId);
  const dispatch = useDispatch();
  const [queryLogin] = useQueryParam('login', StringParam);
  
  const initialValues = useMemo<IFormValues>(() => ({
    login: queryLogin || '',
    password: ''
  }), [queryLogin]);

  const handleSubmit = useCallback<IUseLoginFormSubmitHandler>((values) => {
    const {login, password} = values;
    dispatch(actions.loginRequest({
      userName: login,
      password,
      loginFormId
    }));
  }, [dispatch, loginFormId]);

  return useMemo(() => ({
    initialValues,
    handleSubmit,
    loginPending,
    loginError
  }), [loginPending, handleSubmit, initialValues, loginError]);
};