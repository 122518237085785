import './tooltip-kit.scss';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Tooltip} from 'components/ui/tooltip';
import {TooltipBox} from './tooltip-box';
import UpIcon from '@material-ui/icons/ArrowUpward';
import DownIcon from '@material-ui/icons/ArrowDownward';
import LeftIcon from '@material-ui/icons/ArrowBack';
import RightIcon from '@material-ui/icons/ArrowForward';

/** Tooltip control preview */
export const TooltipKit: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <div className="ui-tooltip-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.tooltip.heading')}
      </Typography>
      <div className="ui-tooltip-kit__boxes">
        <div className="ui-tooltip-kit__directions">

          {/* Vertical */}

          <div className="ui-tooltip-kit__group">

            {/* Top */}

            <Tooltip
              title={t('views.app.ui_kit.tooltip.position.top')}
              placement="top"
            >
              <TooltipBox>
                <UpIcon />
              </TooltipBox>
            </Tooltip>

            {/* Bottom */}

            <Tooltip
              title={t('views.app.ui_kit.tooltip.position.bottom')}
              placement="bottom"
            >
              <TooltipBox>
                <DownIcon />
              </TooltipBox>
            </Tooltip>
          </div>

          {/* Horizontal */}

          <div className="ui-tooltip-kit__group">

            {/* Left */}

            <Tooltip
              title={t('views.app.ui_kit.tooltip.position.left')}
              placement="left"
            >
              <TooltipBox>
                <LeftIcon />
              </TooltipBox>
            </Tooltip>

            {/* Right */}

            <Tooltip
              title={t('views.app.ui_kit.tooltip.position.right')}
              placement="right"
            >
              <TooltipBox>
                <RightIcon />
              </TooltipBox>
            </Tooltip>
          </div>
        </div>

        {/* HTML */}

        <Tooltip
          title={(
            <>
              {t('views.app.ui_kit.tooltip.html.content')}{' '}
              <b>{t('views.app.ui_kit.tooltip.html.content')}</b>{' '}
              <u>{t('views.app.ui_kit.tooltip.html.content')}</u>{' '}
              <i>{t('views.app.ui_kit.tooltip.html.content')}</i>
            </>
          )}
          placement="top"
        >
          <TooltipBox className="ui-tooltip-kit__html">
            <b>{t('views.app.ui_kit.tooltip.html.label')}</b>
          </TooltipBox>
        </Tooltip>
      </div>
    </div>
  );
};
