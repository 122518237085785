import './sidebar.scss';
import React, {forwardRef, useMemo} from 'react';
import {ISidebarProps} from './sidebar.types';
import Drawer from '@material-ui/core/Drawer';
import {Typography} from 'components/ui/typography';
import Divider from '@material-ui/core/Divider';
import CloseIcon from '@material-ui/icons/Close';
import {IconButton} from 'components/ui/icon-button';

/**
 * Sidebar panel
 *
 * @see src/views/app/kit-view/sidebar-kit
 * 
 * @example
 *
 *   <Sidebar
 *     side="right"
 *     open={true}
 *   >
 *     Hello sidebar!
 *   </Sidebar>
 */
export const Sidebar = forwardRef<HTMLDivElement, ISidebarProps>(function Sidebar(props, ref) {
  const {
    className,
    heading,
    side,
    open,
    onClose,
    children,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-sidebar';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <Drawer
      {...restProps}
      ref={ref}
      className={classes}
      PaperProps={{className: 'ui-sidebar__panel'}}
      anchor={side}
      open={open}
      onClose={onClose}
    >
      <div className="ui-sidebar__heading">
        <Typography variant="h2">
          {heading}
        </Typography>
        <IconButton
          className="ui-sidebar__close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
      </div>
      {children}
    </Drawer>
  );
});

Sidebar.defaultProps = {
  side: 'left'
};
