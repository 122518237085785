import './payment-status.scss';
import React, {FunctionComponent, ReactElement, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';
import {IPaymentStatusProps} from './payment-status.types';
import UnpairedIcon from '@material-ui/icons/Error';
import PairedIcon from '@material-ui/icons/CheckCircle';

const UNKNOWN_STATUS = 'unknown';

const statusIcons: Record<string, ReactElement> = {
  unpaired: (<UnpairedIcon />),
  paired: (<PairedIcon />)
};

/** Payment status */
export const PaymentStatus: FunctionComponent<IPaymentStatusProps> = (props) => {
  const {className, statusId: statusIdProp} = props;
  const paymentStatusList = useSelector(selectors.paymentStatusList, shallowEqual);  
  const {t} = useTranslation();

  const status = useMemo(() => {
    return paymentStatusList.data?.find((status) => status.id === statusIdProp);
  }, [paymentStatusList.data, statusIdProp]);

  const statusId = useMemo(() => {
    return status ? statusIdProp : UNKNOWN_STATUS;
  }, [status, statusIdProp]);

  const statusDescription = useMemo(() => {
    if (status)
      return status.name;
    return t('components.payment.payments_table.unknown_status');
  }, [status, t]);

  const classes = useMemo(() => {
    let classes = 'ui-payment-status';
    classes += ` ui-payment-status--${statusId.replace(/_/g, '-')}`;
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, statusId]);   

  return (
    <div className={classes}>
      {statusIcons[statusId]}
      {statusDescription}
    </div>
  );
};