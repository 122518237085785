import {useState, useCallback, useMemo} from 'react';
import {IUseFilterFetchProps} from './use-filter-fetch.types';

/** Filter fetch logic */
export const useFilterFetch = (): IUseFilterFetchProps => {
  const [error, setError] = useState<IRequestError | null>(null);
  const [pendingFieldsCount, setPendingFieldsCount] = useState(0);
  const pending = pendingFieldsCount > 0;

  /**
   * Set filter field pending state
   *
   * @param pending - Pending state
   */
  const setPending = useCallback((pending) => {
    setPendingFieldsCount((prevCount) => {
      if (pending)
        return prevCount + 1;
      else if (prevCount > 0)
        return prevCount - 1;
      return prevCount;
    });
  }, []);

  return useMemo(() => ({
    pending,
    setPending,
    error,
    setError
  }), [error, pending, setPending]);
};