import {useState, useCallback, useMemo} from 'react';
import {IUseRemountProps} from './use-remount.types';

/**
 * Trigger component remount
 *
 * @example
 *
 *   const remount = useRemount();
 * 
 *   // ...
 *
 *   return (
 *     <div {...remount.props}>
 *       <button onClick={remount.trigger}>
 *         Click to remount
 *       </button>
 *     </div>
 *   );
 */
export const useRemount = () => {
  const [key, setKey] = useState(0);

  const trigger = useCallback(() => {
    setKey((key) => key + 1);
  }, []);

  const props: IUseRemountProps = useMemo(() => ({
    props: {key},
    trigger
  }), [key, trigger]);
  
  return props;
};
