import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {InvoiceCommodityListResponse} from './invoice-commodity-list.types';

/** Get a list of invoice commodities */
export const invoiceCommodityList = async () => {
  const response = await axios.get<InvoiceCommodityListResponse>(urls.invoiceCommodityList(), {
    headers: {
      ...headers.language(),
      ...headers.auth()
    }
  });
  return response.data;
};