import {AxiosRequestConfig} from 'axios';

export enum RetryActionTypes {
  retry = 'AUTH_RETRY'
}

export interface IRetryRequestAction {
  type: RetryActionTypes.retry;
  payload: AxiosRequestConfig;
}
