import {
  IUserDetailRequestAction,
  IUserDetailSuccessAction,
  IUserDetailFailureAction,
  UserDetailActionTypes
} from './user-detail.types';
import {IUserDetailResponse} from 'api';

/** Fetch current user account detail */
export const userDetailRequest = (): IUserDetailRequestAction => ({
  type: UserDetailActionTypes.request
});

export const userDetailSuccess = (payload: IUserDetailResponse): IUserDetailSuccessAction => ({
  type: UserDetailActionTypes.success,
  payload
});

export const userDetailFailure = (payload: IRequestError): IUserDetailFailureAction => ({
  type: UserDetailActionTypes.failure,
  payload
});
