import './header-login-dialog.scss';
import React, {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Tooltip} from 'components/ui/tooltip';
import {IconButton} from 'components/ui/icon-button';
import LoginIcon from '@material-ui/icons/Person';
import {LoginDialog} from 'components/user/login-dialog';

/** Header login dialog button */
export const HeaderLoginDialog: FunctionComponent = () => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {t} = useTranslation();

  const toggleDialog = useCallback(() => {
    setDialogOpen((open) => !open);
  }, []);

  return (
    <>
      <Tooltip title={t('components.main.app.header.login_dialog.button_title')}>
        <IconButton
          className="ui-header-login-dialog"
          size="small"
          onClick={toggleDialog}
        >
          <LoginIcon />
        </IconButton>
      </Tooltip>
      <LoginDialog
        open={dialogOpen}
        onClose={toggleDialog}
      />
    </>
  );
};