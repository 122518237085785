import {asRoutePaths} from './paths.types';

/**
 * Route paths
 * 
 * @example
 * 
 *   import {Link} from 'components/ui/link';
 *   import {paths} from 'config/router';
 * 
 *   ...
 * 
 *   <Link to={paths.invoices}>
 *     Invoices
 *   </Link>
 */
export const paths = asRoutePaths({
  home: '/',
  registration: '/registration',
  activation: '/activation',
  contract: '/contract',
  invoices: '/invoices',
  payments: '/payments',
  demands: '/demands',
  consumption: '/consumption',
  contacts: '/contacts',
  settings: '/settings',
  conditions: '/conditions',
  about: '/about',
  news: '/news',
  passwordReset: '/password-reset',
  passwordResetConfirm: '/password-reset-confirm',
  partnerSelect: '/partner-select',
  login: '/login',
  uiKit: '/ui-kit',
  cookiesInfo: '/cookies-info'
});