import React, {FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import diacritics from 'diacritics';
import {View} from 'components/ui/view';
import {ContractSearch} from './contract-search';
import {ContractList} from './contract-list';

/** Contracts and consumption places view */
export const ContractsView: FunctionComponent = () => {
  const partners = useSelector(selectors.userPartners, shallowEqual);
  const activePartnerId = useSelector(selectors.activePartnerId);
  const [search, setSearch] = useState('');

  const activePartner = useMemo(() => {
    return partners?.find((partner) => {
      return String(partner.id) === String(activePartnerId);
    });
  }, [activePartnerId, partners]);

  const handleSearchChange = useCallback((value: string) => {
    setSearch(value);
  }, []);

  const getSearchValue = useCallback((value: (string | number)) => {
    return diacritics.remove(String(value)).toLowerCase();
  }, []);
  
  const filteredContracts = useMemo(() => {
    const searchValue = getSearchValue(search);
    return activePartner!.contracts.filter((contract) => {
      
      //--- Contract number match

      const numberValue = getSearchValue(contract.number);
      if (numberValue.includes(searchValue))
        return true;
      
      //--- Consumption place match

      return contract.consumptionPlaces.find((place) => {
        const nameValue = getSearchValue(place.name);
        const idValue = getSearchValue(place.id);
        const nameMatch = nameValue.includes(searchValue);
        const idMatch = idValue.includes(searchValue);
        return nameMatch || idMatch;
      });
    });
  }, [activePartner, getSearchValue, search]);

  return (
    <>
      <ContractSearch
        value={search}
        onChange={handleSearchChange}
      />
      <View>
        <ContractList contracts={filteredContracts} />
      </View>
    </>
  );
};