import './no-data.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {INoDataProps} from './no-data.types';
import {useTheme} from 'components/ui/theme';
import {ContainerQuery, IQueryClasses} from 'components/ui/container-query';
import {Typography} from 'components/ui/typography';
import {useTranslation} from 'lib/intl/i18n';
import {Grow} from 'components/ui/grow';
import NoDataIcon from '@material-ui/icons/PanTool';

/**
 * No data message
 *
 * @example
 *
 *   <NoData
 *     visible={!request.data}
 *     message="No data"
 *   />
 */
export const NoData: FunctionComponent<INoDataProps> = (props) => {
  const {
    visible,
    message,
    inverted
  } = props;
  const theme = useTheme();
  const {t} = useTranslation();

  const queryClasses = useMemo<IQueryClasses>(() => ({
    'ui-no-data--narrow': {maxWidth: 320}
  }), []);

  const classes = useMemo(() => {
    let classes = 'ui-no-data';
    if (inverted)
      classes += ' ui-no-data--inverted';
    return classes;
  }, [inverted]);

  return (
    <Grow
      timeout={theme.speed.slow}
      in={visible}
    >
      <ContainerQuery
        queryClasses={queryClasses}
        className={classes}
      >
        <div className="ui-no-data__content">
          <NoDataIcon className="ui-no-data__icon" />
          <Typography
            variant="body1"
            className="ui-no-data__message"
          >
            {message || t('components.ui.no_data.message')}
          </Typography>
        </div>
      </ContainerQuery>
    </Grow>
  );
};

NoData.defaultProps = {
  visible: true
};