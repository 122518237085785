import './data-list-item.scss';
import React, {FunctionComponent, useCallback, useState, MouseEvent, useMemo} from 'react';
import {IDataListItemProps} from './data-list-item.types';
import {List, ListItem, ListItemIcon, ListItemText} from 'components/ui/list';
import {Collapse} from 'components/ui/collapse';
import ExpandIcon from '@material-ui/icons/ExpandMore';

/** Data list item */
export const DataListItem: FunctionComponent<IDataListItemProps> = (props) => {
  const {
    className,
    label,
    icon,
    onClick,
    children,
    ...itemProps
  } = props;
  const [open, setOpen] = useState(false);

  const handleClick = useCallback((event: MouseEvent<HTMLDivElement>) => {
    setOpen((prevOpen) => !prevOpen);
    if (onClick)
      onClick(event);
  }, [onClick]);

  const renderItem = useCallback(() => (
    <>
      {icon && (
        <ListItemIcon>
          {icon}
        </ListItemIcon>
      )}
      <ListItemText primary={label} />
    </>
  ), [icon, label]);

  const classes = useMemo(() => {
    let classes = 'ui-data-list-item';
    if (open)
      classes += ' ui-data-list-item--open';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, open]);   
  
  return (
    <>
      <ListItem
        {...itemProps}
        className={classes}
        button={!!(children || onClick)}
        onClick={handleClick}
      >
        {renderItem()}
        {children && (
          <ExpandIcon className="ui-data-list-item__icon" />
        )}
      </ListItem>

      {/* Sub-items */}

      {children && (
        <Collapse in={open}>
          <List>
            {children}
          </List>
        </Collapse>
      )}
    </>
  );
};