import {AppState} from 'reducers';

/** Get the curent theme name */
export const theme = (state: AppState) => {
  return state.ui.theme;
};

/** Get open snackbar info */
export const snackbar = (state: AppState) => {
  return state.ui.snackbar;
};

/** Get the ID of the form that was used for login */
export const loginFormId = (state: AppState) => {
  return state.ui.loginFormId;
};

/** Get cookies approval state */
export const cookiesApproved = (state: AppState) => {
  return state.ui.cookies.approved;
};
/** Is cookies popup open */
export const selectIsOpenCookiesDialog = (state: AppState) => {
  return state.ui.cookies.isOpenDialog;
};