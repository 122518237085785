import React, {forwardRef, useMemo} from 'react';
import {FormDateTimePickerProps} from './form-date-time-picker.types';
import {useFormField} from 'hooks';
import {DateTimePicker} from 'components/ui/date-time-picker';
import {FormPickerField} from 'components/ui/form-picker-field';
import {validate as validators, composeValidators} from 'lib/global/validate';

/**
 * Formik wrapper for date/time picker 
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormDateTimePicker} from 'components/ui/form-date-time-picker';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormDateTimePicker
 *       name="someName"
 *       label="Some label"
 *     />
 *   </Form>
 */
export const FormDateTimePicker = forwardRef<HTMLInputElement, FormDateTimePickerProps>(function FormDateTimePicker(props, ref) {
  const {validate, ...restProps} = props;

  const pickerProps = useMemo(() => ({
    ...props,
    validate: composeValidators(
      validate,
      validators.dateTime,
      !!props.minDate && validators.minDate(props.minDate),
      !!props.maxDate && validators.maxDate(props.maxDate),
      !!props.minTime && validators.minTime(props.minTime),
      !!props.maxTime && validators.maxTime(props.maxTime),
      !!props.minDateTime && validators.minDateTime(props.minDateTime),
      !!props.maxDateTime && validators.maxDateTime(props.maxDateTime)
    )
  } as FormDateTimePickerProps), [props, validate]);
  
  const fieldProps = useFormField(pickerProps);

  const {field, helpers} = fieldProps;
  
  return (
    <DateTimePicker
      ref={ref}
      {...restProps}
      value={field.value || ''}
      onChange={helpers.setValue}
      FieldComponent={FormPickerField}
      fieldProps={fieldProps}
    />
  );
});