import React, {FunctionComponent} from 'react';
import {Paper} from 'components/ui/paper';
import {DesktopMainMenu} from './desktop-main-menu';
import {DesktopSubMenu} from './desktop-sub-menu';
import {DesktopPageInfo} from './desktop-page-info';

/** Application menu for desktop */
export const DesktopMenu: FunctionComponent = () => {
  return (
    <Paper className="ui-desktop-menu">
      <DesktopMainMenu />
      <DesktopSubMenu />
      <DesktopPageInfo />
    </Paper>
  );
};