import './upload-list.scss';
import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {IUploadListProps} from './upload-list.types';
import memoize from 'fast-memoize';
import {UploadItem} from './upload-item';

/** File upload list */
export const UploadList: FunctionComponent<IUploadListProps> = (props) => {
  const {
    files: filesProp,
    onRemove,
    onRetry
  } = props;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRemove = useCallback(memoize((file: File) => () => {
    if (onRemove)
      onRemove(file);
  }), [onRemove]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleRetry = useCallback(memoize((file: File) => () => {
    if (onRetry)
      onRetry(file);
  }), [onRetry]);

  const files = useMemo(() => {
    return Object.entries(filesProp);
  }, [filesProp]);

  return (
    <div className="ui-upload-list">
      {files.map(([fileKey, item]) => (
        <UploadItem
          key={fileKey}
          name={item.file.name}
          progress={item.progress}
          error={!!item.error}
          onRemove={handleRemove(item.file)}
          onRetry={handleRetry(item.file)}
        />
      ))}
    </div>
  );
};