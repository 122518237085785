import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {DemandTypeListResponse} from './demand-type-list.types';

/** Get a list of demand types */
export const demandTypeList = async () => {
  const response = await axios.get<DemandTypeListResponse>(urls.demandTypeList(), {
    headers: {
      ...headers.auth(),
      ...headers.language()
    }
  });
  return response.data;
};