import {Button} from 'components/ui/button';
import {Container} from 'components/ui/container';
import {Dialog} from 'components/ui/dialog';
import {DialogActions} from 'components/ui/dialog-actions';
import {DialogContent} from 'components/ui/dialog-content';
import {DialogContentText} from 'components/ui/dialog-content-text';

import {Heading} from 'components/ui/heading';
import {Tabs, Tab} from 'components/ui/tabs';
import {useTranslation} from 'lib/intl/i18n';
import {gtm} from 'lib/global/gtm';

import React, {useEffect, useState} from 'react';

import {useDispatch, useSelector} from 'react-redux';
import {selectIsOpenCookiesDialog, selectors} from 'selectors';
import {ConsentCookiesButtons} from './cookies-action-buttons/consent-cookies-buttons';
import {DetailCookiesButtons} from './cookies-action-buttons/detail-cookies-buttons';
import {ConsentCookiesInfo} from './cookies-info/consent-cookies-info';
import {DetailCookiesInfo} from './cookies-info/detail-cookies-info';
import './cookies-popup-new.scss';
import {actions, CookiesApprovalState, toggleCookiesDialog} from 'actions';
import {matchPath} from 'lib/global/react-router';
import {useLocation} from 'react-router';
import {paths} from 'config/router';

const CONSENT_STORAGE_KEY = 'cookies_consent';
const GTM_CONSENT_EVENT = 'consentRepush';

export const CookiesPopup = () => {
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const translationPath = 'components.main.app.cookies_popup';
  const isOpenCookiesDialog = useSelector(selectIsOpenCookiesDialog);
  const approved = useSelector(selectors.cookiesApproved);

  const [tab, setTab] = useState<'firstTab' | 'secondTab'>('firstTab');
  const [selectedCookies, setSelectedCookies] = useState<CookiesApprovalState[]>([]);
  const {pathname} = useLocation();

  const cookiesPopupDialogClassName = 'cookies-popup-dialog';
  const isFirstTab = tab === 'firstTab';
  const wasCheckedAtLeastOne = selectedCookies.length > 0;
  const isCookiesPage = matchPath(paths.cookiesInfo, pathname) !== null;

  useEffect(() => {
    if (isCookiesPage) {
      dispatch(toggleCookiesDialog(false));
    }
  }, [isCookiesPage, dispatch]);
  
  useEffect(() => {
    const oldApproved = localStorage.getItem(CONSENT_STORAGE_KEY);
    if (!oldApproved || JSON.parse(oldApproved) !== approved) {
      localStorage.setItem(CONSENT_STORAGE_KEY, JSON.stringify(approved));
      gtm.pushToDataLayer({event: GTM_CONSENT_EVENT});
    }
  }, [approved]);


  useEffect(() => {
    if (!approved && !isCookiesPage) {
      dispatch(toggleCookiesDialog(true));
    }
  }, [approved, dispatch, isCookiesPage]);

  useEffect(() => {
    if (approved !== 'all' && approved) {
      setSelectedCookies([approved]);
    }

    if (approved === 'all') {
      setSelectedCookies(['preferential', 'analytical']);
    }

  }, [approved]);

  // fix for .MuiTabs-indicator init position and width
  useEffect(() => {
    if (isOpenCookiesDialog) {
      setTimeout(() => {
        window.dispatchEvent(new CustomEvent('resize'));
      }, 1);
    }
  }, [isOpenCookiesDialog]);

  const onSelectCookieType = (type: CookiesApprovalState) => {
    if (selectedCookies.includes(type)) {
      const filtered = selectedCookies.filter((el) => el !== type);
      setSelectedCookies(filtered);
      return;
    }

    setSelectedCookies([...selectedCookies, type]);
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: 'firstTab' | 'secondTab') => {
    setTab(newValue);
  };

  const approveAllCookies = () => {
    dispatch(actions.setCookies({approved: 'all'}));
  };
  
  const refuseCookies = () => {
    dispatch(actions.setCookies({approved: 'refused'}));
  };

  const approveSelectedCookies = () => {
    const cookies = selectedCookies.filter((item) => item !== 'refused');
    const approved = cookies.length === 2 ? 'all' : cookies.join(', ') as CookiesApprovalState;
    dispatch(actions.setCookies({approved}));
  };


  return (
    <Dialog
      open={isOpenCookiesDialog} closable={false}
      width="wide" className={cookiesPopupDialogClassName}
    >
      <Tabs
        style={{minWidth: '50%'}}
        onChange={handleTabChange} variant="fullWidth"
        orientation="horizontal" value={tab}
      >
        <Tab value="firstTab" label={t(`${translationPath}.tab.access`)} />
        <Tab value="secondTab" label={t(`${translationPath}.tab.detail`)} />
      </Tabs>

      <Container>
        <DialogContent>
          <DialogContentText>
            <Heading variant="h1" gutterBottom>
              {t(`${translationPath}.heading`)}
            </Heading>
            {isFirstTab ? (
              <ConsentCookiesInfo isCookiesPage={isCookiesPage} />
            ) : (
              <DetailCookiesInfo
                preferentialCookies={selectedCookies.includes('preferential')}
                analyticalCookies={selectedCookies.includes('analytical')}
                onSelectCookieType={onSelectCookieType}
                cookiesPopupDialogClassName={cookiesPopupDialogClassName}
              />
            )}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          {isFirstTab ? (
            <ConsentCookiesButtons setTab={setTab} />
          ) : (
            <DetailCookiesButtons
              approveSelectedCookies={approveSelectedCookies}
              wasCheckedAtLeastOne={wasCheckedAtLeastOne}
              refuseCookies={refuseCookies}
            />
          )}
          <Button className={`${cookiesPopupDialogClassName}-approve_all_button`} onClick={approveAllCookies}>
            {t(`${translationPath}.accept.all`)}{' '}
          </Button>
        </DialogActions>
      </Container>
    </Dialog>
  );
};
