import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {ContactListRequest, ContactPagedListResponse} from './contact-list.types';

/** Get a list of contact groups */
export const contactList = async (request?: ContactListRequest) => {
  const response = await axios.get<ContactPagedListResponse>(urls.contactList(), {
    params: request,
    headers: headers.language()
  });
  return response.data;
};