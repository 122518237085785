import {date as validateDate} from 'lib/global/validate/date';
import {date as parseDate} from 'lib/intl/parse/date';
import {logErrorOnDev} from 'lib/global/dev';

export const REQUEST_DATE_FORMAT = 'YYYY-MM-DD';

/**
 * Format date for usage in request body
 *
 * @param value - Date to format
 * @returns Formatted date
 */
export const requestDate = (value: AnyDate) => {
  const validationError = validateDate(value);
  if (validationError) {
    logErrorOnDev(validationError);
    return value !== undefined && value !== null ? value.toString() : value;
  }
  return parseDate(value).format(REQUEST_DATE_FORMAT);
};
