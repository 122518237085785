import './use-autocomplete-input.scss';
import React from 'react';
import {useCallback} from 'react';
import {IAutocompleteProps, AutocompleteRenderInputParams} from '../autocomplete.types';
import {IUseAutocompleteContainerProps} from '../use-autocomplete-container';
import {IUseAutocompleteInputProps} from './use-autocomplete-input.types';
import {AutocompleteControls} from '../autocomplete-controls';

/** Autocomplete input field related logic */
export const useAutocompleteInput = (props: IAutocompleteProps, containerProps: IUseAutocompleteContainerProps): IUseAutocompleteInputProps => {
  const {
    onChange,
    renderInput: renderInputProp,
    clearable
  } = props;
  const {handleOpen, handleClose, open} = containerProps;

  const handleClear = useCallback(() => {
    if (onChange)
      onChange(null!, null, 'clear');
  }, [onChange]);

  const handleToggle = useCallback(() => {
    open ? handleClose() : handleOpen();
  }, [handleClose, handleOpen, open]);

  const renderInput = useCallback((renderInputProps: AutocompleteRenderInputParams) => {
    renderInputProps.InputProps = {
      ...renderInputProps.InputProps,
      endAdornment: (
        <AutocompleteControls
          onClear={handleClear}
          onToggle={handleToggle}
          clearable={clearable}
        />
      )
    };
    return renderInputProp(renderInputProps);
  }, [clearable, handleClear, handleToggle, renderInputProp]);

  return {renderInput};
};
