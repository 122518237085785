import React, {useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {paths} from 'config/router';
// import {useMatch} from 'react-router-dom';
import {useMatch} from 'lib/global/react-router';
import {useTheme} from 'components/ui/theme';
import {useMediaQuery} from '@material-ui/core';
import {useTranslation} from 'lib/intl/i18n';
import {AppMenuItems} from 'components/main/app/app-menu';
import LoginIcon from '@material-ui/icons/VpnKey';
import HomeIcon from '@material-ui/icons/Home';
import RegistrationIcon from '@material-ui/icons/VpnKey';
import ActivationIcon from '@material-ui/icons/VpnKey';
import ContractsIcon from '@material-ui/icons/Work';
import ContractIcon from '@material-ui/icons/MenuBook';
import InvoicesIcon from '@material-ui/icons/ListAlt';
import PaymentsIcon from '@material-ui/icons/AccountBalanceWalletOutlined';
import DemandsIcon from '@material-ui/icons/AssignmentOutlined';
import ConsumptionIcon from '@material-ui/icons/Equalizer';
import ContactsIcon from '@material-ui/icons/ContactPhoneOutlined';
import SettingsIcon from '@material-ui/icons/Tune';
import NewsIcon from '@material-ui/icons/NewReleases';
import AboutIcon from '@material-ui/icons/Info';
import ConditionsIcon from '@material-ui/icons/Help';
import PasswordResetIcon from '@material-ui/icons/VpnKey';
import PartnerSelectIcon from '@material-ui/icons/People';
import UiKitIcon from '@material-ui/icons/Dashboard';

/** Application menu items */
export const useMenu = (): AppMenuItems => {
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);
  const activePartnerId = useSelector(selectors.activePartnerId, shallowEqual);
  const partnerSelect = userIsLoggedIn && activePartnerId === null;
  const isContractDetailPage = useMatch(`${paths.contract}/*`) !== null;
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.width.lgMax}px)`);
  const {t} = useTranslation();

  return useMemo(() => {
    let items: AppMenuItems = [

      //--- Partner selection

      {
        hidden: !partnerSelect,
        label: t('config.menu.partner_select.label'),
        path: paths.partnerSelect,
        subMenu: {
          icon: (<PartnerSelectIcon />),
          heading: t('config.menu.partner_select.submenu.heading')
        }
      }
    ];

    if (!partnerSelect)
      items = [
        ...items,

        //--- Login

        {
          inactive: userIsLoggedIn,
          path: paths.login,
          breadcrumb: t('config.menu.login.label'),
          subMenu: {
            icon: (<LoginIcon />),
            heading: t('config.menu.login.submenu.heading')
          }
        },

        //--- Home

        {
          icon: (<HomeIcon />),
          label: isMobile ? t('config.menu.home.label') : undefined,
          breadcrumb: t('config.menu.home.label'),
          path: paths.home,
          subMenu: isMobile ? {
            icon: (<HomeIcon />),
            heading: t('config.menu.home.submenu.heading')
          } : undefined
        },

        //--- User registration

        {
          hidden: userIsLoggedIn,
          label: t('config.menu.registration.label'),
          path: paths.registration,
          subMenu: {
            icon: (<RegistrationIcon />),
            heading: t('config.menu.registration.submenu.heading')
          }
        },

        //--- Account activation

        {
          hidden: userIsLoggedIn,
          label: t('config.menu.activation.label'),
          path: paths.activation,
          subMenu: {
            icon: (<ActivationIcon />),
            heading: t('config.menu.activation.submenu.heading')
          }
        },

        //--- Contracts

        {
          hidden: !userIsLoggedIn || isContractDetailPage,
          label: t('config.menu.contracts.label'),
          path: paths.contract,
          subMenu: {
            icon: (<ContractsIcon />),
            heading: t('config.menu.contracts.submenu.heading')
          }
        },

        //--- Contract detail

        {
          hidden: !(userIsLoggedIn && isContractDetailPage),
          label: t('config.menu.contract.label'),
          path: paths.contract,
          goToPath: paths.contract,
          activeForSubPaths: true,
          pageInfo: t('config.menu.contract.page_info'),
          subMenu: {
            icon: (<ContractIcon />),
            heading: t('config.menu.contract.submenu.heading')
          }
        },

        //--- Invoices and payments

        {
          hidden: !userIsLoggedIn,
          label: t('config.menu.invoices_and_payments.label'),
          path: paths.invoices,
          subMenu: {
            items: [

              //--- Invoices

              {
                label: t('config.menu.invoices_and_payments.submenu.invoices.label'),
                path: paths.invoices,
                pageInfo: t('config.menu.invoices_and_payments.submenu.invoices.page_info'),
                subMenu: {
                  icon: (<InvoicesIcon />),
                  heading: t('config.menu.invoices_and_payments.submenu.invoices.submenu.heading')
                }
              },

              //--- Payments

              {
                label: t('config.menu.invoices_and_payments.submenu.payments.label'),
                path: paths.payments,
                pageInfo: t('config.menu.invoices_and_payments.submenu.payments.page_info'),
                subMenu: {
                  icon: (<PaymentsIcon />),
                  heading: t('config.menu.invoices_and_payments.submenu.payments.submenu.heading')
                }
              }
            ]
          }
        },

        //--- Demands

        {
          hidden: !userIsLoggedIn,
          label: t('config.menu.demands.label'),
          path: paths.demands,
          pageInfo: t('config.menu.demands.page_info'),
          subMenu: {
            icon: (<DemandsIcon />),
            heading: t('config.menu.demands.submenu.heading')
          }
        },

        //--- Consumption

        {
          hidden: !userIsLoggedIn,
          label: t('config.menu.consumption.label'),
          path: paths.consumption,
          pageInfo: t('config.menu.consumption.page_info'),
          subMenu: {
            icon: (<ConsumptionIcon />),
            heading: t('config.menu.consumption.submenu.heading')
          }
        },

        //--- Contacts

        {
          hidden: !userIsLoggedIn,
          label: t('config.menu.contacts.label'),
          path: paths.contacts,
          subMenu: {
            icon: (<ContactsIcon />),
            heading: t('config.menu.contacts.submenu.heading')
          }
        },

        //--- Settings

        {
          hidden: !userIsLoggedIn,
          path: paths.settings,
          breadcrumb: t('config.menu.settings.label'),
          pageInfo: t('config.menu.settings.page_info'),
          subMenu: {
            icon: (<SettingsIcon />),
            heading: t('config.menu.settings.submenu.heading')
          }
        },

        //--- News list

        {
          path: paths.news,
          breadcrumb: t('config.menu.news_list.label'),
          subMenu: {
            icon: (<NewsIcon />),
            heading: t('config.menu.news_list.submenu.heading')
          }
        },

        //--- News detail

        {
          path: `${paths.news}/*`,
          subMenu: {
            icon: (<NewsIcon />),
            heading: t('config.menu.news_detail.submenu.heading')
          }
        },

        //--- About

        {
          pageInfo: t('config.menu.about.page_info'),
          path: paths.about,
          breadcrumb: t('config.menu.about.label'),
          subMenu: {
            icon: (<AboutIcon />),
            heading: t('config.menu.about.submenu.heading')
          }
        },

        //--- Conditions

        {
          path: paths.conditions,
          breadcrumb: t('config.menu.conditions.label'),
          subMenu: {
            icon: (<ConditionsIcon />),
            heading: t('config.menu.conditions.submenu.heading')
          }
        },

        //--- Password reset

        {
          path: paths.passwordReset,
          breadcrumb: t('config.menu.password_reset.label'),
          subMenu: {
            icon: (<PasswordResetIcon />),
            heading: t('config.menu.password_reset.submenu.heading')
          }
        },

        //--- Password reset confirmation

        {
          path: paths.passwordResetConfirm,
          breadcrumb: t('config.menu.password_reset_confirm.label'),
          subMenu: {
            icon: (<PasswordResetIcon />),
            heading: t('config.menu.password_reset_confirm.submenu.heading')
          }
        },

        //--- UI kit

        {
          // hidden: userIsLoggedIn,
          hidden: true,
          label: t('config.menu.ui_kit.label'),
          path: paths.uiKit,
          subMenu: {
            icon: (<UiKitIcon />),
            heading: t('config.menu.ui_kit.submenu.heading')
          }
        }
      ];

    return items;
  }, [isContractDetailPage, isMobile, partnerSelect, t, userIsLoggedIn]);
};
