import './home-activation.scss';
import React, {FunctionComponent} from 'react';
import {paths} from 'config/router';
import {RevealFade} from 'components/ui/reveal-fade';
import {Button} from 'components/ui/button';
import KeyIcon from '@material-ui/icons/VpnKey';

/** Account activation section */
export const HomeActivation: FunctionComponent = () => {
  return (
    <div className="ui-home-activation">
      <RevealFade
        delay={1}
        bottom
      >
        <Button
          icon={<KeyIcon />}
          to={paths.activation}
          disableRipple
        >
          Aktivácia účtu
        </Button>
      </RevealFade>
    </div>
  );
};