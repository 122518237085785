import {ConsumptionParameterListResponse} from 'api';

export enum ConsumptionParameterListActionTypes {
  request = 'CONSUMPTION_PARAMETER_LIST_REQUEST',
  success = 'CONSUMPTION_PARAMETER_LIST_SUCCESS',
  failure = 'CONSUMPTION_PARAMETER_LIST_FAILURE'
}

export interface IConsumptionParameterListRequestAction {
  type: ConsumptionParameterListActionTypes.request;
}

export interface IConsumptionParameterListSuccessAction {
  type: ConsumptionParameterListActionTypes.success;
  payload: ConsumptionParameterListResponse;
}

export interface IConsumptionParameterListFailureAction {
  type: ConsumptionParameterListActionTypes.failure;
  payload: IRequestError;
}

export type ConsumptionParameterListAction = (
  IConsumptionParameterListRequestAction |
  IConsumptionParameterListSuccessAction |
  IConsumptionParameterListFailureAction
);