import 'core-js/stable';
import 'intersection-observer';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {Root} from 'components/main/root';

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorkerRegistration.unregister();
reportWebVitals();
