import React, {ReactElement} from 'react';
import {TransitionProps} from '@material-ui/core/transitions/transition';
import {Grow} from 'components/ui/grow';

/** Dialog window animation */
export const DialogTransition = React.forwardRef<unknown, TransitionProps>(function DialogTransition(props, ref) {
  const {children, ...restProps} = props;

  return (
    <Grow
      ref={ref}
      {...restProps}
    >
      {children as ReactElement}
    </Grow>
  );
});
