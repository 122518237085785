import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {useTranslation} from 'lib/intl/i18n';
import {IContactsTableProps} from './contacts-table.types';
import {
  DataTable,
  asTableColumns,
  mapTableRows,
  asTableExportColumns,
  mapTableExportData,
  getExportSource
} from 'components/ui/data-table';
import {Pagination, usePagination} from 'components/ui/pagination';

const ROWS_PER_PAGE = 7;

/** Contacts view */
export const ContactsTable: FunctionComponent<IContactsTableProps> = (props) => {
  const {contactId} = props;
  const contactEntries = useFetch(api.contactEntryList);
  const {currentPage, setCurrentPage} = usePagination();  
  const {t} = useTranslation();

  const fetchOptions = useMemo(() => ({
    contactId
  }), [contactId]);
  
  /** Get the data to be displayed in the contacts table */
  const fetchTableData = useCallback(() => {
    contactEntries.fetch({
      ...fetchOptions,
      page: currentPage - 1,
      size: ROWS_PER_PAGE
    });
  }, [contactEntries, currentPage, fetchOptions]);

  /** Get all contacts data for export */
  const fetchExportData = useCallback((paging?: IPagingRequest) => {
    return api.contactEntryList({
      ...fetchOptions,
      ...paging
    });
  }, [fetchOptions]);

  useEffect(() => {
    fetchTableData();
  // eslint-disable-next-line
  }, [contactId, currentPage]);

  const columns = useMemo(() => asTableColumns({
    region: {
      label: t('views.article.contacts.table.region')
    },
    phone: {
      label: t('views.article.contacts.table.phone'),
      nowrapCell: true
    },
    area: {
      label: t('views.article.contacts.table.area')
    }
  }), [t]);

  const rows = useMemo(() => {
    if (contactEntries.data?.content)
      return mapTableRows(columns, contactEntries.data.content, (contact) => ({
        region: contact.region,
        phone: contact.phoneNumber,
        area: contact.regionDescription
      }));
  }, [columns, contactEntries.data?.content]);

  const exportData = useCallback(async () => {
    const exportSource = await getExportSource(fetchExportData);
    const exportColumns = asTableExportColumns({
      region: t('views.article.contacts.table.region')!,
      phone: t('views.article.contacts.table.phone')!,
      area: t('views.article.contacts.table.area')!
    });
    return mapTableExportData(exportColumns, exportSource, (contact) => ({
      region: contact.region,
      phone: contact.phoneNumber,
      area: contact.regionDescription
    }));
  }, [fetchExportData, t]);

  const render = useCallback(() => (
    <>
      <DataTable
        columns={columns}
        rows={rows!}
        exportData={exportData}
        exportFilename={t('views.article.contacts.table.export_filename')}
      />
      <Pagination
        gutterTop
        pageSize={ROWS_PER_PAGE}
        totalItemCount={contactEntries.data!.totalElements}
        currentPage={currentPage}
        onChange={setCurrentPage}
      />
    </>
  ), [columns, contactEntries.data, currentPage, exportData, rows, setCurrentPage, t]);

  return (
    <WaitForFetch
      pending={contactEntries.pending}
      error={contactEntries.error}
      noData={!contactEntries.data || contactEntries.data.empty}
      noDataMessage={t('views.article.contacts.table.no_data')}
      onRetry={fetchTableData}
      render={render}
    />
  );
};