import './portal-feature-card.scss';
import React, {FunctionComponent} from 'react';
import {IPortalFeatureProps} from './portal-feature-card.types';
import {Card} from 'components/ui/card';
import {CardContent} from 'components/ui/card-content';
import {Typography} from 'components/ui/typography';

/** Portal feature description */
export const PortalFeatureCard: FunctionComponent<IPortalFeatureProps> = (props) => {
  const {icon, title, description} = props;

  return (
    <Card className="ui-portal-feature-card">
      <CardContent className="ui-portal-feature-card__content">
        {icon}
        <Typography
          className="ui-portal-feature-card__title"
          variant="h2"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography
          className="ui-portal-feature-card__description"
          variant="body1"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};