import React, {forwardRef, useMemo} from 'react';
import {useTheme} from 'components/ui/theme';
import {ZoomProps} from './zoom.types';
import MuiZoom from '@material-ui/core/Zoom';

/**
 * Zoom transition
 *
 * @example
 *
 *   <Zoom in={isOpen}>
 *     Animated content...
 *   </Zoom>
 */
export const Zoom = forwardRef<unknown, ZoomProps>(function Zoom(props, ref) {
  const {
    timeout: timeoutProp,
    children,
    ...restProps
  } = props;
  const theme = useTheme();

  const timeout = useMemo(() => {
    if (timeoutProp === undefined)
      return theme.speed.fast;
    return timeoutProp;
  }, [theme.speed.fast, timeoutProp]);
  
  return (
    <MuiZoom
      ref={ref}
      {...restProps}
      timeout={timeout}
    >
      {children}
    </MuiZoom>
  );
});