import './reveal-fade.scss';
import React, {forwardRef, useMemo} from 'react';
import {useTheme} from 'components/ui/theme';
import {IRevealFadeProps} from './reveal-fade.types';
import {Fade} from 'react-reveal';

/**
 * Fade reveal animation
 * 
 * @see https://www.react-reveal.com/examples/common/fade
 *
 * @example
 *
 *   <RevealFade>
 *     <h1>
 *       First
 *     </h1>
 *   </RevealFade>
 *   <RevealFade delay={1}>
 *     <h2>
 *       Second
 *     </h2>
 *   </RevealFade>
 *   <RevealFade delay={2}>
 *     <h3>
 *       Third
 *     </h3>
 *   </RevealFade>
 */
export const RevealFade = forwardRef<HTMLDivElement, IRevealFadeProps>(function RevealFade(props, ref) {
  const {
    className,
    timeout,
    delay,
    fullHeight,
    speedMultiplier: speedMultiplierProp,
    children,
    ...restProps
  } = props;
  const theme = useTheme();
  const speedMultiplier = speedMultiplierProp || theme.speed.fast;

  const classes = useMemo(() => {
    let classes = 'ui-reveal-fade';
    if (fullHeight)
      classes += ' ui-reveal-fade--full-height';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, fullHeight]);
  
  return (
    <Fade
      {...restProps}
      timeout={timeout! * speedMultiplier}
      delay={delay! * speedMultiplier}
    >
      <div
        ref={ref}
        className={classes}
      >
        {children}
      </div>
    </Fade>
  );
});

RevealFade.defaultProps = {
  timeout: 3,
  delay: 0
};