import './reveal-zoom.scss';
import React, {forwardRef, useMemo} from 'react';
import {useTheme} from 'components/ui/theme';
import {IRevealZoomProps} from './reveal-zoom.types';
import {Zoom} from 'react-reveal';

/**
 * Zoom reveal animation
 * 
 * @see https://www.react-reveal.com/examples/common/zoom
 *
 * @example
 *
 *   <RevealZoom>
 *     <h1>
 *       First
 *     </h1>
 *   </RevealZoom>
 *   <RevealZoom delay={1}>
 *     <h2>
 *       Second
 *     </h2>
 *   </RevealZoom>
 *   <RevealZoom delay={2}>
 *     <h3>
 *       Third
 *     </h3>
 *   </RevealZoom>
 */
export const RevealZoom = forwardRef<HTMLDivElement, IRevealZoomProps>(function RevealZoom(props, ref) {
  const {
    className,
    timeout,
    delay,
    fullHeight,
    speedMultiplier: speedMultiplierProp,
    children,
    ...restProps
  } = props;
  const theme = useTheme();
  const speedMultiplier = speedMultiplierProp || theme.speed.fast;

  const classes = useMemo(() => {
    let classes = 'ui-reveal-zoom';
    if (fullHeight)
      classes += ' ui-reveal-zoom--full-height';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, fullHeight]);
  
  return (
    <Zoom
      {...restProps}
      timeout={timeout! * speedMultiplier}
      delay={delay! * speedMultiplier}
    >
      <div
        ref={ref}
        className={classes}
      >
        {children}
      </div>
    </Zoom>
  );
});

RevealZoom.defaultProps = {
  timeout: 3,
  delay: 0
};