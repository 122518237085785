import {
  IEnergyTypeListRequestAction,
  IEnergyTypeListSuccessAction,
  IEnergyTypeListFailureAction,
  EnergyTypeListActionTypes
} from './energy-type-list.types';
import {EnergyTypeListResponse} from 'api';

/** Get list of energy type */
export const energyTypeListRequest = (): IEnergyTypeListRequestAction => ({
  type: EnergyTypeListActionTypes.request
});

export const energyTypeListSuccess = (payload: EnergyTypeListResponse): IEnergyTypeListSuccessAction => ({
  type: EnergyTypeListActionTypes.success,
  payload
});

export const energyTypeListFailure = (payload: IRequestError): IEnergyTypeListFailureAction => ({
  type: EnergyTypeListActionTypes.failure,
  payload
});