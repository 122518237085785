import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Authenticate user (get access token) */
const authTokenSaga = function* (action: actions.ITokenRequestAction) {
  try {
    const response = yield* call(api.token, action.payload);
    yield put(actions.tokenSuccess(response));
  }
  catch (error) {
    yield put(actions.tokenFailure(error as IRequestError));
  }
};

export const authToken = function* () {
  yield takeLatest(actions.TokenActionTypes.request, authTokenSaga);
};
