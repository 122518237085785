
/** Get about us article */
export const aboutArticle = () => {
  return 'article/about';
};

/** Get terms and conditions article */
export const conditionsArticle = () => {
  return 'article/condition';
};

/** Get a list of news articles */
export const newsList = () => {
  return 'article/news/list';
};

/** Get a list of contact groups */
export const contactList = () => {
  return 'article/contact/list';
};

/** Get a list of contact entries */
export const contactEntryList = (contactId: string) => {
  return `article/contact/${contactId}/contact-entry/list`;
};
