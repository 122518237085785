import {
  ISetThemePayload,
  ISetThemeAction,
  ThemeActionTypes
} from './theme.types';

/** Set application theme */
export const setTheme = (payload: ISetThemePayload): ISetThemeAction => ({
  type: ThemeActionTypes.set,
  payload
});
