import {useState, useCallback, useMemo} from 'react';
import {UseFetchCallback, IUseFetchProps} from './use-fetch.types';

/**
 * Fetch a resource
 *
 * @param apiCall - Request callback that returns a promise
 * @param initialData - Initial data value
 *
 * @example
 * 
 *   import {useFetch, useOnFetchSuccess, useOnFetchError} from 'hooks';
 *
 *   // ...
 * 
 *   const list = useFetch(someApi.fetchSomeList);
 *
 *   useEffect(() => {
 *     list.fetch(); // calls someApi.fetchSomeList
 *   }, []);
 *
 *   useOnFetchSuccess([list], () => {
 *     console.log('Fetch succeeded: ', list.data);
 *   });
 *
 *   useOnFetchError([list], () => {
 *     console.error('Fetch failed: ', list.error);
 *   });
 */
export const useFetch = <C extends UseFetchCallback, D = UnwrapPromise<ReturnType<C>>>(apiCall: C) => {
  const [state, setState] = useState<IFetchState<D>>({
    pending: false,
    error: null,
    data: null
  });

  const fetch = useCallback(async (...args: never[]) => {

    //--- Request

    setState((prevState) => ({
      ...prevState,
      pending: true,
      error: null
    }));

    //--- Success

    try {
      const response = await apiCall(...args) as D;
      setState((prevState) => ({
        ...prevState,
        pending: false,
        data: response
      }));
      return response;
    }

    //--- Failure

    catch (error) {
      setState((prevState) => ({
        ...prevState,
        pending: false,
        error: error as IRequestError
      }));
      console.error(error);
    }
  }, [apiCall]);

  return useMemo<IUseFetchProps<C, D>>(() => {
    return {...state, fetch: fetch as C};
  }, [fetch, state]);
};
