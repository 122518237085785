import './password-reset-confirm-view.scss';
import React, {FunctionComponent, useCallback} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {paths} from 'config/router';
import {useNavigate} from 'react-router-dom';
import {View} from 'components/ui/view';
import {RevealFade} from 'components/ui/reveal-fade';
import {PasswordResetConfirmForm} from './password-reset-confirm-form';

/** Password reset confirmation view */
export const PasswordResetConfirmView: FunctionComponent = () => {
  const userDetail = useSelector(selectors.userDetail, shallowEqual);
  const navigate = useNavigate();

  const redirectToLogin = useCallback(() => {
    if (userDetail)
      navigate(`${paths.login}?login=${userDetail.email}`);
    else
      navigate(paths.login);
  }, [navigate, userDetail]);

  return (
    <View className="ui-password-reset-confirm-view">
      <div className="ui-password-reset-confirm-view__content">
        <RevealFade>
          <PasswordResetConfirmForm onSuccess={redirectToLogin} />
        </RevealFade>
      </div>
    </View>
  );
};