import isoCurrencies from 'iso-currencies';

/**
 * Get currency symbol
 *
 * @example
 *
 *   format.currencySymbol('USD'); // $
 *   format.currencySymbol('CZE'); // Kč
 *
 * @param currencyCode - Currency ISO code
 * @returns Currency symbol
 */
export const currencySymbol = (currencyCode: ISOCurrencyCode) => {
  return isoCurrencies.information(currencyCode).symbol;
};
