import React, {FunctionComponent, useCallback, ChangeEvent, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {TextField} from 'components/ui/text-field';
import {IntegerField} from 'components/ui/integer-field';
import {Grid} from 'components/ui/grid';

/** Text field control preview */
export const TextFieldKit: FunctionComponent = () => {
  const {t} = useTranslation();
  const [state, setState] = useState({
    default: '',
    password: '',
    prefix: '',
    suffix: '',
    multiline: '',
    integer: ''
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    const {name, value} = event.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value
    }));
  }, []);

  return (
    <form>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.text_field.heading')}
      </Typography>
      <Grid container>

        {/* Default */}

        <Grid item md={6}>
          <TextField
            name="default"
            label={t('views.app.ui_kit.text_field.label.default')}
            placeholder={t('views.app.ui_kit.text_field.placeholder')}
            value={state.default}
            onChange={handleChange}
          />
        </Grid>

        {/* Password */}

        <Grid item md={6}>
          <TextField
            name="password"
            type="password"
            autoComplete="off"
            label={t('views.app.ui_kit.text_field.label.password')}
            placeholder={t('views.app.ui_kit.text_field.placeholder')}
            value={state.password}
            onChange={handleChange}
          />
        </Grid>

        {/* Prefix */}

        <Grid item md={6}>
          <TextField
            name="prefix"
            label={t('views.app.ui_kit.text_field.label.prefix')}
            placeholder={t('views.app.ui_kit.text_field.placeholder')}
            prefix={t('views.app.ui_kit.text_field.value.prefix')}
            value={state.prefix}
            onChange={handleChange}
          />
        </Grid>

        {/* Suffix */}

        <Grid item md={6}>
          <TextField
            name="suffix"
            label={t('views.app.ui_kit.text_field.label.suffix')}
            placeholder={t('views.app.ui_kit.text_field.placeholder')}
            suffix={t('views.app.ui_kit.text_field.value.suffix')}
            align="right"
            value={state.suffix}
            onChange={handleChange}
          />
        </Grid>

        {/* Integer */}

        <Grid item>
          <IntegerField
            name="integer"
            label={t('views.app.ui_kit.text_field.label.integer')}
            placeholder={t('views.app.ui_kit.text_field.placeholder')}
            align="right"
            value={state.integer}
            onChange={handleChange}
          />
        </Grid>

        {/* Multiline */}

        <Grid item xs={12}>
          <TextField
            name="multiline"
            label={t('views.app.ui_kit.text_field.label.multiline')}
            placeholder={t('views.app.ui_kit.text_field.placeholder')}
            multiline
            rows={4}
            value={state.multiline}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </form>
  );
};
