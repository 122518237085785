/** @file Data table helpers */

import {
  DataTableColumns,
  DataTableRows,
  DataTableRow,
  DataTableExportColumns,
  DataTableExportRows,
  DataTableExportData,
  DataTableExportRow,
  DataTableExportSource
} from '../data-table.types';

/**
 * Table columns helper
 * 
 * @example
 * 
 *   const columns = asTableColumns({
 *     type: {
 *       label: 'Type'
 *     },
 *     consumption: {
 *       label: 'Consumption',
 *       align: 'right'
 *     },
 *     price: {
 *       label: 'Price',
 *       align: 'right'
 *     }
 *   });
 */
export const asTableColumns = <T extends DataTableColumns>(columns: T): T => columns;

/**
 * Table rows helper
 * 
 * @example
 * 
 *   const rows = asTableRows(columns, [
 *     {
 *       type: 'Water',
 *       consumption: 123,
 *       price: 456
 *     },
 *     {
 *       type: 'Gas',
 *       consumption: 234,
 *       price: 567
 *     },
 *     ...
 *   ]);
 */
export const asTableRows = <T extends DataTableColumns>(columns: T, rows: DataTableRows<T>) => rows;

/**
 * Map data to table rows helper
 * 
 * @example
 * 
 *   const data = {
 *     {
 *       type: 'Water',
 *       consumption: 123,
 *       price: 456
 *     },
 *     {
 *       type: 'Gas',
 *       consumption: 234,
 *       price: 567
 *     },
 *     ...
 *   };
 * 
 *   const rows = mapTableRows(columns, data, (item) => ({
 *     type: item.type,
 *     consumption: item.consumption,
 *     price: item.price
 *   }));
 */
export const mapTableRows = <T extends DataTableColumns, D>(
  columns: T,
  data: D[],
  iterator: (item: D, index?: number) => DataTableRow<T>
): DataTableRows<T> => {
  return data.map<DataTableRow<T>>(iterator);
};

/**
 * Table export columns helper
 * 
 * @example
 * 
 *   const columns = asTableExportColumns({
 *     type: 'Type',
 *     consumption: 'Consumption',
 *     price: 'Price'
 *   });
 */
export const asTableExportColumns = <T extends DataTableExportColumns>(columns: T): T => columns;

/**
 * Table data export helper
 * 
 * @example
 * 
 *   const exportColumns = asTableExportColumns({
 *     type: 'Type',
 *     consumption: 'Consumption',
 *     price: 'Price'
 *   });
 *   
 *   const exportData = asTableExportData(exportColumns, [
 *     {
 *       type: 'Water',
 *       consumption: 123,
 *       price: 456
 *     },
 *     {
 *       type: 'Gas',
 *       consumption: 234,
 *       price: 567
 *     },
 *     ...
 *   ]);
 */
export const asTableExportData = <T extends DataTableExportColumns>(
  columns: T,
  rows: DataTableExportRows<T>
): DataTableExportData => {
  const dataCols = Object.values(columns);
  const dataRows = rows.map((row) => Object.values(row));
  return [dataCols, ...dataRows];
};

/**
 * Map table export data helper
 * 
 * @example
 * 
 *   const data = {
 *     {
 *       type: 'Water',
 *       consumption: 123,
 *       price: 456
 *     },
 *     {
 *       type: 'Gas',
 *       consumption: 234,
 *       price: 567
 *     },
 *     ...
 *   };
 * 
 *   const exportColumns = asTableExportColumns({
 *     type: 'Type',
 *     consumption: 'Consumption',
 *     price: 'Price'
 *   });
 *   
 *   const exportData = mapTableExportData(exportColumns, data, (item) => ({
 *     type: item.type,
 *     consumption: item.consumption,
 *     price: item.price
 *   }));
 */
export const mapTableExportData = <T extends DataTableExportColumns, D>(
  columns: T,
  data: D[],
  iterator: (item: D, index?: number) => DataTableExportRow<T>
): DataTableExportData => {
  const rows = data.map<DataTableExportRow<T>>(iterator);
  const dataCols = Object.values(columns);
  const dataRows = rows.map((row) => Object.values(row));
  return [dataCols, ...dataRows];
};

/**
 * Get data for export
 * 
 * @param source - Data to export or a function that will fetch data for export.
 *                 The fetch function must support paging - due to the limited 
 *                 number of items per request data must be loaded in batches.
 * @returns Data to export
 */
export const getExportSource = async <T extends Array<unknown>>(source: DataTableExportSource<T>): Promise<T> => {
  
  //--- Data can be used as is
  
  if (!(source instanceof Function))
    return source;

  //--- Data must be fetched from the server
  
  const fetch = (page = 0) => source({
    
    // This is necessary to get the maximum allowed items per request
    size: 999999999,
    page
  });

  // Send an initial request
  const initialRequest = await fetch();
  const {totalPages} = initialRequest;
  let data = [...initialRequest.content] as T;
  
  // The initial request was sufficient to obtain all data
  if (totalPages <= 1)
    return data;
  
  // Data is larger than the items-per-request limit and must be loaded in several additional requests
  const requests: Promise<IPagedListResponse<T>>[] = [];
  for (let page = 1; page < totalPages; page++)
    requests[page - 1] = fetch(page);
  const chunks = await Promise.all(requests);
  for (let page = 1; page < totalPages; page++)
    data = [...data, ...chunks[page - 1].content] as T;
  return data;
};
