import React, {FC} from 'react';
import DoneIcon from '@material-ui/icons/Done';
import {Switch} from '@material-ui/core';
import {Typography} from 'components/ui/typography';
import {Divider} from 'components/ui/divider';
import {Heading} from 'components/ui/heading';
import {useTranslation} from 'lib/intl/i18n';

interface IDetailCookiesInfoProps {
  preferentialCookies: boolean;
  analyticalCookies: boolean;
  cookiesPopupDialogClassName: string;
  onSelectCookieType: (type: 'preferential' | 'analytical') => void;
}

export const DetailCookiesInfo: FC<IDetailCookiesInfoProps> = ({
  preferentialCookies,
  analyticalCookies,
  onSelectCookieType,
  cookiesPopupDialogClassName
}) => {
  const {t} = useTranslation();
  const translationPath = 'components.main.app.cookies_popup.detail';
  return (
    <>
      <div>
        <div className={`${cookiesPopupDialogClassName}-cookies-type-header`}>
          <Heading>{t(`${translationPath}.technical.title`)}</Heading>
          <div className={`${cookiesPopupDialogClassName}-cookies-type-header_right-side`}>
            <DoneIcon />
          </div>
        </div>
        <Typography>
          {t(`${translationPath}.technical.content`)}
        </Typography>
      </div>
      <Divider />
      <div>
        <div className={`${cookiesPopupDialogClassName}-cookies-type-header`}>
          <Heading>{t(`${translationPath}.preferential.title`)}</Heading>
          <div className={`${cookiesPopupDialogClassName}-cookies-type-header_right-side`}>
            <Switch
              checked={preferentialCookies}
              onChange={() => onSelectCookieType('preferential')}
              name="preferentialCookies"
              color="primary"
            />
          </div>
        </div>
        <Typography>
          {t(`${translationPath}.preferential.content`)}
        </Typography>
      </div>
      <Divider />
      <div>
        <div className={`${cookiesPopupDialogClassName}-cookies-type-header`}>
          <Heading>{t(`${translationPath}.analytical.title`)}</Heading>
          <div className={`${cookiesPopupDialogClassName}-cookies-type-header_right-side`}>
            <Switch
              checked={analyticalCookies}
              onChange={() => onSelectCookieType('analytical')}
              name="analyticalCookies"
              color="primary"
            />
          </div>
        </div>
        <Typography>
          {t(`${translationPath}.analytical.content`)}
        </Typography>
      </div>
    </>
  );
};
