import {actions} from 'actions';
import {ActionType} from './redux-state-sync.types';

/** Actions to sync between browser tabs */
export const SYNCED_ACTION_TYPES: ActionType[] = [

  //--- Login

  actions.LoginActionTypes.request,
  actions.LoginActionTypes.success,
  actions.LoginActionTypes.failure,
  actions.LogoutActionTypes.request,
  actions.RefreshActionTypes.success,
  
  //--- User

  actions.UserDetailActionTypes.request,
  actions.UserDetailActionTypes.success,
  actions.UserDetailActionTypes.failure,
  actions.SelectPartnerActionTypes.select
];
