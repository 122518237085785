import React, {FunctionComponent, useCallback} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IConfirmDialogProps} from './confirm-dialog.types';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {DialogContentText} from 'components/ui/dialog-content-text';
import {DialogActions} from 'components/ui/dialog-actions';
import {Button} from 'components/ui/button';
import YesIcon from '@material-ui/icons/Check';
import NoIcon from '@material-ui/icons/Close';

/**
 * Confirmation dialog
 * 
 * @example
 * 
 *   const [dialogOpen, setDialogOpen] = useState(false);
 * 
 *   const toggleDialog = useCallback(() => {
 *     setDialogOpen((prevOpen) => !prevOpen);
 *   }, []);
 *
 *   const handleYesClick = useCallback(() => {
 *     console.log('User has clicked "yes" button.');
 *   }, []);
 * 
 *   const handleNoClick = useCallback(() => {
 *     console.log('User has clicked "no" button.');
 *   }, []);
 * 
 *   // ...
 * 
 *   <ConfirmDialog
 *     message="Some message to be confirmed"
 *     open={dialogOpen}
 *     onClose={toggleDialog}
 *     onYes={handleYesClick}
 *     onNo={handleNoClick}
 *   />
 */

export const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props) => {
  const {
    open,
    title,
    message,
    yesLabel,
    noLabel,
    onYes,
    onNo,
    closable,
    onClose
  } = props;
  const {t} = useTranslation();

  const handleYesClick = useCallback(() => {
    onClose();
    if (onYes)
      onYes();
  }, [onClose, onYes]);

  const handleNoClick = useCallback(() => {
    onClose();
    if (onNo)
      onNo();
  }, [onClose, onNo]);

  return (
    <Dialog
      title={title || t('components.ui.confirm_dialog.title')}
      width="narrow"
      open={open}
      onClose={onClose}
      closable={closable}
    >
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          icon={<YesIcon />}
          onClick={handleYesClick}
        >
          {yesLabel || t('components.ui.confirm_dialog.yes')}
        </Button>
        <Button
          icon={<NoIcon />}
          onClick={handleNoClick}
        >
          {noLabel || t('components.ui.confirm_dialog.no')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.defaultProps = {
  closable: false
};