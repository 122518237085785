import {
  IRefreshFailureAction,
  IRefreshRequestAction,
  IRefreshSuccessAction,
  RefreshActionTypes
} from './auth-refresh.types';
import {IAuthRefreshResponse} from 'api';

/** Refresh access token */
export const refreshRequest = (): IRefreshRequestAction => ({
  type: RefreshActionTypes.request
});

export const refreshSuccess = (payload: IAuthRefreshResponse): IRefreshSuccessAction => ({
  type: RefreshActionTypes.success,
  payload: {
    ...payload,
    expiresAt: Date.now() + payload.expiresIn * 1000
  }
});

export const refreshFailure = (payload: IRequestError): IRefreshFailureAction => ({
  type: RefreshActionTypes.failure,
  payload
});
