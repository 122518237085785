import elementResizeDetectorMaker from 'element-resize-detector';
import {RefObject, useCallback, useEffect, useMemo, useState} from 'react';
import {IContainerQuery} from './use-container-query.types';

/**
 * Container query hook (media query based on container width instead of viewport width)
 *
 * @param containerRef - Container element reference
 * @param query - Container query
 *
 * @example
 *
 *   import {useTheme} from 'components/ui/theme';
 *   import {useContainerQuery} from 'hooks';
 *
 *   // ...
 *
 *   const theme = useTheme();
 *   const containerRef = useRef<HTMLDivElement>(null!)
 *   const matches = useContainerQuery(containerRef, {minWidth: theme.width.smMin});
 *
 *   // ...
 *
 *   <div ref={containerRef}>
 *     ...
 *   </div>
 */
export const useContainerQuery = (containerRef: RefObject<HTMLElement>, query: IContainerQuery) => {
  const [matches, setMatches] = useState(false);

  const elementResizeDetector = useMemo(() => {
    return elementResizeDetectorMaker({strategy: 'scroll'});
  }, []);

  const checkQuery = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const {minWidth, maxWidth} = query;
      const minWidthNotMatch = minWidth !== undefined && containerWidth < minWidth;
      const maxWidthNotMatch = maxWidth !== undefined && containerWidth > maxWidth;
      return !minWidthNotMatch && !maxWidthNotMatch;
    }
    return false;
  }, [containerRef, query]);

  const handleContainerResize = useCallback(() => {
    setMatches(checkQuery());
  }, [checkQuery]);

  useEffect(() => {
    if (containerRef.current) {
      handleContainerResize();
      elementResizeDetector.listenTo(containerRef.current, handleContainerResize);
      return elementResizeDetector.removeListener(containerRef.current, handleContainerResize);
    }
  }, [containerRef, handleContainerResize, elementResizeDetector]);

  return matches;
};
