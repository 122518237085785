import './pagination-button.scss';
import React, {FunctionComponent, useMemo, useContext} from 'react';
import {IPaginationButtonProps} from './pagination-button.types';
import {PaginationContext} from '../pagination-context';
import {Tooltip} from 'components/ui/tooltip';
import {Button} from 'components/ui/button';

/** Pagination button */
export const PaginationButton: FunctionComponent<IPaginationButtonProps> = (props) => {
  const {
    className,
    disabled,
    tooltip,
    onClick,
    children
  } = props;
  const {showTooltips} = useContext(PaginationContext);

  const classes = useMemo(() => {
    let classes = 'ui-pagination-button';
    if (disabled)
      classes += ' ui-pagination-button--disabled';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, disabled]);

  const button = useMemo(() => (
    <Button
      className={classes}
      onClick={onClick}
    >
      {children}
    </Button>
  ), [classes, onClick, children]);

  if (tooltip && showTooltips)
    return (
      <Tooltip title={tooltip}>
        {button}
      </Tooltip>
    );

  return button;
};
