import {Reducer} from 'redux';
import {LoginAction, LoginActionTypes} from 'actions';
import {LoginFormIdState} from './login-form-id.types';

const initialState: LoginFormIdState = null;

/** Login form component state */
export const loginFormId: Reducer<LoginFormIdState, LoginAction> = (state = initialState, action): LoginFormIdState => {
  switch (action.type) {
    case LoginActionTypes.request:
      return action.payload.loginFormId || null;
    default:
      return state;
  }
};
