import './accordion-summary.scss';
import React, {forwardRef, useMemo} from 'react';
import {AccordionSummaryProps} from './accordion-summary.types';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandIcon from '@material-ui/icons/ExpandMore';

/**
 * Accordion summary
 *
 * @example
 *
 *   import {Accordion} from 'components/ui/accordion';
 *   import {AccordionSummary} from 'components/ui/accordion-summary';
 *   import {AccordionDetails} from 'components/ui/accordion-details';
 *   
 *   // ...
 *   
 *   <Accordion>
 *     <AccordionSummary id="my-accordion">
 *       First item
 *     </AccordionSummary>
 *     <AccordionDetails>
 *       Item content
 *     </AccordionDetails>
 *   </Accordion>
 *   
 *   <Accordion>
 *     <AccordionSummary id="my-accordion">
 *       Second item
 *     </AccordionSummary>
 *     <AccordionDetails>
 *       Item content
 *     </AccordionDetails>
 *   </Accordion>
 */
export const AccordionSummary = forwardRef<HTMLDivElement, AccordionSummaryProps>(function AccordionSummary(props, ref) {
  const {className, children, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-accordion-summary';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <MuiAccordionSummary
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiAccordionSummary>
  );
});

AccordionSummary.defaultProps = {
  expandIcon: (<ExpandIcon />)
};