import './badge-kit.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Badge, IBadgeProps} from 'components/ui/badge';
import MailIcon from '@material-ui/icons/Mail';

/** Badge control preview */
export const BadgeKit: FunctionComponent = () => {
  const {t} = useTranslation();

  const defaultProps = useMemo<IBadgeProps>(() => ({
    children: (<MailIcon />),
    max: 999
  }), []);

  return (
    <div className="ui-badge-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.badge.heading')}
      </Typography>
      <div className="ui-badge-kit__item">
        <Badge
          {...defaultProps}
          badgeContent={9}
          color="success"
        />
      </div>
      <div className="ui-badge-kit__item">
        <Badge
          {...defaultProps}
          badgeContent={99}
          color="warning"
        />
      </div>
      <div className="ui-badge-kit__item">
        <Badge
          {...defaultProps}
          badgeContent={1000}
          color="error"
        />
      </div>
    </div>
  );
};
