import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from 'react';
import {useFetch, useFetchErrorMessage, useFetchSuccessMessage, useOnFetchSuccess} from 'hooks';
import {api, IAccessDemandCreationRequest} from 'api';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {IAccessDemandFormProps, IFormValues} from './access-demand-form.types';
import {useTranslation} from 'lib/intl/i18n';
import {Form} from 'components/ui/form';
import {AccessDemandFormFields, IGrantedPartner} from './access-demand-form-fields';

/** Access demand form */
export const AccessDemandForm: FunctionComponent<IAccessDemandFormProps> = (props) => {
  const {
    initialValues: initialValuesProp,
    onSuccess
  } = props;
  const operations = useFetch(api.accessDemandOperations);
  const roles = useFetch(api.accessDemandRoles);
  const accessDemand = useFetch(api.accessDemandCreation);
  const {t} = useTranslation();
  const [partners, setPartners] = useState<IGrantedPartner[]>([]);

  const fetchData = useCallback(() => {
    operations.fetch();
    roles.fetch();
  }, [operations, roles]);
  
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const initialValues = useMemo<IFormValues>(() => ({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    operationId: null,
    grantedRoleId: null,
    ...initialValuesProp
  }), [initialValuesProp]);

  const handleSubmit = useCallback((values: IFormValues) => {
    const grantedPartnerIds = partners.map(({id}) => id);
    const payload = {...values, grantedPartnerIds};
    payload.grantedRoleId = payload.grantedRoleId || roles.data![0].id; 
    accessDemand.fetch(payload as IAccessDemandCreationRequest);
  }, [accessDemand, partners, roles.data]);

  useFetchErrorMessage([accessDemand], t('components.demand.access_demand_form.failure'));
  
  useFetchSuccessMessage([accessDemand], t('components.demand.access_demand_form.success'));

  useOnFetchSuccess([accessDemand], onSuccess);

  const render = useCallback(
    () => (
      <>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <AccessDemandFormFields
            operations={operations.data!}
            roles={roles.data!}
            partners={partners}
            onPartnersChange={setPartners}
            pending={accessDemand.pending}
          />
        </Form>
      </>
    ),
    [initialValues, handleSubmit, operations.data, roles.data, partners, accessDemand.pending]
  );

  return (
    <WaitForFetch
      pending={operations.pending || roles.pending}
      error={operations.error || roles.error}
      noData={!operations.data || !roles.data}
      onRetry={fetchData}
      render={render}
      minHeight={397}
    />
  );
};