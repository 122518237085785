import './button-kit.scss';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import {Typography} from 'components/ui/typography';
import ImageIcon from '@material-ui/icons/ImageOutlined';

/** Button control preview */
export const ButtonKit: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <div className="ui-button-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.button.heading')}
      </Typography>
      <ButtonActions>
        <Button>
          {t('views.app.ui_kit.button.type.primary')}
        </Button>
        <Button color="secondary">
          {t('views.app.ui_kit.button.type.secondary')}
        </Button>
        <Button color="success">
          {t('views.app.ui_kit.button.type.success')}
        </Button>
        <Button color="warning">
          {t('views.app.ui_kit.button.type.warning')}
        </Button>
        <Button color="error">
          {t('views.app.ui_kit.button.type.error')}
        </Button>
        <Button className="ui-button-kit__custom">
          {t('views.app.ui_kit.button.type.custom')}
        </Button>
        <Button icon={<ImageIcon />}>
          {t('views.app.ui_kit.button.type.left_icon')}
        </Button>
        <Button endIcon={<ImageIcon />}>
          {t('views.app.ui_kit.button.type.right_icon')}
        </Button>
        <Button disabled>
          {t('views.app.ui_kit.button.type.disabled')}
        </Button>
      </ButtonActions>
    </div>
  );
};
