import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IDemandCreationRequest, IDemandCreationResponse} from './demand-creation.types';

/** Demand creation */
export const demandCreation = async (request: IDemandCreationRequest) => {
  const response = await axios.post<IDemandCreationResponse>(urls.demandCreation(), request, {
    headers: {
      ...headers.auth(),
      ...headers.language(),
      ...headers.partner()
    }
  });
  return response.data;
};