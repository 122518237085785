import {Reducer} from 'redux';
import {DemandCreationAction, DemandCreationActionTypes} from 'actions';
import {IDemandCreationState} from './demand-creation.types';

const initialState: IDemandCreationState = {
  pending: false,
  error: null,
  data: null
};

/** Demand creation */
export const demandCreation: Reducer<IDemandCreationState, DemandCreationAction> = (state = initialState, action): IDemandCreationState => {
  switch (action.type) {
    case DemandCreationActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case DemandCreationActionTypes.success:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case DemandCreationActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};