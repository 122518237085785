import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Get list of demand statuses */
const demandStatusListSaga = function* () {
  try {
    const response = yield* call(api.demandStatusList);
    yield put(actions.demandStatusListSuccess(response));
  }
  catch (error) {
    yield put(actions.demandStatusListFailure(error as IRequestError));
  }
};

export const demandStatusList = function* () {
  yield takeLatest(actions.DemandStatusListActionTypes.request, demandStatusListSaga);
};