/**
 * @file Internationalization API
 *
 * @see https://formatjs.io/docs/react-intl/api
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl
 *
 * @example
 *
 *   // INTL API should be used through its intl/lib/format wrappers:
 *
 *   import {format} from 'intl/lib/format';
 *
 *   console.log(format.currency(123, 'EUR')); // €123.00
 *
 * @example
 *
 *   // Direct usage:
 *
 *   import {intlApi} from 'intl/lib/intl-api';
 *
 *   const price = intlApi.formatNumber(123, {
 *     style: 'currency',
 *     currency: 'EUR',
 *     currencyDisplay: 'symbol'
 *   });
 *
 *   console.log(price); // €123.00
 */

import {DEFAULT_LOCALE, SupportedLocales} from 'config/intl';
import {createIntl, createIntlCache, IntlShape} from 'react-intl';

/** INTL instance */
export let intlApi: IntlShape = null!;

/** Re-initialize INTL with new locale */
export const setIntlLocale = (locale: SupportedLocales = DEFAULT_LOCALE) => {
  const cache = createIntlCache();
  intlApi = createIntl(
    {
      locale,
      defaultLocale: DEFAULT_LOCALE
    },
    cache
  );
  return intlApi;
};

setIntlLocale();
