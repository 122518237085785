import React, {useContext, FunctionComponent, ChangeEvent, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {FilterContext} from '../demands-filter-context';
import {useFetch, useOnFetchSuccess} from 'hooks';
import {api} from 'api';
import {FilterComboBox, FilterComboBoxOption} from 'components/ui/filter-combo-box';
import TypeIcon from '@material-ui/icons/AssignmentTurnedInOutlined';

export const ALL_DEMAND_TYPES = 'all-types';

/** Demands filter demand type select */
export const TypeSelect: FunctionComponent = () => {
  const {
    getValues,
    setValues,
    FilterField
  } = useContext(FilterContext);
  const {typeId} = getValues();
  const demandTypes = useFetch(api.demandTypeList);
  const {t} = useTranslation();

  // Fetch list of demand types on mount
  useEffect(() => {
    demandTypes.fetch();
  // eslint-disable-next-line
  }, []);

  const demandTypeList = useMemo(() => {

    // Map demand list to combo box options
    const list = demandTypes.data?.map((demandType): FilterComboBoxOption => ({
      label: demandType.name,
      value: demandType.id
    }));

    // Add 'all types' option
    if (list && list.length > 1)
      list.unshift({
        label: t('views.demand.filter.all_types'),
        value: ALL_DEMAND_TYPES
      });
    
    return list;
  }, [demandTypes.data, t]);

  const setDemandType = useCallback((value: string) => {
    setValues({typeId: value});    
  }, [setValues]);

  useOnFetchSuccess([demandTypes], () => {
    if (demandTypeList)
      setDemandType(demandTypeList[0].value as string);
  });
  
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
    setDemandType(value as string);
  }, [setDemandType]);

  const disabled = !demandTypeList || demandTypeList.length <= 1;

  return (
    <FilterField
      pending={demandTypes.pending}
      error={demandTypes.error}
    >
      <FilterComboBox
        label={t('views.demand.filter.type')}
        labelIcon={<TypeIcon />}
        options={demandTypeList}
        value={typeId}
        disabled={disabled}
        onChange={handleChange}
      />
    </FilterField>
  );
};