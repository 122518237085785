import './wait-for-fetch.scss';
import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {usePrevious} from 'hooks';
import {IWaitForFetchProps} from './wait-for-fetch.types';
import {Preloader} from 'components/ui/preloader';
import {Failure} from 'components/api/failure';
import {NoData} from 'components/api/no-data';
import {Collapse} from 'components/ui/collapse';
import {Fade} from 'components/ui/fade';
import {Zoom} from 'components/ui/zoom';
import {useTheme} from 'components/ui/theme';

/**
 * Wait for fetch before data render and show loading animation or failure message
 *
 * @example
 *
 *   import {useFetch} from 'hooks';
 *   import {api} from 'api';
 * 
 *   // ...
 * 
 *   const list = useFetch(api.listRequest);
 *
 *   const renderItems = useCallback(() => {
 *     return list.data.map((item) => (
 *       <li key={item.id}>
 *         {item.value}
 *       </li>
 *     ));
 *   }, []);
 *
 *   // ...
 *
 *   <ul>
 *     <WaitForFetch
 *       pending={list.pending}
 *       error={list.error}
 *       noData={!list.data}
 *       onRetry={list.fetch}
 *       render={renderItems}
 *     />
 *   </ul>
 */
export const WaitForFetch: FunctionComponent<IWaitForFetchProps> = (props) => {
  const {
    absolutePosition,
    silent,
    hidden,
    pending,
    error,
    loaded: loadedProp,
    noData,
    noDataMessage,
    onRetry,
    effect = 'collapse',
    contrast,
    className,
    preloaderDisplay = 'container',
    preloaderSize = 'default',
    collapseOrientation,
    render,
    minHeight
  } = props;
  const theme = useTheme();
  const prevPending = usePrevious(pending);
  const [loaded, setLoaded] = useState(false);
  const visible = !pending && !error && !noData;

  useEffect(() => {
    if (!(!prevPending && !pending))
      setLoaded(!!((loadedProp && !pending) || (prevPending && !pending)));
  }, [loadedProp, pending, prevPending]); 

  const style = useMemo(() => {
    if (minHeight)
      return {minHeight};
  }, [minHeight]);
  
  const classes = useMemo(() => {
    let classes = 'ui-wait-for-fetch';
    if (!absolutePosition && !silent)
      classes += ' ui-wait-for-fetch--relative';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [absolutePosition, className, silent]);   

  if (hidden)
    return null;

  return (
    <div
      className={classes}
      style={style}
    >
      {!silent && (
        <>
          <Preloader
            visible={pending}
            display={preloaderDisplay}
            size={preloaderSize}
            contrast={contrast}
          />
          <Failure
            visible={!pending && !!error}
            onRetry={onRetry}
            contrast={contrast}
          />
          <NoData
            visible={loaded && !error && !!noData}
            message={noDataMessage}
          />
        </>
      )}
      {effect === 'collapse' && (
        <Collapse
          in={visible || !noData}
          orientation={collapseOrientation}
        >
          <Fade in={visible}>
            <div>
              {(visible || !noData) && render()}
            </div>
          </Fade>
        </Collapse>
      )}
      {effect === 'zoom' && (
        <Zoom
          in={visible || !noData}
          timeout={theme.speed.slow}
        >
          <Fade
            in={visible}
            timeout={theme.speed.slow}
          >
            <div>
              {(visible || !noData) && render()}
            </div>
          </Fade>
        </Zoom>
      )}
      {effect === 'fade' && (
        <Fade in={visible}>
          <div>
            {(visible || !noData) && render()}
          </div>
        </Fade>
      )}
      {effect === 'none' && visible && (
        (visible || !noData) && render()
      )}
    </div>
  );
};