import './contract-invoicing.scss';
import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {IContractInvoicingProps} from './contract-invoicing.types';
import {ButtonActions} from 'components/ui/button-actions';
import {ContractInvoicingDemand} from 'components/demand/contract-invoicing-demand';
import {Collapse} from 'components/ui/collapse';

/** Contract invoicing settings */
export const ContractInvoicing: FunctionComponent<IContractInvoicingProps> = (props) => {
  const {contractId} = props;
  const contracts = useFetch(api.contractList);
  
  const fetchContracts = useCallback(() => {
    contracts.fetch({contractId});
  }, [contractId, contracts]);
  
  useEffect(() => {
    fetchContracts();
  // eslint-disable-next-line
  }, []);
  
  const render = useCallback(() => {
    const contract = contracts.data![0];
    if (!contract.electronicInvoicingAvailable)
      return null;
    return  (
      <ButtonActions align="right">
        <ContractInvoicingDemand contractNumber={contract.number} />
      </ButtonActions>
    );
  }, [contracts.data]);

  const noData = useMemo(() => {
    return !contracts.data || !contracts.data[0];
  }, [contracts.data]);
  
  const hidden = useMemo(() => {
    const hideNoData = !contracts.pending && !contracts.error && noData;
    const hideNoInvoicing = contracts.data && contracts.data[0] && !contracts.data[0].electronicInvoicingAvailable;
    return hideNoData || hideNoInvoicing;
  }, [contracts.data, contracts.error, contracts.pending, noData]);

  return (
    <Collapse in={!hidden}>
      <div className="ui-contract-invoicing">
        <WaitForFetch
          pending={contracts.pending}
          error={contracts.error}
          noData={noData}
          hideNoDataMessage
          onRetry={fetchContracts}
          render={render}
          preloaderSize="small"
          absolutePosition
        />
      </div>
    </Collapse>
  );
};