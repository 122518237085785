import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IConsumptionListRequest, ConsumptionPagedListResponse} from './consumption-list.types';

/** Get the consumption of the consumption place */
export const consumptionList = async (request?: IConsumptionListRequest) => {
  const response = await axios.get<ConsumptionPagedListResponse>(urls.consumptionList(), {
    params: request,
    headers: {
      ...headers.auth(),
      ...headers.language(),
      ...headers.partner()
    }
  });
  return response.data;
};
