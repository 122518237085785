import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** User account activation */
const userActivationSaga = function* (action: actions.IUserActivationRequestAction) {
  try {
    yield call(api.userActivation, action.payload);
    yield put(actions.userActivationSuccess());
  }
  catch (error) {
    yield put(actions.userActivationFailure(error as IRequestError));
  }
};

export const userActivation = function* () {
  yield takeLatest(actions.UserActivationActionTypes.request, userActivationSaga);
};
