import './cookies-table.scss';
import React, {FunctionComponent, useCallback, useMemo} from 'react';
// import {useFetch} from 'hooks';
// import {api} from 'api';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
// import {WaitForFetch} from 'components/api/wait-for-fetch';
import {View} from 'components/ui/view';
import {CmsContent} from 'components/ui/cms-content';

const CONTENT_MOCK: Partial<Record<ISOLocaleCode, string>> = {
  'sk-SK': `
   
<p>Cieľom tejto politiky je informovať návštevníkov o povahe súborov cookie, ich funkciách a ich používaní. Jej zámerom je ďalej pomáhať návštevníkom vyjadriť svoju voľbu správnym nastavením.</p>

<h2>Čo je to súbor cookie?</h2>

<p>Cookies sú malé súbory, ktoré sa ukladajú na disku vášho zariadenia pri návšteve webovej stránky. Tieto súbory obsahujú náhodné reťazce alfanumerických znakov, ktoré identifikujú návštevníka a umožňujú tak správcovi webu zistiť, či sa daný návštevník na stránky vracia, ako často a aké sú jeho preferencie. Vďaka súborom cookie tiež môže zostať stále prihlásený, stránky si pamätajú jeho voľby a môžu mu oznamovať relevantné lokálne informácie.</p>

<p>Súbory cookie neslúžia na infikovanie počítačov vírusmi. Hneď ako sú pridelené, nezisťujú na zariadení návštevníka žiadne osobné informácie.</p>

<p>Jediné osobné údaje, ktoré môžu súbory cookie prípadne obsahovať, sú tie, ktoré zadá sám návštevník &ndash; napríklad vyplnením kontaktného formulára. V takom prípade sú osobné údaje zašifrované tak, aby ich mohol prečítať iba server, ktorý dané súbory cookie vytvoril. Pokiaľ návštevník na stránky žiadne svoje osobné údaje nezadá, stránky síce spoznajú, že sa na ne vracia osoba, ktorá ich už navštívila, ale nie sú schopné určiť, o koho ide.</p>

<p>Prevádzkovateľ je zodpovedný za to, aby daný prevod prebehol v súlade s európskymi zákonmi, a zabezpečí vhodné organizačné a technické opatrenia za účelom zabezpečenia bezpečného a dôverného prevodu dát.</p>

<h2>Na čo slúžia súbory cookie?</h2>

<p>Naše stránky používajú 3 kategórie cookie súborov na účely, ktoré sú popísané nižšie.</p>

<h3>Technické súbory cookie</h3>

<p>Tieto súbory umožňujú základné funkcie webovej stránky, ako je navigácia na stránke a prístup k zabezpečeným oblastiam webovej stránky, vďaka čomu je možné webové stránky používať. Bez týchto súborov cookie nemôže web správne fungovať.</p>

<table class="Cookies-table">
<thead>
<tr>
<th><strong>Názov</strong></th>
<th><strong>Funkcia</strong></th>
<th><strong>Životnosť</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td>persist:root</td>
<td>Slúži pre knižnicu tvoriacu state management (redux)</td>
<td>Trvalá</td>
</tr>
<tr>
<td>cookies_consent</td>
<td>Ukladá informáciu o súhlase s cookies</td>
<td>Trvalá</td>
</tr>
<tr>
<td>pageLoadProgress.hidden</td>
<td>Stav načítania dát pre stránku</td>
<td>Trvalá</td>
</tr>
<tr>
<td>pageLoadProgress.progress</td>
<td>Stav načítania dát pre stránku</td>
<td>Trvalá</td>
</tr>
<tr>
<td>pageLoadProgress.visible</td>
<td>Stav načítania dát pre stránku</td>
<td>Trvalá</td>
</tr>
<tr>
<td>refreshToken</td>
<td>Token pre obnovenie prihlásenia</td>
<td>Trvalá</td>
</tr>
<tr>
<td>accessToken</td>
<td>Token na identifikáciu po prihlásení</td>
<td>Trvalá</td>
</tr>
<tr>
<td>isProxyLogin</td>
<td>Autorizačné cookies</td>
<td>Trvalá</td>
</tr>
<tr>
<td>TS#</td>
<td>Cookie z F5 load balancera</td>
<td>Session</td>
</tr>
<tr>
<td>extendedValidity&nbsp;&nbsp; &nbsp;</td>
<td>Autorizačné cookies</td>
<td>Session</td>
</tr>
</tbody>
</table>

<h3>Preferenčné súbory cookie</h3>

<p>Umožňujú, aby si stránka pamätala informácie ohľadne toho, ako sa stránka chová alebo vyzerá. Napr. vaše prihlásenie alebo krajinu, z ktorej stránku navštevujete.</p>

<h3>Analytické súbory cookie</h3>

<p>Tieto súbory zhromažďujú informácie o používaní stránok, umožňujú merať ich výkonnosť a návštevnosť alebo vypracovať štatistiky a hlavne potom zmerať využitie obsahov &ndash; to všetko za účelom vylepšenia obsahu a užívateľského zážitku. Všetky dáta sa zbierajú anonymne a nemožno ich spojiť s konkrétnou osobou.</p>

<table class="Cookies-table">
<thead>
<tr>
<th><strong>Názov</strong></th>
<th><strong>Funkcia</strong></th>
<th><strong>Životnosť</strong></th>
</tr>
</thead>
<tbody>
<tr>
<td>_pk_ses#</td>
<td>Identifikuje na začiatku / konci návštevy pre meranie do nástroja Matomo</td>
<td>30 minút</td>
</tr>
<tr>
<td>_pk_id#</td>
<td>Anonymný identifikátor na meranie do nástroja Matomo</td>
<td>13 mesiacov</td>
</tr>
<tr>
<td>mtm_cookie_consent</td>
<td>Ukladá informáciu o súhlase s cookies pre nástroj Matomo</td>
<td>30 rokov</td>
</tr>
</tbody>
</table>

<h2 style="padding-top:15px;">Ako spravovať svoje súbory cookie?</h2>

<p>Návštevník má viac možností, ako spravovať súbory cookie dvoma nižšie uvedenými spôsobmi. Môže ich všetky prijať, odmietnuť alebo nastaviť vybrané kategórie, pričom svoju voľbu môže kedykoľvek zmeniť.</p>

<h3>Nastavenie na portáli (vyskakovacie pop-up okno)</h3>

<p>Pri prvom prihlásení môžete pomocou vyskakovacieho okna Prijať všetky cookies alebo nastaviť vaše preferencie pomocou tlačidla &ldquo;Upraviť&rdquo; a následne zaškrtnúť vybrané kategórie cookies &ndash; Preferenčné/Analytické cookies. Technické cookies sú vždy zapnuté a možno ich vypnúť len v nastavení prehliadača.</p>

<h3>Nastavenie v prehliadači</h3>

<p>Väčšina prehliadačov súbory cookie automaticky povoľuje. Užívateľ sa môže rozhodnúť blokovať ich. Nastavenie v prehliadači má prednosť pred nastavením na webovom portáli.</p>

<p>Vykonanie zmeny nastavenia závisí od typu prehliadača. Návody na zmenu nastavenia u vybraných internetových prehliadačov nájdete nižšie.</p>

<ul>
<li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=sk" target="_blank">Google Chrome</a></li>
<li><a href="https://support.microsoft.com/sk-sk/windows/microsoft-edge-%C3%BAdaje-preh%C4%BEad%C3%A1vania-a-ochrana-osobn%C3%BDch-%C3%BAdajov-bb8174ba-9d73-dcf2-9b4a-c582b4e640dd" target="_blank">Microsoft Edge</a></li>
<li><a href="https://support.mozilla.org/sk/kb/cookies-udaje-ktore-webove-stranky-ukladaju-na-vas-pocitac" target="_blank">Mozilla Firefox</a></li>
<li><a href="https://help.opera.com/cs/latest/web-preferences" target="_blank">Opera</a></li>
<li><a href="https://support.apple.com/sk-sk/guide/safari/sfri11471/mac" target="_blank">Safari</a></li>
</ul>

<h2 style="padding-top:15px;">Kde sa môžem dozvedieť viac?</h2>

<p>Úrad na ochranu osobných údajov (ÚOOÚ): Na stránky ÚOOÚ sa dostanete kliknutím <a href="https://dataprotection.gov.sk/uoou/" target="_blank">tu</a>.</p>
  `
}; 

/** Cookies info article view */
export const CookiesView: FunctionComponent = () => {
  // const cookiesInfo = useFetch(api.cookiesInfo);
  const localeCode = useSelector(selectors.locale);

  // useEffect(() => {
  //   cookiesInfo.fetch();
  // // eslint-disable-next-line
  // }, []);

  const content = useMemo(() => {
    return CONTENT_MOCK[localeCode];
  }, [localeCode]);

  const render = useCallback(() => (
    <CmsContent fromHtmlString={content /*cookiesInfo.data!.body*/} />
  ), [content]);

  return (
    <View>
      {/* <WaitForFetch
        pending={cookiesInfo.pending}
        error={cookiesInfo.error}
        noData={!cookiesInfo.data}
        onRetry={cookiesInfo.fetch}
        render={render}
      /> */}
      {render()}
    </View>
  );
};
