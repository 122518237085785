import {getErrorMessage} from 'lib/api/axios';
import {useOnFetchError, useWatchEffect} from 'hooks';
import {useSnackbar} from 'components/ui/snackbar';
import {SnackbarOptions} from './use-fetch-error-message.types';
import {useMemo} from 'react';
import uniqid from 'uniqid';

/**
 * Show error message when fetch fails
 * 
 * @example
 * 
 *   import {useFetch, useFetchErrorMessage} from 'hooks';
 *   import {api} from 'api';
 * 
 *   // ...
 * 
 *   const someRequest = useFetch(api.someApiCall);
 *   
 *   useFetchErrorMessage([someRequest], 'Optional default error message.');
 * 
 * @example
 * 
 *   // Check multiple requests
 *   useFetchErrorMessage([someRequest, anotherRequest]);
 */
export const useFetchErrorMessage = (requests: IRequestProgress[], defaultMessage?: string, snackbarOptions?: SnackbarOptions) => {
  const {showSnackbar, hideSnackbar} = useSnackbar();

  const snackbarId = useMemo(() => {
    return uniqid();
  }, []);

  const errorMessage = useMemo(() => {
    const requestError = requests.reduce((error: (IRequestError | null), request) => {
      return error || request.error;
    }, null);
    return getErrorMessage(requestError, defaultMessage);
  }, [defaultMessage, requests]);

  const pending = useMemo(() => {
    return requests.reduce((pending: boolean, request) => {
      return pending || request.pending;
    }, false);
  }, [requests]);

  useOnFetchError(requests, () => {
    showSnackbar(errorMessage, {
      id: snackbarId,
      variant: 'error',
      ...snackbarOptions
    });  
  });

  useWatchEffect(() => {
    if (pending)
      hideSnackbar(snackbarId);
  }, [pending]);
};