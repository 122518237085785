import './flexbox.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {IFlexboxProps} from './flexbox.types';
import {Box} from 'components/ui/box';

/**
 * Flexbox container
 * 
 * @example
 * 
 *   <Flexbox justifyContent="center">
 *     This content will be centered
 *   </Flexbox>
 */
export const Flexbox: FunctionComponent<IFlexboxProps> = (props) => {
  const {className, fullHeight} = props;

  const classes = useMemo(() => {
    let classes = 'ui-flexbox';
    if (fullHeight)
      classes += ' ui-flexbox--full-height';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, fullHeight]);

  return (
    <Box
      {...props}
      display="flex"
      className={classes}
    />
  );
};