import './dialog.scss';
import React, {forwardRef, useCallback, MouseEvent, useMemo, useRef, useState} from 'react';
import {useWatchEffect} from 'hooks';
import {triggerClickEvent} from 'lib/ui/dom';
import {IDialogProps, DialogCloseReason} from './dialog.types';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {useLocation} from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import {DraggableDialog} from './draggable-dialog';
import MuiDialog from '@material-ui/core/Dialog';
import {DialogTitle} from 'components/ui/dialog-title';
import Typography from '@material-ui/core/Typography';
import {IconButton} from 'components/ui/icon-button';
import CloseIcon from '@material-ui/icons/Close';
import {useTheme} from 'components/ui/theme';
import {DialogTransition} from './dialog-transition';

/**
 * Dialog window
 *
 * @see /src/views/app/kit-view/dialog-kit
 * 
 * @example
 *
 *   import {Dialog} from 'components/ui/dialog';
 *   import {DialogContent} from 'components/ui/dialog-content';
 *   import {DialogContentText} from 'components/ui/dialog-content-text';
 *   import {DialogActions} from 'components/ui/dialog-actions';
 *   import {Typography} from 'components/ui/Typography';
 *   import {Button} from 'components/ui/button';
 *
 *   // ...
 *
 *   <Dialog
 *     title="Title"
 *     width="narrow"
 *     open={dialogIsOpen}
 *     onClose={closeDialog}
 *   >
 *     <DialogContent>
 *       <DialogContentText>
 *         Lorem ipsum dolor sit amet. Consectetur adipiscing elit. Lorem ipsum dolor sit amet.
 *         Consectetur adipiscing elit. Lorem ipsum dolor sit amet.
 *       </DialogContentText>
 *     </DialogContent>
 *     <DialogActions>
 *       <Button>
 *         Yes
 *       </Button>
 *       <Button>
 *         No
 *       </Button>
 *     </DialogActions>
 *   </Dialog>
 */
export const Dialog = forwardRef<HTMLDivElement, IDialogProps>(function Dialog(props, ref) {
  const {
    className,
    title,
    closable,
    draggable,
    open,
    onClose,
    width,
    children,
    ...restProps
  } = props;
  const theme = useTheme();
  const fullScreen = useMediaQuery(`(max-width: ${theme.width.xsMax}px)`);
  const location = useLocation();
  const buttonRef = useRef(null!);
  const [dragging, setDragging] = useState(false);

  const handleClose = useCallback((event: MouseEvent, reason?: DialogCloseReason) => {
    if (onClose && reason !== 'backdropClick')
      onClose(event, 'escapeKeyDown');
  }, [onClose]);

  // Close dialog on route change
  useWatchEffect(() => {
    if (open)
      triggerClickEvent(buttonRef.current);
  }, [location.pathname]);

  const handleDragStart = useCallback(() => {
    setDragging(true);
  }, []);

  const handleDragStop = useCallback(() => {
    setDragging(false);
  }, []);

  const classes = useMemo(() => {
    let classes = 'ui-dialog';
    classes += ` ui-dialog--width-${width}`;
    if (draggable)
      classes += ' ui-dialog--draggable';
    if (draggable && dragging)
      classes += ' ui-dialog--dragging';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [width, draggable, dragging, className]);

  return (
    <MuiDialog
      {...restProps}
      ref={ref}
      className={classes}
      fullScreen={fullScreen}
      open={open}
      onClose={handleClose}
      scroll={fullScreen ? 'paper' : 'body'}
      PaperComponent={draggable ? DraggableDialog : Paper}
      TransitionComponent={DialogTransition}
      onMouseUp={handleDragStop}
      onMouseLeave={handleDragStop}
    >

      {/* Title */}

      {title && (
        <DialogTitle onMouseDown={handleDragStart}>
          <Typography variant="h2">
            {title}
          </Typography>
        </DialogTitle>
      )}

      {/* Close button */}

      {closable && (
        <IconButton
          ref={buttonRef}
          className="ui-dialog__close"
          size="small"
          onClick={handleClose}
        >
          <CloseIcon />
        </IconButton>
      )}

      {/* Contents */}

      {children}
    </MuiDialog>
  );
});

Dialog.defaultProps = {
  title: '',
  closable: true,
  draggable: true,
  width: 'normal'
};