import React, {FunctionComponent} from 'react';
import {IConsumptionFilterValues} from './consumption-filter.types';
import {withFilter} from 'components/ui/filter';
import {FilterContext} from './consumption-filter-context';
import {Grid} from 'components/ui/grid';
import {ContractSelect, ALL_CONTRACTS} from './consumption-filter-contract';
import {PlaceSelect, ALL_PLACES} from './consumption-filter-place';
import {EnergyTypeSelect, ALL_ENERGY_TYPES} from './consumption-filter-energy';
import {IntervalSelect} from './consumption-filter-interval';

const FilterComponent: FunctionComponent = () => {
  return (
    <Grid container>
      <Grid item xl={6}>
        <ContractSelect />
      </Grid>
      <Grid item xl={6}>
        <PlaceSelect />
      </Grid>
      <Grid item xl={6}>
        <EnergyTypeSelect />
      </Grid>
      <Grid item xl={6}>
        <IntervalSelect />
      </Grid>
    </Grid>
  );
};

export const ConsumptionFilter = withFilter<IConsumptionFilterValues>({
  filterId: 'consumption',

  FilterContext,

  initialValues: {
    contractId: null,
    placeId: null,
    energyTypeId: null,
    from: '',
    to: ''
  },
  
  isFilterComplete: (values) => (
    values.contractId !== null &&
    values.placeId !== null &&
    values.energyTypeId !== null
  ),

  transformValues: (values) => {
    if (values.contractId === ALL_CONTRACTS)
      values.contractId = null;
    if (values.placeId === ALL_PLACES)
      values.placeId = null;
    if (values.energyTypeId === ALL_ENERGY_TYPES)
      values.energyTypeId = null;
    return values;
  }
})(FilterComponent);