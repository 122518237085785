import React, {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Chart, ChartOptions} from 'components/ui/chart';

/** Chart control preview */
export const ChartKit: FunctionComponent = () => {
  const {t} = useTranslation();

  const options = useMemo<ChartOptions>(() => ({
    chart: {
      type: 'spline',
      events: {
        // eslint-disable-next-line object-shorthand
        load: function() {
          const series = this.series[0];
          setInterval(() => {
            const x = (new Date()).getTime();
            const y = Math.random();
            try {
              series.addPoint([x, y], true, true);
            }
            catch (error) {}
          }, 1000);
        }
      }
    },
    time: {
      useUTC: false
    },
    xAxis: {
      type: 'datetime',
      tickPixelInterval: 150
    },
    tooltip: {
      headerFormat: '<b>{series.name}</b><br/>',
      pointFormat: '{point.x:%Y-%m-%d %H:%M:%S}<br/>{point.y:.2f}'
    },
    legend: {
      enabled: false
    },
    series: [{
      type: 'spline',
      name: t('views.app.ui_kit.chart.series_name'),
      data: (() => {
        const data = [];
        const time = (new Date()).getTime();
        for (let i = -19; i <= 0; i += 1)
          data.push({
            x: time + i * 1000,
            y: Math.random()
          });
        return data;
      })()
    }]
  }), [t]);

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.chart.heading')}
      </Typography>
      <Chart options={options} />
    </>
  );
};
