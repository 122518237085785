import './kit-pagination.scss';
import React, {FunctionComponent, useCallback, useLayoutEffect} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {KitPaginationProps} from './kit-pagination.types';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import memoize from 'fast-memoize';
import PageIcon from '@material-ui/icons/DeveloperBoardSharp';

/** UI kit pagination */
export const KitPagination: FunctionComponent<KitPaginationProps> = (props) => {
  const {page, onChange} = props;
  const {t} = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const goToPage = useCallback(memoize((pageNumber: number) => () => {
    onChange(pageNumber);
  }), []);

  useLayoutEffect(() => {
    document.documentElement.scrollTop = 0;
  }, [page]);

  return (
    <ButtonActions
      className="ui-kit-pagination"
      align="center"
    >
      <Button
        icon={<PageIcon />}
        onClick={goToPage(0)}
        disabled={page === 0}
      >
        {t('views.app.ui_kit.pages.first')}
      </Button>
      <Button
        icon={<PageIcon />}
        onClick={goToPage(1)}
        disabled={page === 1}
      >
        {t('views.app.ui_kit.pages.second')}
      </Button>
      <Button
        icon={<PageIcon />}
        onClick={goToPage(2)}
        disabled={page === 2}
      >
        {t('views.app.ui_kit.pages.third')}
      </Button>
    </ButtonActions>
  );
};