import './form-label.scss';
import React, {forwardRef, useMemo} from 'react';
import {IFormLabelProps} from './form-label.types';
import MuiFormLabel from '@material-ui/core/FormLabel';
import {Tooltip} from 'components/ui/tooltip';
import HintIcon from '@material-ui/icons/Help';

/**
 * Form label
 *
 * @example
 *
 *   <FormLabel>
 *     Hello world!
 *   </FormLabel>
 * 
 * @example
* 
 *   <FormLabel hint="Some useful tip to display on hover">
 *     This label will have a hint icon
 *   </FormLabel>
 */
export const FormLabel = forwardRef<HTMLLabelElement, IFormLabelProps>(function FormLabel(props, ref) {
  const {
    className,
    icon,
    required,
    hint,
    disabled,
    children,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-form-label';
    if (required)
      classes += ' ui-form-label--required';
    if (disabled)
      classes += ' ui-form-label--disabled';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, disabled, required]);

  if (children === undefined || children === null || children === false)
    return null;

  const NBSP = '\u00a0';

  return (
    <MuiFormLabel
      ref={ref}
      {...restProps}
      className={classes}
    >
      {icon}
      <span>
        {children || NBSP}
      </span>
      {hint && (
        <Tooltip title={hint}>
          <HintIcon className="ui-form-label__hint" />
        </Tooltip>
      )}
    </MuiFormLabel>
  );
});
