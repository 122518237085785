/** @file Documentation */

import {
  IInvoiceStatusListRequestAction,
  IInvoiceStatusListSuccessAction,
  IInvoiceStatusListFailureAction,
  InvoiceStatusListActionTypes
} from './invoice-status-list.types';
import {InvoiceStatusListResponse} from 'api';

export const invoiceStatusListRequest = (): IInvoiceStatusListRequestAction => ({
  type: InvoiceStatusListActionTypes.request
});

export const invoiceStatusListSuccess = (payload: InvoiceStatusListResponse): IInvoiceStatusListSuccessAction => ({
  type: InvoiceStatusListActionTypes.success,
  payload
});

export const invoiceStatusListFailure = (payload: IRequestError): IInvoiceStatusListFailureAction => ({
  type: InvoiceStatusListActionTypes.failure,
  payload
});