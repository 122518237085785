import {Reducer} from 'redux';
import {DemandStatusListAction, DemandStatusListActionTypes} from 'actions';
import {IDemandStatusListState} from './demand-status-list.types';

const initialState: IDemandStatusListState = {
  pending: false,
  error: null,
  data: null
};

/** List of demand statuses */
export const demandStatusList: Reducer<IDemandStatusListState, DemandStatusListAction> = (state = initialState, action): IDemandStatusListState => {
  switch (action.type) {
    case DemandStatusListActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case DemandStatusListActionTypes.success:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case DemandStatusListActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};