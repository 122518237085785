import './app-breadcrumbs.scss';
import React, {FunctionComponent, useCallback} from 'react';
import {Container} from 'components/ui/container';
import {Divider} from 'components/ui/divider';
import {Breadcrumbs, useBreadcrumbs, IBreadcrumb} from 'components/ui/breadcrumbs';
import {Link} from 'components/ui/link';
// import {matchPath} from 'react-router-dom';
import {IAppMenuSubItem} from '../app-menu';
import {useMenu} from 'config/menu';

/** Application breadcrumbs */
export const AppBreadcrumbs: FunctionComponent = () => {
  const {breadcrumbs, urlTokenLabels} = useBreadcrumbs();
  const appMenuItems = useMenu();

  const matchMenuItem = useCallback((pathToMatch: string, items = appMenuItems) => {
    if (!items)
      return null;
    for (const item of items) {
      if (item.subMenu?.items) {
        const ActiveSubItem = matchMenuItem(pathToMatch, item.subMenu.items) as IAppMenuSubItem;
        if (ActiveSubItem !== null)
          return ActiveSubItem;
      }
      if (item.path === pathToMatch)
        return item;
    }
    return null;
  }, [appMenuItems]);

  const getBreadcrumbLabel = useCallback((breadcrumb: IBreadcrumb) => {
    const {path, urlToken} = breadcrumb;
    
    // User-defined URL token label (addUrlToken function)
    if (urlTokenLabels[urlToken])
      return urlTokenLabels[urlToken];
    
    // Label of main menu item with the same path as the breadcrumb
    const item = matchMenuItem(path);
    if (item)
      return item.breadcrumb || item.label;
    const tokens = path.split('/');
    return tokens[tokens.length - 1];
  }, [matchMenuItem, urlTokenLabels]);

  return (
    <Container className="ui-app-breadcrumbs">
      <Divider />
      <Breadcrumbs>
        {breadcrumbs.map((breadcrumb) => (
          <Link
            key={breadcrumb.path}
            to={breadcrumb.path}
          >
            {getBreadcrumbLabel(breadcrumb)}
          </Link>
        ))}
      </Breadcrumbs>
    </Container>
  );
};