import React, {useContext, FunctionComponent, ChangeEvent, useCallback, useEffect, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {FilterContext} from '../place-consumption-filter-context';
import {useFetch, useOnFetchSuccess} from 'hooks';
import {api} from 'api';
import {FilterComboBox, FilterComboBoxOption} from 'components/ui/filter-combo-box';
import PlaceIcon from '@material-ui/icons/Place';

/** Consumption filter place select */
export const PlaceSelect: FunctionComponent = () => {
  const {
    getValues,
    setValues,
    setAndStoreValues,
    getStoredValue,
    FilterField
  } = useContext(FilterContext);
  const {chartPlaceId} = getValues();
  const places = useFetch(api.placeOverviewList);
  const {t} = useTranslation();

  const fetchPlaces = useCallback(() => {
    places.fetch({size: 999999999});
  // eslint-disable-next-line
  }, []);

  // Fetch list of places
  useEffect(() => {
    fetchPlaces();
  // eslint-disable-next-line
  }, []);

  // Map list of places to combo box options
  const placesList = useMemo(() => {
    return places.data?.content.map((place): FilterComboBoxOption => ({
      label: place.name,
      value: place.id
    }));
  }, [places.data]);

  const getPlaceById = useCallback((placeId: number) => {
    return places.data?.content.find(({id}) => id === placeId);
  }, [places.data?.content]);

  const setPlace = useCallback((placeId: number) => {
    const {partnerId} = getPlaceById(placeId)!;
    setAndStoreValues({
      chartPlaceId: placeId,
      chartPlacePartnerId: partnerId
    });
  }, [getPlaceById, setAndStoreValues]);

  useOnFetchSuccess([places], () => {
    if (placesList) {
      const {value} = getStoredValue('chartPlaceId')
        .onlyIfIncludedInList(placesList)
        .orGetFirstFromList();
      setValues({
        placesList: places.data!.content,
        placesCount: placesList.length
      });
      setPlace(value as number);
    }
  });

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
    setPlace(value as number);
  }, [setPlace]);

  const disabled = !placesList || placesList.length <= 1;

  return (
    <FilterField
      pending={places.pending}
      error={places.error}
    >
      <FilterComboBox
        label={t('views.consumption.place.filter.place')}
        labelIcon={<PlaceIcon />}
        options={placesList}
        value={chartPlaceId}
        disabled={disabled}
        onChange={handleChange}
        virtualizedList
      />
    </FilterField>
  );
};