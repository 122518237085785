import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {useFetch} from 'hooks';
import {api} from 'api';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {useTranslation} from 'lib/intl/i18n';
import {View} from 'components/ui/view';
import {Typography} from 'components/ui/typography';
import {CmsContent} from 'components/ui/cms-content';

/** Terms & conditions view */
export const ConditionsView: FunctionComponent = () => {
  const article = useFetch(api.conditionsArticle);
  const {t} = useTranslation();

  useEffect(() => {
    article.fetch();
  // eslint-disable-next-line
  }, []);

  const render = useCallback(() => {
    const {title, body} = article.data!;
    
    return (
      <>
        {title && (
          <Typography
            variant="h2"
            gutterBottom
          >
            {title}
          </Typography>
        )}
        <CmsContent fromHtmlString={body} />
      </>
    );
  }, [article.data]);

  return (
    <View>
      <WaitForFetch
        pending={article.pending}
        error={article.error}
        noData={!article.data?.body}
        noDataMessage={t('views.article.conditions.no_data')}
        onRetry={article.fetch}
        render={render}
        absolutePosition
      />
    </View>
  );
};