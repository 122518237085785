import './card-actions.scss';
import React, {forwardRef, useMemo} from 'react';
import {CardActionsProps} from './card-actions.types';
import MuiCardActions from '@material-ui/core/CardActions';

/**
 * Card actions (links, buttons, ...)
 *
 * @example
 *
 *   import {Card} from 'components/ui/card';
 *   import {CardHeader} from 'components/ui/card-header';
 *   import {CardMedia} from 'components/ui/card-media';
 *   import {CardContent} from 'components/ui/card-content';
 *   import {CardActionArea} from 'components/ui/card-action-area';
 *   import {CardActions} from 'components/ui/card-actions';
 *
 *   // ...
 * 
 *   <Card>
 *     <CardActionArea>
 *       <CardHeader
 *         icon={<SomeIcon />}
 *         title="Card title"
 *       />
 *       <CardMedia
 *         image="/path/to/image.png"
 *         title="Image title"
 *       />
 *       <CardContent>
 *         Card content
 *       </CardContent>
 *     </CardActionArea>
 *     <CardActions>
 *       <Link to="https://some.path">
 *         Card action
 *       </Link>
 *     </CardActions>
 *   </Card>
 */
export const CardActions = forwardRef<HTMLDivElement, CardActionsProps>(function CardActions(props, ref) {
  const {className, children, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-card-actions';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <MuiCardActions
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiCardActions>
  );
});