import './contacts-view.scss';
import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {useTranslation} from 'lib/intl/i18n';
import {View} from 'components/ui/view';
import {RevealFade} from 'components/ui/reveal-fade';
import {Box} from 'components/ui/box';
import {CmsContent} from 'components/ui/cms-content';
import {Typography} from 'components/ui/typography';
import {ContactsTable} from './contacts-table';
import {Link} from 'components/ui/link';

/** Contacts view */
export const ContactsView: FunctionComponent = () => {
  const contacts = useFetch(api.contactList);
  const {t} = useTranslation();

  const fetchContacts = useCallback(() => {
    contacts.fetch();
  }, [contacts]);

  useEffect(() => {
    fetchContacts();
  // eslint-disable-next-line
  }, []);

  const render = useCallback(() => contacts.data!.content.map((contact) => (
    <RevealFade
      key={contact.id}
      className="ui-contacts-view__contact"
    >
      
      {/* Description */}
      
      <Box mb={3}>
        <CmsContent fromHtmlString={contact.description} />
      </Box>
      <Typography
        variant="h3"
        gutterBottom
      >
        {contact.title}:
      </Typography>
      {contact.hasContactEntries && (
        <ContactsTable contactId={contact.id} />
      )}
      {contact.link && (
        <Box mt={2}>
          <Link
            to={contact.link}
            target="_blank"
          >
            {contact.link}
          </Link>
        </Box>
      )}
    </RevealFade>
  )), [contacts.data]);

  return (
    <View>
      <WaitForFetch
        pending={contacts.pending}
        error={contacts.error}
        noData={!contacts.data?.content?.length}
        noDataMessage={t('views.article.contacts.no_data')}
        onRetry={fetchContacts}
        render={render}
      />
    </View>
  );
};