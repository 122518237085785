import './divider.scss';
import React, {forwardRef, useMemo} from 'react';
import {DividerProps} from './divider.types';
import MuiDivider from '@material-ui/core/Divider';

/**
 * Content divider (HR)
 *
 * @example
 *
 *    Lorem ipsum dolor sit amet.
 *    <Divider />
 *    Consectetur adipiscing elit.
 */
export const Divider = forwardRef<HTMLHRElement, DividerProps>(function Divider(props, ref) {
  const {className, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-divider';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <MuiDivider
      {...restProps}
      ref={ref}
      className={classes}
    />
  );
});
