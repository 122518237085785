import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import memoize from 'fast-memoize';
import {t} from 'lib/intl/i18n';

/**
 * Get exact length validator
 *
 * @param length - Expected value length
 */
export const exactLength = memoize((length: number) => {

  /**
   * Validate exact value length
   *
   * @param input - Value to validate
   * @returns Error message or undefined
   */
  const exactLength: IValidator = (input) => {
    const value = String(input || '').trim();
    if (value.length !== length)
      return t('lib.global.validate.length.invalid', {length});
  };

  return exactLength;
});

/**
 * Check that the exact length validator doesn't return any error
 *
 * @param input - Value to check
 * @param length - Expected value length
 */
export const hasExactLength: ValidatorQuery = (input, length: number) => {
  return !exactLength(length)(input);
};