import {intlApi} from 'lib/intl/intl-api';
import {UnitDisplay, ISuffixedUnit} from './unit.types';
import {number as formatNumber} from 'lib/intl/format/number';

const UNIT_PREFIX = {
  square: '0x00B2',
  cubic: '0x00B3'
};

/**
 * Support for volume and area units that are currently missing in the INTL API, e.g. square-meter or cubic-meter
 * 
 * @example
 * 
 *   format.unit(123, 'cubic-meter');
 *   // 123 m³
 */
const formatAreaAndVolume = (unit: MeasurementUnit): ISuffixedUnit => {
  const hasPrefix = Object.keys(UNIT_PREFIX).some((prefix) => {
    return unit.startsWith(`${prefix}-`);
  });
  if (hasPrefix) {
    const tokens = unit.split('-');
    const prefix = tokens[0] as (keyof typeof UNIT_PREFIX);
    const baseUnit = tokens.slice(1).join('-') as MeasurementUnit;
    const valueSuffix = String.fromCharCode(parseInt(UNIT_PREFIX[prefix], 16));
    return {baseUnit, suffix: valueSuffix};
  }
  return {baseUnit: unit, suffix: ''};
};

/**
 * Format measurement unit
 *
 * @example
 *
 *   format.unit(37, 'celsius');
 *   // 37 °C
 *
 * @example
 *
 *   format.unit(37, 'celsius', 'long');
 *   // 37 degrees Celsius
 *
 * @param value - Value to format
 * @param unit - Measurement unit
 * @param [display] - Unit display style
 * @returns Formatted value
 */
export const unit = (value: number, unit: MeasurementUnit, display?: UnitDisplay) => {
  
  // Quick workaround for kilowatt units missing in INTL
  if (unit === 'kilowatt')
    return `${formatNumber(value)} kW`;
  if (unit === 'kilowatt-hour')
    return `${formatNumber(value)} kWh`;

  const {baseUnit, suffix: valueSuffix} = formatAreaAndVolume(unit);
  const formattedValue = intlApi.formatNumber(value, {
    style: 'unit',
    unit: baseUnit,
    unitDisplay: display
  });
  return `${formattedValue}${valueSuffix}`;
};
