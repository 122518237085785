import './theme-switch.scss';
import React, {MouseEvent, FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {useTranslation} from 'lib/intl/i18n';
import {useTheme} from 'components/ui/theme';
import {IThemeSwitchProps} from './theme-switch.types';
import {IconButton} from 'components/ui/icon-button';
import ThemeIcon from '@material-ui/icons/FormatColorFillSharp';
import {DataMenu, DataMenuItems} from 'components/ui/data-menu';
import memoize from 'fast-memoize';

/**
 * Color theme switcher
 *
 * @example
 *
 *   <ThemeSwitch />
 */
export const ThemeSwitch: FunctionComponent<IThemeSwitchProps> = (props) => {
  const {className} = props;
  const theme = useTheme();
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const currentTheme = useSelector(selectors.theme);
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleThemeClick = useCallback(memoize((theme: string) => () => {
    closeMenu();
    dispatch(actions.setTheme({theme}));
  }), [dispatch]);

  const themeItems = useMemo<DataMenuItems>(() => theme.themes.map((themeName) => ({
    label: t(`config.theme.${themeName}`),
    onClick: handleThemeClick(themeName),
    selected: themeName === currentTheme
  })), [currentTheme, handleThemeClick, t, theme.themes]);
  
  const classes = useMemo(() => {
    let classes = 'ui-theme-switch';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <>
      <IconButton
        className={classes}
        size="small"
        onClick={openMenu}
      >
        <ThemeIcon className="ui-theme-switch__icon" />
      </IconButton>
      <DataMenu
        open={menuAnchor !== null}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        items={themeItems}
      />
    </>
  );
};