import {
  ILoginRequestAction,
  ILoginSuccessAction,
  ILoginFailureAction,
  LoginActionTypes,
  ILoginRequestPayload
} from './login.types';

/** Authenticate user and fetch account detail */
export const loginRequest = (payload: ILoginRequestPayload): ILoginRequestAction => ({
  type: LoginActionTypes.request,
  payload
});

export const loginSuccess = (): ILoginSuccessAction => ({
  type: LoginActionTypes.success
});

export const loginFailure = (payload: IRequestError): ILoginFailureAction => ({
  type: LoginActionTypes.failure,
  payload
});
