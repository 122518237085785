import {IAuthRefreshResponse} from 'api';

export enum RefreshActionTypes {
  request = 'AUTH_REFRESH_REQUEST',
  success = 'AUTH_REFRESH_SUCCESS',
  failure = 'AUTH_REFRESH_FAILURE'
}

export interface IRefreshSuccessPayload extends Omit<IAuthRefreshResponse, 'expiresIn'> {
  expiresAt: number;
}

export interface IRefreshRequestAction {
  type: RefreshActionTypes.request;
}

export interface IRefreshSuccessAction {
  type: RefreshActionTypes.success;
  payload: IRefreshSuccessPayload;
}

export interface IRefreshFailureAction {
  type: RefreshActionTypes.failure;
  payload: IRequestError;
}

export type RefreshAction = (
  IRefreshRequestAction |
  IRefreshSuccessAction |
  IRefreshFailureAction
);
