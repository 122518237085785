import React, {ChangeEvent, FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import memoize from 'fast-memoize';
import {Typography} from 'components/ui/typography';
import {Tabs, Tab} from 'components/ui/tabs';
import EmailIcon from '@material-ui/icons/Email';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import DeleteIcon from '@material-ui/icons/Delete';
import TimeIcon from '@material-ui/icons/AccessTime';
import ChartIcon from '@material-ui/icons/BarChart';
import SettingsIcon from '@material-ui/icons/Tune';
import PersonIcon from '@material-ui/icons/Person';
import OneIcon from '@material-ui/icons/LooksOneSharp';
import TwoIcon from '@material-ui/icons/LooksTwoSharp';
import ThreeIcon from '@material-ui/icons/Looks3Sharp';
import FourIcon from '@material-ui/icons/Looks4Sharp';
import FiveIcon from '@material-ui/icons/Looks5Sharp';
import SixIcon from '@material-ui/icons/Looks6Sharp';

/** Tabs preview */
export const TabsKit: FunctionComponent = () => {
  const {t} = useTranslation();
  const [values, setValues] = useState({
    icons: 0,
    labels: 0,
    iconsAndLabels: 0
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChange = useCallback(memoize((name: keyof typeof values) => (event: ChangeEvent<unknown>, value: number) => {
    setValues({
      ...values,
      [name]: value
    });
  }), [values]);

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.tabs.heading')}
      </Typography>

      {/* Icons */}

      <Typography
        variant="h3"
        gutterBottom
      >
        {t('views.app.ui_kit.tabs.type.icons')}
      </Typography>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={values.icons}
        onChange={handleChange('icons')}
      >
        <Tab icon={<EmailIcon />} />
        <Tab icon={<AddCircleIcon />} />
        <Tab icon={<EditIcon />} />
        <Tab icon={<SearchIcon />} />
        <Tab icon={<DeleteIcon />} />
        <Tab icon={<TimeIcon />} />
        <Tab icon={<ChartIcon />} />
        <Tab icon={<SettingsIcon />} />
        <Tab icon={<PersonIcon />} />
      </Tabs>
      <br />

      {/* Labels */}

      <Typography
        variant="h3"
        gutterBottom
      >
        {t('views.app.ui_kit.tabs.type.labels')}
      </Typography>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={values.labels}
        onChange={handleChange('labels')}
      >
        <Tab label={t('views.app.ui_kit.tabs.label.first')} />
        <Tab label={t('views.app.ui_kit.tabs.label.second')} />
        <Tab label={t('views.app.ui_kit.tabs.label.third')} />
        <Tab label={t('views.app.ui_kit.tabs.label.fourth')} />
        <Tab label={t('views.app.ui_kit.tabs.label.fifth')} />
        <Tab label={t('views.app.ui_kit.tabs.label.sixth')} />
        <Tab label={t('views.app.ui_kit.tabs.label.seventh')} />
        <Tab label={t('views.app.ui_kit.tabs.label.eighth')} />
        <Tab label={t('views.app.ui_kit.tabs.label.ninth')} />
        <Tab label={t('views.app.ui_kit.tabs.label.tenth')} />
      </Tabs>
      <br />

      {/* Icons and labels  */}

      <Typography
        variant="h3"
        gutterBottom
      >
        {t('views.app.ui_kit.tabs.type.icons_and_labels')}
      </Typography>
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={values.iconsAndLabels}
        onChange={handleChange('iconsAndLabels')}
      >
        <Tab
          icon={<OneIcon />}
          label={t('views.app.ui_kit.tabs.label.first')}
        />
        <Tab
          icon={<TwoIcon />}
          label={t('views.app.ui_kit.tabs.label.second')}
        />
        <Tab
          icon={<ThreeIcon />}
          label={t('views.app.ui_kit.tabs.label.third')}
        />
        <Tab
          icon={<FourIcon />}
          label={t('views.app.ui_kit.tabs.label.fourth')}
        />
        <Tab
          icon={<FiveIcon />}
          label={t('views.app.ui_kit.tabs.label.fifth')}
        />
        <Tab
          icon={<SixIcon />}
          label={t('views.app.ui_kit.tabs.label.sixth')}
        />
      </Tabs>
    </>
  );
};
