import {useCallback, useMemo, useRef, useState} from 'react';
import {IUseDebouncedStateResult, SetValue} from './use-debounced-state.types';

/**
 * Delay setting of the new state value
 * 
 * @example
 * 
 *   import {useState} from 'react';
 *   import {useDebouncedState} from 'hooks';
 * 
 *   // ...
 * 
 *   const [inputValue, setInputValue] = useState('');
 *   const search = useDebouncedState('');
 * 
 *   const onInputChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
 *     setInputValue(event.target.value);
 *     search.setValue(event.target.value);
 *   }, []);
 * 
 *   // Search will be debounced
 *   useEffect(() => {
 *     someSearchApi(search.value);
 *   }, [search.value]);
 * 
 *   // ...
 * 
 *   <TextField
 *     value={inputValue}
 *     onChange={handleInputChange}
 *   />
 */
export const useDebouncedState = <TValue>(initialValue: TValue, debounceDelay = 750) =>  {
  const [value, setValue] = useState(initialValue);
  const [pending, setPending] = useState(false);
  const timeout = useRef<Timeout>(null!);

  const setNewValue = useCallback<SetValue<TValue>>((newValue) => {
    clearTimeout(timeout.current);
    setPending(true);
    timeout.current = setTimeout(() => {
      setValue(newValue);
      setPending(false);
    }, debounceDelay);
  }, [debounceDelay]);

  const cancel = useCallback(() => {
    clearTimeout(timeout.current);
    setPending(false);
  }, []);

  return useMemo<IUseDebouncedStateResult<TValue>>(() => ({
    value,
    setValue: setNewValue,
    pending,
    cancel
  }), [value, setNewValue, pending, cancel]);
};