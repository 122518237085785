import './alert-kit.scss';
import React, {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IAlertOpenState} from './alert-kit.types';
import {ButtonActions} from 'components/ui/button-actions';
import {Alert} from 'components/ui/alert';
import {Button} from 'components/ui/button';
import InfoIconOutlined from '@material-ui/icons/InfoOutlined';
import SuccessIconOutlined from '@material-ui/icons/CheckCircleOutline';
import WarningIconOutlined from '@material-ui/icons/ReportProblemOutlined';
import ErrorIconOutlined from '@material-ui/icons/ErrorOutline';
import {Typography} from 'components/ui/typography';
import memoize from 'fast-memoize';

/** Alert component preview */
export const AlertKit: FunctionComponent = () => {
  const [alertIsOpen, setAlertIsOpen] = useState<IAlertOpenState>({
    info: false,
    success: false,
    warning: false,
    error: false
  });
  const {t} = useTranslation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const toggleAlert = useCallback(memoize((alertId: keyof IAlertOpenState) => () => {
    setAlertIsOpen((state) => ({
      ...state,
      [alertId]: !state[alertId]
    }));
  }), []);

  return (
    <div className="ui-alert-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.alert.heading')}
      </Typography>

      {/* Info */}

      <Alert
        variant="info"
        heading={t('views.app.ui_kit.alert.info.heading')}
        open={alertIsOpen['info']}
        onClose={toggleAlert('info')}
      >
        {t('views.app.ui_kit.alert.info.content')}
      </Alert>

      {/* Success */}

      <Alert
        variant="success"
        heading={t('views.app.ui_kit.alert.success.heading')}
        open={alertIsOpen['success']}
        onClose={toggleAlert('success')}
      >
        {t('views.app.ui_kit.alert.success.content')}
      </Alert>

      {/* Warning */}

      <Alert
        variant="warning"
        heading={t('views.app.ui_kit.alert.warning.heading')}
        open={alertIsOpen['warning']}
        onClose={toggleAlert('warning')}
      >
        {t('views.app.ui_kit.alert.warning.content')}
      </Alert>

      {/* Error */}

      <Alert
        variant="error"
        heading={t('views.app.ui_kit.alert.error.heading')}
        open={alertIsOpen['error']}
        onClose={toggleAlert('error')}
      >
        {t('views.app.ui_kit.alert.error.content')}
      </Alert>

      <ButtonActions className="ui-alert-kit__buttons">

        {/* Info */}

        <Button
          className="ui-alert-kit__info-button"
          icon={<InfoIconOutlined />}
          disabled={alertIsOpen['info']}
          onClick={toggleAlert('info')}
        >
          {t('views.app.ui_kit.alert.info.button')}
        </Button>

        {/* Success */}

        <Button
          color="success"
          icon={<SuccessIconOutlined />}
          disabled={alertIsOpen['success']}
          onClick={toggleAlert('success')}
        >
          {t('views.app.ui_kit.alert.success.button')}
        </Button>

        {/* Warning */}

        <Button
          color="warning"
          icon={<WarningIconOutlined />}
          disabled={alertIsOpen['warning']}
          onClick={toggleAlert('warning')}
        >
          {t('views.app.ui_kit.alert.warning.button')}
        </Button>

        {/* Error */}

        <Button
          color="error"
          icon={<ErrorIconOutlined />}
          disabled={alertIsOpen['error']}
          onClick={toggleAlert('error')}
        >
          {t('views.app.ui_kit.alert.error.button')}
        </Button>
      </ButtonActions>
    </div>
  );
};
