import './upload-item-icon.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {IUploadItemIconProps} from './upload-item-icon.types';
import {Collapse} from 'components/ui/collapse';
import {Tooltip} from 'components/ui/tooltip';
import {IconButton} from 'components/ui/icon-button';

/** File upload item icon */
export const UploadItemIcon: FunctionComponent<IUploadItemIconProps> = (props) => {
  const {
    className,
    icon,
    tooltip,
    visible,
    onClick
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-upload-item-icon';
    if (!onClick)
      classes += ' ui-upload-item-icon--disabled';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, onClick]);   

  return (
    <Collapse
      className={classes}
      in={visible}
      orientation="horizontal"
    >
      <Tooltip title={tooltip}>
        <IconButton
          size="small"
          onClick={onClick}
          disableRipple={!onClick}
          disableTouchRipple={!onClick}
          disableFocusRipple={!onClick}
        >
          {icon}
        </IconButton>
      </Tooltip>
    </Collapse>
  );
};