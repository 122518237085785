import './not-found-view.scss';
import React, {FunctionComponent} from 'react';
import {paths} from 'config/router';
import {useTranslation} from 'lib/intl/i18n';
import {View} from 'components/ui/view';
import {RevealZoom} from 'components/ui/reveal-zoom';
import {Container} from 'components/ui/container';
import {Typography} from 'components/ui/typography';
import {ReactComponent as NotFoundIcon} from './assets/not-found.svg';
import {Link} from 'components/ui/link';

/** Page not found view (404) */
export const NotFoundView: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <View className="ui-not-found-view">
      <RevealZoom>
        <NotFoundIcon className="ui-not-found-view__image" />
      </RevealZoom>
      <RevealZoom>
        <Container
          maxWidth="md"
          className="ui-not-found-view__content"
        >

          {/* Heading */}

          <Typography
            variant="h1"
            gutterBottom
            className="ui-not-found-view__heading"
          >
            {t('views.app.not_found.heading')}
          </Typography>

          {/* Message */}

          <Typography
            variant="body1"
            gutterBottom
          >
            {t('views.app.not_found.message')}
          </Typography>

          {/* Return to home page */}

          <Typography
            variant="body2"
            className="ui-not-found-view__homepage"
          >
            {t('views.app.not_found.return_to')}
            {' '}
            <Link
              to={paths.home}
              underline
            >
              {t('views.app.not_found.homepage')}
            </Link>
            {'.'}
          </Typography>
        </Container>
      </RevealZoom>
    </View>
  );
};
