import './password-reset-view.scss';
import React, {FunctionComponent, useCallback} from 'react';
import {paths} from 'config/router';
import {useNavigate} from 'react-router-dom';
import {View} from 'components/ui/view';
import {RevealFade} from 'components/ui/reveal-fade';
import {PasswordResetForm} from './password-reset-form';

/** Password reset view */
export const PasswordResetView: FunctionComponent = () => {
  const navigate = useNavigate();

  const redirectToHome = useCallback(() => {
    navigate(paths.home);
  }, [navigate]);
  
  return (
    <View className="ui-password-reset-view">
      <div className="ui-password-reset-view__content">
        <RevealFade>
          <PasswordResetForm onSuccess={redirectToHome} />
        </RevealFade>
      </div>
    </View>
  );
};