import './ordered-list-item.scss';
import React, {forwardRef, useMemo} from 'react';
import {IOrderedListItemProps} from './ordered-list-item.types';

/**
 * Ordered list item
 *
 * @example
 *
 *   import {OrderedList, OrderedListItem} from 'components/ui/ordered-list';
 * 
 *   // ...
 * 
 *   <OrderedList>
 *     <OrderedListItem>
 *       First item
 *     </OrderedListItem>
 *     <OrderedListItem>
 *       Second item
 *     </OrderedListItem>
 *     <OrderedListItem>
 *       Third item
 *     </OrderedListItem>
 *   </OrderedList>
 */
export const OrderedListItem = forwardRef<HTMLLIElement, IOrderedListItemProps>(function OrderedListItem(props, ref) {
  const {className, children} = props;
  
  const classes = useMemo(() => {
    let classes = 'ui-ordered-list-item';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <li
      ref={ref}
      className={classes}
    >
      {children}
    </li>
  );
});