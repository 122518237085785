import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {View} from 'components/ui/view';
import {NewsList} from 'components/article/news-list';
import {Pagination, usePagination} from 'components/ui/pagination';

const ROWS_PER_PAGE = 5;

/** News list view */
export const NewsListView: FunctionComponent = () => {
  const news = useFetch(api.newsList);
  const {currentPage, setCurrentPage} = usePagination();
  const {t} = useTranslation();

  useEffect(() => {
    news.fetch({
      page: currentPage - 1,
      size: ROWS_PER_PAGE
    });
  // eslint-disable-next-line
  }, [currentPage]);

  const render = useCallback(() => (
    <>
      <NewsList news={news.data!.content} />
      <Pagination
        gutterTop
        pageSize={ROWS_PER_PAGE}
        totalItemCount={news.data!.totalElements}
        currentPage={currentPage}
        onChange={setCurrentPage}
      />
    </>
  ), [currentPage, news.data, setCurrentPage]);

  return (
    <View>
      <WaitForFetch
        pending={news.pending}
        error={news.error}
        noData={!news.data?.content?.length}
        noDataMessage={t('views.article.news_list.no_data')}
        onRetry={news.fetch}
        render={render}
        absolutePosition
      />
    </View>
  );
};