import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {DemandPriorityListResponse} from './demand-priority-list.types';

/** Get a list of demand priorities */
export const demandPriorityList = async () => {
  const response = await axios.get<DemandPriorityListResponse>(urls.demandPriorityList(), {
    headers: {
      ...headers.auth(),
      ...headers.language()
    }
  });
  return response.data;
};