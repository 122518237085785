import './toggle-button.scss';
import React, {forwardRef, useMemo} from 'react';
import {ToggleButtonProps} from './toggle-button.types';
import MuiToggleButton from '@material-ui/core/ToggleButton';

/**
 * Toggle button
 * 
 * @see src/views/app/kit-view/toggle-button-kit
 *
 * @example
 *
 *   import {ToggleButton} from 'components/ui/toggle-button';
 *   import CheckIcon from '@material-ui/icons/CheckIcon';
 *
 *   // ...
 *
 *   <ToggleButton
 *     value="check"
 *     selected={selected}
 *     onChange={handleChange}
 *   >
 *     <CheckIcon />
 *   </ToggleButton>
 */
export const ToggleButton = forwardRef<HTMLButtonElement, ToggleButtonProps>(function ToggleButton(props, ref) {
  const {className, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-toggle-button';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <MuiToggleButton
      {...restProps}
      ref={ref}
      className={classes}
    />
  );
});
