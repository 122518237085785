import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types'; 
import memoize from 'fast-memoize';
import {t} from 'lib/intl/i18n';
import {date as parseDate} from 'lib/intl/parse/date';
import {date as formatDate} from 'lib/intl/format/date';
import {date as validateDate} from 'lib/global/validate/date/date';
import {logWarningOnDev} from 'lib/global/dev';

/**
 * Get maximum date validator
 *
 * @param maximumDate - Maximum date value
 */
export const maxDate = memoize((maximumDate: AnyDate) => {
  const invalidMaxDate = !parseDate(maximumDate).isValid();
  if (invalidMaxDate)
    logWarningOnDev(t('lib.global.validate.date.invalid'));

  /**
   * Validate maximum date
   *
   * @param input - Date to validate
   * @returns Error message or undefined
   */
  const maxDate: IValidator<AnyDate> = (input) => {
    if (!invalidMaxDate && input) {
      const isValid = !validateDate(input);
      const isSameOrBefore = parseDate(input).isSameOrBefore(parseDate(maximumDate));
      if (isValid && !isSameOrBefore) {
        const date = formatDate(maximumDate);
        return t('lib.global.validate.date.max', {date});
      }
    }
  };

  return maxDate;
});

/**
 * Check that the maximum date validator doesn't return any error
 *
 * @param input - Date to validate
 * @param maximumDate - Maximum date value
 */
export const isMaxDate: ValidatorQuery = (input, maximumDate: AnyDate) => {
  return !!input && !maxDate(maximumDate)(input);
};
