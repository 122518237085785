import {IAuthTokenRequest} from 'api';

export enum LoginActionTypes {
  request = 'LOGIN_REQUEST',
  success = 'LOGIN_SUCCESS',
  failure = 'LOGIN_FAILURE'
}

export interface ILoginRequestPayload extends IAuthTokenRequest {

  /**
   * Identify the form used to login to prevent displaying login errors in multiple places
   * 
   * @see lib/hooks/use-login-form
   * @see lib/hooks/use-login-error
   */
  loginFormId?: string;
}

export interface ILoginRequestAction {
  type: LoginActionTypes.request;
  payload: ILoginRequestPayload;
}

export interface ILoginSuccessAction {
  type: LoginActionTypes.success;
}

export interface ILoginFailureAction {
  type: LoginActionTypes.failure;
  payload: IRequestError;
}

export type LoginAction = (
  ILoginRequestAction |
  ILoginSuccessAction |
  ILoginFailureAction
);
