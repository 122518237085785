
/** Check if current environment is the development */
export const isDevEnvironment = () => {
  return process.env.NODE_ENV === 'development';
};

/** Log error to console only in dev environment */
export const logErrorOnDev = (error: string) => {
  if (isDevEnvironment())
    console.error(error);
};

/** Log warning message to console only in dev environment */
export const logWarningOnDev = (error: string) => {
  if (isDevEnvironment())
    console.warn(error);
};
