import './alert.scss';
import React, {forwardRef, ReactElement, useMemo} from 'react';
import {useTheme} from 'components/ui/theme';
import {AlertVariant, IAlertProps} from './alert.types';
import {IconButton} from 'components/ui/icon-button';
import {Collapse} from 'components/ui/collapse';
import {Fade} from 'components/ui/fade';
import MuiAlert from '@material-ui/core/Alert';
import AlertTitle from '@material-ui/core/AlertTitle';
import CloseIcon from '@material-ui/icons/Close';
import InfoIconOutlined from '@material-ui/icons/InfoOutlined';
import SuccessIconOutlined from '@material-ui/icons/CheckCircleOutline';
import WarningIconOutlined from '@material-ui/icons/ReportProblemOutlined';
import ErrorIconOutlined from '@material-ui/icons/ErrorOutline';

/**
 * Inline alert message
 * 
 * @see /src/views/app/kit-view/alert-kit
 *
 * @example
 *
 *   <Alert
 *     variant="error"
 *     heading="Error!"
 *     open={open}
 *     onClose={handleClose}
 *   >
 *     Error world!
 *   </Alert>
 *
 * @example
 * 
 *   import {Alert, useAlert} from 'components/ui/alert';
 *   
 *   // ...
 *   
 *   const alert = useAlert({variant: 'error'});
 *   
 *   const someErrorHandler = useCallback(() => {
 *     alert.open('Some error message.');
 *   }, []);
 *   
 *   // ...
 *   
 *   <Alert {...alert.props} />;
 */
export const Alert = forwardRef<HTMLDivElement, IAlertProps>(function Alert(props, ref) {
  const {
    className,
    variant,
    heading,
    closeIcon,
    open,
    onClose,
    children,
    ...restProps
  } = props;
  const theme = useTheme();

  const timeoutProp = useMemo(() => ({
    enter: theme.speed.fast,
    exit: theme.speed.slow
  }), [theme.speed.fast, theme.speed.slow]);

  const iconMappingProp = useMemo<Record<AlertVariant, ReactElement>>(() => ({
    info: (<InfoIconOutlined />),
    success: (<SuccessIconOutlined />),
    warning: (<WarningIconOutlined />),
    error: (<ErrorIconOutlined />)
  }), []);

  const classes = useMemo(() => {
    let classes = 'ui-alert';
    classes += ` ui-alert--${variant}`;
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, variant]);

  return (
    <Collapse
      in={open}
      timeout={timeoutProp}
      className={classes}
    >
      <Fade
        in={open}
        timeout={timeoutProp}
      >
        <MuiAlert
          ref={ref}
          variant="filled"
          severity={variant}
          iconMapping={iconMappingProp}
          action={closeIcon && (
            <IconButton
              className="ui-alert__close"
              size="small"
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          )}
          {...restProps}
        >
          {heading && (
            <AlertTitle>
              {heading}
            </AlertTitle>
          )}
          {children}
        </MuiAlert>
      </Fade>
    </Collapse>
  );
});

Alert.defaultProps = {
  variant: 'info',
  closeIcon: true
};
