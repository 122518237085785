import md5 from 'md5';
import {useCallback, useMemo} from 'react';
import {browser} from 'lib/global/browser';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {
  IUseFilterStorageOptions,
  IUseFilterStorageProps,
  SelectListItem,
  GetStoredValueFn,
  OrGetFirstFromListFn,
  AndNotEqualsToFn,
  OnlyIfIncludedInListFn
} from './use-filter-storage.types';
import {FilterValues} from '../with-filter.types';

/**
 * Filter values storage logic. All filter values are stored in local storage and URL query.
 * This allows past values to be restored the next time the filter is loaded, as well as setting filter values using URL.
 */
export const useFilterStorage = <T extends FilterValues<T>>(options: IUseFilterStorageOptions<T>): IUseFilterStorageProps<T> => {
  const {filterId, initialValues} = options;
  const userDetail = useSelector(selectors.userDetail);
  
  //--- Local storage
  
  const userName = useMemo(() => {
    if (userDetail)
      return userDetail.email;
    return 'unknown';
  }, [userDetail]);
  
  const storageKey = useMemo(() => {
    return `${md5(userName)}/${filterId}`;
  }, [filterId, userName]);

  const readValuesFromStorage = useCallback((): Record<keyof T, unknown> => {
    const storage = localStorage.getItem(storageKey) || '{}';
    return JSON.parse(storage);
  }, [storageKey]);

  const writeValueToStorage = useCallback((name: keyof T, value: unknown) => {
    const values = readValuesFromStorage();
    values[name] = value;
    localStorage.setItem(storageKey, JSON.stringify(values));
  }, [readValuesFromStorage, storageKey]);

  const readValueFromStorage = useCallback((valueName: keyof T) => {
    const values = readValuesFromStorage();
    return values[valueName];
  }, [readValuesFromStorage]);

  //--- URL query
  
  const writeValueToUrl = useCallback((name: string, value: unknown) => {
    if (value !== null && value !== '')
      browser.setQueryParam(name, value);
    else
      browser.removeQueryParam(name);
  }, []);

  const readValueFromUrl = useCallback((valueName: keyof T) => {
    return browser.getQueryParam(valueName as string);
  }, []);

  //--- Helpers

  const isSelectListItem = useCallback((item: unknown) => {
    return (
      item &&
      typeof item === 'object' &&
      item.hasOwnProperty('label') &&
      item.hasOwnProperty('value')
    );
  }, []);

  const getListItemValue = useCallback((item: unknown) => {
    if (isSelectListItem(item))
      return (item as SelectListItem).value;
    return item;
  }, [isSelectListItem]);

  const listIncludes = useCallback((list: unknown[], value: unknown) => {
    if (isSelectListItem(list[0]))
      return list.some((item) => {
        return String((item as SelectListItem).value) === String(value);
      });
    return list.some((item) => String(item) === String(value));
  }, [isSelectListItem]);

  //--- Filter storage API

  /** Store values in local storage and URL query */
  const storeValues = useCallback((values: Partial<T>) => {
    for (const [name, value] of Object.entries(values)) {
      writeValueToStorage(name as keyof T, value);
      writeValueToUrl(name, value);
    }
  }, [writeValueToStorage, writeValueToUrl]);

  /**
   * Get the filter value stored either as a URL parameter or in local storage
   *
   * @example
   *
   *   // Get the filter value stored either as a URL parameter or in local storage
   *   const {value} = getStoredValue('contract');
   *
   * @example
   *
   *   // Get a stored value only if it's included in the list
   *   const {value} = getStoredValue('contract').onlyIfIncludedInList(contracts);
   *
   * @example
   *
   *   // Get a stored value or the first item in a list
   *   const {value} = getStoredValue('contract').orGetFirstFromList(contracts);
   */
  const getStoredValue: GetStoredValueFn<T> = useCallback((valueName) => {

    // Get a stored value or the first item in a list
    const orGetFirstFromList = (value: unknown, defaultList = [] as unknown[]): OrGetFirstFromListFn => {
      return (list = defaultList) => {
        let result = value;
        const valueNotFound = value === null || value === undefined;
        if (valueNotFound && isSelectListItem(list[0]))
          result = getListItemValue(list[0]);
        return {value: result};
      };
    };

    // Get a stored value only if it doesn't equal a certain value
    const andNotEqualsTo = (value: unknown, list: unknown[]): AndNotEqualsToFn => {
      return (nonEqualValue: unknown) => {
        let result = null;
        if (value !== nonEqualValue)
          result = value;
        return {
          value: result,
          orGetFirstFromList: orGetFirstFromList(result, list)
        };
      };
    };

    // Get a stored value only if it's included in the list
    const onlyIfIncludedInList = (value: unknown): OnlyIfIncludedInListFn => {
      return (list: unknown[]) => {
        let result = null;
        if (listIncludes(list, value))
          result = value;
        return {
          value: result,
          andNotEqualsTo: andNotEqualsTo(result, list),
          orGetFirstFromList: orGetFirstFromList(result, list)
        };
      };
    };

    // Get the filter value stored either as a URL parameter or in local storage
    const getStoredValue = (valueName: keyof T) => {
      
      //--- URL query value

      const urlValue = readValueFromUrl(valueName);
      if (urlValue !== undefined && urlValue !== null)
        return urlValue;
      
      //--- Local storage value

      const storageValue = readValueFromStorage(valueName);
      if (storageValue !== undefined && storageValue !== null)
        return storageValue;
      
      //--- Initial value
      
      const initialValue = initialValues[valueName as keyof T];
      if (initialValue !== undefined)
        return initialValue;

      return null;
    };

    const value = getStoredValue(valueName);
    return {
      value,
      orGetFirstFromList: orGetFirstFromList(value),
      onlyIfIncludedInList: onlyIfIncludedInList(value)
    };
  }, [listIncludes, isSelectListItem, getListItemValue, readValueFromUrl, readValueFromStorage, initialValues]);

  return useMemo(() => ({
    storeValues,
    getStoredValue
  }), [getStoredValue, storeValues]);
};