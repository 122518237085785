import React from 'react';
import {useCallback} from 'react';
import {IAutocompleteProps, AutocompleteOption, RenderOption} from '../autocomplete.types';
import {IUseAutocompleteOptionsProps} from './use-autocomplete-options.types';
import {stripHtml} from 'string-strip-html';

/** Autocomplete options list related logic */
export const useAutocompleteOptions = (props: IAutocompleteProps): IUseAutocompleteOptionsProps => {
  const {renderOptionsAsHtml, renderOption: renderOptionProp} = props;

  const renderOption = useCallback<RenderOption>((props, option, state) => {
    if (renderOptionProp)
      return renderOptionProp(props, option, state);
    if (option.element)
      return (
        <li
          {...props}
          title={option.title}
        >
          {option.element}
        </li>
      );
    if (renderOptionsAsHtml)
      return (
        <li
          {...props}
          title={option.title}
          dangerouslySetInnerHTML={{__html: option.label}}
        />
      );
    return (
      <li
        {...props}
        title={option.title}
      >
        {option.label}
      </li>
    );
  }, [renderOptionProp, renderOptionsAsHtml]);

  const getOptionLabel = useCallback((option: AutocompleteOption) => {
    if (renderOptionsAsHtml)
      return stripHtml(option.label).result;
    return option.label;
  }, [renderOptionsAsHtml]);

  return {renderOption, getOptionLabel};
};
