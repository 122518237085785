import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useDeepEffect} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {useFetch} from 'hooks';
import {api} from 'api';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {format} from 'lib/intl/format';
import {IFilteredConsumptionTableProps} from './filtered-consumption-table.types';
import {ConsumptionTable} from 'components/consumption/consumption-table';
import {Pagination, usePagination} from 'components/ui/pagination';

const ROWS_PER_PAGE = 7;

/** Table of filtered consumption data */
export const FilteredConsumptionTable: FunctionComponent<IFilteredConsumptionTableProps> = (props) => {
  const {filter} = props;
  const consumptionList = useFetch(api.consumptionList);
  const {currentPage, setCurrentPage} = usePagination();
  const {t} = useTranslation();

  const fetchOptions = useMemo(() => {
    const {contractId, placeId, energyTypeId, from, to} = filter.values;
    return {
      contractId,
      consumptionPlaceId: placeId,
      energyTypeId,
      from: from && format.requestDate(from),
      to: to && format.requestDate(to)
    };
  }, [filter.values]);
  
  /** Get the data to be displayed in the consumption table */
  const fetchTableData = useCallback(() => {
    if (filter.isComplete)
      consumptionList.fetch({
        ...fetchOptions,
        page: currentPage - 1,
        size: ROWS_PER_PAGE
      });
  }, [filter.isComplete, consumptionList, currentPage, fetchOptions]);

  /** Get all consumption data for export */
  const fetchExportData = useCallback((paging?: IPagingRequest) => {
    return api.consumptionList({
      ...fetchOptions,
      ...paging
    });
  }, [fetchOptions]);

  // Fetch data on page load
  useEffect(() => {
    fetchTableData();
  // eslint-disable-next-line
  }, []);

  // Reset pagination on filter change
  useDeepEffect(() => {
    if (filter.isComplete)
      setCurrentPage(1);
  }, [filter.values, filter.isComplete]);

  // Fetch data on any change
  useDeepEffect(() => {
    fetchTableData();
  }, [filter.values, filter.isComplete, currentPage]);

  const render = useCallback(() => (
    <>
      <ConsumptionTable
        consumption={consumptionList.data!.content}
        exportDataSource={fetchExportData}
      />
      <Pagination
        gutterTop
        pageSize={ROWS_PER_PAGE}
        totalItemCount={consumptionList.data!.totalElements}
        currentPage={currentPage}
        onChange={setCurrentPage}
      />
    </>
  ), [consumptionList.data, fetchExportData, currentPage, setCurrentPage]);

  return (
    <WaitForFetch
      pending={consumptionList.pending}
      error={consumptionList.error}
      noData={!consumptionList.data?.content?.length}
      loaded={!!consumptionList.data}
      noDataMessage={t('views.consumption.table.no_data')}
      onRetry={fetchTableData}
      render={render}
    />
  );
};