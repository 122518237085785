import './download-button.scss';
import React, {forwardRef, useCallback, useMemo, useState, MouseEvent} from 'react';
import {useDownload} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {IDownloadButtonProps} from './download-button.types';
import {IconButton} from 'components/ui/icon-button';
import DownloadIcon from '@material-ui/icons/GetApp';
import {FileIcon} from 'components/ui/file-icon';
import {DataMenu, DataMenuItems} from 'components/ui/data-menu';
import {Tooltip} from 'components/ui/tooltip';

/**
 * Document download button
 *
 * @example
 *
 *   const myDocument: IDocumentResponse = {
 *     id: 123,
 *     size: 1234567,
 *     type: 'pdf',
 *     name: 'invoice_123'
 *     description: 'Some description'
 *   };
 * 
 *   // ...
 * 
 *   <DownloadButton documents={myDocument} />
 */
export const DownloadButton = forwardRef<HTMLButtonElement, IDownloadButtonProps>(function DownloadButton(props, ref) {
  const {
    className,
    documents: documentsProp,
    showTooltip,
    ...restProps
  } = props;
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const {download, pending} = useDownload();
  const {t} = useTranslation();

  const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const downloadDocument = useCallback((document: IDocumentResponse) => {
    closeMenu();
    download(document);
  }, [closeMenu, download]);

  const documents = useMemo<IDocumentResponse[] | null>(() => {
    if (!documentsProp)
      return null;
    if (!Array.isArray(documentsProp))
      return [documentsProp];
    return documentsProp;
  }, [documentsProp]);

  const noNameFile = useMemo(() => (
    <span className="ui-download-button__no-name">
      {t('components.ui.download_button.no_name')}
    </span>
  ), [t]);
  
  const menuItems = useMemo<DataMenuItems | null>(() => documents && documents.map((document) => ({
    icon: (<FileIcon type={document.type} />),
    label: document.name || noNameFile,
    onClick: () => downloadDocument(document)
  })), [documents, downloadDocument, noNameFile]);

  const handleButtonClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (documents) {
      if (documents.length > 1)
        openMenu(event);
      else
        downloadDocument(documents[0]);
    }
  }, [documents, downloadDocument, openMenu]);

  const tooltip = useMemo(() => {
    if (!documents)
      return t('components.ui.download_button.no_downloads');
    if (showTooltip)
      return t('components.ui.download_button.download');
    return false;
  }, [documents, showTooltip, t]);

  const classes = useMemo(() => {
    let classes = 'ui-download-button';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <>
      <Tooltip title={tooltip}>
        <span>
          <IconButton
            ref={ref}
            {...restProps}
            className={classes}
            pending={pending}
            onClick={handleButtonClick}
            disabled={!documents}
          >
            <DownloadIcon />
          </IconButton>
        </span>
      </Tooltip>
      {menuItems && (
        <DataMenu
          open={menuAnchor !== null}
          anchorEl={menuAnchor}
          onClose={closeMenu}
          items={menuItems}
        />
      )}
    </>
  );
});

DownloadButton.defaultProps = {
  size: 'small'
};