import './chart-menu.scss';
import React, {FunctionComponent, MouseEvent, useCallback, useMemo, useState} from 'react';
import {IChartMenuProps} from './chart-menu.types';
import {useTranslation} from 'lib/intl/i18n';
import {IconButton} from 'components/ui/icon-button';
import {DataMenu, DataMenuItems} from 'components/ui/data-menu';
import MenuIcon from '@material-ui/icons/Menu';
import FullscreenIcon from '@material-ui/icons/ZoomOutMap';
// import PrintIcon from '@material-ui/icons/PrintOutlined';
import PngIcon from '@material-ui/icons/ImageOutlined';
import XlsIcon from '@material-ui/icons/GridOnSharp';
import CsvIcon from '@material-ui/icons/ListAlt';
import PdfIcon from '@material-ui/icons/PictureAsPdfOutlined';

/** Chart actions menu */
export const ChartMenu: FunctionComponent<IChartMenuProps> = (props) => {
  const {chart} = props;
  const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
  const {t} = useTranslation();

  const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchor(event.currentTarget);
  }, []);

  const closeMenu = useCallback(() => {
    setMenuAnchor(null);
  }, []);

  const viewFullscreen = useCallback(() => {
    closeMenu();
    chart.fullscreen.toggle();
  }, [chart.fullscreen, closeMenu]);

  // const printChart = useCallback(() => {
  //   closeMenu();
  //   chart.print();
  // }, [chart, closeMenu]);

  const exportPng = useCallback(() => {
    closeMenu();
    chart.exportChart({type: 'image/png'}, {});
  }, [chart, closeMenu]);

  const exportXls = useCallback(() => {
    closeMenu();
    chart.downloadXLS();
  }, [chart, closeMenu]);

  const exportCsv = useCallback(() => {
    closeMenu();
    chart.downloadCSV();
  }, [chart, closeMenu]);

  const exportPdf = useCallback(() => {
    closeMenu();
    chart.exportChart({type: 'application/pdf'}, {});
  }, [chart, closeMenu]);

  const menuItems = useMemo<DataMenuItems>(() => [
    {
      icon: (<FullscreenIcon />),
      label: t('components.ui.chart_menu.fullscreen_view'),
      onClick: viewFullscreen
    },
    // {
    //   icon: (<PrintIcon />),
    //   label: t('components.ui.chart_menu.print_chart'),
    //   onClick: printChart
    // },
    {label: '-'},
    {
      icon: (<PngIcon />),
      label: t('components.ui.chart_menu.export_png'),
      onClick: exportPng
    },
    {
      icon: (<XlsIcon />),
      label: t('components.ui.chart_menu.export_xls'),
      onClick: exportXls
    },
    {
      icon: (<CsvIcon />),
      label: t('components.ui.chart_menu.export_csv'),
      onClick: exportCsv
    },
    {
      icon: (<PdfIcon />),
      label: t('components.ui.chart_menu.export_pdf'),
      onClick: exportPdf
    }
  ], [exportCsv, exportPdf, exportPng, exportXls, t, viewFullscreen]);

  return (
    <>
      <IconButton
        className="ui-chart-menu"
        size="small"
        onClick={openMenu}
      >
        <MenuIcon />
      </IconButton>
      <DataMenu
        open={menuAnchor !== null}
        anchorEl={menuAnchor}
        onClose={closeMenu}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'bottom'
        }}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'top'
        }}
        items={menuItems}
      />
    </>
  );
};
