import './menu.scss';
import React, {forwardRef, useMemo} from 'react';
import {IMenuProps} from './menu.types';
import MuiMenu from '@material-ui/core/Menu';

/**
 * Context menu
 * 
 * @see src/views/app/kit-view/menu-kit
 *
 * @example
 *
 *   import React, {FunctionComponent, useState, useCallback, MouseEvent} from 'react';
 *   import {Button} from 'components/ui/button';
 *   import {Menu, MenuItem} from 'components/ui/menu';
 *   import {ListItemText} from 'components/ui/list';
 *   import {Divider} from 'components/ui/divider';
 *
 *   // ...
 * 
 *   const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
 *
 *   const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
 *     setMenuAnchor(event.currentTarget);
 *   }, []);
 *
 *   const closeMenu = useCallback(() => {
 *     setMenuAnchor(null);
 *   }, []);
 *
 *   return (
 *     <>
 *       <Button onClick={openMenu}>
 *         Open menu
 *       </Button>
 *       <Menu
 *         open={menuAnchor !== null}
 *         anchorEl={menuAnchor}
 *         onClose={closeMenu}
 *       >
 *         <MenuItem>
 *           <ListItemText>
 *             First item
 *           </ListItemText>
 *         </MenuItem>
 *         <Divider />
 *         <MenuItem>
 *           <ListItemText>
 *             Second item
 *           </ListItemText>
 *         </MenuItem>
 *       </Menu>
 *     </>
 *   );
 */
export const Menu = forwardRef<HTMLDivElement, IMenuProps>(function Menu(props, ref) {
  const {
    className,
    margin,
    children,
    ...restProps
  } = props;
  
  const classes = useMemo(() => {
    let classes = 'ui-menu';
    if (margin)
      classes += ` ui-menu--margin-${margin}`;
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, margin]);   

  return (
    <MuiMenu
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiMenu>
  );
});

Menu.defaultProps = {
  anchorOrigin: {
    horizontal: 'center',
    vertical: 'bottom'
  },
  transformOrigin: {
    horizontal: 'center',
    vertical: 'top'
  },
  getContentAnchorEl: null,
  margin: 'top',
  elevation: 4
};