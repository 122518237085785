import './place-list.scss';
import React, {FunctionComponent} from 'react';
import {paths} from 'config/router';
import {useTranslation} from 'lib/intl/i18n';
import {IPlaceListProps} from './place-list.types';
import {Link} from 'components/ui/link';
import ConsumptionIcon from '@material-ui/icons/Equalizer';

/** List of consumption places */
export const PlaceList: FunctionComponent<IPlaceListProps> = (props) => {
  const {places} = props;
  const {t} = useTranslation();
  
  return (
    <div className="ui-place-list">
      
      {/* No places */}
      
      {!places && (
        <div className="ui-place-list__no-places">
          {t('views.user.contracts.no_places')}
        </div>
      )}

      {/* Places list */}
      
      {places && (
        <ul className="ui-place-list__list">
          {places.map((place) => (
            <li
              key={place.id}  
              className="ui-place-list__item"
            >
              <Link
                className="ui-place-list__consumption"
                to={`${paths.consumption}?placeId=${place.id}`}
              >
                <ConsumptionIcon />
                {t('views.user.contracts.consumption')}
              </Link>
              {' '}
              {place.name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};