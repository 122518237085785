import {
  IPasswordResetConfirmRequestAction,
  IPasswordResetConfirmSuccessAction,
  IPasswordResetConfirmFailureAction,
  PasswordResetConfirmActionTypes
} from './password-reset-confirm.types';
import {IPasswordResetConfirmRequest} from 'api';

/** Confirm user password reset */
export const passwordResetConfirmRequest = (payload: IPasswordResetConfirmRequest): IPasswordResetConfirmRequestAction => ({
  type: PasswordResetConfirmActionTypes.request,
  payload
});

export const passwordResetConfirmSuccess = (): IPasswordResetConfirmSuccessAction => ({
  type: PasswordResetConfirmActionTypes.success
});

export const passwordResetConfirmFailure = (payload: IRequestError): IPasswordResetConfirmFailureAction => ({
  type: PasswordResetConfirmActionTypes.failure,
  payload
});
