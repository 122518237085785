import React, {forwardRef} from 'react';
import {FormPickerFieldProps} from './form-picker-field.types';
import {PickerField} from 'components/ui/picker-field';
import {FormFieldError} from 'components/ui/form-field-error';

/**
 * Formik wrapper for picker field (internal)
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {DatePicker} from 'components/ui/date-picker';
 *   import {FormPickerField} from 'components/ui/form-picker-field';
 *   
 *   // ...
 *   
 *   <DatePicker
 *     ...
 *     FieldComponent={FormPickerField}
 *   />
 */
export const FormPickerField = forwardRef<HTMLDivElement, FormPickerFieldProps>(function FormPickerField(props, ref) {
  const {field, meta, errorMessage, ...restProps} = props;

  return (
    <>
      <PickerField
        ref={ref}
        {...restProps}
        name={field.name}
        onBlur={field.onBlur}
      />
      <FormFieldError>
        {errorMessage || meta.touched && meta.error}
      </FormFieldError>
    </>
  );
});