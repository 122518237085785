import React, {ElementType, forwardRef, useCallback, useMemo} from 'react';
import {ContainerQuery as Query} from 'react-container-query';
import {ChildFunction} from 'react-container-query/src/interfaces';
import {IContainerQueryProps} from './container-query.types';
import classNames from 'classnames';

/**
 * Container queries (media queries based on container width instead of viewport width)
 *
 * @example
 *
 *   import ContainerQuery, {IQueryClasses} from 'components/container-query';
 *   import {useTheme} from 'components/ui/theme';
 *
 *   // ...
 *
 *   const theme = useTheme();
 *
 *   const queryClassNames = useMemo<IQueryClasses>(() => ({
 *     'ui-md-min': {minWidth: theme.width.mdMin},
 *     'ui-lg-max': {maxWidth: theme.width.lgMax}
 *   }), [theme.width]);
 *
 *   // ...
 *
 *   <ContainerQuery
 *     className="ui-my-component"
 *     queryClassNames={queryClassNames}
 *   >
 *     ...
 *   </ContainerQuery>
 *
 * @example
 *
 *  .ui-my-component {
 *    display: none;
 *
 *    &.ui-md-min.ui-lg-max {
 *      display: block;
 *    }
 *  }
 */
export const ContainerQuery = forwardRef<unknown, IContainerQueryProps>(function ContainerQuery(props, ref) {
  const {
    className,
    queryClasses,
    observe,
    component: componentProp,
    children,
    ...restProps
  } = props;

  const component = useMemo<ElementType>(() => {
    return componentProp || 'div';
  }, [componentProp]);

  const render = useCallback<ChildFunction>((params) => {
    let classes = className || '';
    classes += ` ${classNames(params)}`;
    return React.createElement(
      component,
      {
        ...restProps,
        ref,
        className: classes
      },
      children
    );  
  }, [children, className, component, ref, restProps]);
  
  if (observe)
    return (
      <Query query={queryClasses}>
        {render}
      </Query>
    );

  return React.createElement(component, restProps, children);
});

ContainerQuery.defaultProps = {
  observe: true
};