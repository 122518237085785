import React, {FunctionComponent, useMemo} from 'react';
import {useParams} from 'react-router-dom';
import {View} from 'components/ui/view';
import {Box} from 'components/ui/box';
import {ContractInfo} from './contract-info';
import {ContractDocuments} from './contract-documents';
import {ContractPlaces} from './contract-places';
import {ContractInvoicing} from './contract-invoicing';

/** Contract view */
export const ContractView: FunctionComponent = () => {
  const params = useParams();

  const contractId = useMemo(() => {
    return parseInt(params.contractId);
  }, [params.contractId]);

  return (
    <View>
      <Box mb={5}>
        <ContractInfo contractId={contractId} />
      </Box>
      <Box mb={5}>
        <ContractDocuments contractId={contractId} />
      </Box>
      <ContractPlaces contractId={contractId} />
      <ContractInvoicing contractId={contractId} />
    </View>
  );
};