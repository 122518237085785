import React, {FunctionComponent, useCallback, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import {Typography} from 'components/ui/typography';
import LeftIcon from '@material-ui/icons/ArrowBack';
import RightIcon from '@material-ui/icons/ArrowForward';
import {Sidebar} from 'components/ui/sidebar';

/** Sidebar control preview */
export const SidebarKit: FunctionComponent = () => {
  const [leftSidebar, setLeftSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(false);
  const {t} = useTranslation();
  
  const toggleLeftSidebar = useCallback(() => {
    setLeftSidebar((open) => !open);
  }, []);

  const toggleRightSidebar = useCallback(() => {
    setRightSidebar((open) => !open);
  }, []);
  
  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.sidebar.heading')}
      </Typography>

      {/* Left sidebar */}

      <Sidebar
        heading={t('views.app.ui_kit.sidebar.panel.left')}
        open={leftSidebar}
        onClose={toggleLeftSidebar}
      />
      <ButtonActions>
        <Button
          icon={<LeftIcon />}
          onClick={toggleLeftSidebar}
        >
          {t('views.app.ui_kit.sidebar.panel.left')}
        </Button>

        {/* Right sidebar */}

        <Sidebar
          heading={t('views.app.ui_kit.sidebar.panel.right')}
          side="right"
          open={rightSidebar}
          onClose={toggleRightSidebar}
        />
        <Button
          endIcon={<RightIcon />}
          onClick={toggleRightSidebar}
        >
          {t('views.app.ui_kit.sidebar.panel.right')}
        </Button>
      </ButtonActions>
    </>
  );
};
