import React, {forwardRef, useEffect, useMemo} from 'react';
import {ILoginDialogProps} from './login-dialog.types';
import {useSelector} from 'react-redux';
import {usePrevious} from 'hooks';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {LoginForm} from 'components/user/login-form';

/**
 * Login form dialog
 * 
 * @example
 * 
 *   const [dialogOpen, setDialogOpen] = useState(false);
 *   
 *   const toggleDialog = useCallback(() => {
 *     setDialogOpen((open) => !open);
 *   }, []);
 *   
 *   // ...
 *   
 *   <LoginDialog
 *     open={dialogOpen}
 *     onClose={toggleDialog}
 *   />
 */  
export const LoginDialog = forwardRef<HTMLDivElement, ILoginDialogProps>(function LoginDialog(props, ref) {
  const {className, open, onClose} = props;
  const loginPending = useSelector(selectors.loginPending);
  const prevLoginPending = usePrevious(loginPending);
  const {t} = useTranslation();

  useEffect(() => {
    if (prevLoginPending && !loginPending && open)
      onClose();
  }, [loginPending, onClose, open, prevLoginPending]);

  const classes = useMemo(() => {
    let classes = 'ui-login-dialog';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <Dialog
      ref={ref}
      open={open}
      onClose={onClose}
      className={classes}
      title={t('components.user.login_dialog.title')}
    >
      <DialogContent>
        <LoginForm />
      </DialogContent>
    </Dialog>
  );
});