import './autocomplete-popper.scss';
import React, {ReactElement, useCallback} from 'react';
import {Popper, PopperProps} from 'components/ui/popper';
import * as Css from 'csstype';

/** Autocomplete list */
export const AutocompletePopper = React.forwardRef<HTMLDivElement, PopperProps>(function ComboBoxTransition(props, ref) {
  const {children, ...restProps} = props;

  const renderContent = useCallback((transitionProps) => {
    const {placement} = transitionProps;
    const verticalOrigin = placement === 'bottom' ? 'top' : 'bottom';
    const style: Css.Properties = {transformOrigin: `center ${verticalOrigin}`};
    return (
      <div
        className="ui-autocomplete-popper__content"
        style={style}
      >
        {children as ReactElement}
      </div>
    );
  }, [children]);

  return (
    <Popper
      ref={ref}
      {...restProps}
    >
      {renderContent}
    </Popper>
  );
});
