import {useRef, useEffect} from 'react';

/**
 * Use previous value
 * 
 * @example
 * 
 *   const MyComponent: FunctionComponent<MyProps> = (props) => {
 *     const prevValue = usePrevious(props.value);
 *     ...
 *   };
 */
export const usePrevious = <T>(value: T) => {
  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};
