import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {paths} from 'config/router';
import {useFetch, useOnFetchFinish} from 'hooks';
import {api} from 'api';
import {validate} from 'lib/global/validate';
import {getErrorMessage} from 'lib/api/axios';
import {IPasswordResetFormProps, IFormValues} from './password-reset-form.types';
import {useTranslation} from 'lib/intl/i18n';
import {useSnackbar} from 'components/ui/snackbar';
import {Form} from 'components/ui/form';
import {Grid} from 'components/ui/grid';
import {FormTextField} from 'components/ui/form-text-field';
import {Box} from 'components/ui/box';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import SendIcon from '@material-ui/icons/Check';
import urlJoin from 'url-join';

/**
 * Password reset form
 * 
 * @example
 * 
 *   <PasswordResetForm />
 */
export const PasswordResetForm: FunctionComponent<IPasswordResetFormProps> = (props) => {
  const {onSuccess} = props;
  const passwordReset = useFetch(api.passwordReset);
  const {showSnackbar} = useSnackbar();
  const {t} = useTranslation();

  const initialValues = useMemo<IFormValues>(() => ({
    login: ''
  }), []);

  const redirectUrl = useMemo(() => {
    const url = urlJoin(window.location.origin, paths.passwordResetConfirm);
    return `${url}?guid=#GUID#`;
  }, []);

  const handleSubmit = useCallback((values: IFormValues) => {
    passwordReset.fetch({
      login: values.login,
      redirectUrl
    });
  }, [passwordReset, redirectUrl]);

  useOnFetchFinish([passwordReset], () => {
    const {error} = passwordReset;
    if (error) {
      const message = getErrorMessage(error, t('views.user.password_reset.form.failure'));
      showSnackbar(message, {variant: 'error'});
    }
    else {
      showSnackbar(t('views.user.password_reset.form.success'), {variant: 'success'});
      if (onSuccess)
        onSuccess();
    }
  });

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Grid
        container
        disabled={passwordReset.pending}
      >

        {/* Login */}

        <Grid item>
          <FormTextField
            name="login"
            label={t('views.user.password_reset.form.label.login')}
            validate={validate.email}
            required
            autoFocus
          />
        </Grid>
      </Grid>

      {/* Submit */}

      <Box mt={4}>
        <ButtonActions align="center">
          <Button
            type="submit"
            icon={<SendIcon />}
            pending={passwordReset.pending}
          >
            {t('views.user.password_reset.form.submit')}
          </Button>
        </ButtonActions>
      </Box>
    </Form>
  );
};