import React, {forwardRef, useCallback, FocusEvent, useMemo, useState} from 'react';
import {FormSliderRangeProps} from './form-slider-range.types';
import {useFormField} from 'hooks';
import {SliderRange} from 'components/ui/slider-range';
import {FormFieldError} from 'components/ui/form-field-error';
import {useFormikContext, FormikValues} from 'formik';

/**
 * Formik wrapper for sliderrange
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormSlider} from 'components/ui/form-slider';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormSliderRange
 *       fromName="from"
 *       toName="to"
 *       label="someLabel"
 *     />
 *   </Form>
 */
export const FormSliderRange = forwardRef<HTMLSpanElement, FormSliderRangeProps>(function FormSliderRange(props, ref) {
  const {fromValidate, toValidate, ...restProps} = props;
  const {values, setValues} = useFormikContext();
  const formValues = values as FormikValues;
  const fromValue = formValues[props.fromName] || '';
  const toValue = formValues[props.toName] || '';
  const [sliderValues, setSliderValues] = useState({
    from: fromValue,
    to: toValue
  });

  const fromProps = useMemo(() => ({
    name: props.fromName,
    validate: fromValidate
  }), [fromValidate, props.fromName]);

  const toProps = useMemo(() => ({
    name: props.toName,
    validate: fromValidate
  }), [fromValidate, props.toName]);
  
  const fromFieldProps = useFormField(fromProps);
  
  const toFieldProps = useFormField(toProps);

  const handleChange = useCallback((from: number, to: number) => {
    setSliderValues({from, to});
  }, []);

  const handleBlur = useCallback((event: FocusEvent<HTMLSpanElement>) => {
    setValues({
      ...formValues,
      [props.fromName]: sliderValues.from,
      [props.toName]: sliderValues.to
    });
    fromFieldProps.field.onBlur(event);
    toFieldProps.field.onBlur(event);
  }, [formValues, fromFieldProps.field, props.fromName, props.toName, setValues, sliderValues.from, sliderValues.to, toFieldProps.field]);

  const touched = useMemo(() => {
    return fromFieldProps.meta.touched || toFieldProps.meta.touched;
  }, [fromFieldProps.meta.touched, toFieldProps.meta.touched]);

  const error = useMemo(() => {
    return fromFieldProps.meta.error || toFieldProps.meta.error;
  }, [fromFieldProps.meta.error, toFieldProps.meta.error]);

  return (
    <>
      <SliderRange
        ref={ref}
        {...restProps}
        fromValue={sliderValues.from}
        toValue={sliderValues.to}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <FormFieldError>
        {touched && error}
      </FormFieldError>
    </>
  );
});