import './dialog-actions.scss';
import React, {forwardRef, useMemo} from 'react';
import MuiDialogActions from '@material-ui/core/DialogActions';
import {IDialogActionsProps} from './dialog-actions.types';
import {ButtonActions} from 'components/ui/button-actions';

/**
 * Dialog window actions
 *
 * @see /src/views/app/kit-view/dialog-kit
 * 
 * @example
 *
 *   import {Dialog} from 'components/ui/dialog';
 *   import {DialogContent} from 'components/ui/dialog-content';
 *   import {DialogContentText} from 'components/ui/dialog-content-text';
 *   import {DialogActions} from 'components/ui/dialog-actions';
 *   import {Button} from 'components/ui/button';
 *
 *   // ...
 *
 *   <Dialog
 *     title="Title"
 *     width="narrow"
 *     open={dialogIsOpen}
 *     onClose={closeDialog}
 *   >
 *     <DialogContent>
 *       <DialogContentText>
 *         Lorem ipsum dolor sit amet. Consectetur adipiscing elit. Lorem ipsum dolor sit amet.
 *         Consectetur adipiscing elit. Lorem ipsum dolor sit amet.
 *       </DialogContentText>
 *     </DialogContent>
 *     <DialogActions>
 *       <Button>
 *         Yes
 *       </Button>
 *       <Button>
 *         No
 *       </Button>
 *     </DialogActions>
 *   </Dialog>
 */
export const DialogActions = forwardRef<HTMLDivElement, IDialogActionsProps>(function DialogActions(props, ref) {
  const {className, children, align, ...dialogProps} = props;

  const classes = useMemo<string>(() => {
    let classes = 'ui-dialog-actions';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <MuiDialogActions
      ref={ref}
      {...dialogProps}
      className={classes}
    >
      <ButtonActions align={align}>
        {children}
      </ButtonActions>
    </MuiDialogActions>
  );
});

DialogActions.defaultProps = {
  align: 'center'
};