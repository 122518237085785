import {Typography} from '@material-ui/core';
import {paths} from 'config/router';
import {useTranslation} from 'lib/intl/i18n';
import React, {FC} from 'react';
import {Link} from 'components/ui/link';

interface ConsentCookiesInfoProps {
  isCookiesPage: boolean;
}

export const ConsentCookiesInfo: FC<ConsentCookiesInfoProps> = ({isCookiesPage}) => {

  const {t} = useTranslation();
  const translationPath = 'components.main.app.cookies_popup';
  return (
    <>
      <Typography>
        {t(`${translationPath}.consent.info-1`)}
      </Typography>
      <Typography>
        {t(`${translationPath}.consent.info-2`)}
      </Typography>
      <Typography>
        {t(`${translationPath}.consent.info-3`)}
      </Typography>

      {!isCookiesPage && (
        <>
          {' '}
          {t(`${translationPath}.info_page.description`)}
          {' '}
          <Link href={paths.cookiesInfo} target="_blank">
           
           
            {t(`${translationPath}.info_page.link`)}
       
          </Link>
          .
        </>

      )}
    </>
  );
};
