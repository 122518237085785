import {Reducer} from 'redux';
import {SnackbarAction, SnackbarActionTypes} from 'actions';
import {SnackbarState} from './snackbar.types';

const initialState: SnackbarState = [];

/** Snackbar component state */
export const snackbar: Reducer<SnackbarState, SnackbarAction> = (state = initialState, action): SnackbarState => {
  switch (action.type) {
    case SnackbarActionTypes.show:
      return [
        ...state,
        action.payload
      ];
    case SnackbarActionTypes.hide:
      return state.filter((snackbar) => {
        return snackbar.id !== action.payload.id && snackbar.key !== action.payload.key;
      });
    default:
      return state;
  }
};
