import React, {FunctionComponent, useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';
import {validate} from 'lib/global/validate';
import {Grid} from 'components/ui/grid';
import {FormTextField} from 'components/ui/form-text-field';
import {FormComboBox, ComboBoxOptions} from 'components/ui/form-combo-box';
import {Upload} from 'components/ui/upload';
import {Box} from 'components/ui/box';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import SendIcon from '@material-ui/icons/Check';
import {IDemandFormFieldsProps} from './demand-form-fields.types';
import {FormLabel} from 'components/ui/form-label';

/** Demand form fields */
export const DemandFormFields: FunctionComponent<IDemandFormFieldsProps> = (props) => {
  const {
    disableSubmit,
    onAttachmentRequest,
    onAttachmentSuccess,
    onAttachmentError,
    onAttachmentRemove
  } = props;
  const demandTypeList = useSelector(selectors.demandTypeList, shallowEqual);
  const demandCreation = useSelector(selectors.demandCreation, shallowEqual);
  const {t} = useTranslation();

  const demandTypeOptions = useMemo((): (ComboBoxOptions | null) => {
    return demandTypeList.data && demandTypeList.data.map((demandType) => ({
      label: demandType.name,
      value: demandType.id
    }));
  }, [demandTypeList]);

  return (
    <>
      <Grid
        container
        disabled={demandCreation.pending}
      >

        {/* Subject */}

        <Grid item md={6}>
          <FormTextField
            name="subject"
            label={t('components.demand.demand_form.label.subject')}
            required
            autoFocus
          />
        </Grid>

        {/* Demand type */}

        <Grid item md={6}>
          <FormComboBox
            name="typeId"
            label={t('components.demand.demand_form.label.type')}
            options={demandTypeOptions}
            required
          />
        </Grid>

        {/* Description */}

        <Grid item>
          <FormTextField
            name="description"
            label={t('components.demand.demand_form.label.description')}
            multiline
            rows={5}
            required
          />
        </Grid>
  
        {/* Phone number */}

        <Grid item>
          <FormTextField
            name="phoneNumber"
            label={t('components.demand.demand_form.label.phone')}
            validate={validate.phone}
          />
        </Grid>

        {/* City */}

        <Grid item md={6}>
          <FormTextField
            name="city"
            label={t('components.demand.demand_form.label.city')}
          />
        </Grid>

        {/* Street */}

        <Grid item md={6}>
          <FormTextField
            name="street"
            label={t('components.demand.demand_form.label.street')}
          />
        </Grid>

        {/* Attachments */}

        <Grid item>
          <FormLabel>
            {t('components.demand.demand_form.label.attachments')}
          </FormLabel>
          <Upload
            onRequest={onAttachmentRequest}
            onSuccess={onAttachmentSuccess}
            onError={onAttachmentError}
            onRemove={onAttachmentRemove}
          />
        </Grid>
      </Grid>

      {/* Submit */}

      <Box mt={4}>
        <ButtonActions align="center">
          <Button
            type="submit"
            icon={<SendIcon />}
            pending={demandCreation.pending}
            disabled={disableSubmit}
          >
            {t('components.demand.demand_form.submit')}
          </Button>
        </ButtonActions>
      </Box>
    </>
  );
};