import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Get a list of energy types */
const energyTypeListSaga = function* () {
  try {
    const response = yield* call(api.energyTypeList);
    yield put(actions.energyTypeListSuccess(response));
  }
  catch (error) {
    yield put(actions.energyTypeListFailure(error as IRequestError));
  }
};

export const energyTypeList = function* () {
  yield takeLatest(actions.EnergyTypeListActionTypes.request, energyTypeListSaga);
};