/** @file Application routes */

import React, {useMemo} from 'react';
import {RouteArray} from './use-routes.types';
import {paths} from 'config/router';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {useTranslation} from 'lib/intl/i18n';
import {HomeView} from 'views/app/home-view';
import {KitView} from 'views/app/kit-view';
import {NotFoundView} from 'views/app/not-found-view';
import {ConsumptionView} from 'views/consumption/consumption-view';
import {DemandsView} from 'views/demand/demands-view';
import {InvoicesView} from 'views/invoice/invoices-view';
import {PaymentsView} from 'views/payment/payments-view';
import {ContactsView} from 'views/article/contacts-view';
import {NewsListView} from 'views/article/news-list-view';
import {NewsDetailView} from 'views/article/news-detail-view';
import {AboutUsView} from 'views/article/about-us-view';
import {ConditionsView} from 'views/article/conditions-view';
import {RegistrationView} from 'views/user/registration-view';
import {ActivationView} from 'views/user/activation-view';
import {LoginView} from 'views/user/login-view';
import {PasswordResetView} from 'views/user/password-reset-view';
import {PasswordResetConfirmView} from 'views/user/password-reset-confirm-view';
import {PartnerSelectView} from 'views/user/partner-select-view';
import {ContractsView} from 'views/user/contracts-view';
import {ContractView} from 'views/user/contract-view';
import {SettingsView} from 'views/user/settings-view';
import {CookiesView} from 'views/article/cookies-view';

/**
 * Get application routes
 * 
 * @example
 * 
 *   import {useRoutes} from 'config/router';
 * 
 *   ...
 * 
 *   const routes = useRoutes();
 */
export const useRoutes = () => {
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);
  const {t} = useTranslation();
  
  return useMemo<RouteArray>(() => [

    //--- Login
    
    {
      path: paths.login,
      element: (<LoginView />),
      title: t('config.router.title.login'),
      active: !userIsLoggedIn
    },

    //--- Home page

    {
      path: paths.home,
      element: (<HomeView />)
    },

    //--- Partner selection

    {
      path: paths.partnerSelect,
      element: (<PartnerSelectView />),
      title: t('config.router.title.partner_select')
    },

    //--- User registration

    {
      path: paths.registration,
      element: (<RegistrationView />),
      title: t('config.router.title.registration')
    },

    //--- User account activation

    {
      path: paths.activation,
      element: (<ActivationView />),
      title: t('config.router.title.activation')
    },

    //--- Cookies Info
    {
      path: paths.cookiesInfo,
      element: (<CookiesView />),
      title: t('config.router.title.cookies_info')
    },
    
    //--- Contract list

    {
      path: paths.contract,
      element: (<ContractsView />),
      title: t('config.router.title.contracts'),
      authenticate: true
    },

    //--- Contract detail

    {
      path: `${paths.contract}/:contractId`,
      element: (<ContractView />),
      title: t('config.router.title.contract'),
      authenticate: true
    },

    //--- Invoices list

    {
      path: paths.invoices,
      element: (<InvoicesView />),
      title: t('config.router.title.invoices'),
      authenticate: true
    },

    //--- Payments list

    {
      path: paths.payments,
      element: (<PaymentsView />),
      title: t('config.router.title.payments'),
      authenticate: true
    },

    //--- Demands list

    {
      path: paths.demands,
      element: (<DemandsView />),
      title: t('config.router.title.demands'),
      authenticate: true
    },

    //--- Consumption stats

    {
      path: paths.consumption,
      element: (<ConsumptionView />),
      title: t('config.router.title.consumption'),
      authenticate: true
    },

    //--- Terms and conditions

    {
      path: paths.conditions,
      element: (<ConditionsView />),
      title: t('config.router.title.conditions')
    },

    //--- About article

    {
      path: paths.about,
      element: (<AboutUsView />),
      title: t('config.router.title.about')
    },

    //--- News list

    {
      path: paths.news,
      element: (<NewsListView />),
      title: t('config.router.title.news_list')
    },

    //--- News detail

    {
      path: `${paths.news}/:id`,
      element: (<NewsDetailView />),
      title: t('config.router.title.news_detail')
    },

    //--- Password reset

    {
      path: paths.passwordReset,
      element: (<PasswordResetView />),
      title: t('config.router.title.password_reset')
    },

    //--- Password reset confirmation

    {
      path: paths.passwordResetConfirm,
      element: (<PasswordResetConfirmView />),
      title: t('config.router.title.password_reset_confirm')
    },

    //--- User account settings

    {
      path: paths.settings,
      element: (<SettingsView />),
      title: t('config.router.title.settings')
    },

    //--- Contacts list

    {
      path: paths.contacts,
      element: (<ContactsView />),
      title: t('config.router.title.contacts')
    },

    //--- UI kit

    {
      path: paths.uiKit,
      element: (<KitView />),
      title: t('config.router.title.ui_kit')
    },

    //--- Page not found (404)

    {
      path: '*',
      element: (<NotFoundView />),
      title: t('config.router.title.not_found')
    }
  ], [t, userIsLoggedIn]);
};