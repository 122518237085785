import {IGtmDataLayerItem} from './gtm.types';


/**
 * Push variables to google tag manager data layer (production only)
 * 
 * @param variables - Variables to push
 * 
 * @example
 *   
 *   import {gtm} from 'lib/global/gtm';
 * 
 *   gtm.pushToDataLayer({event: 'myEvent'});
 */
export const pushToDataLayer = (variables: IGtmDataLayerItem) => {

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(variables);
 
};