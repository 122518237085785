import './badge.scss';
import React, {forwardRef, useMemo} from 'react';
import {IBadgeProps} from './badge.types';
import MuiBadge from '@material-ui/core/Badge';

/**
 * Badge (event counter)
 * 
 * @see src/views/app/kit-view/badge-kit
 *
 * @example
 *
 *   <Badge badgeContent={123}>
 *     <MailIcon />
 *   </Badge>
 */
export const Badge = forwardRef<HTMLSpanElement, IBadgeProps>(function Badge(props, ref) {
  const {
    className,
    color,
    children,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-badge';
    classes += ` ui-badge--color-${color}`;
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [color, className]);

  return (
    <MuiBadge
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiBadge>
  );
});

Badge.defaultProps = {
  color: 'error'
};
