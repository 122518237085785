import './filter-text-field.scss';
import React, {forwardRef, useMemo} from 'react';
import {IFilterTextFieldProps} from './filter-text-field.types';
import {useFilterField} from 'components/ui/filter';
import {TextField} from 'components/ui/text-field';
import {FormFieldError} from 'components/ui/form-field-error';

/**
 * Filter wrapper for combo box field
 * 
 * @example
 * 
 *   import {useContext} from 'react';
 *   import {FilterContext} from './my-filter-context';
 * 
 *   // ...
 * 
 *   const {getValues, setValues} = useContext(FilterContext);
 *   const {myValue} = getValues();
 * 
 *   const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
 *     setValues({myValue: event.target.value});
 *   }, []);
 *
 *   // ...
 * 
 *   <FilterTextField
 *     name="someName"
 *     label="Some label"
 *     placeholder="Some placeholder..."
 *     value={value}
 *     onChange={handleChange}
 *   />
 */
export const FilterTextField = forwardRef<HTMLDivElement, IFilterTextFieldProps>(function FilterTextField(props, ref) {
  const {className, validate, ...restProps} = props;
  const {field, meta} = useFilterField(props);

  const classes = useMemo(() => {
    let classes = 'ui-filter-combo-box';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <>
      <TextField
        ref={ref}
        {...restProps}
        {...field}
        className={classes}
      />
      <FormFieldError>
        {meta.touched && meta.error}
      </FormFieldError>
    </>
  );
});