import {Reducer} from 'redux';
import {DemandTypeListAction, DemandTypeListActionTypes} from 'actions';
import {IDemandTypeListState} from './demand-type-list.types';

const initialState: IDemandTypeListState = {
  pending: false,
  error: null,
  data: null
};

/** List of demand types  */
export const demandTypeList: Reducer<IDemandTypeListState, DemandTypeListAction> = (state = initialState, action): IDemandTypeListState => {
  switch (action.type) {
    case DemandTypeListActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case DemandTypeListActionTypes.success:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case DemandTypeListActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};