import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Breadcrumbs} from 'components/ui/breadcrumbs';
import {Link} from 'components/ui/link';

/** Breadcrumbs control preview */
export const BreadcrumbsKit: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.breadcrumbs.heading')}
      </Typography>
      <Breadcrumbs>
        <Link>
          Abc
        </Link>
        <Link>
          Def
        </Link>
        <Link>
          Ghi
        </Link>
        <Link>
          Jkl
        </Link>
        <Link>
          Mno
        </Link>
      </Breadcrumbs>
    </>
  );
};
