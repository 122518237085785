import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {InvoiceStatusListResponse} from './invoice-status-list.types';

/** Get a list of invoice statuses */
export const invoiceStatusList = async () => {
  const response = await axios.get<InvoiceStatusListResponse>(urls.invoiceStatusList(), {
    headers: {
      ...headers.auth(),
      ...headers.language()
    }
  });
  return response.data;
};