import {Reducer} from 'redux';
import {PaymentStatusListAction, PaymentStatusListActionTypes} from 'actions';
import {IPaymentStatusListState} from './payment-status-list.types';

const initialState: IPaymentStatusListState = {
  pending: false,
  error: null,
  data: null
};

/** List of payment statuses */
export const paymentStatusList: Reducer<IPaymentStatusListState, PaymentStatusListAction> = (state = initialState, action): IPaymentStatusListState => {
  switch (action.type) {
    case PaymentStatusListActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case PaymentStatusListActionTypes.success:
      return {
        ...state,
        pending: false,
        data: action.payload
      };
    case PaymentStatusListActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};