import React, {forwardRef, useMemo} from 'react';
import {FormCheckBoxProps} from './form-checkbox.types';
import {useFormField} from 'hooks';
import {Checkbox} from 'components/ui/checkbox';
import {FormFieldError} from 'components/ui/form-field-error';

/**
 * Formik wrapper for checkbox
 * 
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {Form} from 'components/ui/form';
 *   import {FormCheckbox} from 'components/ui/form-checkbox';
 * 
 *   // ...
 * 
 *   <Form onSubmit={handleSubmit}>
 *     <FormCheckbox
 *       name="someName"
 *       label="Some label"
 *     />
 *   </Form>
 */
export const FormCheckbox = forwardRef<HTMLButtonElement, FormCheckBoxProps>(function FormCheckbox(props, ref) {
  const {validate, ...restProps} = props;
  
  const fieldProps = useMemo(() => ({
    ...props,
    type: 'checkbox'
  }), [props]);

  const {field, meta} = useFormField<boolean>(fieldProps);

  return (
    <>
      <Checkbox
        ref={ref}
        {...restProps}
        {...field}
      />
      <FormFieldError>
        {meta.touched && meta.error}
      </FormFieldError>
    </>
  );
});