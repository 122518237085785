import {createStore} from 'redux';
import {persistedReducer} from './redux-persist';
import {middlewares, sagaMiddleware} from './middlewares';
import {persistStore} from 'redux-persist';
import {sagas} from 'sagas';
import {AppState} from 'reducers';

export const store = createStore(persistedReducer, middlewares);
export const {dispatch} = store;
export const getState = store.getState as () => AppState;
export const persistor = persistStore(store);

sagaMiddleware.run(sagas);
