import React, {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IAlertDialogProps} from './alert-dialog.types';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {DialogContentText} from 'components/ui/dialog-content-text';
import {DialogActions} from 'components/ui/dialog-actions';
import {Button} from 'components/ui/button';
import OkIcon from '@material-ui/icons/Check';

/**
 * Information dialog
 * 
 * @example
 * 
 *   const [dialogOpen, setDialogOpen] = useState(false);
 * 
 *   const toggleDialog = useCallback(() => {
 *     setDialogOpen((prevOpen) => !prevOpen);
 *   }, []);
 *
 *   // ...
 * 
 *   <AlertDialog
 *     message="Some message"
 *     open={dialogOpen}
 *     onClose={toggleDialog}
 *   />
 */
export const AlertDialog: FunctionComponent<IAlertDialogProps> = (props) => {
  const {
    className,
    open,
    title,
    message,
    okLabel,
    onClose
  } = props;
  const {t} = useTranslation();

  const classes = useMemo<string>(() => {
    let classes = 'ui-alert-dialog';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <Dialog
      className={classes}
      title={title || t('components.ui.alert_dialog.title')}
      width="narrow"
      open={open}
      onClose={onClose}
      closable={false}
    >
      <DialogContent>
        <DialogContentText>
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          icon={<OkIcon />}
          onClick={onClose}
        >
          {okLabel || t('components.ui.alert_dialog.ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};