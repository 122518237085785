import './desktop-main-menu.scss';
import React, {FunctionComponent, useContext} from 'react';
import {AppMenuContext} from '../../app-menu-context';
import {DesktopMenuItems} from '../desktop-menu-items';

/** Desktop main menu */
export const DesktopMainMenu: FunctionComponent = () => {
  const {menuItems, activeItem} = useContext(AppMenuContext);

  return (
    <DesktopMenuItems
      className="ui-desktop-main-menu"
      items={menuItems}
      activeItemPath={activeItem?.path}
    />
  );
};