import {combineReducers} from 'redux';
import {theme} from './theme';
import {snackbar} from './snackbar';
import {loginFormId} from './login-form-id';
import {cookies} from './cookies';

export const ui = combineReducers({
  theme,
  snackbar,
  loginFormId,
  cookies
});
