/** @file Translation resources */

import {Resource} from 'i18next';
import en from './en/translation.json';
import sk from './sk/translation.json';

export const translations: Resource = {
  en: {translation: en},
  sk: {translation: sk}
};
