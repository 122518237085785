/** @file Extract color themes from /config/theme/themes.scss */

// eslint-disable-next-line import/no-webpack-loader-syntax
import themeStyles from 'sass-extract-loader!config/theme/themes.scss';
import {SassColor, SassList, SassMap, SassString} from 'types/extract-sass';
import extractSassSchema from 'schemas/extract-sass.schema.json';
import themesSchema from './themes.schema.json';
import {validateJsonSchema} from 'lib/global/json-schema';
import {IThemeColors, ThemesList} from '../theme.types';
import {camelCase} from 'change-case';

validateJsonSchema(themeStyles.global, [extractSassSchema, themesSchema], 'themesList', 'Color theme is not valid.');

const getThemesList = (sassThemes: SassMap) => {
  const themes: ThemesList = {};
  for (const [themeName, theme] of Object.entries(sassThemes.value)) {
    const camelThemeName = camelCase(themeName);
    themes[camelThemeName] = {} as IThemeColors;
    for (const [variableName, variable] of Object.entries((theme as SassMap).value)) {
      const camelVariableName = camelCase(variableName);
      themes[camelThemeName][camelVariableName as keyof IThemeColors] = (variable as SassColor).value.hex;
    }
  }
  return themes;
};

const getSubThemeNames = (sassSubThemes: (SassString | SassList | undefined)) => {
  if (!sassSubThemes)
    return null;
  if (sassSubThemes.type === 'SassString')
    return [sassSubThemes.value];
  return sassSubThemes.value.map((subThemeName) => {
    return (subThemeName as SassString).value;
  });
};

/** Color themes extracted from config/theme/themes.scss */
export const themes = getThemesList(themeStyles.global.$themes);

/** List of sub-theme names extracted from config/theme/themes.scss */
export const subThemes = getSubThemeNames(themeStyles.global['$sub-themes']);
