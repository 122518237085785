import {useMemo} from 'react';
import {useSelector, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {IUseLocaleProps} from './use-locale.types';
import {setLocale} from 'lib/intl/app-locale';

/**
 * Locale helper
 * 
 * @example
 * 
 * import {useLocale} from 'hooks/intl'
 * 
 * // ...
 * 
 * const {locale, setLocale} = useLocale();
 * 
 * // ...
 * 
 * console.log('Current application locale is: ', locale.code);
 */
export const useLocale = (): IUseLocaleProps => {
  const locale = useSelector(selectors.localeState, shallowEqual);

  return useMemo(() => ({
    locale,
    setLocale
  }), [locale]);
};