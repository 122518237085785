import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {t} from 'lib/intl/i18n';
import {number as parseNumber} from 'lib/intl/parse/number';

/**
 * Validate number value
 *
 * @param input - Value to validate
 * @returns Error message or undefined
 */
export const number: IValidator = (input) => {
  const value = String(input || '').trim();
  if (value && isNaN(parseNumber(value)))
    return t('lib.global.validate.number');
};

/**
 * Check that the number validator doesn't return any error
 *
 * @param input - Value to check
 */
export const isNumber: ValidatorQuery = (input) => {
  return !!input && !number(input);
};
