import './card-content.scss';
import React, {ElementType, forwardRef, ReactElement, useMemo} from 'react';
import {CardContentProps} from './card-content.types';
import MuiCardContent from '@material-ui/core/CardContent';

/**
 * Card content
 *
 * @example
 *
 *   import {Card} from 'components/ui/card';
 *   import {CardHeader} from 'components/ui/card-header';
 *   import {CardMedia} from 'components/ui/card-media';
 *   import {CardContent} from 'components/ui/card-content';
 *   import {CardActionArea} from 'components/ui/card-action-area';
 *   import {CardActions} from 'components/ui/card-actions';
 *
 *   // ...
 * 
 *   <Card>
 *     <CardActionArea>
 *       <CardHeader
 *         icon={<SomeIcon />}
 *         title="Card title"
 *       />
 *       <CardMedia
 *         image="/path/to/image.png"
 *         title="Image title"
 *       />
 *       <CardContent>
 *         Card content
 *       </CardContent>
 *     </CardActionArea>
 *     <CardActions>
 *       <Link to="https://some.path">
 *         Card action
 *       </Link>
 *     </CardActions>
 *   </Card>
 */
export const CardContent = forwardRef(function CardContent<T extends ElementType = 'div'>(props: CardContentProps<T>, ref: Unpack<CardContentProps<T>, 'ref'>) {
  const {className, children, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-card-content';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <MuiCardContent
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiCardContent>
  );
} as <T extends ElementType = 'div'>(props: CardContentProps<T> & {ref?: Unpack<CardContentProps<T>, 'ref'>;}) => ReactElement);
// ^ Make JSX generic parameters work with forwardRef
//
// @see https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref