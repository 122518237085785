import './place-consumption-filter.scss';
import React, {FunctionComponent, useContext} from 'react';
import {IPlaceConsumptionFilterValues} from './place-consumption-filter.types';
import {useTranslation} from 'lib/intl/i18n';
import {withFilter} from 'components/ui/filter';
import {FilterContext} from './place-consumption-filter-context';
import {Grid} from 'components/ui/grid';
import {Typography} from 'components/ui/typography';
import {PlaceSelect} from './place-consumption-filter-place';
import {EnergyTypeSelect} from './place-consumption-filter-energy';

const FilterComponent: FunctionComponent = () => {
  const {getValues} = useContext(FilterContext);
  const {placesCount} = getValues();
  const singlePlace = placesCount === 1;
  const {t} = useTranslation();

  return (
    <Grid
      container
      className="ui-place-consumption-filter"
    >

      {/* Title */}
      
      <Grid item lg={singlePlace ? 8 : 5}>
        <Typography
          variant="h2"
          className="ui-place-consumption-filter__title"
        >
          {t('views.consumption.place.filter.title')}
        </Typography>
        <Typography variant="body1">
          {singlePlace && t('views.consumption.place.filter.single_place')}
          {!singlePlace && t('views.consumption.place.filter.multiple_places')}
        </Typography>
      </Grid>

      {/* Filters */}

      <Grid
        item
        md={6}
        lg={singlePlace ? 4 : 3}
      >
        <EnergyTypeSelect />
      </Grid>
      {/* TODO: !singlePlace */}
      <Grid
        item
        md={6}
        lg={4}
      >
        <PlaceSelect />
      </Grid>
    </Grid>
  );
};

export const PlaceConsumptionFilter = withFilter<IPlaceConsumptionFilterValues>({
  filterId: 'placeConsumption',

  FilterContext,

  initialValues: {
    chartEnergyTypeId: null,
    chartPlaceId: null,
    chartPlacePartnerId: null,
    placesList: null,
    placesCount: 0
  },

  isFilterComplete: (values) => (
    values.chartEnergyTypeId !== null &&
    values.chartPlaceId !== null
  )
})(FilterComponent);