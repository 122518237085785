import {useMemo} from 'react';
import {useWatchEffect} from 'hooks';

/**
 * Effect to run on fetch failure
 *
 * @param requestProgress - Any object with "pending" and "error" properties
 * @param callback - Callback to fire on fetch failure
 *
 * @example
 *
 *   import {useFetch, useOnFetchError} from 'hooks';
 *
 *   // ...
 * 
 *   const MyComponent: FunctionComponent = () => {
 *     const list = useFetch(someApi.fetchSomeList);
 *
 *     useEffect(() => {
 *       list.fetch(); // calls someApi.fetchSomeList
 *     }, []);
 *
 *     useOnFetchError([list], () => {
 *       console.error('Fetch failed: ', list.error);
 *     });
 *   };
 * 
 * @example
 * 
 *   // Wait for multiple requests
 *
 *   useOnFetchError([someList, anotherList], () => {
 *     console.error('Fetch failed: ', someList.error || anotherList.error);
 *   });
 */
export const useOnFetchError = (requests: IRequestProgress[], callback: () => void) => {
  const pending = useMemo(() => {
    return requests.reduce((pending, request) => {
      return pending || request.pending;
    }, false);
  }, [requests]);

  const error = useMemo(() => {
    return requests.reduce((error: (IRequestError | null), request) => {
      return error || request.error;
    }, null);
  }, [requests]);

  useWatchEffect(() => {
    if (!pending && error)
      callback();
  }, [pending, error]);
};
