import React, {FunctionComponent} from 'react';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {HomeLastNews} from './home-last-news';
import {HomeNewsList} from './home-news-list';

/** News section */
export const HomeNews: FunctionComponent = () => {
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);
  
  if (!userIsLoggedIn)
    return (<HomeLastNews />);

  return (<HomeNewsList />);
};