import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IDemandListRequest, DemandPagedListResponse} from './demand-list.types';

/** Get a list of requested demands */
export const demandList = async (request?: IDemandListRequest) => {
  const response = await axios.get<DemandPagedListResponse>(urls.demandList(), {
    params: request,
    headers: {
      ...headers.auth(),
      ...headers.language(),
      ...headers.partner()
    }
  });
  return response.data;
};