import {useCallback, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {UseLoginErrorProps} from './use-login-error.types';
import {useSelector} from 'react-redux';
import {selectors} from 'selectors';
import {httpCodes} from 'lib/api/http-codes';

/**
 * Get login error
 * 
 * @example
 * 
 *   const loginError = useLoginError();
 *   
 *   useEffect(() => {
 *     if (loginError)
 *       console.log(loginError.message);
 *   }, [loginError]);
 */
export const useLoginError = (loginFormId?: string): UseLoginErrorProps => {
  const lastLoginFormId = useSelector(selectors.loginFormId);
  const loginError = useSelector(selectors.loginError);
  const loginPending = useSelector(selectors.loginPending);
  const {t} = useTranslation();
  
  const getMessage = useCallback(() => {
    if (loginError) {
      let messageId = 'general_error';
      if (loginError.status === httpCodes.UNAUTHORIZED_401)
        messageId = 'incorrect_credentials';
      else if (loginError.status === httpCodes.TOO_MANY_REQUESTS_429)
        messageId = 'too_many_requests';
      return t(`hooks.user.use_login_error.${messageId}`);
    }
    return '';
  }, [loginError, t]);

  return useMemo(() => {
    const formIdNotMatch = loginFormId && lastLoginFormId !== loginFormId;
    if (loginPending || !loginError || formIdNotMatch)
      return null;
    return {
      message: getMessage(),
      status: loginError.status
    };
  }, [loginError, loginPending, getMessage, lastLoginFormId, loginFormId]);
};