import {useCallback, useMemo, useState} from 'react';
import {IUsePaginationOptions, IUsePaginationProps} from './use-pagination.types';

/**
 * Pagination helper for synchronizing current page with URL query parameter
 *
 * @param [options] - Hook options
 * @param [options.queryParamName] - Name of the query parameter to synchronize with current page
 * @param [options.defaultPage] - Default value for current page
 *
 * @example
 *
 *   import {usePagination, Pagination} from 'components/ui/pagination';
 *
 *   // ...
 *
 *   const {currentPage, setCurrentPage} = usePagination();
 *
 *   const handlePageChange = useCallback((page: number) => {
 *     setCurrentPage(page);
 *   }, [setCurrentPage]);
 *
 *   // ...
 *
 *   <Pagination
 *     pageCount={99}
 *     currentPage={currentPage}
 *     onChange={handlePageChange}
 *   />
 *
 * @see ./pagination
 */
export const usePagination = (options: IUsePaginationOptions = {}): IUsePaginationProps => {
  const {defaultPage} = options;
  const [page, setPage] = useState(defaultPage || 1);

  const setCurrentPage = useCallback((page: number) => {
    setPage(page);
  }, []);

  const props = useMemo<IUsePaginationProps>(() => ({
    currentPage: page,
    setCurrentPage
  }), [page, setCurrentPage]);

  return props;
};
