import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {IPlaceDetailDialogProps} from './place-detail-dialog.types';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {PlaceDetailTable} from 'components/consumption/place-detail-table';

/**
 * Consumption place detail dialog
 * 
 * @example
 * 
 *   const [dialogOpen, setDialogOpen] = useState(false);
 *   
 *   const toggleDialog = useCallback(() => {
 *     setDialogOpen((open) => !open);
 *   }, []);
 *   
 *   // ...
 *   
 *   <PlaceDetailDialog
 *     place={someConsumptionPlace}
 *     open={dialogOpen}
 *     onClose={toggleDialog}
 *   />
 */
export const PlaceDetailDialog: FunctionComponent<IPlaceDetailDialogProps> = (props) => {
  const {place, open, onClose} = props;
  const {t} = useTranslation();

  return (
    <Dialog
      title={t('components.consumption.place_detail_dialog.title')}
      open={open}
      onClose={onClose}
      width="wide"
    >
      <DialogContent>
        <PlaceDetailTable place={place} />
      </DialogContent>
    </Dialog>
  );
};