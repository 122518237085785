import './typography.scss';
import React, {forwardRef, useMemo} from 'react';
import {TypographyProps} from './typography.types';
import MuiTypography from '@material-ui/core/Typography';

/**
 * Typography elements
 *
 * @example
 *
 *   <Typography variant="h1">
 *     Main heading
 *   </Typography>
 */
export const Typography = forwardRef<HTMLElement, TypographyProps>(function Typography(props, ref) {
  const {className, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-typography';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);

  return (
    <MuiTypography
      {...restProps}
      ref={ref}
      className={classes}
    />
  );
});
