import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IInvoiceSummaryRequest, IInvoiceSummaryResponse} from './invoice-summary.types';

/** Get a summary of invoice payments */
export const invoiceSummary = async (request?: IInvoiceSummaryRequest) => {
  const response = await axios.get<IInvoiceSummaryResponse>(urls.invoiceSummary(), {
    params: request,
    headers: {
      ...headers.auth(),
      ...headers.language(),
      ...headers.partner()
    }
  });
  return response.data;
};