import React, {FunctionComponent} from 'react';
import {paths} from 'config/router';
import {useTranslation} from 'lib/intl/i18n';
import {IContractListProps} from './contract-list.types';
import {Grid} from 'components/ui/grid';
import {Card} from 'components/ui/card';
import {CardContent} from 'components/ui/card-content';
import {CardActions} from 'components/ui/card-actions';
import {Typography} from 'components/ui/typography';
import {PlaceList} from '../place-list';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import ContractIcon from '@material-ui/icons/MenuBook';
import InvoiceIcon from '@material-ui/icons/ListAlt';
import {NoData} from 'components/api/no-data';

/** User contract list */
export const ContractList: FunctionComponent<IContractListProps> = (props) => {
  const {contracts} = props;
  const {t} = useTranslation();
  
  return (
    <div className="ui-contract-list">
      <NoData
        visible={contracts.length === 0}
        message={t('views.user.contracts.list.no_items')}
      />
      {contracts.length > 0 && (
        <Grid container>
          {contracts.map((contract) => (
            <Grid
              key={contract.id}
              item
              xl={4}
            >
              <Card fullHeight>

                {/* Contract info */}

                <CardContent>
                  <Typography
                    variant="h3"
                    gutterBottom
                  >
                    {t('views.user.contracts.contract')} {contract.number}
                  </Typography>
                  <PlaceList places={contract.consumptionPlaces} />
                </CardContent>
                
                {/* Actions */}
                
                <CardActions>
                  <ButtonActions align="center">
                    <Button
                      icon={<ContractIcon />}
                      to={`${paths.contract}/${contract.id}`}
                    >
                      {t('views.user.contracts.detail')}
                    </Button>
                    <Button
                      icon={<InvoiceIcon />}
                      to={`${paths.invoices}?contractId=${contract.id}`}
                    >
                      {t('views.user.contracts.invoices')}
                    </Button>
                  </ButtonActions>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </div>
  );
};