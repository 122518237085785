import './home-invoices.scss';
import React, {FunctionComponent, useCallback} from 'react';
import {paths} from 'config/router';
import {useTranslation} from 'lib/intl/i18n';
import {InvoiceSummary} from 'components/invoice/invoice-summary';
import {Button} from 'components/ui/button';
import InvoicesIcon from '@material-ui/icons/ListAlt';
import {useNavigate} from 'react-router-dom';

/** Invoices overview */
export const HomeInvoices: FunctionComponent = () => {
  const navigate = useNavigate();
  const {t} = useTranslation();
  
  const goToInvoices = useCallback(() => {
    navigate(paths.invoices);
  }, [navigate]);

  return (
    <div className="ui-home-invoices">
      <InvoiceSummary />
      <div className="ui-home-invoices__button">
        <Button
          icon={<InvoicesIcon />}
          onClick={goToInvoices}
        >
          {t('views.app.home.invoices.link')}
        </Button>
      </div>
    </div>
  );
};