import {useContext} from 'react';
import {ThemeContext} from '../theme-context';

/**
 * Get theme settings
 *
 * @example
 *
 *   const theme = useTheme();
 *   console.log(theme.color.primary);
 */
export const useTheme = () => {
  return useContext(ThemeContext);
};
