import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types'; 
import memoize from 'fast-memoize';
import {t} from 'lib/intl/i18n';
import {dateTime as validateDateTime} from 'lib/global/validate/date-time';
import {dateTime as parseDateTime} from 'lib/intl/parse/date-time';
import {date as formatDate} from 'lib/intl/format/date';
import {time as formatTime} from 'lib/intl/format/time';
import {logWarningOnDev} from 'lib/global/dev';

/**
 * Get maximum date/time validator
 *
 * @param maximumDateTime - Maximum date/time value
 */
export const maxDateTime = memoize((maximumDateTime: AnyDate) => {
  if (!parseDateTime(maximumDateTime).isValid())
    logWarningOnDev(t('lib.global.validate.date_time.invalid'));

  /**
   * Validate maximum date/time
   *
   * @param input - Date/time to validate
   * @returns Error message or undefined
   */
  const maxDateTime: IValidator<AnyDate> = (input) => {
    if (input) {
      const isValid = !validateDateTime(input);
      const isSameOrBefore = parseDateTime(input).isSameOrBefore(parseDateTime(maximumDateTime));
      if (isValid && !isSameOrBefore) {
        const date = formatDate(maximumDateTime);
        const time = formatTime(maximumDateTime);
        return t('lib.global.validate.date_time.max', {date, time});
      }
    }
  };

  return maxDateTime;
});

/**
 * Check that the maximum date/time validator doesn't return any error
 *
 * @param input - Date/time to validate
 * @param maximumDateTime - Maximum date/time value
 */
export const isMaxDateTime: ValidatorQuery = (input, maximumDateTime: AnyDate) => {
  return !!input && !maxDateTime(maximumDateTime)(input);
};
