import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {PaymentStatusListResponse} from './payment-status-list.types';

/** Get a list of payment statuses */
export const paymentStatusList = async () => {
  const response = await axios.get<PaymentStatusListResponse>(urls.paymentStatusList(), {
    headers: {
      ...headers.auth(),
      ...headers.language()
    }
  });
  return response.data;
};