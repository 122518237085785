import './data-table.scss';
import React, {PropsWithChildren, useCallback, useMemo, useRef} from 'react';
import {useContainerQuery} from 'hooks';
import {DataTableColumns, IDataTableColumn, IDataTableProps} from './data-table.types';
import {TableContainer} from 'components/ui/table-container';
import {Table} from 'components/ui/table';
import {TableHead} from 'components/ui/table-head';
import {TableBody} from 'components/ui/table-body';
import {TableRow} from 'components/ui/table-row';
import {TableCell} from 'components/ui/table-cell';
import {DataTableMenu} from './data-table-menu';

/**
 * Data table
 * 
 * @example
 * 
 *   import {DataTable, asTableColumns, asTableRows} from 'components/ui/data-table';
 *   
 *   // ...
 *   
 *   const columns = useMemo(() => asTableColumns({
 *     user: {
 *       label: 'User'
 *     },
 *     consumption: {
 *       label: 'Consumption',
 *       align: 'right'
 *     },
 *     price: {
 *       label: 'Price',
 *       align: 'right'
 *     }
 *   }), []);
 *   
 *   const rows = useMemo(() => asTableRows(columns, [
 *     {
 *       user: 'John Doe',
 *       consumption: 123,
 *       price: 234
 *     },
 *     {
 *       user: 'Jane Smith',
 *       consumption: 345,
 *       price: 456
 *     },
 *     ...
 *   ]), [columns]);
 *   
 *   // ...
 *   
 *   <DataTable
 *     columns={columns}
 *     rows={rows}
 *   />
 * 
 * @example
 * 
 *   import {DataTable, asTableColumns, mapTableRows} from 'components/ui/data-table';
 *   
 *   const data = {
 *     {
 *       user: 'John Doe',
 *       consumption: 123,
 *       price: 234
 *     },
 *     {
 *       user: 'Jane Smith',
 *       consumption: 345,
 *       price: 456
 *     },
 *     ...
 *   };
 * 
 *   // ...
 *   
 *   const columns = useMemo(() => asTableColumns({
 *     user: {
 *       label: 'User'
 *     },
 *     consumption: {
 *       label: 'Consumption',
 *       align: 'right'
 *     },
 *     price: {
 *       label: 'Price',
 *       align: 'right'
 *     }
 *   }), []);
 *   
 *   const rows = useMemo(() => mapTableRows(columns, data, (item) => (
 *     user: item.user,
 *     consumption: item.consumption,
 *     price: item.price
 *   ]), [columns, data]);
 *   
 *   // ...
 *   
 *   <DataTable
 *     columns={columns}
 *     rows={rows}
 *   />
 */
export function DataTable<T extends DataTableColumns>(props: PropsWithChildren<IDataTableProps<T>>) {
  const {
    className,
    columns,
    rows,
    gutterBottom,
    responsiveWidth = 900,
    exportData,
    exportFilename
  } = props;
  const containerRef = useRef<HTMLDivElement>(null!);
  const isMobile = useContainerQuery(containerRef, {maxWidth: responsiveWidth});
  const verticalLayout = props.renderVertically || isMobile;

  const getRowClassName = useCallback((rowId: number) => {
    let classes = 'ui-data-table__row';
    if (rowId % 2 === 0)
      classes += ' ui-data-table__row--odd';
    else
      classes += ' ui-data-table__row--even';
    return classes;
  }, []);

  const getHeaderClassName = useCallback((column: IDataTableColumn) => {
    let classes = 'ui-data-table__header';
    if (column.nowrapHeader && !verticalLayout)
      classes += ' ui-data-table__header--nowrap';
    return classes;
  }, [verticalLayout]);

  const getCellClassName = useCallback((column: IDataTableColumn) => {
    let classes = 'ui-data-table__cell';
    if (column.bold && !verticalLayout)
      classes += ' ui-data-table__cell--bold';
    if (column.nowrapCell && !verticalLayout)
      classes += ' ui-data-table__cell--nowrap';
    if (column.shrink && !verticalLayout)
      classes += ' ui-data-table__cell--shrink';
    return classes;
  }, [verticalLayout]);

  const renderHorizontally = useCallback(() => {
    const entries = Object.entries(columns);
    return (
      <>

        {/* Columns */}

        <TableHead>
          <TableRow>
            {entries.map(([columnKey, column], columnId) => (
              <>
                <TableCell
                  key={columnKey}
                  align={column.align}
                  className={getHeaderClassName(column)}
                >
                  <div>
                    {column.label}

                    {/* Menu */}

                    {columnId === entries.length - 1 && (
                      <DataTableMenu
                        exportData={exportData}
                        exportFilename={exportFilename}
                      />
                    )}
                  </div>
                </TableCell>
              </>
            ))}
          </TableRow>
        </TableHead>

        {/* Rows */}

        <TableBody>
          {rows.map((row, rowId) => (
            <TableRow
              key={rowId}
              className={getRowClassName(rowId)}
            >
              {Object.entries(columns).map(([columnKey, column]) => (
                <TableCell
                  key={columnKey}
                  align={column.align}
                  className={getCellClassName(column)}
                >
                  {row[columnKey]}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </>
    );
  }, [columns, exportData, exportFilename, getCellClassName, getHeaderClassName, getRowClassName, rows]);

  const renderVertically = useCallback(() => (
    <TableBody>
      {rows.map((row, rowId) => {
        const entries = Object.entries(columns);
        return entries.map(([columnKey, column], columnId) => (
          <TableRow
            key={rowId}
            className={getRowClassName(rowId)}
          >
            <TableCell
              component="th"
              className={getCellClassName(column)}
            >
              {column.label}
            </TableCell>
            <TableCell className={getCellClassName(column)}>
              <div>
                {row[columnKey]}

                {/* Menu */}

                {rowId === 0 && columnId === 0 && (
                  <DataTableMenu
                    exportData={exportData}
                    exportFilename={exportFilename}
                  />
                )}
              </div>
            </TableCell>
          </TableRow>
        ));
      })}
    </TableBody>
  ), [columns, exportData, exportFilename, getCellClassName, getRowClassName, rows]);

  const classes = useMemo(() => {
    let classes = 'ui-data-table';
    if (verticalLayout)
      classes += ' ui-data-table--vertical';
    else
      classes += ' ui-data-table--horizontal';
    if (exportData)
      classes += ' ui-data-table--menu';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, exportData, verticalLayout]);   

  return (
    <div style={{position: 'relative'}}>
      <TableContainer
        ref={containerRef}
        className={classes}
        gutterBottom={gutterBottom}
      >
        <Table>
          {verticalLayout ? renderVertically() : renderHorizontally()}
        </Table>
      </TableContainer>
    </div>
  );
}