
/** Get a list of payments */
export const paymentList = () => {
  return 'reimbursement/list';
};

/** Get a list of payment statuses */
export const paymentStatusList = () => {
  return 'index/reimbursement-status/list';
};
