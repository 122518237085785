/** @file Browser helpers */

/** Try to parse value of the query parameter  */
const parseQueryParam = (value: string): unknown => {
  try {
    return JSON.parse(value);
  }
  catch {
    return value;
  }
};

/** Try to stringify a value to be stored as a query parameter */
const stringifyQueryParam = (value: unknown) => {
  try {
    return JSON.stringify(value);
  }
  catch {
    return '';
  }
};

/**
 * Get the object containing the parsed query parameters
 * 
 * @example
 * 
 *   // http://example.com?a=123&b=true&c=[1,2,3]
 * 
 *   getQueryParams(); // {a: 123, b: true, c: [1, 2, 3]}
 */
export const getQueryParams = (): Record<string, unknown> => {
  const hasParams = window.location.search.includes('=');
  if (!hasParams)
    return {};
  const queryString = decodeURIComponent(window.location.search).slice(1);
  const params = queryString.split('&');
  const entries = params.map((param) => param.split('='));
  const queryObject = entries.reduce((query, [key, value]) => {
    query[key] = parseQueryParam(value);
    return query;
  }, {} as Record<string, unknown>);
  return queryObject;
};

/** Clean-up empty (null or undefined) params from the object */
const stripEmptyParams = (params: Record<string, unknown>) => {
  const result: Record<string, unknown> = {};
  for (const [name, value] of Object.entries(params)) {
    if (value !== undefined && value !== null)
      result[name] = value;
  }
  return result;
};

/**
 * Set a URL query without refreshing the page
 * 
 * @example
 * 
 *   // http://example.com?a=1&b=2
 * 
 *   replaceQueryParams({c: 3, d: 4});
 * 
 *   // http://example.com?c=3&d=4
 */
export const replaceQueryParams = (params: Record<string, unknown>) => {
  let query = '';
  const entries = Object.entries(stripEmptyParams(params));
  if (entries.length > 0) {
    const params = entries.map(([name, value]) => {
      return `${name}=${stringifyQueryParam(value)}`;
    });
    const queryString = params.join('&');
    query = `?${queryString}`;
  }
  window.history.replaceState(null, '', `${window.location.pathname}${query}`);
};

/**
 * Set query parameter values without replacing the original query
 * 
 * @example
 * 
 *   // http://example.com?a=1&b=2
 * 
 *   setQueryParams({b: 3, c: 4});
 * 
 *   // http://example.com?a=1&b=3&c=4
 */
export const setQueryParams = (params: Record<string, unknown>) => {
  replaceQueryParams({
    ...getQueryParams(),
    ...params
  });
};

/**
 * Get the value of the single query parameter
 * 
 * @example
 * 
 *   // http://example.com?a=1&b=2
 * 
 *   getQueryParam('b'); // 2
 */
export const getQueryParam = (paramName: string) => {
  const params = getQueryParams();
  return params[paramName];
};

/**
 * Set the value of the single query parameter 
 * 
 * @example
 * 
 *   // http://example.com?a=1&b=2
 * 
 *   setQueryParam('b', 3);
 * 
 *   // http://example.com?a=1&b=3
 */
export const setQueryParam = (name: string, value: unknown) => {
  const params = getQueryParams();
  params[name] = value;
  replaceQueryParams(params);
};

/**
 * Remove the query parameter
 * 
 * @example
 * 
 *   // http://example.com?a=1&b=2
 * 
 *   removeQueryParam('a');
 * 
 *   // http://example.com?b=2
 */
export const removeQueryParam = (paramName: string) => {
  const params = getQueryParams();
  delete params[paramName];
  replaceQueryParams(params);
};

/**
 * Remove the URL hash
 * 
 * @example
 * 
 *   // http://example.com#some-hash
 * 
 *   removeUrlHash();
 * 
 *   // http://example.com
 */
export const removeUrlHash = () => { 
  history.pushState('', document.title, `${location.pathname}${location.search}`);
};