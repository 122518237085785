import {call, put, takeLatest, select} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';
import {selectors} from 'selectors';

/** Refresh access token */
const authRefreshSaga = function* () {
  try {
    const refreshToken = yield* select(selectors.refreshToken);
    const response = yield* call(api.refresh, {refreshToken: refreshToken!});
    yield put(actions.refreshSuccess(response));
  }
  catch (error) {
    yield put(actions.refreshFailure(error as IRequestError));
    yield put(actions.logoutRequest({
      logoutReason: 'tokenRefreshFail'
    }));
  }
};

export const authRefresh = function* () {
  yield takeLatest(actions.RefreshActionTypes.request, authRefreshSaga);
};
