import {Reducer} from 'redux';
import {CookiesActionTypes, TCookiesActions} from 'actions';
import {CookiesState} from './cookies.types';
import {Persist} from 'lib/global/persist';

const persist = new Persist<CookiesState>('ui.cookies');

const initialState: CookiesState = persist.initialState({
  approved: false,
  isOpenDialog: false
});

/** Cookies approval */
export const cookies: Reducer<CookiesState, TCookiesActions> = persist.reducer(
  (state = initialState, action): CookiesState => {
    switch (action.type) {
      case CookiesActionTypes.set:
        return {
          ...state,
          ...action.payload,
          isOpenDialog: false
        };
      case CookiesActionTypes.toggleDialog:
        return {
          ...state,
          isOpenDialog: action.payload
        };
      default:
        return state;
    }
  }
);
