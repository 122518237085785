import {IPasswordChangeRequest} from 'api';

export enum PasswordChangeActionTypes {
  request = 'PASSWORD_CHANGE_REQUEST',
  success = 'PASSWORD_CHANGE_SUCCESS',
  failure = 'PASSWORD_CHANGE_FAILURE'
}

export interface IPasswordChangeRequestAction {
  type: PasswordChangeActionTypes.request;
  payload: IPasswordChangeRequest;
}

export interface IPasswordChangeSuccessAction {
  type: PasswordChangeActionTypes.success;
}

export interface IPasswordChangeFailureAction {
  type: PasswordChangeActionTypes.failure;
  payload: IRequestError;
}

export type PasswordChangeAction = (
  IPasswordChangeRequestAction |
  IPasswordChangeSuccessAction |
  IPasswordChangeFailureAction
);
