import React, {FunctionComponent, ChangeEvent, useCallback} from 'react';
import {IContractSearchProps} from './contract-search.types';
import {useTranslation} from 'lib/intl/i18n';
import {FilterPanel} from 'components/ui/filter-panel';
import {FilterTextField} from 'components/ui/filter-text-field';
import {Tooltip} from 'components/ui/tooltip';
import {Grow} from 'components/ui/grow';
import {IconButton} from 'components/ui/icon-button';
import ClearIcon from '@material-ui/icons/Close';

/** Contract filter */
export const ContractSearch: FunctionComponent<IContractSearchProps> = (props) => {
  const {value, onChange} = props;
  const {t} = useTranslation();
  
  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange(event.target.value);
  }, [onChange]);

  const handleClear = useCallback(() => {
    onChange('');
  }, [onChange]);

  return (
    <FilterPanel className="ui-contract-search">
      <FilterTextField
        label={t('views.user.contracts.search.label')}
        placeholder={t('views.user.contracts.search.placeholder')}
        value={value}
        onChange={handleChange}
        InputProps={{
          endAdornment: (
            <Grow in={!!(value || '').trim()}>
              <Tooltip title={t('views.user.contracts.search.clear')}>
                <IconButton
                  className="ui-contract-search__clear"
                  size="small"
                  onClick={handleClear}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
            </Grow>
          )
        }}
      />
    </FilterPanel>
  );
};