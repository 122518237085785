import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IInvoiceListRequest, InvoicePagedListResponse} from './invoice-list.types';

/** Get a list of invoices */
export const invoiceList = async (request?: IInvoiceListRequest) => {
  const response = await axios.get<InvoicePagedListResponse>(urls.invoiceList(), {
    params: request,
    headers: {
      ...headers.auth(),
      ...headers.language(),
      ...headers.partner()
    }
  });
  return response.data;
};
