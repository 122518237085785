import React, {forwardRef, useMemo} from 'react';
import {useTheme} from 'components/ui/theme';
import {FadeProps} from './fade.types';
import MuiFade from '@material-ui/core/Fade';

/**
 * Fade transition
 *
 * @example
 *
 *   <Fade in={isOpen}>
 *     Animated content...
 *   </Fade>
 */
export const Fade = forwardRef<unknown, FadeProps>(function Fade(props, ref) {
  const {
    timeout: timeoutProp,
    children,
    ...restProps
  } = props;
  const theme = useTheme();

  const timeout = useMemo(() => {
    if (timeoutProp === undefined)
      return theme.speed.fast;
    return timeoutProp;
  }, [theme.speed.fast, timeoutProp]);
  
  return (
    <MuiFade
      ref={ref}
      {...restProps}
      timeout={timeout}
    >
      {children}
    </MuiFade>
  );
});