import React, {FunctionComponent} from 'react';
import {useFormikContext} from 'formik';
import {Grid} from 'components/ui/grid';
import {validate} from 'lib/global/validate';
import {FormTextField} from 'components/ui/form-text-field';
import {useTranslation} from 'lib/intl/i18n';
import {IFormValues} from '../form-kit.types';

/** Form kit password confirmation */
export const PasswordConfirm: FunctionComponent = () => {
  const {values} = useFormikContext<IFormValues>();
  const {t} = useTranslation();
  
  return (
    <>
          
      {/* Password */}

      <Grid item md={6}>
        <FormTextField
          name="password"
          type="password"
          label={t('views.app.ui_kit.form.label.password.value')}
          required
          validate={validate.minLength(5)}
        />
      </Grid>

      {/* Password confirmation */}

      <Grid item md={6}>
        <FormTextField
          name="passwordConfirm"
          type="password"
          label={t('views.app.ui_kit.form.label.password.confirm')}
          required
          validate={validate.passwordConfirm(values.password)}
        />
      </Grid>
    </>
  );
};