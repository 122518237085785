import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {UserRolesResponse} from './user-roles.types';

/** Get a list of user roles */
export const userRoles = async () => {
  const response = await axios.get<UserRolesResponse>(urls.userRoles(), {
    headers: {
      ...headers.language(),
      ...headers.auth()
    }
  });
  return response.data;
};