import './radio-group.scss';
import React, {forwardRef, useMemo} from 'react';
import {IRadioGroupProps} from './radio-group.types';
import MuiRadioGroup from '@material-ui/core/RadioGroup';

/**
 * Radio button group
 *
 * @example
 * 
 *   import {RadioGroup} from 'components/ui/radio-group';
 *   import {Radio} from 'components/ui/radio';
 * 
 *   // ...
 *
 *   <RadioGroup
 *     name="options"
 *     value={value}
 *     onChange={handleChange}
 *   >
 *     <Radio
 *       label="First option"
 *       value="option1"
 *     />
 *     <Radio
 *       label="Second option"
 *       value="option2"
 *     />
 *   </RadioGroup>
 */
export const RadioGroup = forwardRef<HTMLButtonElement, IRadioGroupProps>(function RadioGroup(props, ref) {
  const {
    className,
    direction,
    children,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-radio-group';
    classes += ` ui-radio-group--direction-${direction}`;
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, direction]);   

  return (
    <MuiRadioGroup
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiRadioGroup>
  );
});

RadioGroup.defaultProps = {
  direction: 'column'
};