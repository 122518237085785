import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Get a list of payment statuses */
const paymentStatusListSaga = function* () {
  try {
    const response = yield* call(api.paymentStatusList);
    yield put(actions.paymentStatusListSuccess(response));
  }
  catch (error) {
    yield put(actions.paymentStatusListFailure(error as IRequestError));
  }
};

export const paymentStatusList = function* () {
  yield takeLatest(actions.PaymentStatusListActionTypes.request, paymentStatusListSaga);
};