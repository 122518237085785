import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {format} from 'lib/intl/format';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {useTranslation} from 'lib/intl/i18n';
import {IDemandsTableProps} from './demands-table.types';
import {
  DataTable,
  asTableColumns,
  mapTableRows,
  asTableExportColumns,
  mapTableExportData,
  getExportSource
} from 'components/ui/data-table';
import {DemandType} from './demand-type';
import {DemandDetailButton} from './demand-detail-button';
import {DemandStatus} from './demand-status';
import {DownloadButton} from 'components/ui/download-button';

/**
 * Requested demands table
 * 
 * @example
 * 
 *   <DemandsTable demands={someDemands} />
 */
export const DemandsTable: FunctionComponent<IDemandsTableProps> = (props) => {
  const {demands, exportDataSource} = props;
  const demandTypeList = useSelector(selectors.demandTypeList, shallowEqual);
  const demandStatusList = useSelector(selectors.demandStatusList, shallowEqual);  
  const dispatch = useDispatch();
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (!demandTypeList.data)
      dispatch(actions.demandTypeListRequest());
    if (!demandStatusList.data)
      dispatch(actions.demandStatusListRequest());
  }, [demandStatusList.data, demandTypeList.data, dispatch]);
  
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const columns = useMemo(() => asTableColumns({
    number: {
      label: t('components.demand.demands_table.column.number')
    },
    created: {
      label: t('components.demand.demands_table.column.created'),
      align: 'right',
      nowrapCell: true
    },
    subject: {
      label: t('components.demand.demands_table.column.subject')
    },
    type: {
      label: t('components.demand.demands_table.column.type'),
      nowrapCell: true
    },
    status: {
      label: t('components.demand.demands_table.column.status')
    },
    answer: {
      label: t('components.demand.demands_table.column.answer')
    },
    attachments: {
      label: t('components.demand.demands_table.column.attachments'),
      align: 'center',
      shrink: true
    },
    detail: {
      label: t('components.demand.demands_table.column.detail'),
      align: 'center',
      shrink: true
    }
  }), [t]);

  const rows = useMemo(() => mapTableRows(columns, demands, (demand) => ({
    number: demand.number,
    created: format.date(demand.creationDate),
    subject: demand.subject,
    type: (
      <DemandType typeId={demand.typeId} />
    ),
    status: (
      <DemandStatus statusId={demand.statusId} />
    ),
    answer: demand.wayOfAnswer,
    attachments: (
      <DownloadButton documents={demand.attachments} />
    ),
    detail: (
      <DemandDetailButton demand={demand} />
    )
  })), [columns, demands]);

  const getTypeName = useCallback((typeId: string) => {
    const demandType = demandTypeList.data?.find((type) => type.id === typeId);
    if (demandType)
      return demandType.name;
    return t('components.demand.demands_table.unknown_type');
  }, [demandTypeList.data, t]);

  const getStatusName = useCallback((statusId: string) => {
    const status = demandStatusList.data?.find((status) => status.id === statusId);
    if (status)
      return status.name;
    return t('components.invoice.invoices_table.unknown_status');
  }, [demandStatusList.data, t]);

  const exportData = useCallback(async () => {
    const exportSource = await getExportSource(exportDataSource || demands);
    const exportColumns = asTableExportColumns({
      number: t('components.demand.demands_table.column.number')!,
      created: t('components.demand.demands_table.column.created')!,
      subject: t('components.demand.demands_table.column.subject')!,
      type: t('components.demand.demands_table.column.type')!,
      status: t('components.demand.demands_table.column.status')!
    });
    return mapTableExportData(exportColumns, exportSource, (demand) => ({
      number: demand.number,
      created: format.date(demand.creationDate),
      subject: demand.subject,
      type: getTypeName(demand.typeId),
      status: getStatusName(demand.statusId)
    }));
  }, [demands, exportDataSource, getStatusName, getTypeName, t]);
  
  const render = useCallback(() => (
    <DataTable
      columns={columns}
      rows={rows}
      exportData={exportData}
      exportFilename={t('components.demand.demands_table.export_filename')}
      responsiveWidth={1100}
    />
  ), [columns, exportData, rows, t]);

  return (
    <WaitForFetch
      pending={demandTypeList.pending || demandStatusList.pending}
      error={demandTypeList.error || demandStatusList.error}
      noData={!demandTypeList.data || !demandStatusList.data}
      onRetry={fetchData}
      render={render}
      absolutePosition
    />
  );
};