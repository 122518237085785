/** @file DOM helpers */

/**
 * Trigger change event programmatically
 *
 * @param inputElement - Input to trigger event on
 * @param value - Value to be passed to the trigger event
 */
export const triggerChangeEvent = (inputElement: HTMLInputElement, value: string) => {
  const inputValueSetter = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value')!.set!;
  inputValueSetter.call(inputElement, value);
  const event = new CustomEvent('input', {bubbles: true});
  inputElement.dispatchEvent(event);
};

/**
 * Trigger click event programmatically
 *
 * @param element - Element to trigger event on
 */
export const triggerClickEvent = (element: HTMLElement) => {
  const event = new CustomEvent('click', {bubbles: true});
  element.dispatchEvent(event);
};
