import {
  ISetLocaleAction,
  LocaleActionTypes
} from './locale.types';
import {SupportedLocales} from 'config/intl';
import {localeCode} from 'lib/intl/locale-code';

/** Set application locale */
export const setLocale = (locale: SupportedLocales): ISetLocaleAction => {
  const language = localeCode.getLanguageCode(locale);
  const country = localeCode.getCountryCode(locale);
  return {
    type: LocaleActionTypes.set,
    payload: {
      code: locale,
      language,
      country
    }
  };
};

