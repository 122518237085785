import moment, {Moment} from 'moment';

export const VALID_DATE_TIME_FORMATS: string[] = [
  'D. M. YYYY H:mm',
  'D. M. YYYY h:mm A',
  'D.M. YYYY H:mm',
  'D.M. YYYY m:mm A',
  'D. M.YYYY H:mm',
  'D. M.YYYY h:mm A',
  'D.M.YYYY H:mm',
  'D.M.YYYY h:mm A',
  'D/M/YYYY H:mm',
  'D/M/YYYY h:mm A',
  'D-M-YYYY H:mm',
  'D-M-YYYY h:mm A',
  'D M YYYY H:mm',
  'D M YYYY h:mm A',
  'DDMMYYYY H:mm',
  'DDMMYYYY h:mm A',
  'YYYY/M/D H:mm',
  'YYYY/M/D h:mm A',
  'D. M. YYYY H:mm:ss',
  'D. M. YYYY h:mm:ss A',
  'D.M. YYYY H:mm:ss',
  'D.M. YYYY h:mm:ss A',
  'D. M.YYYY H:mm:ss',
  'D. M.YYYY h:mm:ss A',
  'D.M.YYYY H:mm:ss',
  'D.M.YYYY h:mm:ss A',
  'D/M/YYYY H:mm:ss',
  'D/M/YYYY h:mm:ss A',
  'D-M-YYYY H:mm:ss',
  'D-M-YYYY h:mm:ss A',
  'D M YYYY H:mm:ss',
  'D M YYYY h:mm:ss A',
  'DDMMYYYY H:mm:ss',
  'DDMMYYYY h:mm:ss A',
  'YYYY/M/D H:mm:ss',
  'YYYY/M/D h:mm:ss A',
  'YYYY-M-DTHH:mm',
  'YYYY-M-DTHH:mm:ss'
];

/**
 * Parse date-time
 *
 * @param value - Input date-time
 * @returns Moment instance
 *
 * @see https://momentjs.com
 */
export const dateTime = (value: AnyDate): Moment => {
  if (typeof value === 'string')
    return moment(value, VALID_DATE_TIME_FORMATS, true);
  return moment(value);
};
