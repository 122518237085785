import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Get a list of dynamic consumption place parameters */
const consumptionParameterListSaga = function* () {
  try {
    const response = yield* call(api.consumptionParameterList);
    yield put(actions.consumptionParameterListSuccess(response));
  }
  catch (error) {
    yield put(actions.consumptionParameterListFailure(error as IRequestError));
  }
};

export const consumptionParameterList = function* () {
  yield takeLatest(actions.ConsumptionParameterListActionTypes.request, consumptionParameterListSaga);
};