import './chart.scss';
import React, {forwardRef, useCallback, useMemo, useState} from 'react';
import {IChartProps, ChartOptions, ChartRef} from './chart.types';
import {useTheme} from 'components/ui/theme';
import {getChartDefaults} from './chart-defaults';
import deepMerge from 'deepmerge';
import {Highcharts} from 'lib/ui/highcharts';
import HighchartsReact from 'highcharts-react-official';
import {ChartMenu} from './chart-menu';
import * as Css from 'csstype';

/**
 * Highcharts wrapper
 * 
 * @see src/views/app/kit-view/chart-kit
 * @see https://github.com/highcharts/highcharts-react#readme
 *
 * @example
 *
 *   import {Chart, ChartOptions} from 'components/ui/chart';
 *
 *   const options: ChartOptions = {
 *     title: {
 *       text: 'My chart'
 *     },
 *     series: [{
 *       type: 'line',
 *       data: [1, 2, 3]
 *     }]
 *   };
 *
 *   // ...
 *
 *   <Chart options={options} />
 */
export const Chart = forwardRef<HTMLDivElement, IChartProps>(function Chart(props, ref) {
  const {
    className,
    options,
    minWidth,
    height = 400,
    ...restProps
  } = props;
  const theme = useTheme();
  const [chartRef, setChartRef] = useState<ChartRef>();

  const getChartRef = useCallback((ref: ChartRef) => {
    setChartRef(ref);
  }, []);

  const chartDefaults = useMemo<ChartOptions>(() => {
    return getChartDefaults(theme);
  }, [theme]);

  const chartOptions = useMemo<ChartOptions>(() => {
    return deepMerge(chartDefaults, options);
  }, [chartDefaults, options]);

  const containerProps = useMemo<{style: Css.Properties;}>(() => ({
    style: {
      height: `${height}px`
    }
  }), [height]);

  const contentStyle = useMemo<Css.Properties>(() => ({
    minWidth: minWidth && `${minWidth}px`
  }), [minWidth]);

  const classes = useMemo(() => {
    let classes = 'ui-chart';
    if (options.title)
      classes += ' ui-chart--title';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, options.title]);

  return (
    <div
      {...restProps}
      ref={ref}
      className={classes}
    >
      <div
        className="ui-chart__content"
        style={contentStyle}
      >
        <HighchartsReact
          ref={getChartRef}
          highcharts={Highcharts}
          options={chartOptions}
          containerProps={containerProps}
        />
        {chartRef && (
          <ChartMenu chart={chartRef.chart} />
        )}
      </div>
    </div>
  );
});
