import React, {FunctionComponent} from 'react';
import {IAccessDemandDialogProps} from './access-demand-dialog.types';
import {useTranslation} from 'lib/intl/i18n';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {AccessDemandForm} from './access-demand-form';

/**
 * Access demand form dialog
 * 
 * @example
 * 
 *   <AccessDemandDialog
 *     open={dialogIsOpen}
 *     onClose={closeDialog}
 *   />
 */  
export const AccessDemandDialog: FunctionComponent<IAccessDemandDialogProps> = (props) => {
  const {open, onClose} = props;
  const {t} = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      title={t('components.demand.access_demand_dialog.title')}
      width="wide"
    >
      <DialogContent>
        <AccessDemandForm onSuccess={onClose} />
      </DialogContent>
    </Dialog>
  );
};