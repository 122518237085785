import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useSelector, useDispatch, shallowEqual} from 'react-redux';
import {selectors} from 'selectors';
import {actions} from 'actions';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {useOnFetchFinish} from 'hooks';
import {api} from 'api';
import {getErrorMessage} from 'lib/api/axios';
import {IDemandFormProps, IFormValues} from './demand-form.types';
import {useTranslation} from 'lib/intl/i18n';
import {useSnackbar} from 'components/ui/snackbar';
import {Form} from 'components/ui/form';
import {DemandFormFields} from './demand-form-fields';
import {useUpload} from 'components/ui/upload';
import {ConfirmDialog} from 'components/ui/confirm-dialog';

/**
 * Create demand form
 * 
 * @example
 * 
 *   <DemandForm />
 */
export const DemandForm: FunctionComponent<IDemandFormProps> = (props) => {
  const {
    initialValues: initialValuesProp,
    onSuccess
  } = props;
  const demandTypeList = useSelector(selectors.demandTypeList, shallowEqual);
  const demandCreation = useSelector(selectors.demandCreation, shallowEqual);
  const dispatch = useDispatch();  
  const {showSnackbar} = useSnackbar();
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const submitConfirmCallback = useRef<() => void>();
  const {
    uploadPending,
    uploadError,
    uploadFileIds,
    handleUploadRequest,
    handleUploadSuccess,
    handleUploadError,
    handleUploadRemove
  } = useUpload();
  const {t} = useTranslation();

  const fetchData = useCallback(() => {
    if (!demandTypeList.data)
      dispatch(actions.demandTypeListRequest());
  }, [demandTypeList.data, dispatch]);
  
  const toggleConfirmDialog = useCallback(() => {
    setConfirmDialogOpen((prevOpen) => !prevOpen);
  }, []);
  
  useEffect(() => {
    fetchData();
  // eslint-disable-next-line
  }, []);

  const initialValues = useMemo<IFormValues>(() => ({
    typeId: '',
    priorityId: '',
    subject: '',
    description: '',
    phoneNumber: '',
    street: '',
    city: '',
    ...initialValuesProp
  }), [initialValuesProp]);

  const submitDemand = useCallback((values: IFormValues) => {
    const payload = {
      ...values,
      attachmentIds: uploadFileIds
    } as api.IDemandCreationRequest;
    dispatch(actions.demandCreationRequest(payload));
  }, [dispatch, uploadFileIds]);

  const handleSubmit = useCallback((values: IFormValues) => {
    if (uploadError) {
      submitConfirmCallback.current = () => submitDemand(values);
      toggleConfirmDialog();
    }
    else
      submitDemand(values);
  }, [submitDemand, toggleConfirmDialog, uploadError]);

  const handleSubmitConfirm = useCallback(() => {
    if (submitConfirmCallback.current)
      submitConfirmCallback.current();
  }, []);

  useOnFetchFinish([demandCreation], () => {
    const {error} = demandCreation;
    if (error) {
      const message = getErrorMessage(error, t('components.demand.demand_form.failure'));
      showSnackbar(message, {variant: 'error'});
    }
    else {
      showSnackbar(t('components.demand.demand_form.success'), {variant: 'success'});
      onSuccess();
    }
  });

  const render = useCallback(
    () => (
      <>
        <Form
          initialValues={initialValues}
          onSubmit={handleSubmit}
        >
          <DemandFormFields
            disableSubmit={uploadPending}
            onAttachmentRequest={handleUploadRequest}
            onAttachmentSuccess={handleUploadSuccess}
            onAttachmentError={handleUploadError}
            onAttachmentRemove={handleUploadRemove}
          />
        </Form>
        <ConfirmDialog
          message={t('components.demand.demand_form.failed_attachments')}
          open={confirmDialogOpen}
          onClose={toggleConfirmDialog}
          onYes={handleSubmitConfirm}
        />
      </>
    ),
    [
      initialValues,
      handleSubmit,
      uploadPending,
      handleUploadRequest,
      handleUploadSuccess,
      handleUploadError,
      handleUploadRemove,
      confirmDialogOpen,
      toggleConfirmDialog,
      handleSubmitConfirm,
      t
    ]
  );

  return (
    <WaitForFetch
      pending={demandTypeList.pending}
      error={demandTypeList.error}
      noData={!demandTypeList.data}
      onRetry={fetchData}
      render={render}
    />
  );
};