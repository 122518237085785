import './accordion.scss';
import React, {forwardRef, useMemo} from 'react';
import {AccordionProps} from './accordion.types';
import MuiAccordion from '@material-ui/core/Accordion';

/**
 * Accordion item
 * 
 * @see src/views/app/kit-view/accordion-kit
 *
 * @example
 *
 *   import {Accordion, AccordionSummary, AccordionDetails} from 'components/ui/accordion';
 *   
 *   // ...
 *   
 *   <Accordion>
 *     <AccordionSummary id="my-accordion">
 *       First item
 *     </AccordionSummary>
 *     <AccordionDetails>
 *       Item content
 *     </AccordionDetails>
 *   </Accordion>
 *   
 *   <Accordion>
 *     <AccordionSummary id="my-accordion">
 *       Second item
 *     </AccordionSummary>
 *     <AccordionDetails>
 *       Item content
 *     </AccordionDetails>
 *   </Accordion>
 */
export const Accordion = forwardRef<HTMLDivElement, AccordionProps>(function Accordion(props, ref) {
  const {className, children, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-accordion';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <MuiAccordion
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiAccordion>
  );
});

Accordion.defaultProps = {
  elevation: 0
};