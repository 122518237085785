import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {date as parseDate} from 'lib/intl/parse/date';
import {t} from 'lib/intl/i18n';

/**
 * Validate date value
 *
 * @param input - Date to validate
 * @returns Error message or undefined
 */
export const date: IValidator<AnyDate> = (input) => {
  if (input) {
    const dateIsValid = parseDate(input).isValid();
    if (!dateIsValid)
      return t('lib.global.validate.date.invalid');
  }
};

/**
 * Check that the date validator doesn't return any error
 *
 * @param input - Date value to check
 */
export const isDate: ValidatorQuery<AnyDate> = (input) => {
  return !!input && !date(input);
};
