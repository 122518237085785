import './form.scss';
import React, {forwardRef, ReactElement, Ref, useCallback, useMemo} from 'react';
import {IFormProps} from './form.types';
import {Formik, Form as FormikForm, FormikValues, FormikHelpers} from 'formik';
import {OnFormSubmitError} from './on-form-submit-error';
import scrollWindow from 'animated-scroll-to';
import isVisible from 'is-visible';
import {useTheme} from 'components/ui/theme';

const INVALID_INPUT_CLASS = 'ui-form__invalid';

/**
 * Formik wrapper
 * 
 * @see src/views/app/kit-view/form-kit
 * @see https://formik.org/docs/overview
 * 
 * @example
 * 
 *   import {FormikValues} from 'formik';
 *   
 *   export interface IFormValues extends FormikValues {
 *     firstName: string;
 *     lastName: string;
 *   }
 * 
 * @example
 *   
 *   import {Form} from 'components/ui/form';
 * 
 *   // ...
 * 
 *   const initialValues = useMemo<IFormValues>(() => ({
 *     firstName: '',
 *     lastName: ''
 *   }), []);
 * 
 *   const handleSubmit = useCallback((values: IFormValues) => {
 * 
 *     // ...
 * 
 *   }, []);
 * 
 *   // ...
 * 
 *   <Form
 *     initialValues={initialValues}
 *     onSubmit={handleSubmit}
 *   >
 *     ...
 * 
 *   </Form>
 * 
 * @example
 * 
 *   // Access form values using context
 * 
 *   import {useFormikContext} from 'formik';
 * 
 *   const MyFields: FunctionComponent = () => {
 *     const {values} = useFormikContext<IMyFormValues>();
 * 
 *     // ...
 *   };
 * 
 *   // ...
 * 
 *   import {Form} from 'components/ui/form';
 * 
 *   // ...
 * 
 *   <Form
 *     initialValues={initialValues}
 *     onSubmit={handleSubmit}
 *   >
 *     <MyFields />
 *   </Form>
 * 
 * @example
 * 
 *   // Access form values using render prop
 * 
 *   import {FormikProps} from 'formik';
 * 
 *   const renderFields = useCallback((renderProps: IFormikProps<IMyFormValues>) => {
 *     const {values} = renderProps;
 *     
 *     // ...
 * 
 *   }, []);
 * 
 *   // ...
 * 
 *   import {Form} from 'components/ui/form';
 * 
 *   // ...
 * 
 *   <Form
 *     initialValues={initialValues}
 *     onSubmit={handleSubmit}
 *   >
 *     {renderFields}
 *   </Form>
 */
export const Form = forwardRef(function Form<V extends FormikValues = FormikValues>(props: IFormProps<V>, ref: Ref<HTMLFormElement>) {
  const {
    className,
    disabled,
    onSubmit,
    onSubmitError,
    children,
    ...restProps
  } = props;
  const theme = useTheme();
  
  // Trim excess whitespace from string values on submit
  const trimValues = useCallback((values: V): V => {
    const newValues = {...values} as Record<string, unknown>;
    for (const [key, value] of Object.entries(newValues)) {
      if (typeof value === 'string')
        newValues[key] = value.trim();
    }
    return newValues as V;
  }, []);

  const clearNulls = useCallback((values: V): V => {
    const newValues = {...values} as Record<string, unknown>;
    for (const [key, value] of Object.entries(newValues)) {
      if (value === 'null')
        newValues[key] = '';
    }
    return newValues as V;
  }, []);
  
  const handleSubmit = useCallback((values: V, helpers: FormikHelpers<V>) => {
    const outputValues = trimValues(clearNulls(values));
    onSubmit(outputValues, helpers);
  }, [clearNulls, onSubmit, trimValues]);
  
  // Scroll to the first invalid input
  const scrollToInvalid = useCallback(() => {
    const $errorMessages = document.querySelectorAll('.ui-form-field-error');
    const $firstVisible = Array.from($errorMessages).find(isVisible);
    const $container = $firstVisible?.closest('.MuiCollapse-root');
    const $parent = $container?.parentElement;
    const $input = $parent?.querySelector(':scope input, textarea') as HTMLInputElement;
    if ($input) {
      
      //--- Scroll

      const margin = 32;
      const top = $input.getBoundingClientRect().top - margin;
      const $dialogContainer = document.querySelector('.MuiDialog-scrollBody');
      scrollWindow(top + window.pageYOffset, {
        speed: theme.speed.fast,
        elementToScroll: $dialogContainer || window
      });
      $input.focus();
      
      //--- Animate

      const $labelParent = $input.closest('label');
      const $inputParent = $labelParent || $input.parentElement;
      document.querySelector(`.${INVALID_INPUT_CLASS}`)?.classList.remove(INVALID_INPUT_CLASS);
      setTimeout(() => $inputParent?.classList.add(INVALID_INPUT_CLASS), 20);
    }
  }, [theme.speed.fast]);

  const handleSubmitError = useCallback(() => {
    scrollToInvalid();
    if (onSubmitError)
      onSubmitError();
  }, [onSubmitError, scrollToInvalid]);
  
  const classes = useMemo(() => {
    let classes = 'ui-form';
    if (disabled)
      classes += ' ui-form--disabled';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, disabled]);   
  
  return (
    <Formik
      {...restProps}
      onSubmit={handleSubmit}
    >
      <FormikForm
        ref={ref}
        noValidate
        className={classes}
      >
        {children}
        <OnFormSubmitError onError={handleSubmitError} />
      </FormikForm>
    </Formik>
  );
}) as <V extends FormikValues = FormikValues>(props: IFormProps<V> & {ref?: Ref<HTMLFormElement>;}) => ReactElement;
// ^ Make JSX generic parameters work with forwardRef
//
// @see https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref 