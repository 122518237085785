import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Fetch list of demand priorities */
const demandPriorityListSaga = function* () {
  try {
    const response = yield* call(api.demandPriorityList);
    yield put(actions.demandPriorityListSuccess(response));
  }
  catch (error) {
    yield put(actions.demandPriorityListFailure(error as IRequestError));
  }
};

export const demandPriorityList = function* () {
  yield takeLatest(actions.DemandPriorityListActionTypes.request, demandPriorityListSaga);
};