import {SnackbarId} from 'components/ui/snackbar';
import {SnackbarKey} from 'notistack';

export enum SnackbarActionTypes {
  show = 'UI_SNACKBAR_SHOW',
  hide = 'UI_SNACKBAR_HIDE'
}

export interface ISnackbarShowPayload {
  id: SnackbarId;
  key: SnackbarKey;
}

export interface ISnackbarShowAction {
  type: SnackbarActionTypes.show;
  payload: ISnackbarShowPayload;
}

export interface ISnackbarHidePayload {
  id?: SnackbarId;
  key?: SnackbarKey;
}

export interface ISnackbarHideAction {
  type: SnackbarActionTypes.hide;
  payload: ISnackbarHidePayload;
}

export type SnackbarAction = (
  ISnackbarShowAction |
  ISnackbarHideAction
);
