import {Reducer} from 'redux';
import {PasswordResetConfirmAction, PasswordResetConfirmActionTypes} from 'actions';
import {IPasswordResetConfirmState} from './password-reset-confirm.types';

const initialState: IPasswordResetConfirmState = {
  pending: false,
  error: null
};

/** Password reset confirmation */
export const passwordResetConfirm: Reducer<IPasswordResetConfirmState, PasswordResetConfirmAction> = (state = initialState, action): IPasswordResetConfirmState => {
  switch (action.type) {
    case PasswordResetConfirmActionTypes.request:
      return {
        ...state,
        pending: true,
        error: null
      };
    case PasswordResetConfirmActionTypes.success:
      return {
        ...state,
        pending: false
      };
    case PasswordResetConfirmActionTypes.failure:
      return {
        ...state,
        pending: false,
        error: action.payload
      };
    default:
      return state;
  }
};