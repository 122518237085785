import {intlApi} from 'lib/intl/intl-api';

/**
 * Format percents
 *
 * @example
 *
 *   format.percent(1.25, 2);
 *   // 125.00%
 *
 * @param value - Value to format
 * @param [precision] - Number of decimal digits
 * @returns Formatted value
 */
export const percent = (value: number, precision?: number) => {
  if (precision !== undefined)
    return intlApi.formatNumber(value, {
      style: 'percent',
      minimumFractionDigits: precision,
      maximumFractionDigits: precision
    });
  return intlApi.formatNumber(value, {style: 'percent'});
};
