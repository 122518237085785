import './card.scss';
import React, {forwardRef, useMemo} from 'react';
import {ICardProps} from './card.types';
import MuiCard from '@material-ui/core/Card';

/**
 * Card container
 * 
 * @see src/views/app/kit-view/card-kit
 *
 * @example
 *
 *   import {Card} from 'components/ui/card';
 *   import {CardHeader} from 'components/ui/card-header';
 *   import {CardMedia} from 'components/ui/card-media';
 *   import {CardContent} from 'components/ui/card-content';
 *   import {CardActionArea} from 'components/ui/card-action-area';
 *   import {CardActions} from 'components/ui/card-actions';
 *
 *   // ...
 * 
 *   <Card>
 *     <CardActionArea>
 *       <CardHeader
 *         icon={<SomeIcon />}
 *         title="Card title"
 *       />
 *       <CardMedia
 *         image="/path/to/image.png"
 *         title="Image title"
 *       />
 *       <CardContent>
 *         Card content
 *       </CardContent>
 *     </CardActionArea>
 *     <CardActions>
 *       <Link to="https://some.path">
 *         Card action
 *       </Link>
 *     </CardActions>
 *   </Card>
 */
export const Card = forwardRef<HTMLDivElement, ICardProps>(function Card(props, ref) {
  const {
    className,
    fullHeight,
    children,
    ...restProps
  } = props;
  
  const classes = useMemo(() => {
    let classes = 'ui-card';
    if (fullHeight)
      classes += ' ui-card--full-height';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, fullHeight]);   

  return (
    <MuiCard
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiCard>
  );
});

Card.defaultProps = {
  elevation: 0
};