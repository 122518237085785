import {IPasswordResetConfirmRequest} from 'api';

export enum PasswordResetConfirmActionTypes {
  request = 'PASSWORD_CONFIRM_REQUEST',
  success = 'PASSWORD_CONFIRM_SUCCESS',
  failure = 'PASSWORD_CONFIRM_FAILURE'
}

export interface IPasswordResetConfirmRequestAction {
  type: PasswordResetConfirmActionTypes.request;
  payload: IPasswordResetConfirmRequest;
}

export interface IPasswordResetConfirmSuccessAction {
  type: PasswordResetConfirmActionTypes.success;
}

export interface IPasswordResetConfirmFailureAction {
  type: PasswordResetConfirmActionTypes.failure;
  payload: IRequestError;
}

export type PasswordResetConfirmAction = (
  IPasswordResetConfirmRequestAction |
  IPasswordResetConfirmSuccessAction |
  IPasswordResetConfirmFailureAction
);
