import React, {FunctionComponent, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Accordion, AccordionSummary, AccordionDetails} from 'components/ui/accordion';
import {LoremIpsum} from 'lorem-ipsum';

/** Accordion control preview */
export const AccordionKit: FunctionComponent = () => {
  const {t} = useTranslation();

  const loremIpsum = useMemo(() => {
    return new LoremIpsum();
  }, []);
  
  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.accordion.heading')}
      </Typography>

      {/* First item */}

      <Accordion>
        <AccordionSummary id="my-accordion">
          {t('views.app.ui_kit.accordion.label.first')}
        </AccordionSummary>
        <AccordionDetails>
          {loremIpsum.generateWords(100)}
        </AccordionDetails>
      </Accordion>

      {/* Second item */}

      <Accordion>
        <AccordionSummary id="my-accordion">
          {t('views.app.ui_kit.accordion.label.second')}
        </AccordionSummary>
        <AccordionDetails>
          {loremIpsum.generateWords(100)}
        </AccordionDetails>
      </Accordion>

      {/* Third item */}

      <Accordion>
        <AccordionSummary id="my-accordion">
          {t('views.app.ui_kit.accordion.label.third')}
        </AccordionSummary>
        <AccordionDetails>
          {loremIpsum.generateWords(100)}
        </AccordionDetails>
      </Accordion>
    </>
  );
};
