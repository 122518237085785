import './invoice-summary.scss';
import React, {FunctionComponent, useCallback, useEffect, useMemo} from 'react';
import {format} from 'lib/intl/format';
import {useTranslation} from 'lib/intl/i18n';
import {useFetch} from 'hooks';
import {api} from 'api';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {IInvoiceSummaryProps} from './invoice-summary.types';
import PaidIcon from '@material-ui/icons/CheckCircle';
import UnpaidIcon from '@material-ui/icons/Error';
import {Typography} from 'components/ui/typography';

/**
 * Summary of invoice payments
 *
 * @example
 *
 *   <InvoiceSummary />
 */
export const InvoiceSummary: FunctionComponent<IInvoiceSummaryProps> = (props) => {
  const {className, contractId} = props;
  const invoiceSummary = useFetch(api.invoiceSummary);
  const {t} = useTranslation();

  useEffect(() => {
    invoiceSummary.fetch({contractId});
  // eslint-disable-next-line
  }, [contractId]);

  const invoicesArePaid = useMemo(() => {
    if (invoiceSummary.data) {
      const {dueAmount} = invoiceSummary.data;
      const paid = dueAmount.value <= 0;
      return paid;
    }
  }, [invoiceSummary.data]);

  const render = useCallback(() => {
    const {dueAmount} = invoiceSummary.data!;
    return (
      <div className="ui-invoice-summary__container">
        <div className="ui-invoice-summary__icon">
          {invoicesArePaid && (<PaidIcon />)}
          {!invoicesArePaid && (<UnpaidIcon />)}
        </div>
        <div className="ui-invoice-summary__message">
          <Typography variant="h2">
            {invoicesArePaid && t('components.invoice.invoice_summary.paid')}
            {!invoicesArePaid && t('components.invoice.invoice_summary.unpaid', {
              amount: format.currency(dueAmount.value, dueAmount.currencyId)
            })}
          </Typography>
        </div>
      </div>
    );
  }, [invoiceSummary.data, invoicesArePaid, t]);
  
  const classes = useMemo(() => {
    let classes = 'ui-invoice-summary';
    if (invoicesArePaid)
      classes += ' ui-invoice-summary--paid';
    else
      classes += ' ui-invoice-summary--unpaid';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, invoicesArePaid]);   
  
  return (
    <div className={classes}>
      <WaitForFetch
        pending={invoiceSummary.pending}
        error={invoiceSummary.error}
        noData={!invoiceSummary.data}
        onRetry={invoiceSummary.fetch}
        render={render}
        absolutePosition
        effect="zoom"
      />  
    </div>
  );
};