/**
 * @file Axios library
 * 
 * @see  https://github.com/axios/axios
 * 
 * @example 
 * 
 *   import {axios, headers} from 'api/lib/axios';
 *   
 *   export const fetchExample = (abc, def) => {
 *     return axios.post('my/endpoint', {
 *       headers: {
 *         ...headers.lang(),
 *         ...headers.auth()
 *       },
 *       body: {abc, def}
 *     });
 *   };
 */

import {API_URL} from 'config/api';
import axiosLib, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  CancelToken
} from 'axios';
import {
  RequestGetter,
  IExtendedRequestConfig,
  IExtendedAxiosInstance,
  ExtendablePromise
} from './axios.types';
import {CANCEL} from 'redux-saga';
import {actions} from 'actions';
import {dispatch} from 'store';
import {httpCodes} from 'lib/api/http-codes';
import {AnyAction} from 'redux';
import {InputValidationException} from 'lib/global/exceptions';
import {headers} from './headers';
import {urls} from 'config/urls';
import md5 from 'md5';
import moment from 'moment';

/**
 * Fetch response cache
 * 
 * @example
 * 
 *   axios.get('my/endpoint', {cache: true});
 * 
 * @example
 * 
 *   // Invalidate cache after 1 day without page reload
 * 
 *   axios.get('my/endpoint', {
 *     cache: true,
 *     cacheExpiresAfter: [1, 'day']
 *   });
 */
let responseCache = new Map<string, unknown>();

/** Reset response cache */
export const invalidateCache = () => {
  responseCache = new Map<string, unknown>();
};

/**
 * Refresh access token on expiration
 * 
 * @param axiosInstance - instance to be extended
 */
const setupTokenRefresh = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(undefined, (error: AxiosError) => {
    if (error.response?.status === httpCodes.UNAUTHORIZED_401 && error.config.url !== urls.token())
      dispatch(actions.retryRequest(error.config) as AnyAction);
    return Promise.reject(error);
  });
};

/**
 * Format error messages to implement IRequestError interface
 * 
 * @param axiosInstance - Instance to be extended
 */
const setupErrorFormat = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.response.use(undefined, (axiosError: AxiosError) => {
    const {response} = axiosError;
    const error = {} as IRequestError;
    if (!response)
      error.message = 'No response';
    else {
      error.message = response?.data?.message || response.statusText;
      error.status = response.status;
    }
    error.cancel = axiosLib.isCancel(axiosError);
    return Promise.reject(error);
  });
};

/**
 * Add a cancel token to request config
 * 
 * @param config - Config to extend
 * @param cancelToken - Cancel token to add
 * @returns Request config extended by cancel token
 */
const addCancelToken = (config: AxiosRequestConfig = {}, cancelToken: CancelToken) => {
  return {
    ...config,
    cancelToken
  };
};

/**
 * Cancel the request in the cancelled saga
 * 
 * @param getRequest - Request getter
 * @param config - Request config
 * @returns Request that is set to cancel in a cancelled saga
 * @throws {InputValidationException}
 */
const addSagaCancellation = <T = unknown, R = AxiosResponse<T>>(
  getRequest: RequestGetter,
  config: IExtendedRequestConfig = {}
): Promise<R> => {
  if (config.cancelToken) {
    if (config.cancelInSaga !== false && process.env.NODE_ENV === 'development')
      throw new InputValidationException([
        'This request cannot be automatically cancelled in the cancelled saga',
        '- "cancelToken" property is already set. If you want to use request cancellation,',
        'set explicitly "cancelInSaga" to false in the request config.'
      ].join(' '));
    return getRequest(config);
  }
  const source = axiosLib.CancelToken.source();
  const request = getRequest<T, R>(addCancelToken(config, source.token));
  (request as ExtendablePromise<UnwrapPromise<typeof request>>)[CANCEL] = () => source.cancel();
  return request;
};

/**
 * Cancel all requests that was initiated in the cancelled saga and/or cache fetch results
 * 
 * @param getRequest - Request getter
 * @param config - Request config
 */
const fetch = <T = unknown, R = AxiosResponse<T>>(
  getRequest: RequestGetter,
  config: IExtendedRequestConfig = {}
): Promise<R> => {
  const {cache, cacheExpiresAfter, ...restConfig} = config;
  return addSagaCancellation((async (newConfig: IExtendedRequestConfig) => {
    if (cache) {
      const cacheKey = md5(JSON.stringify(newConfig));
      
      // Use previously stored response
      if (responseCache.has(cacheKey))
        return responseCache.get(cacheKey) as R;
      const response = await getRequest(newConfig);
      
      // Store response
      responseCache.set(cacheKey, response);
      
      // Invalidate cache
      if (cacheExpiresAfter)
        setTimeout(() => {
          responseCache.delete(cacheKey);
        }, moment.duration(...cacheExpiresAfter).asMilliseconds());

      return response;
    }
    return await getRequest(newConfig);
  }) as RequestGetter, restConfig);
};

/**
 * Setup request methods for saga cancellation and response caching
 * 
 * @param axiosInstance - Instance to be extended
 * @returns Extended instance
 */
const setupFetch = (axiosInstance: AxiosInstance) => {
  const {request, defaults, interceptors, getUri} = axiosInstance;

  function instance(config: IExtendedRequestConfig) {
    return fetch(request, config);
  }

  instance.request = (config: IExtendedRequestConfig) => {
    return fetch(request, config);
  };

  instance.get = (url: string, config?: IExtendedRequestConfig) => {
    return fetch(request, {
      ...config,
      method: 'get',
      url      
    });
  };

  instance.delete = (url: string, config?: IExtendedRequestConfig) => {
    return fetch(request, {
      ...config,
      method: 'delete',
      url
    });
  };

  instance.head = (url: string, config?: IExtendedRequestConfig) => {
    return fetch(request, {
      ...config,
      method: 'head',
      url
    });
  };

  instance.options = (url: string, config?: IExtendedRequestConfig) => {
    return fetch(request, {
      ...config,
      method: 'options',
      url
    });
  };

  instance.post = (url: string, data?: unknown, config?: IExtendedRequestConfig) => {
    return fetch(request, {
      ...config,
      method: 'post',
      url,
      data
    });
  };

  instance.put = (url: string, data?: unknown, config?: IExtendedRequestConfig) => {
    return fetch(request, {
      ...config,
      method: 'put',
      url,
      data
    });
  };

  instance.patch = (url: string, data?: unknown, config?: IExtendedRequestConfig) => {
    return fetch(request, {
      ...config,
      method: 'patch',
      url,
      data
    });
  };

  instance.defaults = defaults;
  instance.interceptors = interceptors;
  instance.getUri = getUri;

  return instance as IExtendedAxiosInstance;
};

const setupDefaultHeaders = (axiosInstance: AxiosInstance) => {
  axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers = {
      ...headers.partner(),
      ...config.headers
    };
    return config;
  });
};

const setupStaticProps = (instance: IExtendedAxiosInstance) => {
  instance.Cancel = axiosLib.Cancel;
  instance.CancelToken = axiosLib.CancelToken;
  instance.isCancel = axiosLib.isCancel;
  return instance;
};

/** Get initialized axios instance */
const createAxiosInstance = () => {
  const axiosInstance = axiosLib.create({baseURL: API_URL}) as IExtendedAxiosInstance;
  setupTokenRefresh(axiosInstance);
  setupErrorFormat(axiosInstance);
  setupDefaultHeaders(axiosInstance);
  return setupStaticProps(setupFetch(axiosInstance));
};

/** Axios instance */
export const axios = createAxiosInstance();

/** Request cancelling */
export type {CancelToken};