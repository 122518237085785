import {call, put, takeLatest} from 'typed-redux-saga/macro';
import {actions} from 'actions';
import {api} from 'api';

/** Create a new demand */
const demandCreationSaga = function* (action: actions.IDemandCreationRequestAction) {
  try {
    const response = yield* call(api.demandCreation, action.payload);
    yield put(actions.demandCreationSuccess(response));
  }
  catch (error) {
    yield put(actions.demandCreationFailure(error as IRequestError));
  }
};

export const demandCreation = function* () {
  yield takeLatest(actions.DemandCreationActionTypes.request, demandCreationSaga);
};