import React, {FunctionComponent, useCallback, useEffect} from 'react';
import {useFetch} from 'hooks';
import {WaitForFetch} from 'components/api/wait-for-fetch';
import {api} from 'api';
import {IContractDocumentsProps} from './contract-documents.types';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import DocumentsIcon from '@material-ui/icons/FileCopyOutlined';
import {DocumentsTable} from 'components/document/documents-table';

/** Contract documents */
export const ContractDocuments: FunctionComponent<IContractDocumentsProps> = (props) => {
  const {contractId} = props;
  const contracts = useFetch(api.contractList);
  const {t} = useTranslation();

  const fetchContracts = useCallback(() => {
    contracts.fetch({contractId});
  }, [contractId, contracts]);
  
  useEffect(() => {
    fetchContracts();
  // eslint-disable-next-line
  }, []);

  const render = useCallback(() => (
    <DocumentsTable documents={contracts.data![0].documents!} />
  ), [contracts.data]);

  return (
    <>

      {/* Heading */}

      <Typography
        variant="h2"
        color="primary"
        gutterBottom
      >
        <DocumentsIcon />
        {t('views.user.contract.documents.heading')}
      </Typography>
      
      {/* Table */}
      
      <WaitForFetch
        pending={contracts.pending}
        error={contracts.error}
        noData={!contracts.data || !contracts.data[0] || !contracts.data[0].documents}
        noDataMessage={t('views.user.contract.documents.no_data')}
        onRetry={fetchContracts}
        render={render}
      />
    </>
  );
};