import './news-list-item.scss';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {INewsListItemProps} from './news-list-item.types';
import {RevealFade} from 'components/ui/reveal-fade';
import {Typography} from 'components/ui/typography';
import {CmsContent} from 'components/ui/cms-content';
import {Box} from 'components/ui/box';
import {Link} from 'components/ui/link';

/** News list item */
export const NewsListItem: FunctionComponent<INewsListItemProps> = (props) => {
  const {
    title,
    date,
    content,
    detailUrl
  } = props;
  const {t} = useTranslation();
  
  return (
    <li className="ui-news-list-item">
      <RevealFade>
        
        {/* Heading */}
        
        {title && (
          <Typography
            variant="h3"
            gutterBottom
          >
            {title}
          </Typography>
        )}
        
        {/* Content */}
        
        <CmsContent
          className="ui-news-list-item__content"
          fromHtmlString={`
            <span class="ui-news-list-item__date">
              ${date}
            </span>
            ${content}
          `}
        />

        {/* Footer */}

        <Box mt={3}>
          <Link to={detailUrl}>
            {t('components.article.news_list.detail')}
          </Link>
        </Box>
      </RevealFade>
    </li>
  );
};