import {intlApi} from 'lib/intl/intl-api';
import {CurrencyDisplay} from './currency.types';

/**
 * Format currency
 *
 * @example
 *
 *   format.currency(123, 'USD');
 *   // $123.00
 *
 * @example
 *
 *   format.currency(123, 'USD', 'code');
 *   // USD 123.00
 *
 * @example
 *
 *   format.currency(123, 'USD', 'name');
 *   // 123.00 US dollars
 *
 * @param value - Price value to format
 * @param currency - Currency ISO code
 * @param [display] - Currency display style
 * @returns Formatted price
 */
export const currency = (value: number, currency: ISOCurrencyCode, display: CurrencyDisplay = 'symbol') => {
  return intlApi.formatNumber(value, {
    style: 'currency',
    currency,
    currencyDisplay: display
  });
};
