import React, {ChangeEvent, FunctionComponent, useCallback, useMemo, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {ComboBox, ComboBoxOptions} from 'components/ui/combo-box';
import {Grid} from 'components/ui/grid';

/** Combo box control preview */
export const ComboBoxKit: FunctionComponent = () => {
  const [values, setValues] = useState<Record<string, number | null>>({
    value1: null,
    value2: null
  });
  const {t} = useTranslation();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>, value: unknown) => {
    setValues((prevValues) => ({
      ...prevValues,
      [event.target.name]: value as number
    }));
  }, []);

  const options = useMemo<ComboBoxOptions>(() => [
    {
      label: 'What',
      value: 0
    },
    {
      label: 'Did',
      value: 1
    },
    {
      label: 'You',
      value: 2
    },
    {
      label: 'Expect',
      value: 3
    },
    {
      label: 'To',
      value: 4
    },
    {
      label: 'Find',
      value: 5
    }
  ], []);

  const htmlOptions = useMemo<ComboBoxOptions>(() => [
    {
      label: 'Lorem ipsum',
      value: 0
    },
    {
      label: '<b>Dolor sit amet</b>',
      value: 1
    },
    {
      label: '<i>Consectetur</u>',
      value: 2
    },
    {
      label: '<u>Adipiscing elit</u>',
      value: 3
    }
  ], []);

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.combo_box.heading')}
      </Typography>
      <Grid container>

        {/* String options */}

        <Grid item md={6}>
          <ComboBox
            name="value1"
            label={t('views.app.ui_kit.combo_box.label.default')}
            options={options}
            value={values.value1}
            onChange={handleChange}
          />
        </Grid>

        {/* Html options */}

        <Grid item md={6}>
          <ComboBox
            name="value2"
            label={t('views.app.ui_kit.combo_box.label.html')}
            options={htmlOptions}
            renderOptionsAsHtml
            value={values.value2}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
