import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import validator from 'validator';
import {t} from 'lib/intl/i18n';

/**
 * Validate URL address
 *
 * @param input - URL to validate
 * @returns Error message or undefined
 */
export const url: IValidator = (input) => {
  const value = String(input || '').trim();
  if (value && !validator.isURL(value))
    return t('lib.global.validate.url');
};

/**
 * Check that the URL validator doesn't return any error
 *
 * @param input - URL value to check
 */
export const isUrl: ValidatorQuery = (input) => {
  return !!input && !url(input);
};
