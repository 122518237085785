/**
 * @file Filter component HOC
 * 
 * @example
 * 
 *   //--- my-filter.types.ts
 * 
 *   export interface IMyFilterValues {
 *     contractId: (number | null);
 *     placeId: (number | null);
 *     energyType: (string | null);
 *   }
 * 
 * @example
 * 
 *   //--- my-filter.tsx
 * 
 *   import React, {FunctionComponent} from 'react';
 *   import {IMyFilterValues} from './my-filter.types';
 *   import {withFilter} from 'components/ui/filter';
 *   import {FilterContext} from './my-filter-context';
 *   import {ContractSelect} from './my-filter-contract';
 *   import {PlaceSelect} from './my-filter-place';
 *   import {EnergyTypeSelect} from './my-filter-energy';
 * 
 *   const FilterComponent: FunctionComponent = () => {
 *     return (
 *       <>
 *         <ContractSelect />
 *         <PlaceSelect />
 *         <EnergyTypeSelect />
 *       </>
 *     );
 *   };
 * 
 *   export const MyFilter = withFilter<IMyFilterValues>({
 *     filterId: 'my-filter',
 * 
 *     FilterContext,
 * 
 *     initialValues: {
 *       contractId: null,
 *       placeId: null,
 *       energyType: null
 *     },
 * 
 *     // Optional
 *     transformValues: (values) => ({
 *       ...values,
 *       energyType: 'some custom value'
 *     }),
 *     
 *     isFilterComplete: (values) => (
 *       values.contractId !== null &&
 *       values.placeId !== null &&
 *       values.energyType !== null
 *     )
 *   })(FilterComponent);
 * 
 * @example
 * 
 *   //--- my-filer-field.tsx
 * 
 *   import {useContext} from 'react';
 *   import {FilterContext} from './my-filter-context';
 *   import {FilterComboBox} from 'components/ui/filter-combo-box';
 * 
 *   // ...
 * 
 *   const {
 *     getValues,
 *     setValues,
 *     getStoredValue,
 *     FilterField
 *   } = useContext(FilterContext);
 * 
 *   // ...
 * 
 *   <FilterField
 *     pending={someList.pending}
 *     error={someList.error}
 *   >
 *     <FilterComboBox
 *       options={someList.data}
 *       ...
 *     />
 *   </FilterField>
 * 
 * @example
 * 
 *   //--- some-view.tsx
 * 
 *   import React, {FunctionComponent, useState} from 'react';
 *   import {useDeepEffect} from 'hooks';
 *   import {MyFilter, IMyFilterValues} from './my-filter';
 *   import {useFilterState, FilterOutput} from 'components/ui/filter';
 *   import {SomeTable} from './some-table';
 * 
 *   // ...
 * 
 *   const [filter, setFilter] = useFilterState<IMyFilterValues>();
 * 
 *   useDeepEffect(() => {
 *     console.log('Filter values:', filter.values);
 *     console.log('Filter is pending:', filter.isPending);
 *     console.log('Filter is complete:', filter.isComplete);
 *   }, [filter]);
 * 
 *   // ...
 * 
 *   <MyFilter onChange={setFilter} />
 *   <FilterOutput filter={filter}>
 *     <SomeTable filter={filter} />
 *   </FilterOutput>
 */

import React, {useCallback, useEffect, useMemo, createContext, FunctionComponent} from 'react';
import {createUseSharedState} from 'hooks';
import {
  IWithFilterOptions,
  FilterValues,
  IFilterProps,
  IFilterContextValue
} from './with-filter.types';
import {createFilterField, IFilterFieldContextValue} from './filter-field';
import {useFilterFetch} from './use-filter-fetch';
import {useFilterStorage} from './use-filter-storage';
import {useFilterChange} from './use-filter-change';
import {FilterPanel} from 'components/ui/filter-panel';

// Symbol to store message returned from showMessage()
export const FILTER_MESSAGE = Symbol('filter-message');

/**
 * Filter component HOC
 *
 * @param options - Filter options
 * @param options.filterId - Unique filter ID
 * @param options.FilterContext - Context for sharing status between filter components
 * @param options.initialValues - Initial filter values
 * @param options.isFilterComplete - Function to determine whether all required values are selected
 * @param options.showMessage - Function to specify the message to be displayed in the filter output
 * @param [options.transformValues] - Function for transforming filter values before providing them to the onChange handler
 * @returns Filter component
 */
export const withFilter = <T extends FilterValues<T>>(options: IWithFilterOptions<T>) => {
  const {
    filterId,
    FilterContext,
    initialValues,
    transformValues,
    isFilterComplete,
    showMessage
  } = options;
  const useFilterValues = createUseSharedState(initialValues);

  return (WrappedFilter: FunctionComponent) => {
    const FilterFieldContext = createContext<IFilterFieldContextValue>(null!);
    const FilterField = createFilterField(FilterFieldContext);

    const FilterWrapper: FunctionComponent<IFilterProps<T>> = (props) => {
      const {className, onChange, children} = props;
      const [values, setValues] = useFilterValues();
      
      // Filter fetch logic
      const {pending, setPending, error, setError} = useFilterFetch();
      
      // Filter values storage logic
      const {storeValues, getStoredValue} = useFilterStorage({filterId, initialValues});

      // Filter onChange handler logic
      useFilterChange({
        values,
        transformValues,
        onChange,
        pending,
        isFilterComplete,
        showMessage
      });

      // Reset shared state on filter unmount
      useEffect(() => {
        return () => setValues(initialValues);
      // eslint-disable-next-line
      }, []);

      /** Set values, store them in local storage, and mirror them in a URL query */
      const setAndStoreValues = useCallback((newValues: Partial<T>) => {
        storeValues(newValues);
        setValues(newValues);
      }, [setValues, storeValues]);

      /** Get the current filter values */
      const getValues = useCallback(() => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [values] = useFilterValues();
        return values;
      }, []);

      /** Values shared by filter components */
      const filterContextValue = useMemo<IFilterContextValue<T>>(() => ({
        getValues, 
        setValues,
        setAndStoreValues,
        storeValues,
        getStoredValue,
        FilterField,
        setPending,
        setError
      }), [getStoredValue, getValues, setAndStoreValues, setError, setPending, setValues, storeValues]);

      /** Filter field wrapper (<FilterField> component) context */
      const filterFieldContextValue = useMemo<IFilterFieldContextValue>(() => ({
        setPending,
        setError
      }), [setError, setPending]);

      return (
        <FilterContext.Provider value={filterContextValue}>
          <FilterFieldContext.Provider value={filterFieldContextValue}>
            <FilterPanel
              className={className}
              pending={pending}
              error={error}
            >
              <WrappedFilter>
                {children}
              </WrappedFilter>
            </FilterPanel>
          </FilterFieldContext.Provider>
        </FilterContext.Provider>
      );
    };

    return FilterWrapper;
  };
};