import {
  IConsumptionParameterListRequestAction,
  IConsumptionParameterListSuccessAction,
  IConsumptionParameterListFailureAction,
  ConsumptionParameterListActionTypes
} from './consumption-parameter-list.types';
import {ConsumptionParameterListResponse} from 'api';

/** Get list of dynamic consumption place parameters */
export const consumptionParameterListRequest = (): IConsumptionParameterListRequestAction => ({
  type: ConsumptionParameterListActionTypes.request
});

export const consumptionParameterListSuccess = (payload: ConsumptionParameterListResponse): IConsumptionParameterListSuccessAction => ({
  type: ConsumptionParameterListActionTypes.success,
  payload
});

export const consumptionParameterListFailure = (payload: IRequestError): IConsumptionParameterListFailureAction => ({
  type: ConsumptionParameterListActionTypes.failure,
  payload
});