import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {DataList} from 'components/ui/data-list';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import DraftsIcon from '@material-ui/icons/DescriptionSharp';
import SentIcon from '@material-ui/icons/Send';
import DeletedIcon from '@material-ui/icons/DeleteForever';

/** List control preview */
export const ListKit: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.list.heading')}
      </Typography>
      <DataList
        items={[
          {
            icon: (<InboxIcon />),
            label: t('views.app.ui_kit.list.label.inbox')
          },
          {
            icon: (<SentIcon />),
            label: t('views.app.ui_kit.list.label.sent')
          },
          {
            icon: (<DraftsIcon />),
            label: t('views.app.ui_kit.list.label.drafts')
          },
          {
            icon: (<DeletedIcon />),
            label: t('views.app.ui_kit.list.label.deleted')
          }
        ]}
      />
    </>
  );
};
