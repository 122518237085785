import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IUserActivationRequest} from './user-activation.types';

/** User account activation */
export const userActivation = async (request: IUserActivationRequest) => {
  const response = await axios.post(urls.userActivation(), request, {
    headers: headers.language()
  });
  return response.data;
};