import {DemandTypeListResponse} from 'api';

export enum DemandTypeListActionTypes {
  request = 'DEMAND_TYPE_LIST_REQUEST',
  success = 'DEMAND_TYPE_LIST_SUCCESS',
  failure = 'DEMAND_TYPE_LIST_FAILURE'
}

export interface IDemandTypeListRequestAction {
  type: DemandTypeListActionTypes.request;
}

export interface IDemandTypeListSuccessAction {
  type: DemandTypeListActionTypes.success;
  payload: DemandTypeListResponse;
}

export interface IDemandTypeListFailureAction {
  type: DemandTypeListActionTypes.failure;
  payload: IRequestError;
}

export type DemandTypeListAction = (
  IDemandTypeListRequestAction |
  IDemandTypeListSuccessAction |
  IDemandTypeListFailureAction
);