import './header-login-form.scss';
import React, {FunctionComponent} from 'react';
import {paths} from 'config/router';
import {useLoginForm} from 'hooks';
import {useTranslation} from 'lib/intl/i18n';
import {Form} from 'components/ui/form';
import {Flexbox} from 'components/ui/flexbox';
import {Box} from 'components/ui/box';
import {FormTextField} from 'components/ui/form-text-field';
import {Button} from 'components/ui/button';
import LoginIcon from '@material-ui/icons/PowerSettingsNew';
import {Link} from 'components/ui/link';

/** Header login form */
export const HeaderLoginForm: FunctionComponent = () => {
  const {initialValues, handleSubmit, loginPending} = useLoginForm('header-login');
  const {t} = useTranslation();

  return (
    <Form
      className="ui-header-login-form"
      initialValues={initialValues}
      onSubmit={handleSubmit}
    >
      <Flexbox>
        <Box mr={2}>
          <Flexbox>

            {/* Login */}
            
            <Box mr={2}>
              <FormTextField
                name="login"
                placeholder={t('components.main.app.header.login_form.user')}
                size="small"
                disabled={loginPending}
              />
            </Box>
            
            {/* Password */}
            
            <FormTextField
              name="password"
              type="password"
              placeholder={t('components.main.app.header.login_form.password')}
              size="small"
              disabled={loginPending}
            />
          </Flexbox>

          {/* Forgotten password */}

          <div className="ui-header-login-form__forgotten-password">
            <Link
              to={paths.passwordReset}
              underline
              size="small"
              disabled={loginPending}
            >
              {t('components.main.app.header.login_form.forgotten_password')}
            </Link>
          </div>
        </Box>

        {/* Submit */}

        <Button
          className="ui-header-login-form__submit"
          type="submit"
          icon={<LoginIcon />}
          pending={loginPending}
        >
          {t('components.main.app.header.login_form.submit')}
        </Button>
      </Flexbox>
    </Form>
  );
};