import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IConsumptionSummaryRequest, ConsumptionSummaryResponse} from './consumption-summary.types';

/** Get the number of records per energy type */
export const consumptionSummary = async (request?: IConsumptionSummaryRequest) => {
  const response = await axios.get<ConsumptionSummaryResponse>(urls.consumptionSummary(), {
    params: request,
    headers: {
      ...headers.auth(),
      ...headers.partner()
    }
  });
  return response.data;
};