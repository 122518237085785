import React, {FunctionComponent} from 'react';
import {IInvoicesFilterValues} from './invoices-filter.types';
import {withFilter} from 'components/ui/filter';
import {FilterContext} from './invoices-filter-context';
import {Grid} from 'components/ui/grid';
import {ContractSelect, ALL_CONTRACTS} from './invoices-filter-contract';
import {IntervalSelect} from './invoices-filter-interval';
import {CommodityTypeSelect, ALL_COMMODITY_TYPES} from './invoices-filter-commodity';
import {UnpaidCheckbox} from './invoices-filter-unpaid';
import {CommodityTypeId} from 'api';
 
const FilterComponent: FunctionComponent = () => {
  return (
    <Grid container>
      <Grid item xl={6}>
        <ContractSelect />
      </Grid>
      <Grid item xl={6}>
        <IntervalSelect />
      </Grid>
      <Grid item xl={6}>
        <CommodityTypeSelect />
      </Grid>
      <Grid item xl={6}>
        <UnpaidCheckbox />
      </Grid>
    </Grid>
  );
};

export const InvoicesFilter = withFilter<IInvoicesFilterValues>({
  filterId: 'invoices',

  FilterContext,

  initialValues: {
    contractId: null,
    commodityTypeId: null,
    unpaid: false,
    from: null,
    to: null
  },
  
  isFilterComplete: (values) => (
    values.contractId !== null
  ),

  transformValues: (values) => {
    if (values.contractId === ALL_CONTRACTS)
      values.contractId = null;
    const commodityTypeId = values.commodityTypeId as  (CommodityTypeId | typeof ALL_COMMODITY_TYPES);
    if (commodityTypeId === ALL_COMMODITY_TYPES)
      values.commodityTypeId = null;
    return values;
  }
})(FilterComponent);