import React, {FunctionComponent} from 'react';
import {IDesktopMenuItemsProps} from './desktop-menu-items.types';
import {Tabs} from 'components/ui/tabs';
import {Tab} from 'components/ui/tab';
import {NavLink} from 'react-router-dom';

/** Desktop menu items list */
export const DesktopMenuItems: FunctionComponent<IDesktopMenuItemsProps> = (props) => {
  const {className, items, activeItemPath} = props;

  if (!items)
    return null;

  return (
    <Tabs
      className={className}
      variant="scrollable"
      scrollButtons="auto"
      value={activeItemPath}
    >
      {items.map((item) => !item.hidden && (item.icon || item.label) && (
        <Tab
          key={`${item.path}-${item.label}`}
          icon={item.icon}
          label={item.label}
          component={NavLink}
          to={item.goToPath || item.path}
          value={item.path}
        />
      ))}
    </Tabs>
  );
};