import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {Banner} from 'components/ui/banner';
import firstBanner from './assets/banner1.png';
import secondBanner from './assets/banner2.png';

/** Banner control preview */
export const BannerKit: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.banner.heading')}
      </Typography>
      <Banner
        image={firstBanner}
        text={t('views.app.ui_kit.banner.content.first')}
        url="https://vesr.sk"
      />
      <br /><br />
      <Banner
        image={secondBanner}
        text={t('views.app.ui_kit.banner.content.second')}
        url="https://vesr.sk"
      />
    </>
  );
};
