import {date as validateDate} from 'lib/global/validate/date';
import {date as parseDate} from 'lib/intl/parse/date';
import {logErrorOnDev} from 'lib/global/dev';

export const LOCALE_DATE_FORMAT = 'l';

/**
 * Format date
 *
 * @param value - Date to format
 * @param [format] - Custom date format
 * @returns Formatted date
 */
export const date = (value: AnyDate, format = LOCALE_DATE_FORMAT) => {
  if (!value)
    return '';
  const validationError = validateDate(value);
  if (validationError) {
    logErrorOnDev(validationError);
    return value !== undefined && value !== null ? value.toString() : value;
  }
  return parseDate(value).format(format);
};
