import React, {forwardRef, useContext} from 'react';
import {OuterElementContext} from './outer-element-context';

export const OuterElementType = forwardRef<HTMLDivElement>(function OuterElementType(props, ref) {
  const outerProps = useContext(OuterElementContext);

  return (
    <div
      ref={ref}
      {...props}
      {...outerProps}
    />
  );
});
