import {DemandPriorityListResponse} from 'api';

export enum DemandPriorityListActionTypes {
  request = 'DEMAND_PRIORITY_LIST_REQUEST',
  success = 'DEMAND_PRIORITY_LIST_SUCCESS',
  failure = 'DEMAND_PRIORITY_LIST_FAILURE'
}

export interface IDemandPriorityListRequestAction {
  type: DemandPriorityListActionTypes.request;
}

export interface IDemandPriorityListSuccessAction {
  type: DemandPriorityListActionTypes.success;
  payload: DemandPriorityListResponse;
}

export interface IDemandPriorityListFailureAction {
  type: DemandPriorityListActionTypes.failure;
  payload: IRequestError;
}

export type DemandPriorityListAction = (
  IDemandPriorityListRequestAction |
  IDemandPriorityListSuccessAction |
  IDemandPriorityListFailureAction
);