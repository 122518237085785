import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types'; 
import memoize from 'fast-memoize';
import {t} from 'lib/intl/i18n';
import {time as parseTime} from 'lib/intl/parse/time';
import {time as formatTime} from 'lib/intl/format/time';
import {time as validateTime} from 'lib/global/validate/time';
import {logWarningOnDev} from 'lib/global/dev';

/**
 * Get maximum time validator
 *
 * @param maximumTime - Maximum time value
 */
export const maxTime = memoize((maximumTime: AnyDate) => {
  if (!parseTime(maximumTime).isValid())
    logWarningOnDev(t('lib.global.validate.time.invalid'));

  /**
   * Validate maximum time
   *
   * @param input - Time to validate
   * @returns Error message or undefined
   */
  const maxTime: IValidator<AnyDate> = (input) => {
    if (input) {
      const isValid = !validateTime(input);
      const isSameOrBefore = parseTime(input).isSameOrBefore(parseTime(maximumTime));
      if (isValid && !isSameOrBefore) {
        const time = formatTime(maximumTime);
        return t('lib.global.validate.time.max', {time});
      }
    }
  };

  return maxTime;
});

/**
 * Check that the maximum time validator doesn't return any error
 *
 * @param input - Time to validate
 * @param maximumTime - Maximum time value
 */
export const isMaxTime: ValidatorQuery = (input, maximumTime: AnyDate) => {
  return !!input && !maxTime(maximumTime)(input);
};
