
/** Get a list of invoices */
export const invoiceList = () => {
  return 'invoice/list';
};

/** Get a list of invoice statuses */
export const invoiceStatusList = () => {
  return 'index/invoice-status/list';
};

/** Get a list of invoice commodities */
export const invoiceCommodityList = () => {
  return 'index/invoice-commodity/list';
};

/** Get a summary of invoice payments */
export const invoiceSummary = () => {
  return 'invoice/summary';
};
