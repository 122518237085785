import './table-row.scss';
import React, {forwardRef, useMemo} from 'react';
import {TableRowProps} from './table-row.types';
import MuiTableRow from '@material-ui/core/TableRow';

/**
 * Table row
 * 
 * @see src/views/app/kit-view/table-kit
 * 
 * @example
 *  
 *   import {Table, TableContainer, TableHead, TableBody, TableRow, TableCell} from 'components/ui/table';
 *   
 *   // ...
 *   
 *   <TableContainer>
 *     <Table>
 * 
 *       <TableHead>
 *         <TableRow>
 *           <TableCell>
 *             First name
 *           </TableCell>
 *           <TableCell>
 *             Last name
 *           </TableCell>
 *         </TableRow>
 *       </TableHead>
 *       
 *       <TableBody>
 *         <TableRow>
 *           <TableCell>
 *             Alan
 *           </TableCell>
 *           <TableCell>
 *             Turing
 *           </TableCell>
 *         </TableRow>
 *         ...
 *       </TableBody>
 * 
 *     </Table>
 *   </TableContainer>
 */
export const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(function TableRow(props, ref) {
  const {className, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-table-row';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <MuiTableRow
      ref={ref}
      {...restProps}
      className={classes}
    />
  );
});