import './button-actions.scss';
import React, {forwardRef, useMemo} from 'react';
import {IButtonActionsProps} from './button-actions.types';
import {ContainerQuery, IQueryClasses} from 'components/ui/container-query';

/**
 * Margins and alignment for direct button childs
 *
 * @example
 *
 *   import {ButtonActions} from 'components/ui/button-actions';
 *   import {Button} from 'components/ui/button';
 * 
 *   // ...
 * 
 *   <ButtonActions>
 *     <Button>
 *       Yes
 *     </Button>
 *     <Button>
 *       No
 *     </Button>
 *     <Button>
 *       Cancel
 *     </Button>
 *   </ButtonActions>
 */
export const ButtonActions = forwardRef<HTMLDivElement, IButtonActionsProps>(function ButtonActions(props, ref) {
  const {
    className,
    align,
    rows,
    children,
    ...restProps
  } = props;

  const queryClasses = useMemo<IQueryClasses>(() => ({
    'ui-button-actions--narrow': {maxWidth: 340}
  }), []);

  const classes = useMemo(() => {
    let classes = 'ui-button-actions';
    if (align)
      classes += ` ui-button-actions--align-${align}`;
    if (rows)
      classes += ' ui-button-actions--rows';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [align, className, rows]);

  return (
    <ContainerQuery
      {...restProps}
      ref={ref}
      queryClasses={queryClasses}
      className={classes}
    >
      {children}
    </ContainerQuery>
  );
});

ButtonActions.defaultProps = {
  align: 'left'
};