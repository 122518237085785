import React, {FunctionComponent, useCallback, useRef, useReducer} from 'react';
import {uploadReducer} from './upload-reducer';
import {uploadActions} from './upload-actions';
import {useDeepEffect} from 'hooks';
import {api} from 'api';
import {IUploadProps} from './upload.types';
import {UploadZone} from './upload-zone';
import {UploadList} from './upload-list';

const OPERATION = {
  REQUEST: 'request',
  SUCCESS: 'success',
  FAIL: 'fail',
  ADD: 'add',
  REMOVE: 'remove'
};

/**
 * File upload
 * 
 * NOTE: A dirty rewrite from a class component - should be refactored in a more reactive manner
 *
 * @example
 *
 *   import {Upload, useUpload} from 'components/ui/upload';
 *   
 *   // ...
 *   
 *   const {
 *     uploadPending,
 *     uploadError,
 *     uploadFileIds,
 *     handleUploadRequest,
 *     handleUploadSuccess,
 *     handleUploadError,
 *     handleUploadRemove
 *   } = useUpload();
 *   
 *   useEffect(() => {
 *     console.log('Upload pending: ', uploadPending);
 *     console.log('Upload error: ', uploadError);
 *     console.log('Upload file ids:', uploadFileIds);
 *   }, [uploadPending, uploadError, uploadFileIds]);
 *   
 *   // ...
 *   
 *   <Upload
 *     onRequest={handleUploadRequest}
 *     onSuccess={handleUploadSuccess}
 *     onError={handleUploadError}
 *     onRemove={handleUploadRemove}
 *   />
 */
export const Upload: FunctionComponent<IUploadProps> = (props) => {
  const {
    accept,
    maxSize,
    multiple,
    onRequest,
    onSuccess,
    onError,
    onRemove
  } = props;
  const operations = useRef<Set<ValueOf<typeof OPERATION>>>(new Set()); // setState callback workaround
  const addedFiles = useRef<File[]>();
  const [files, dispatch] = useReducer(uploadReducer, {});

  const getPendingFiles = useCallback(() => {
    return Object.values(files).filter((file) => !!file.pending);
  }, [files]);

  const getFailedFiles = useCallback(() => {
    return Object.values(files).filter((file) => !!file.error);
  }, [files]);

  const getFileIds = useCallback(() => {
    return Object.values(files)
      .filter((file) => !!file.id)
      .map((file) => file.id) as string[];
  }, [files]);

  //--- Request

  const handleRequest = useCallback((file: File) => {
    operations.current.add(OPERATION.REQUEST);
    dispatch(uploadActions.setFileState({
      file,
      state: {pending: true, error: false}
    }));
  }, []);

  useDeepEffect(() => {
    if (operations.current.has(OPERATION.REQUEST)) {
      if (onRequest && getPendingFiles().length === 1)
        onRequest();
      operations.current.delete(OPERATION.REQUEST);
    }
  }, [files]);

  //--- Progress
  
  const handleProgress = useCallback((file: File, loaded: number) => {
    let progress = 0;
    if (!file.size)
      progress = 100;
    else if (loaded)
      progress = Math.round(loaded / (file.size / 100));
    progress = Math.min(100, progress);
    dispatch(uploadActions.setFileState({
      file,
      state: {progress}
    }));
  }, []);

  //--- Success
  
  const handleSuccess = useCallback((file: File, id: string) => {
    operations.current.add(OPERATION.SUCCESS);
    dispatch(uploadActions.setFileState({
      file,
      state: {pending: false, id}
    }));
  }, []);

  useDeepEffect(() => {
    if (operations.current.has(OPERATION.SUCCESS)) {
      if (onSuccess && getPendingFiles().length === 0 && getFailedFiles().length === 0)
        onSuccess(getFileIds());
      operations.current.delete(OPERATION.SUCCESS);
    }
  }, [files]);

  //--- Error

  const handleError = useCallback((file: File) => {
    operations.current.add(OPERATION.FAIL);
    dispatch(uploadActions.setFileState({
      file,
      state: {pending: false, error: true}
    }));
  }, []);
  
  useDeepEffect(() => {
    if (operations.current.has(OPERATION.FAIL)) {
      if (onError && getFailedFiles().length === 1)
        onError();
      operations.current.delete(OPERATION.FAIL);
    }
  }, [files]);

  //--- Add
  
  const uploadFile = useCallback(async (file: File) => {
    handleRequest(file);
    try {
      const response = await api.documentUpload({
        file,
        onUploadProgress: (progressEvent) => {
          handleProgress(file, progressEvent.loaded);
        }
      });
      handleSuccess(file, response.id);
    }
    catch {
      handleError(file);
    }
  }, [handleError, handleProgress, handleRequest, handleSuccess]);

  const handleDrop = useCallback((acceptedFiles: File[]) => {
    operations.current.add(OPERATION.ADD);
    addedFiles.current = acceptedFiles;
    dispatch(uploadActions.addFiles({files: acceptedFiles}));
  }, []);

  useDeepEffect(() => {
    if (operations.current.has(OPERATION.ADD)) {
      addedFiles.current?.forEach(uploadFile);
      operations.current.delete(OPERATION.ADD);
    }
  }, [files]);

  //--- Remove

  const handleRemove = useCallback((file: File) => {
    operations.current.add(OPERATION.REMOVE);
    dispatch(uploadActions.removeFile({file}));
  }, []);

  useDeepEffect(() => {
    if (operations.current.has(OPERATION.REMOVE)) {
      if (onRemove)
        onRemove(getFileIds());
      operations.current.delete(OPERATION.REMOVE);
    }
  }, [files]);

  return (
    <>
      <UploadZone
        accept={accept!}
        maxSize={maxSize!}
        multiple={multiple}
        onDrop={handleDrop}
      />
      <UploadList
        files={files}
        onRemove={handleRemove}
        onRetry={uploadFile}
      />
    </>
  );
};

Upload.defaultProps = {
  accept: {
    png: 'image/png',
    jpg: 'image/jpeg',
    pdf: 'application/pdf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  maxSize: 4.5 * 1048576,
  multiple: true
};
