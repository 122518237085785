import React, {FunctionComponent} from 'react';
import {IPaymentsFilterValues} from './payments-filter.types';
import {withFilter} from 'components/ui/filter';
import {FilterContext} from './payments-filter-context';
import {Grid} from 'components/ui/grid';
import {ContractSelect, ALL_CONTRACTS} from './payments-filter-contract';
import {IntervalSelect} from './payments-filter-interval';
 
const FilterComponent: FunctionComponent = () => {
  return (
    <Grid container>
      <Grid item xl={6}>
        <ContractSelect />
      </Grid>
      <Grid item xl={6}>
        <IntervalSelect />
      </Grid>
    </Grid>
  );
};

export const PaymentsFilter = withFilter<IPaymentsFilterValues>({
  filterId: 'payments',

  FilterContext,

  initialValues: {
    contractId: null,
    from: null,
    to: null
  },

  isFilterComplete: (values) => (
    values.contractId !== null
  ),

  transformValues: (values) => {
    if (values.contractId === ALL_CONTRACTS)
      values.contractId = null;
    return values;
  }
})(FilterComponent);