import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {IPasswordChangeRequest} from './password-change.types';

/** Change the user password */
export const passwordChange = async (request: IPasswordChangeRequest) => {
  const response = await axios.post(urls.passwordChange(), request, {
    headers: {
      ...headers.auth(),
      ...headers.language()
    }
  });
  return response.data;
};
