import './grid.scss';
import React, {forwardRef, useMemo} from 'react';
import {IGridProps} from './grid.types';
import MuiGrid from '@material-ui/core/Grid';

/**
 * Grid layout
 * 
 * @see src/views/app/kit-view/grid-kit
 *
 * @example
 *
 *   <Grid container>
 *     <Grid item lg={6}>
 *       Left column
 *     </Grid>
 *     <Grid item lg={6}>
 *       Right column
 *     </Grid>
 *   </Grid>
 */
export const Grid = forwardRef<HTMLDivElement, IGridProps>(function Grid(props, ref) {
  const {
    className,
    verticalSpacing,
    horizontalSpacing,
    disabled,
    children,
    ...restProps
  } = props;

  const type = useMemo(() => {
    return props.item ? 'item' : 'container';
  }, [props.item]);

  const defaultProps = useMemo<Record<string, IGridProps>>(() => ({
    container: {
      container: true,
      spacing: 3
    },
    item: {
      xs: 12
    }
  }), []);
  
  const classes = useMemo(() => {
    let classes = 'ui-grid';
    if (!verticalSpacing)
      classes += ' ui-grid--no-vertical-spacing';
    if (!horizontalSpacing)
      classes += ' ui-grid--no-horizontal-spacing';
    if (disabled)
      classes += ' ui-grid--disabled';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [verticalSpacing, horizontalSpacing, disabled, className]);
  
  return (
    <MuiGrid
      ref={ref}
      {...defaultProps[type]}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiGrid>
  );
});

Grid.defaultProps = {
  verticalSpacing: true,
  horizontalSpacing: true
};
