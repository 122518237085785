import {
  IPasswordChangeRequestAction,
  IPasswordChangeSuccessAction,
  IPasswordChangeFailureAction,
  PasswordChangeActionTypes
} from './password-change.types';
import {IPasswordChangeRequest} from 'api';

/** Change user password */
export const passwordChangeRequest = (payload: IPasswordChangeRequest): IPasswordChangeRequestAction => ({
  type: PasswordChangeActionTypes.request,
  payload
});

export const passwordChangeSuccess = (): IPasswordChangeSuccessAction => ({
  type: PasswordChangeActionTypes.success
});

export const passwordChangeFailure = (payload: IRequestError): IPasswordChangeFailureAction => ({
  type: PasswordChangeActionTypes.failure,
  payload
});
