import './table-container.scss';
import React, {forwardRef, useMemo} from 'react';
import {ITableContainerProps} from './table-container.types';
import MuiTableContainer from '@material-ui/core/TableContainer';

/**
 * Table container
 * 
 * @see src/views/app/kit-view/table-kit
 * 
 * @example
 *  
 *   import {Table, TableContainer, TableHead, TableBody, TableRow, TableCell} from 'components/ui/table';
 *   
 *   // ...
 *   
 *   <TableContainer>
 *     <Table>
 * 
 *       <TableHead>
 *         <TableRow>
 *           <TableCell>
 *             First name
 *           </TableCell>
 *           <TableCell>
 *             Last name
 *           </TableCell>
 *         </TableRow>
 *       </TableHead>
 *       
 *       <TableBody>
 *         <TableRow>
 *           <TableCell>
 *             Alan
 *           </TableCell>
 *           <TableCell>
 *             Turing
 *           </TableCell>
 *         </TableRow>
 *         ...
 *       </TableBody>
 * 
 *     </Table>
 *   </TableContainer>
 */
export const TableContainer = forwardRef<HTMLDivElement, ITableContainerProps>(function TableContainer(props, ref) {
  const {className, gutterBottom, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-table-container';
    if (gutterBottom)
      classes += ' ui-table-container--gutter-bottom';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, gutterBottom]);

  return (
    <MuiTableContainer
      ref={ref}
      {...restProps}
      className={classes}
    />
  );
});