import React, {forwardRef} from 'react';
import {PaperProps} from './paper.types';
import MuiPaper from '@material-ui/core/Paper';

/**
 * Component surface (background)
 *
 * @example
 *
 *   <Paper className="ui-my-component">
 *     ...
 *   </Paper>
 */
export const Paper = forwardRef<HTMLDivElement, PaperProps>(function Paper(props, ref) {
  const {children, ...restProps} = props;

  return (
    <MuiPaper
      ref={ref}
      {...restProps}
    >
      {children}
    </MuiPaper>
  );
});

Paper.defaultProps = {
  elevation: 0
};
