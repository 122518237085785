import {
  ILogoutRequestPayload,
  ILogoutRequestAction,
  LogoutActionTypes
} from './logout.types';

/** Log user out and clean-up resources */
export const logoutRequest = (payload: ILogoutRequestPayload): ILogoutRequestAction => ({
  type: LogoutActionTypes.request,
  payload
});