import React, {FunctionComponent, useContext, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {PaginationContext} from '../pagination-context';
import {PaginationButton} from '../pagination-button';
import {PaginationInput} from '../pagination-input';

/** Pagination page buttons list */
export const PaginationList: FunctionComponent = () => {
  const {
    pageCount,
    currentPage,
    visiblePages,
    goToPage,
    applyConstraints
  } = useContext(PaginationContext);
  const {t} = useTranslation();

  const {firstPage, lastPage} = useMemo(() => {
    const toLeft =  Math.floor((visiblePages! - 1) * 0.5);
    const toRight = visiblePages! - (toLeft + 1);

    let firstPage = currentPage - toLeft;
    firstPage = Math.min(firstPage, pageCount - (visiblePages! - 1));
    firstPage = applyConstraints(firstPage);

    let lastPage = currentPage + toRight;
    lastPage = Math.max(currentPage + toRight, visiblePages!);
    lastPage = applyConstraints(lastPage);

    return {firstPage, lastPage};
  }, [applyConstraints, currentPage, pageCount, visiblePages]);
  
  const buttons = useMemo(() => {
    const buttons = [];
    for (let page = firstPage; page <= lastPage; page++) {

      // Page input
      if (page === currentPage && pageCount > visiblePages)
        buttons.push(<PaginationInput key={page} />);
      
      // First page
      else if (page === firstPage && page > 1)
        buttons.push(
          <PaginationButton
            key="first"
            tooltip={t('components.ui.pagination.first_page')}
            onClick={goToPage(1)}
          >
            1 &hellip;
          </PaginationButton>
        );

      // Last page
      else if (page === lastPage && page < pageCount)
        buttons.push(
          <PaginationButton
            key="last"
            tooltip={t('components.ui.pagination.last_page')}
            onClick={goToPage(pageCount)}
          >
            &hellip; {pageCount}
          </PaginationButton>
        );

      // Other pages
      else
        buttons.push(
          <PaginationButton
            key={page}
            disabled={page === currentPage}
            onClick={goToPage(page)}
          >
            {page}
          </PaginationButton>
        );
    }
    return buttons;
  }, [currentPage, firstPage, goToPage, lastPage, pageCount, t, visiblePages]);

  return (<>{buttons}</>);
};
