const BaseException = (ErrorClass: typeof Error) => {
  return class extends ErrorClass {};
};

//--- Built-in exceptions

/** This exception represents any exception which occurs during runtime. */
export class Exception extends BaseException(Error) {}

/** This exception is thrown when a variable or parameter is not of a valid type. */
export class TypeException extends BaseException(TypeError) {}

/** This exception is thrown when encodeURI() or decodeURI() are passed invalid parameters. */
export class URIException extends BaseException(URIError) {}

/** This exception is thrown when a numeric variable or parameter is outside of its valid range. */
export class RangeException extends BaseException(RangeError) {}

/** This exception is thrown when de-referencing an invalid reference. */
export class ReferenceException extends BaseException(ReferenceError) {}

//--- Custom exceptions

/** This exception is thrown when one of the arguments provided to a method is not valid. */
export class ArgumentException extends Exception {}

/** This exception is thrown during validation to indicate that an input value is not correct. */
export class InputValidationException extends Exception {}

/** This exception is thrown when there is an attempt to dynamically access a property that does not exist. */
export class MissingPropertyException extends Exception {}

/** Describes an exception that occurred during the processing of HTTP requests. */
export class HttpException extends Exception implements IRequestError {
  status: (number | undefined);

  constructor(error: IRequestError) {
    super(error.message);
    this.status = error.status;
  }
}
