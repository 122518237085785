import './description-label.scss';
import React, {forwardRef, useMemo} from 'react';
import {IDescriptionLabelProps} from './description-label.types';

/**
 * Description item label
 *
 * @example
 *
 *   import {DescriptionList, DescriptionLabel, DescriptionValue} from 'components/ui/description-list';
 *   
 *   // ...
 *   
 *   <DescriptionList>
 *     <DescriptionLabel>
 *       HTML
 *     </DescriptionLabel>
 *     <DescriptionValue>
 *       Hypertext Markup Language
 *     </DescriptionValue>
 * 
 *     <DescriptionLabel>
 *       CSS
 *     </DescriptionLabel>
 *     <DescriptionValue>
 *       Cascading Style Sheets
 *     </DescriptionValue>
 *   </DescriptionList>
 */
export const DescriptionLabel = forwardRef<HTMLDListElement, IDescriptionLabelProps>(function DescriptionLabel(props, ref) {
  const {className, children} = props;
  
  const classes = useMemo(() => {
    let classes = 'ui-description-label';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <dt
      ref={ref}
      className={classes}
    >
      {children}
    </dt>
  );
});