import moment, {Moment} from 'moment';
import {VALID_DATE_TIME_FORMATS} from '../date-time';

export const VALID_DATE_FORMATS: string[] = [
  'D. M. YYYY',
  'D.M. YYYY',
  'D. M.YYYY',
  'D.M.YYYY',
  'D-M-YYYY',
  'D M YYYY',
  'D/M/YYYY',
  'YYYY M. D.',
  'YYYY-M-D',
  'YYYY M D',
  'YYYY/M/D',
  'DDMMYYYY'
];

/**
 * Parse date
 *
 * @param value - Input date
 * @returns Moment.js instance
 *
 * @see https://momentjs.com
 */
export const date = (value: AnyDate): Moment => {
  if (typeof value === 'string') {
    const validFormats = [...VALID_DATE_FORMATS, ...VALID_DATE_TIME_FORMATS];
    return moment(value, validFormats, true);
  }
  return moment(value);
};
