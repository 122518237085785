import './checkbox-kit.scss';
import React, {FunctionComponent, ChangeEvent, useCallback, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {FormGroup} from 'components/ui/form-group';
import {Checkbox} from 'components/ui/checkbox';
import CustomIcon from '@material-ui/icons/FavoriteBorder';
import CustomIconChecked from '@material-ui/icons/Favorite';

/** Checkbox control preview */
export const CheckboxKit: FunctionComponent = () => {
  const [defaultChecked, setDefaultChecked] = useState(true);
  const [customChecked, setCustomChecked] = useState(false);
  const {t} = useTranslation();

  const handleDefaultChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setDefaultChecked(checked);
  }, []);

  const handleCustomChange = useCallback((event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setCustomChecked(checked);
  }, []);

  return (
    <div className="ui-checkbox-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.checkbox.heading')}
      </Typography>
      <FormGroup>

        {/* Default */}

        <Checkbox
          label={t('views.app.ui_kit.checkbox.type.default')}
          checked={defaultChecked}
          onChange={handleDefaultChange}
        />

        {/* Disabled */}

        <Checkbox
          label={t('views.app.ui_kit.checkbox.type.disabled')}
          disabled
        />

        {/* Custom */}

        <Checkbox
          className="ui-checkbox-kit__custom"
          label={t('views.app.ui_kit.checkbox.type.custom')}
          icon={(<CustomIcon />)}
          checkedIcon={(<CustomIconChecked />)}
          checked={customChecked}
          onChange={handleCustomChange}
        />
      </FormGroup>
    </div>
  );
};
