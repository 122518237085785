import {AppState} from 'reducers';

/** Select current locale code */
export const locale = (state: AppState) => {
  return state.intl.locale.code;
};

/** Select current locale language code */
export const language = (state: AppState) => {
  return state.intl.locale.language;
};

/** Select current locale country code */
export const country = (state: AppState) => {
  return state.intl.locale.country;
};

/** Select current locale state */
export const localeState = (state: AppState) => {
  return state.intl.locale;
};
