
/** Register new user */
export const userRegistration = () => {
  return 'user/registration';
};

/** Activate a new user account */
export const userActivation = () => {
  return 'user/activation';
};

/** Get user detail */
export const userDetail = () => {
  return 'user/detail';
};

/** Get a list of contracts */
export const contractList = () => {
  return 'contract/list';
};

/** Password strength evaluation */
export const passwordValidation = () => {
  return 'password/evaluation';
};

/** Reset the user password */
export const passwordReset = () => {
  return 'login/password-reset/new';
};

/** Confirm user password reset */
export const passwordResetConfirm = () => {
  return 'login/password-reset/confirmation';
};

/** Change the user password */
export const passwordChange = () => {
  return 'login/password-change/new';
};

/** Get a list of user roles */
export const userRoles = () => {
  return 'index/user-role/list';
};
