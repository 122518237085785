import './menu-item.scss';
import React, {ElementType, forwardRef, ReactElement, Ref, useMemo} from 'react';
import {MenuItemProps} from './menu-item.types';
import MuiMenuItem from '@material-ui/core/MenuItem';

/**
 * Context menu item
 *
 * @example
 *
 *   import React, {FunctionComponent, useState, useCallback, MouseEvent} from 'react';
 *   import {Button} from 'components/ui/button';
 *   import {Menu, MenuItem} from 'components/ui/menu';
 *   import {ListItemText} from 'components/ui/list';
 *   import {Divider} from 'components/ui/divider';
 *
 *   // ...
 * 
 *   const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
 *
 *   const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
 *     setMenuAnchor(event.currentTarget);
 *   }, []);
 *
 *   const closeMenu = useCallback(() => {
 *     setMenuAnchor(null);
 *   }, []);
 *
 *   return (
 *     <>
 *       <Button onClick={openMenu}>
 *         Open menu
 *       </Button>
 *       <Menu
 *         open={menuAnchor !== null}
 *         anchorEl={menuAnchor}
 *         onClose={closeMenu}
 *       >
 *         <MenuItem>
 *           <ListItemText>
 *             First item
 *           </ListItemText>
 *         </MenuItem>
 *         <Divider />
 *         <MenuItem>
 *           <ListItemText>
 *             Second item
 *           </ListItemText>
 *         </MenuItem>
 *       </Menu>
 *     </>
 *   );
 */
export const MenuItem = forwardRef(function MenuItem<T extends ElementType>(props: MenuItemProps<T>, ref: Ref<HTMLLIElement>) {
  const {className, children, ...restProps} = props;

  const classes = useMemo(() => {
    let classes = 'ui-menu-item';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <MuiMenuItem
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiMenuItem>
  );
})  as <T extends ElementType>(props: MenuItemProps<T> & {ref?: Ref<HTMLLIElement>;}) => ReactElement;
// ^ Make JSX generic parameters work with forwardRef
//
// @see https://stackoverflow.com/questions/58469229/react-with-typescript-generics-while-using-react-forwardref