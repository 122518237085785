import {PaymentStatusListResponse} from 'api';

export enum PaymentStatusListActionTypes {
  request = 'PAYMENT_STATUS_LIST_REQUEST',
  success = 'PAYMENT_STATUS_LIST_SUCCESS',
  failure = 'PAYMENT_STATUS_LIST_FAILURE'
}

export interface IPaymentStatusListRequestAction {
  type: PaymentStatusListActionTypes.request;
}

export interface IPaymentStatusListSuccessAction {
  type: PaymentStatusListActionTypes.success;
  payload: PaymentStatusListResponse;
}

export interface IPaymentStatusListFailureAction {
  type: PaymentStatusListActionTypes.failure;
  payload: IRequestError;
}

export type PaymentStatusListAction = (
  IPaymentStatusListRequestAction |
  IPaymentStatusListSuccessAction |
  IPaymentStatusListFailureAction
);