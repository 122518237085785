import {IValidator} from 'lib/global/validate/validate.types';
import memoize from 'fast-memoize';
import {fnArgsSerializer} from 'lib/global/memoize';

/**
 * Compose multiple validators into single validator
 * 
 * @param {...} - Multiple validators to compose
 * @returns Composed validator
 * 
 * @example
 * 
 *   import {FormTextField} from 'components/ui/form-text-field';
 *   import {validate, composeValidators} from 'lib/validate';
 * 
 *   // ...
 *   
 *   <FormTextField
 *     validate={composeValidators(
 *       validate.number,
 *       validate.minLength(5),
 *       validate.maxLength(15)
 *     )}
 *     ...
 *   />
 */
export const composeValidators = memoize(
  <T extends FormFieldValue>(...validators: (IValidator<T> | null | false | undefined)[]): IValidator<T> => {
    const defined = validators.filter((item) => !!item) as IValidator<T>[];
    
    const validator: IValidator<T> = (input) => {
      for (const validator of defined) {
        const errorMessage = validator(input);
        if (errorMessage)
          return errorMessage;
      }
    };

    // Add a static property containing names of all joined validators
    validator.names = [];
    for (const item of defined) {
      if (item.names)
        validator.names = [...validator.names, ...item.names];
      else
        validator.names.push(item.name);
    }

    return validator;
  },
  {
    strategy: memoize.strategies.variadic,
    serializer: fnArgsSerializer
  }
);