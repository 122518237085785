import './breadcrumbs.scss';
import React, {forwardRef, useMemo} from 'react';
import {BreadcrumbsProps} from './breadcrumbs.types';
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs';
import {BreadcrumbsSeparator} from './breadcrumbs-separator';

/**
 * Breadcrumbs
 *
 * @example
 * 
 *   import {Breadcrumbs, useBreadcrumbs} from 'components/ui/breadcrumbs';
 *   import {Link} from 'components/ui/link';
 *   
 *   // ...
 *   
 *   const breadcrumbs = useBreadcrumbs();
 *   
 *   // ...
 *   
 *   <Breadcrumbs>
 *     {breadcrumbs.map(({label, path}) => (
 *       <Link
 *         key={path}
 *         to={path}
 *       >
 *         {label}
 *       </Link>
 *     ))}
 *   </Breadcrumbs>
 */
export const Breadcrumbs = forwardRef<HTMLDivElement, BreadcrumbsProps>(function Breadcrumbs(props, ref) {
  const {
    className,
    children,
    ...restProps
  } = props;

  const classes = useMemo(() => {
    let classes = 'ui-breadcrumbs';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <MuiBreadcrumbs
      ref={ref}
      {...restProps}
      className={classes}
    >
      {children}
    </MuiBreadcrumbs>
  );
});

Breadcrumbs.defaultProps = {
  separator: (<BreadcrumbsSeparator />)
};