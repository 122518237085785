import React, {FunctionComponent, useMemo} from 'react';
import {LoremIpsum} from 'lorem-ipsum';
import {IBranch} from './branch.types';
import {TreeItem} from 'components/ui/tree-item';

/** Tree view kit branch */
export const Branch: FunctionComponent<IBranch> = (props) => {
  const {id} = props;
  const items = [1, 2, 3];

  const loremIpsum = useMemo(() => {
    return new LoremIpsum();
  }, []);

  return (
    <TreeItem
      nodeId={id}
      label={loremIpsum.generateWords(4)}
    >
      {items.map((item) => {
        const itemId = `${id}-${item}`;
        return (
          <TreeItem
            key={itemId}
            nodeId={itemId}
            label={loremIpsum.generateWords(4)}
          >
            {items.map((subItem) => {
              const subItemId = `${id}-${item}-${subItem}`;
              return (
                <TreeItem
                  key={subItemId}
                  nodeId={subItemId}
                  label={loremIpsum.generateWords(4)}
                />
              );
            })}
          </TreeItem>
        );
      })}
    </TreeItem>
  );
};
