import './app.scss';
import React,  {FunctionComponent, useCallback, useEffect} from 'react';
import {useWatchEffect, useRemount, usePrevious} from 'hooks';
import {useSelector, shallowEqual} from 'react-redux';
import {useTranslation} from 'lib/intl/i18n';
import {selectors} from 'selectors';
import {useNavigate} from 'react-router-dom';
import {gtm} from 'lib/global/gtm';
import {paths} from 'config/router';
import {Outlet as AppContent} from 'react-router-dom';
import {useSnackbar} from 'components/ui/snackbar';
import {AppHeader} from './app-header';
import {AppBreadcrumbs} from './app-breadcrumbs';
import {AppFooter} from './app-footer';
import {Container} from 'components/ui/container';
import {CookiesPopup} from './cookies-popup-new';


/** Main application component */
export const App: FunctionComponent = () => {
  const navigate = useNavigate();
  const remount = useRemount();
  const userIsLoggedIn = useSelector(selectors.userIsLoggedIn);
  const logoutReason = useSelector(selectors.logoutReason);
  const activePartnerId = useSelector(selectors.activePartnerId, shallowEqual);
  const prevPartnerId = usePrevious(activePartnerId);
  const partnerSelect = userIsLoggedIn && activePartnerId === null;
  // const accessTokenExpired = useSelector(selectors.accessTokenExpired);
  // const dispatch = useDispatch();
  const {showSnackbar} = useSnackbar();
  const {t} = useTranslation();

  // useEffect(() => {
  //   if (accessTokenExpired)
  //     dispatch(actions.logoutRequest({logoutReason: 'tokenExpired'}));
  // // eslint-disable-next-line
  // }, []);

  useWatchEffect(() => {
    navigate(paths.home);
    if (!userIsLoggedIn && logoutReason === 'tokenExpired' || logoutReason === 'tokenRefreshFail')
      showSnackbar(t('components.main.app.expired_token_logout'));
  }, [navigate, userIsLoggedIn]);
  
  // Reload app on partner change
  useWatchEffect(() => {
    if (prevPartnerId && activePartnerId)
      remount.trigger();
  }, [activePartnerId]);
  
  useWatchEffect(() => {
    gtm.pushToDataLayer({loginStatus: userIsLoggedIn ? 'prihlaseny' : 'neprihlaseny'});
  }, [userIsLoggedIn]);

  const logExternalNavigation = useCallback((event: MouseEvent) => {
    const target = (event.target as HTMLElement);
    if (target.tagName === 'A' || target.parentElement?.tagName === 'A') {
      const element = (target.tagName === 'A' ? target : target.parentElement) as HTMLAnchorElement;
      if (!element.href.includes(window.location.origin)) {
        gtm.pushToDataLayer({
          'event': 'hitType',
          'eventCategory': 'navigace',
          'eventAction': 'exit'
        });
      }
    }
  }, []);
  
  useEffect(() => {
    document.addEventListener('click', logExternalNavigation);
    return () => document.removeEventListener('click', logExternalNavigation);
  // eslint-disable-next-line
  }, []);

  return (
    <Container
      className="ui-app"
      {...remount.props}
    >
      <AppHeader />
      <AppContent />
      {!partnerSelect && (
        <AppBreadcrumbs />
      )}
      <AppFooter />
      <CookiesPopup />
    </Container>
  );
};
