import {axios, headers} from 'lib/api/axios';
import {urls} from 'config/urls';
import {DemandStatusListResponse} from './demand-status-list.types';

/** Get a list of demand statuses */
export const demandStatusList = async () => {
  const response = await axios.get<DemandStatusListResponse>(urls.demandStatusList(), {
    headers: {
      ...headers.auth(),
      ...headers.language()
    }
  });
  return response.data;
};