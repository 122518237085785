import './header-login-error.scss';
import React, {FunctionComponent, useCallback, useEffect, useRef, useState} from 'react';
import {Alert} from 'components/ui/alert';
import {useLoginError} from 'hooks';

const AUTOHIDE_TIMEOUT_MS = 8000;

/** Header login error message */
export const HeaderLoginError: FunctionComponent = () => {
  const [open, setOpen] = useState(false);
  const timeout = useRef<Timeout>(null!);
  const loginError = useLoginError('header-login');

  const onClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    setOpen(!!loginError);
  }, [loginError]);

  useEffect(() => {
    clearTimeout(timeout.current);
    if (open)
      timeout.current = setTimeout(onClose, AUTOHIDE_TIMEOUT_MS);
  }, [onClose, open]);

  return (
    <Alert
      className="ui-header-login-error"
      variant="error"
      open={open}
      onClose={onClose}
    >
      {loginError?.message}
    </Alert>
  );
};