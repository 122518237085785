import React, {FunctionComponent, useCallback, useState} from 'react';
import {IContractDetailButtonProps} from './contract-detail-button.types';
import {IconButton} from 'components/ui/icon-button';
import DetailIcon from '@material-ui/icons/MoreHoriz';
import {ContractDetailDialog} from 'components/user/contract-detail-dialog';

/** Contract detail button */
export const ContractDetailButton: FunctionComponent<IContractDetailButtonProps> = (props) => {
  const {contract} = props;
  const [open, setOpen] = useState(false);

  const toggleOpen = useCallback(() => {
    setOpen((prevOpen) => !prevOpen);
  }, []);

  return (
    <>
      <IconButton
        className="ui-demand-detail-button"
        size="small"
        onClick={toggleOpen}
      >
        <DetailIcon />
      </IconButton>
      <ContractDetailDialog
        contract={contract}
        open={open}
        onClose={toggleOpen}
      />
    </>
  );
};