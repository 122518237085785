import React, {forwardRef} from 'react';
import {IMenuProps} from './data-menu.types';
import {Menu} from 'components/ui/menu';
import {MenuItem} from 'components/ui/menu-item';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {Divider} from 'components/ui/divider';

/**
 * Context menu
 *
 * @example
 *
 *   import React, {FunctionComponent, useState, useCallback, MouseEvent} from 'react';
 *   import {Button} from 'components/ui/button';
 *   import {Menu} from 'components/ui/menu';
 *
 *   // ...
 * 
 *   const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(null);
 *
 *   const openMenu = useCallback((event: MouseEvent<HTMLButtonElement>) => {
 *     setMenuAnchor(event.currentTarget);
 *   }, []);
 *
 *   const closeMenu = useCallback(() => {
 *     setMenuAnchor(null);
 *   }, []);
 *
 *   return (
 *     <>
 *       <Button onClick={openMenu}>
 *         Open menu
 *       </Button>
 *       <Menu
 *         open={menuAnchor !== null}
 *         anchorEl={menuAnchor}
 *         onClose={closeMenu}
 *         items={[
 *           {label: 'First item'},
 *           {label: 'Second item'},
 *           {label: '-'},
 *           {label: 'Third item'},
 *           {label: 'Fourth item'}
 *         ]}
 *       />
 *     </>
 *   );
 */
export const DataMenu = forwardRef<HTMLDivElement, IMenuProps>(function DataMenu(props, ref) {
  const {items, ...restProps} = props;

  return (
    <Menu
      {...restProps}
      ref={ref}
    >
      {items.map((item, id) => {
        if (item.visible === false)
          return null;
        if (item.label === '-')
          return (
            <Divider
              key={id}
              className={item.className}
            />
          );
        const {icon, label, ...itemProps} = item;
        return (
          <MenuItem
            key={id}
            {...itemProps}
          >
            {item.icon && (
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
            )}
            <ListItemText>
              {item.label}
            </ListItemText>
          </MenuItem>
        );
      })}
    </Menu>
  );
});