import './text-field.scss';
import React, {forwardRef, useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {ITextFieldProps} from './text-field.types';
import {InputProps as MuiInputProps} from '@material-ui/core/Input/Input';
import MuiTextField from '@material-ui/core/TextField';
import {InputAdornment} from 'components/ui/input-adornment';
import {FormLabel} from 'components/ui/form-label';
import {Tooltip} from 'components/ui/tooltip';
import {IconButton} from 'components/ui/icon-button';
import ShowPasswordIcon from '@material-ui/icons/Visibility';
import HidePasswordIcon from '@material-ui/icons/VisibilityOff';
import {LinearProgress} from 'components/ui/linear-progress';

/**
 * Text field
 * 
 * @see src/views/app/kit-view/text-field-kit
 *
 * @example
 *
 *   const [value, setValue] = useState('Some value');
 *   
 *   const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
 *     setValue(event.target.value);
 *   }, []);
 *
 *   // ...
 * 
 *   <TextField
 *     name="someName"
 *     label="Some label"
 *     placeholder="Some placeholder..."
 *     value={value}
 *     onChange={handleChange}
 *   />
 */
export const TextField = forwardRef<HTMLDivElement, ITextFieldProps>(function TextField(props, ref) {
  const {
    className,
    name,
    id,
    label,
    labelIcon,
    type: typeProp,
    readOnly,
    prefix,
    suffix,
    align,
    size,
    value,
    disabled,
    required,
    pending,
    hidden,
    autoFocus,
    ...restProps
  } = props;
  const [showPassword, setShowPassword] = useState(false);
  const [autoFocused, setAutoFocused] = useState(false);
  const {t} = useTranslation();

  const getInputRef = useCallback((inputRef: (HTMLInputElement | null)) => {
    if (autoFocus && inputRef && !autoFocused)
      requestAnimationFrame(() => {
        inputRef.focus();
        setAutoFocused(true);
      });
    if (typeof restProps.inputRef === 'function')
      restProps.inputRef(inputRef);
  }, [autoFocus, autoFocused, restProps]);

  useEffect(() => {
    return () => setAutoFocused(false);
  }, []);

  const toggleShowPassword = useCallback(() => {
    setShowPassword((checked) => !checked);
  }, []);
  
  // Additional input props
  const InputProps = useMemo<Partial<MuiInputProps>>(() => {
    const InputProps = {...restProps.InputProps || {}};

    //--- Start adornment
    
    if (prefix)
      InputProps.startAdornment = (
        <InputAdornment position="start">
          {prefix}
        </InputAdornment>
      );

    //--- Show password icon
    
    if (typeProp === 'password') {
      const tooltip = showPassword ? 'components.ui.text_field.password.hide' : 'components.ui.text_field.password.show';
      InputProps.endAdornment = (
        <Tooltip title={t(tooltip)}>
          <IconButton
            size="small"
            onClick={toggleShowPassword}
          >
            {showPassword ? <HidePasswordIcon /> : <ShowPasswordIcon />}
          </IconButton>
        </Tooltip>
      );
    }

    //--- End adornment
      
    else if (suffix)
      InputProps.endAdornment = (
        <InputAdornment position="end">
          {suffix}
        </InputAdornment>
      );

    //--- Read only input
    
    if (readOnly)
      InputProps.readOnly = true;

    return InputProps;
  }, [restProps.InputProps, prefix, typeProp, suffix, readOnly, showPassword, t, toggleShowPassword]);

  const type = useMemo<string | undefined>(() => {
    if (typeProp === 'password')
      return showPassword ? 'text' : 'password';
    return typeProp;
  }, [typeProp, showPassword]);
  
  const classes = useMemo(() => {
    let classes = 'ui-text-field';
    classes += ` ui-text-field--align-${align}`;
    if (typeProp === 'password')
      classes += ' ui-text-field--password';
    if (size === 'small')
      classes += ' ui-text-field--small';
    if (disabled)
      classes += ' ui-text-field--disabled';
    if (hidden)
      classes += ' ui-text-field--hidden';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [align, typeProp, size, disabled, hidden, className]);

  return (
    <>
      <FormLabel
        htmlFor={name || id}
        icon={labelIcon}
        required={required}
        disabled={disabled}
      >
        {label}
      </FormLabel>
      <div className={classes}>
        <MuiTextField
          ref={ref}
          {...restProps}
          inputRef={getInputRef}
          name={name}
          id={id}
          InputProps={InputProps}
          type={type}
          value={value}
          disabled={disabled}
          required={required}
        />
        {pending && (
          <LinearProgress color="secondary" />
        )}
      </div>
    </>
  );
});

TextField.defaultProps = {
  fullWidth: true,
  align: 'left'
};
