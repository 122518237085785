import React, {FunctionComponent, useCallback, useMemo} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {DataTable, asTableColumns, asTableRows} from 'components/ui/data-table';
import {format} from 'lib/intl/format';
import {LoremIpsum} from 'lorem-ipsum';

/** Table control preview */
export const TableKit: FunctionComponent = () => {
  const {t} = useTranslation();

  const loremIpsum = useMemo(() => {
    return new LoremIpsum();
  }, []);

  const columns = useMemo(() => asTableColumns({
    user: {
      label: t('views.app.ui_kit.table.columns.user')
    },
    place: {
      label: t('views.app.ui_kit.table.columns.place')
    },
    type: {
      label: t('views.app.ui_kit.table.columns.type')
    },
    consumption: {
      label: t('views.app.ui_kit.table.columns.consumption'),
      align: 'right'
    },
    price: {
      label: t('views.app.ui_kit.table.columns.price'),
      align: 'right'
    }
  }), [t]);

  const generateRow = useCallback((user: string) => {
    const consumption = 1000 + Math.floor(Math.random() * 1000);
    return {
      user,
      place: loremIpsum.generateWords(3),
      type: loremIpsum.generateWords(1),
      consumption: format.unit(consumption, 'cubic-meter'),
      price: format.currency(consumption * 3, 'EUR')
    };
  }, [loremIpsum]);

  const rows = useMemo(() => asTableRows(columns, [
    generateRow('John Doe'),
    generateRow('Jane Smith'),
    generateRow('Joe Bloggs'),
    generateRow('John Smith'),
    generateRow('Jane Doe')
  ]), [columns, generateRow]);

  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.table.heading')}
      </Typography>
      <DataTable
        columns={columns}
        rows={rows}
      />
    </>
  );
};
