import './failure.scss';
import React, {forwardRef, useCallback, useMemo} from 'react';
import {IFailureProps} from 'components/api/failure/failure.types';
import {useTheme} from 'components/ui/theme';
import {ContainerQuery, IQueryClasses} from 'components/ui/container-query';
import {Typography} from 'components/ui/typography';
import {useTranslation} from 'lib/intl/i18n';
import {Grow} from 'components/ui/grow';
import {Link} from 'components/ui/link';
import FailureIcon from '@material-ui/icons/WifiOff';

/**
 * Fetch failure message
 *
 * @example
 *
 *   <Failure
 *     visible={!request.pending && !!request.error}
 *     onRetry={request.fetch}
 *   />
 */
export const Failure = forwardRef<HTMLDivElement, IFailureProps>(function Failure(props, ref) {
  const {
    className,
    visible,
    message,
    contrast,
    onRetry
  } = props;
  const theme = useTheme();
  const {t} = useTranslation();

  const handleRetry = useCallback(() => {
    if (onRetry)
      onRetry();
  }, [onRetry]);

  const queryClasses = useMemo<IQueryClasses>(() => ({
    'ui-failure--narrow': {maxWidth: 320}
  }), []);

  const classes = useMemo(() => {
    let classes = 'ui-failure';
    if (contrast)
      classes += ` ui-failure--contrast-${contrast}`;
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, contrast]);   

  return (
    <Grow
      timeout={theme.speed.slow}
      in={visible}
    >
      <ContainerQuery
        queryClasses={queryClasses}
        className={classes}
      >
        <div className="ui-failure__content">
          <FailureIcon className="ui-failure__icon" />
          <div className="ui-failure__message">
            <Typography
              ref={ref}
              variant="h3"
            >
              {message || t('components.ui.failure.message')}
            </Typography>
            {onRetry && (
              <Link onClick={handleRetry}>
                {t('components.ui.failure.retry')}
              </Link>
            )}
          </div>
        </div>
      </ContainerQuery>
    </Grow>
  );
});
