import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {ButtonActions} from 'components/ui/button-actions';
import {AlertDialog} from 'components/ui/alert-dialog';
import {ConfirmDialog} from 'components/ui/confirm-dialog';
import {Dialog} from 'components/ui/dialog';
import {DialogContent} from 'components/ui/dialog-content';
import {DialogContentText} from 'components/ui/dialog-content-text';
import {DialogActions} from 'components/ui/dialog-actions';
import {useDialog} from 'hooks';
import {Button} from 'components/ui/button';
import {Typography} from 'components/ui/typography';
import AlertIcon from '@material-ui/icons/InfoOutlined';
import ConfirmIcon from '@material-ui/icons/Check';
import CustomIcon from '@material-ui/icons/WebAssetSharp';
import CloseIcon from '@material-ui/icons/Close';

/** Dialog control preview */
export const DialogKit: FunctionComponent = () => {
  const {t} = useTranslation();
  const alertDialog = useDialog();
  const confirmDialog = useDialog();
  const customDialog = useDialog();
  
  return (
    <>
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.dialog.heading')}
      </Typography>
      <ButtonActions>
        
        {/* Alert dialog */}

        <AlertDialog
          {...alertDialog.props}
          message={t('views.app.ui_kit.dialog.alert.message')}
        />
        <Button
          icon={<AlertIcon />}
          onClick={alertDialog.open}
        >
          {t('views.app.ui_kit.dialog.alert.button')}
        </Button>

        {/* Confirm dialog */}

        <ConfirmDialog
          {...confirmDialog.props}
          message={t('views.app.ui_kit.dialog.confirm.message')}
        />
        <Button
          icon={<ConfirmIcon />}
          onClick={confirmDialog.open}
        >
          {t('views.app.ui_kit.dialog.confirm.button')}
        </Button>

        {/* Custom dialog */}

        <Dialog
          {...customDialog.props}
          title={t('views.app.ui_kit.dialog.custom.title')}
          width="narrow"
        >
          <DialogContent>
            <DialogContentText>
              {t('views.app.ui_kit.dialog.custom.content')}
            </DialogContentText>
          </DialogContent>
          <DialogActions align="center">
            <Button
              icon={<CloseIcon />}
              onClick={customDialog.close}
            >
              {t('views.app.ui_kit.dialog.custom.close')}
            </Button>
          </DialogActions>
        </Dialog>
        <Button
          icon={<CustomIcon />}
          onClick={customDialog.open}
        >
          {t('views.app.ui_kit.dialog.custom.button')}
        </Button>
      </ButtonActions>
    </>
  );
};