import {
  ISetCookiesAction,
  CookiesActionTypes,
  ISetCookiesPayload,
  ICookiesToogleDialogAction
} from './cookies.types';

/** Set cookies consent state */
export const setCookies = (payload: ISetCookiesPayload): ISetCookiesAction => ({
  type: CookiesActionTypes.set,
  payload
});

export const toggleCookiesDialog = (payload: boolean): ICookiesToogleDialogAction => ({
  type: CookiesActionTypes.toggleDialog,
  payload
});