import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import {t} from 'lib/intl/i18n';

/**
 * Validate bank account code
 *
 * @param input - Bank account code value
 * @returns Error message or undefined
 */
export const bankAccountCode: IValidator = (input) => {
  const value = String(input || '').trim();
  if (value && (value.length < 4 || isNaN(Number(value))))
    return t('lib.global.validate.bank_account.code');
};

/**
 * Check that the bank account code validator doesn't return any error
 *
 * @param input - Bank account code value to check
 */
export const isBankAccountCode: ValidatorQuery = (input) => {
  return !!input && !bankAccountCode(input);
};
