export enum ThemeActionTypes {
  set = 'SET_THEME'
}

export interface ISetThemePayload {
  theme: string;
}

export interface ISetThemeAction {
  type: ThemeActionTypes.set;
  payload: ISetThemePayload;
}
