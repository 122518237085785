import './cms-content.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {ICmsContentProps} from './cms-content.types';

/**
 * CMS content styles
 *
 * @example
 * 
 *   <CmsContent>
 *     <h2>
 *       Heading
 *     </h2>
 *     <p>
 *       Some <b>paragraph</b> ...
 *     </p>
 *     <hr />
 *     <p>
 *       ... <a href="#">some link</a> in another paragraph ...
 *     </p>
 *   </CmsContent>
 * 
 * @example
 * 
 *   <CmsContent fromHtmlString={`
 *     <h2>
 *       Heading
 *     </h2>
 *     <p>
 *       Some <b>paragraph</b> ...
 *     </p>
 *     <hr />
 *     <p>
 *       ... <a href="#">some link</a> in another paragraph ...
 *     </p>
 *   `} />
 */
export const CmsContent: FunctionComponent<ICmsContentProps> = (props) => {
  const {className, fromHtmlString, children} = props;

  const innerHtml = useMemo(() => {
    if (fromHtmlString)
      return {__html: fromHtmlString};
  }, [fromHtmlString]);

  const classes = useMemo(() => {
    let classes = 'ui-cms-content';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   
  
  return (
    <div
      className={classes}
      dangerouslySetInnerHTML={innerHtml}
    >
      {children}
    </div>
  );
};