import React, {FunctionComponent} from 'react';
import {View} from 'components/ui/view';
import {UserSettings} from './user-settings';
import {Flexbox} from 'components/ui/flexbox';

/** Settings view */
export const SettingsView: FunctionComponent = () => {
  return (
    <View>
      <Flexbox justifyContent="center">
        <UserSettings />
      </Flexbox>
    </View>
  );
};