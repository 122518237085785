import './file-icon.scss';
import React, {forwardRef, useMemo} from 'react';
import {IFileIconProps} from './file-icon.types';

/**
 * File type icon
 *
 * @example
 *
 *   <FileIcon type="pdf" />
 */
export const FileIcon = forwardRef<HTMLDivElement, IFileIconProps>(function FileIcon(props, ref) {
  const {className, type} = props;
  
  const classes = useMemo(() => {
    let classes = 'ui-file-icon';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className]);   

  return (
    <div
      ref={ref}
      className={classes}
    >
      <div className="ui-file-icon__label">
        {type}
      </div>
      <div className="ui-file-icon__corner" />
    </div>
  );
});