import './home-portal-features.scss';
import React, {FunctionComponent} from 'react';
import {Typography} from 'components/ui/typography';
import {Grid} from 'components/ui/grid';
import {RevealFade} from 'components/ui/reveal-fade';
import {PortalFeatureCard} from './portal-feature-card';
import {ReactComponent as DemandsIcon} from './assets/demands.svg';
import {ReactComponent as ConsumptionIcon} from './assets/consumption.svg';
import {ReactComponent as InvoicesIcon} from './assets/invoices.svg';

/** Portal features section */
export const HomePortalFeatures: FunctionComponent = () => {
  return (
    <div className="ui-home-portal-features">
      <RevealFade bottom>
        <div className="ui-home-portal-features__about">
          <Typography variant="body1">
            Zákaznický portál Moja Veolia je určený pre zmluvných partnerov spoločností skupiny Veolia Energia Slovensko, správcovské spoločnosti ako aj zástupcom vlastníkov bytov a nebytových priestorov. Prístup do Zákazníckeho portálu Moja Veolia majú len oprávnené osoby uvedené v Zmluve o dodávke a odbere tepla (štatutárni zástupcovia správcovských spoločností, obchodných spoločností a inštitúcií). Prístup pre ostatných užívateľov (technikov správcovských spoločností, ekonómov, zástupcov vlastníkov bytov) prideľujú vyššie spomenuté oprávnené osoby.
          </Typography>
          <Typography
            variant="body1"
            mt={2}
          >
            V zákazníckom portáli Moja Veolia nájdete informácie týkajúce sa zmluvných parametrov, fakturácie a spotreby. Zároveň tu môžete podať žiadosť a sledovať stav jej riešenia. Všetky tieto informácie máte k dispozícii nepretržite online aj cez mobilný telefón.
          </Typography>
        </div>
      </RevealFade>
      <Grid container>
        
        {/* Demands */}
        
        <Grid item xl={4}>
          <RevealFade
            bottom
            fullHeight
          >
            <PortalFeatureCard
              icon={<DemandsIcon />}
              title="Zadávanie žiadostí"
              description="Online zadávanie a sledovanie stavu žiadostí"
            />
          </RevealFade>
        </Grid>
        
        {/* Consumption */}
        
        <Grid item xl={4}>
          <RevealFade
            delay={1}
            bottom
            fullHeight
          >
            <PortalFeatureCard
              icon={<ConsumptionIcon />}
              title="Online prehľady spotreby"
              description="Prístup k informáciám o vašej spotrebe"
            />
          </RevealFade>
        </Grid>
        
        {/* Invoices */}
        
        <Grid item xl={4}>
          <RevealFade
            delay={2}
            bottom
            fullHeight
          >
            <PortalFeatureCard
              icon={<InvoicesIcon />}
              title="Prehľad a správa faktúr"
              description="Prístup k informáciám o vašich faktúrach"
            />
          </RevealFade>
        </Grid>
      </Grid>    
    </div>
  );
};