import {combineReducers} from 'redux';
import {login} from './login';
import {userDetail} from './user-detail';
import {userActivation} from './user-activation';
import {partner} from './partner';
import {passwordChange} from './password-change';
import {passwordResetConfirm} from './password-reset-confirm';

export const user = combineReducers({
  login,
  detail: userDetail,
  activation: userActivation,
  partner,
  passwordChange,
  passwordResetConfirm
});