import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import {translations} from 'config/translations';
import {DEFAULT_LOCALE} from 'config/intl';

i18n
  .use(initReactI18next)
  .use(LanguageDetector) // TODO: https://www.npmjs.com/package/i18next-browser-languagedetector
  .init({
    fallbackLng: DEFAULT_LOCALE,
    interpolation: {escapeValue: false},
    resources: translations
  })
  .then(null);

/** Fix issues with default translation return type */
export const t = (key: string, options?: Record<string, unknown>) => {
  return i18n.t(key, options) as string;
};

export {i18n};