import './desktop-sub-menu.scss';
import React, {FunctionComponent, useContext, useEffect, useMemo, useState} from 'react';
import {useTheme} from 'components/ui/theme';
import {useMediaQuery} from '@material-ui/core';
import {AppMenuContext} from '../../app-menu-context';
import {IAppMenuSubMenu} from '../../app-menu.types';
import {Collapse} from 'components/ui/collapse';
import {Container} from 'components/ui/container';
import {Typography} from 'components/ui/typography';
import {DesktopMenuItems} from '../desktop-menu-items';

/** Desktop sub-menu */
export const DesktopSubMenu: FunctionComponent = () => {
  const theme = useTheme();
  const mobileHeader = useMediaQuery(`(max-width: ${theme.width.xlMax}px)`);
  const {activeItem, activeSubItem} = useContext(AppMenuContext);
  const [subMenu, setSubMenu] = useState<IAppMenuSubMenu | null>(null);
  const [open, setOpen] = useState(false);
  
  useEffect(() => {
    const className = activeItem?.subMenu?.className;
    const icon = activeSubItem?.subMenu?.icon || activeItem?.subMenu?.icon;
    const heading = activeSubItem?.subMenu?.heading || activeItem?.subMenu?.heading;
    const items = activeItem?.subMenu?.items;
    const willBeOpen = !!(icon || heading || items);
    if (willBeOpen)
      setSubMenu({className, icon, heading, items});
    setOpen(willBeOpen);
  }, [activeItem, activeSubItem]);

  const classes = useMemo(() => {
    let classes = 'ui-desktop-sub-menu';
    if (subMenu?.className)
      classes += ` ${subMenu.className}`;
    return classes;
  }, [subMenu]);   

  return (
    <Collapse
      className={classes}
      in={open}
    >
      <Container className="ui-desktop-sub-menu__content">
        <div className="ui-desktop-sub-menu__info">

          {/* Icon */}

          {subMenu?.icon && (
            <div className="ui-desktop-sub-menu__icon">
              {subMenu?.icon}
            </div>
          )}

          {/* Heading */}

          {subMenu?.heading && (
            <Typography
              variant={mobileHeader ? 'h2' : 'h1'}
              className="ui-desktop-sub-menu__heading"
            >
              {subMenu.heading}
            </Typography>
          )}
        </div>

        {/* Items */}

        {subMenu?.items && (
          <DesktopMenuItems
            className="ui-desktop-sub-menu__items"
            items={subMenu.items}
            activeItemPath={activeSubItem?.path}
          />
        )}
      </Container>
    </Collapse>
  );
};