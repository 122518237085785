export enum DeviceType {
  desktop = 'desktop',
  smartphone = 'smartphone',
  tablet = 'tablet',
  television = 'television',
  unknown = 'unknown'
}

export interface IDeviceInfo {
  type: DeviceType;
  model?: string;
  os?: string;
}