import React, {FunctionComponent} from 'react';
import {validate} from 'lib/global/validate';
import {useTranslation} from 'lib/intl/i18n';
import {IRegistrationFormProps} from './registration-form.types';
import {Form} from 'components/ui/form';
import {Box} from 'components/ui/box';
import {Grid} from 'components/ui/grid';
import {FormTextField} from 'components/ui/form-text-field';
import {Typography} from 'components/ui/typography';
import {Link} from 'components/ui/link';
import {ButtonActions} from 'components/ui/button-actions';
import {Button} from 'components/ui/button';
import SendIcon from '@material-ui/icons/Check';

/** User registration form */
export const RegistrationForm: FunctionComponent<IRegistrationFormProps> = (props) => {
  const {initialValues, pending, onSubmit} = props;
  const {t} = useTranslation();
  
  return (
    <Form
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      <Grid
        container
        disabled={pending}
      >
          
        {/* First name */}

        <Grid item md={6}>
          <FormTextField
            name="privilegedPersonFirstName"
            label={t('views.user.registration.form.first_name.label')}
            placeholder={t('views.user.registration.form.first_name.placeholder')}
            required
            autoFocus
          />
        </Grid>
          
        {/* Last name */}

        <Grid item md={6}>
          <FormTextField
            name="privilegedPersonSurname"
            label={t('views.user.registration.form.last_name.label')}
            placeholder={t('views.user.registration.form.last_name.placeholder')}
            required
          />
        </Grid>
          
        {/* E-mail */}

        <Grid item md={6}>
          <FormTextField
            name="privilegedPersonEmail"
            label={t('views.user.registration.form.email.label')}
            placeholder={t('views.user.registration.form.email.placeholder')}
            validate={validate.email}
            required
          />
        </Grid>
          
        {/* Phone */}

        <Grid item md={6}>
          <FormTextField
            name="phoneNumber"
            label={t('views.user.registration.form.phone.label')}
            placeholder={t('views.user.registration.form.phone.placeholder')}
            validate={validate.phone}
            required
          />
        </Grid>

        {/* Company */}

        <Grid item md={6}>
          <FormTextField
            name="companyName"
            label={t('views.user.registration.form.company.label')}
            placeholder={t('views.user.registration.form.company.placeholder')}
            required
          />
        </Grid>
        
        {/* Contract */}

        <Grid item md={6}>
          <FormTextField
            name="contractNumber"
            label={t('views.user.registration.form.contract.label')}
            placeholder={t('views.user.registration.form.contract.placeholder')}
            required
          />
        </Grid>
      </Grid>

      {/* Privacy */}
        
      <Box mt={3}>
        <Typography variant="body1">
          {t('views.user.registration.form.privacy')}
          {' '}
          <Link
            to="https://vesr.sk/sk/ochrana-osobnych-udajov"
            target="_blank"
          >
            https://vesr.sk/sk/ochrana-osobnych-udajov
          </Link>
        </Typography>
      </Box>

      {/* Submit */}

      <Box mt={4} mb={2}>
        <ButtonActions align="center">
          <Button
            type="submit"
            icon={<SendIcon />}
            pending={pending}
          >
            {t('views.user.registration.form.submit')}
          </Button>
        </ButtonActions>
      </Box>
    </Form>
  );
};