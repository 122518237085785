import './button.scss';
import React, {forwardRef, useMemo, MouseEvent, useCallback} from 'react';
import {useNavigate} from 'react-router-dom';
import MuiButton from '@material-ui/core/Button';
import {IButtonProps} from './button.types';
import {Fade} from 'components/ui/fade';
import {Preloader} from 'components/ui/preloader';

/**
 * Button component
 * 
 * @see src/views/app/kit-view/button-kit
 *
 * @example
 *
 *   import WarningIcon from '@material-ui/icons/Warning';
 * 
 *   // ...
 *
 *   <Button
 *     color="warning"
 *     icon={<WarningIcon />}
 *   >
 *     Warning!
 *   </Button>
 */
export const Button = forwardRef<HTMLButtonElement, IButtonProps>(function Button(props, ref) {
  const {
    className,
    margins,
    color,
    icon,
    pending,
    pendingAnimation,
    to,
    onClick,
    children,
    ...restProps
  } = props;
  const navigate = useNavigate();

  const handleClick = useCallback((event: MouseEvent<HTMLButtonElement>) => {
    if (to)
      navigate(to);
    if (onClick)
      onClick(event);
  }, [navigate, onClick, to]);

  const classes = useMemo(() => {
    let classes = 'ui-button';
    classes += ` ui-button--color-${color}`;
    if (margins)
      classes += ' ui-button--margins';
    if (pending)
      classes += ' ui-button--pending';
    if (className)
      classes += ` ${className}`;
    return classes;
  }, [className, color, margins, pending]);

  const fadeIn = !(pendingAnimation === 'crossfade' && pending);

  return (
    <MuiButton
      {...restProps}
      ref={ref}
      className={classes}
      variant="contained"
      disableElevation
      startIcon={icon && (
        <Fade in={fadeIn}>
          {icon}
        </Fade>
      )}
      onClick={handleClick}
    >
      <Fade in={fadeIn}>
        <span className="ui-button__label">
          {children}
        </span>
      </Fade>
      <Preloader
        display={pendingAnimation === 'crossfade' ? 'container' : 'inline'}
        visible={pending}
        size="small"
      />
    </MuiButton>
  );
});

Button.defaultProps = {
  color: 'primary',
  pendingAnimation: 'crossfade'
};
