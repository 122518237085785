import {
  ISnackbarShowAction,
  ISnackbarShowPayload,
  ISnackbarHideAction,
  ISnackbarHidePayload,
  SnackbarActionTypes
} from './snackbar.types';

/** Store opened snackbar identification */
export const snackbarShow = (payload: ISnackbarShowPayload): ISnackbarShowAction => ({
  type: SnackbarActionTypes.show,
  payload
});

/** Remove closed snackbar identification */
export const snackbarHide = (payload: ISnackbarHidePayload): ISnackbarHideAction => ({
  type: SnackbarActionTypes.hide,
  payload
});
