import {Reducer} from 'redux';
import {IAuthState} from '../auth.types';
import {initialAuthState} from '../auth';
import {TokenAction, TokenActionTypes} from 'actions';

/** Access token */
export const token: Reducer<IAuthState, TokenAction> = (state = initialAuthState, action): IAuthState => {
  switch (action.type) {
    case TokenActionTypes.request:
      return {
        ...state,
        tokenPending: true,
        tokenError: null,
        refreshError: null
      };
    case TokenActionTypes.success:
      return {
        ...state,
        tokenPending: false,
        accessToken: action.payload.accessToken,
        tokenType: action.payload.tokenType,
        refreshToken: action.payload.refreshToken,
        expiresAt: action.payload.expiresAt
      };
    case TokenActionTypes.failure:
      return {
        ...state,
        tokenPending: false,
        tokenError: action.payload,
        accessToken: null,
        tokenType: null,
        refreshToken: null,
        expiresAt: null
      };
    default:
      return state;
  }
};
