import {bankAccountPrefix} from 'lib/intl/parse/account-prefix';
import {bankAccountNumber} from 'lib/intl/parse/account-number';
import {bankAccountCode} from 'lib/intl/parse/account-code';

/**
 * Parse bank account
 *
 * @param bankAccount - Bank account string
 * @returns Object containing prefix, number and code
 */
export const bankAccount = (bankAccount: string): IBankAccount => {
  return {
    accountPrefix: bankAccountPrefix(bankAccount),
    accountNumber: bankAccountNumber(bankAccount),
    bankCode: bankAccountCode(bankAccount)
  };
};
