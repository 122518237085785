import './preloader.scss';
import React, {FunctionComponent, useMemo} from 'react';
import {IPreloaderProps} from './preloader.types';
import {Collapse} from 'components/ui/collapse';
import {Backdrop} from 'components/ui/backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

/**
 * Loading animation
 * 
 * @see src/views/app/kit-view/preloader-kit
 *
 * @example
 *
 *   <Preloader
 *     visible={pending}
 *     display="fullscreen"
 *   />
 */
export const Preloader: FunctionComponent<IPreloaderProps> = (props) => {
  const {visible, display, size, contrast} = props;

  const classes = useMemo(() => {
    let classes = 'ui-preloader';
    classes += ` ui-preloader--display-${display}`;
    classes += ` ui-preloader--size-${size}`;
    if (contrast)
      classes += ` ui-preloader--contrast-${contrast}`;
    return classes;
  }, [contrast, display, size]);

  if (display === 'inline')
    return (
      <Collapse
        className={classes}
        in={visible}
        orientation="horizontal"
      >
        <CircularProgress />
      </Collapse>
    );

  return (
    <Backdrop
      className={classes}
      invisible={display === 'container'}
      open={visible || false}
    >
      <CircularProgress />
    </Backdrop>
  );
};

Preloader.defaultProps = {
  display: 'container',
  size: 'default'
};
