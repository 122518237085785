import {all} from 'typed-redux-saga/macro';
import * as auth from './auth';
import * as user from './user';
import * as consumption from './consumption';
import * as demand from './demand';
import * as invoice from './invoice';
import * as payment from './payment';

export const sagas = function* () {
  yield all([
    auth.authToken(),
    auth.authRefresh(),
    auth.authRetry(),
    consumption.consumptionParameterList(),
    consumption.energyTypeList(),
    demand.demandCreation(),
    demand.demandPriorityList(),
    demand.demandStatusList(),
    demand.demandTypeList(),
    invoice.invoiceStatusList(),
    payment.paymentStatusList(),
    user.login(),
    user.passwordChange(),
    user.passwordReset(),
    user.passwordResetConfirm(),
    user.userActivation(),
    user.userDetail()
  ]);
};