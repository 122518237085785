import {IValidator, ValidatorQuery} from 'lib/global/validate/validate.types';
import memoize from 'fast-memoize';
import {t} from 'lib/intl/i18n';
import {date as parseDate} from 'lib/intl/parse/date';
import {date as formatDate} from 'lib/intl/format/date';
import {date as validateDate} from 'lib/global/validate/date';
import {logWarningOnDev} from 'lib/global/dev';

/**
 * Get minimum date validator
 *
 * @param minimumDate - Minimum date value
 */
export const minDate = memoize((minimumDate: AnyDate) => {
  const invalidMinDate = !parseDate(minimumDate).isValid();
  if (invalidMinDate)
    logWarningOnDev(t('lib.global.validate.date.invalid'));

  /**
   * Validate minimum date
   *
   * @param input - Date to validate
   * @returns Error message or undefined
   */
  const minDate: IValidator<AnyDate> = (input) => {
    if (!invalidMinDate && input) {
      const isValid = !validateDate(input);
      const isSameOrAfter = parseDate(input).isSameOrAfter(parseDate(minimumDate));
      if (isValid && !isSameOrAfter) {
        const date = formatDate(minimumDate);
        return t('lib.global.validate.date.min', {date});
      }
    }
  };

  return minDate;
});

/**
 * Check that the minimum date validator doesn't return any error
 *
 * @param input - Date to validate
 * @param minimumDate - Minimum date value
 */
export const isMinDate: ValidatorQuery = (input, minimumDate: AnyDate) => {
  return !!input && !minDate(minimumDate)(input);
};
