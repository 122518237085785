import './tree-view-kit.scss';
import React, {FunctionComponent} from 'react';
import {useTranslation} from 'lib/intl/i18n';
import {Typography} from 'components/ui/typography';
import {TreeView} from 'components/ui/tree-view';
import {Branch} from './branch';
import OpenIcon from '@material-ui/icons/Folder';
import CloseIcon from '@material-ui/icons/FolderOpen';
import ItemIcon from '@material-ui/icons/InsertDriveFileOutlined';

/** Tree control preview */
export const TreeViewKit: FunctionComponent = () => {
  const {t} = useTranslation();

  return (
    <div className="ui-tree-view-kit">
      <Typography
        variant="h2"
        gutterBottom
      >
        {t('views.app.ui_kit.tree.heading')}
      </Typography>

      {/* Default icons */}

      <Typography
        variant="h3"
        gutterBottom
      >
        {t('views.app.ui_kit.tree.type.default')}
      </Typography>
      <TreeView>
        <Branch id="1" />
        <Branch id="2" />
        <Branch id="3" />
      </TreeView>
      <br /><br />

      {/* Custom icons */}

      <Typography
        variant="h3"
        gutterBottom
      >
        {t('views.app.ui_kit.tree.type.custom')}
      </Typography>
      <TreeView
        defaultExpandIcon={<OpenIcon />}
        defaultCollapseIcon={<CloseIcon />}
        defaultEndIcon={<ItemIcon />}
      >
        <Branch id="1" />
        <Branch id="2" />
        <Branch id="3" />
      </TreeView>
    </div>
  );
};
