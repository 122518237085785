import React, {FunctionComponent, useCallback} from 'react';
import {BrowserRouter, Routes} from 'react-router-dom';
import {BASE_URL} from 'config/api';
import {Route} from './route';
import {paths, useRoutes} from 'config/router';
import {App} from 'components/main/app';
import {RouteArray} from 'config/router';
import uniqid from 'uniqid';
import memoize from 'fast-memoize';

/**
 * Application routing
 * 
 * @see config/router/use-routes
 */
export const Router: FunctionComponent = () => {
  const routes = useRoutes();

  // eslint-disable-next-line
  const getRouteKey = useCallback(memoize((routeProps) => {
    return uniqid();
  }), []);
  
  const renderRoutes = useCallback((routes: RouteArray) => {
    const activeRoutes = routes.filter(({active}) => active !== false);
    return activeRoutes.map(({children, element, ...routeProps}) => (
      <Route
        key={getRouteKey(routeProps)}
        {...routeProps}
        element={element}
      >
        {children && renderRoutes(children)}
      </Route>
    ));
  }, [getRouteKey]);

  return (
    <BrowserRouter>
      <Routes basename={BASE_URL}>
        <Route
          path={paths.home}
          element={<App />}
        >
          {renderRoutes(routes)}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};
