import './snackbar-provider.scss';
import React, {FunctionComponent,  useMemo} from 'react';
import {SnackbarProvider as NsSnackbarProvider, SnackbarProviderProps} from 'notistack';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import {actions} from 'actions';
import {useDispatch} from 'react-redux';

/**
 * Snackbar alert provider
 * 
 * @see src/views/app/kit-view/snackbar-kit
 *
 * @example
 *
 *   <SnackbarProvider>
 *     <App>
 *       ...
 *     </App>
 *   </SnackbarProvider>
 *
 * @see ./use-snackbar
 */
export const SnackbarProvider: FunctionComponent = (props) => {
  const {children} = props;
  const dispatch = useDispatch();

  const providerProps = useMemo<Omit<SnackbarProviderProps, 'children'>>(() => ({
    anchorOrigin: {
      horizontal: 'center',
      vertical: 'top'
    },
    maxSnack: 2,
    preventDuplicate: true,
    iconVariant: {
      info: (<InfoIcon />),
      success: (<SuccessIcon />),
      warning: (<WarningIcon />),
      error: (<ErrorIcon />)
    },
    classes: {
      containerRoot: 'ui-snackbar__container',
      root: 'ui-snackbar__root mui-fixed',
      variantInfo: 'ui-snackbar ui-snackbar--info',
      variantSuccess: 'ui-snackbar ui-snackbar--success',
      variantWarning: 'ui-snackbar ui-snackbar--warning',
      variantError: 'ui-snackbar ui-snackbar--error'
    },
    autoHideDuration: 5000,
    onClose: (event, reason, key) => {
      if (reason === 'timeout' || reason === 'maxsnack')
        dispatch(actions.snackbarHide({key}));
    }
  }), [dispatch]);

  return (
    <NsSnackbarProvider {...providerProps}>
      {children}
    </NsSnackbarProvider>
  );
};
